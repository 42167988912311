import React, {Component} from "react";
import ThermalTherapyEnum from "../../treatment/enums/treatmentrecieved/ThermalTherapyEnum";
import TherapeuticExerciseEnum from "../../treatment/enums/treatmentrecieved/TherapeuticExerciseEnum";
import ProceduresEnum from "../../treatment/enums/treatmentrecieved/ProceduresEnum";
import ManipulationEnum from "../../treatment/enums/treatmentrecieved/ManipulationEnum";
import MobilizationEnum from "../../treatment/enums/treatmentrecieved/MobilizationEnum";
import ReportSection from "../ReportSection";
import ReportViewUtils from "./ReportViewUtils";

export default class TreatmentReceivedReportView extends Component {

    render() {
        if (ReportViewUtils.isTreatmentInfoNull(this.props.treatmentInfo)) {
            return null;
        }

        const tables = [
            this.treatmentInfoTypeATable("Manipulation", "manipulation", ManipulationEnum),
            this.treatmentInfoTypeATable("Mobilization", "mobilization", MobilizationEnum),
            this.treatmentInfoTypeBTable("Stretching", "stretching"),
            this.treatmentInfoTypeBTable("Dry Needling", "dryNeedling"),
            this.treatmentInfoTypeBTable("Acupuncture", "acupuncture"),
            this.treatmentInfoTypeBTable("Electrotherapy", "electrotherapy"),
            this.treatmentInfoTypeBTable("Shockwave", "shockwave"),
            this.treatmentInfoTypeBTable("Cupping", "cupping"),
            this.treatmentInfoTypeBTable("Taping", "taping"),
            this.treatmentInfoTypeBTable("Therapeutic Massage", "therapeuticMassage"),
            this.treatmentInfoTypeBTable("Lymphatic Drainage", "lymphaticDrainage"),
            this.treatmentInfoTypeATable("Thermal Therapy", "thermalTherapy", ThermalTherapyEnum),
            this.treatmentInfoTypeCTable("Therapeutic Exercise", "therapeuticExercise", TherapeuticExerciseEnum),
            this.treatmentInfoTypeBTable("Gait Training", "gaitTraining"),
            this.treatmentInfoTypeBTable("Breathing Patterns", "breathingPatterns"),
            this.treatmentInfoTypeATable("Procedures", "procedures", ProceduresEnum),
            this.treatmentInfoTypeBTable("Other", "other")
        ].filter(table => !!table);

        return (
            <div className="container" style={{maxWidth: '100%', marginLeft: '-5px'}}>
                <div className="row">
                    {
                        tables.map((table, index) => {
                                return <>
                                    <div className={"col-12"} style={{padding: "0px"}}>
                                        {table}
                                    </div>
                                </>;
                            }
                        )
                    }
                </div>
            </div>
        )
    }

    treatmentInfoTypeATable = (title, field, dropdownEnum) => {
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length == 0) {
            return null;
        }

        return <ReportSection header1={title}>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Type</th>
                    <th>Area of Focus</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{dropdownEnum[item.type] ? dropdownEnum[item.type].name : item.type}</td>
                                    <td>{item.areaOfFocus}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
        </ReportSection>
    }

    treatmentInfoTypeBTable = (title, field) => {
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length == 0) {
            return null;
        }

        return <ReportSection header1={title}>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Area of Focus</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{item.areaOfFocus}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
        </ReportSection>
    }

    treatmentInfoTypeCTable = (title, field, dropdownEnum) => {
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length == 0) {
            return null;
        }

        return <ReportSection header1={title}>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Focus</th>
                    <th>Exercise</th>
                    <th>Weight</th>
                    <th>Sets</th>
                    <th>Reps</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{dropdownEnum[item.focus]?.name}</td>
                                    <td>{item.exercise}</td>
                                    <td>{item.weight}</td>
                                    <td>{item.sets}</td>
                                    <td>{item.reps}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
        </ReportSection>
    }

}
