import React, {Component} from 'react';
import {TextInput} from '../index';
import {FormBase, FormField, FormSection} from '../form';

export default class ExampleValidationTypes extends Component {

    render() {
        return (
            <div>
                <h1>Validation Types Example</h1>
                <FormBase>
                    <FormSection sectionId={5} label="required">
                        <FormField required validateOn={this.state.requiredExample}>
                            <TextInput value={this.state.requiredExample} onChange={(newValue) => this.setState({requiredExample: newValue})} />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={5} label="length">
                        <FormField minLength={5} validateOn={this.state.lengthExample}>
                            <TextInput value={this.state.lengthExample} onChange={(newValue) => this.setState({lengthExample: newValue})} />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={6} label="email">
                        <FormField email validateOn={this.state.emailExample}>
                            <TextInput value={this.state.emailExample} onChange={(newValue) => this.setState({emailExample: newValue})} />
                        </FormField>
                    </FormSection>
                </FormBase>
            </div>
        )
    }
}
