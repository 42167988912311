import React from 'react';
import Logo from './Logo';
import Menu from './Menu';
import UserInfo from '../modules/user/UserInfo';
import {connect} from "react-redux";

class Header extends React.Component {

    componentDidMount() {
        this.appInit();
    }

    appInit = () => {
        // can retrieve and cache application wide data that does not require login here
    };

    render() {
        const user = this.props.user && this.props.user.token;
        return (
            <>
            <div className="Header">
                <Logo/>
                <div  className={"d-none d-xl-flex col"}>
                  {user && <Menu/>}
                </div>
                {user && <UserInfo/>}
            </div>
                <div className="d-xl-none d-flex">
                      {user && <Menu/>}
                </div>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(Header);
