import React, {Component} from "react";
import Tooltip from "../../../../components/tooltip/Tooltip";
import StringUtil from "../../../../utils/StringUtil";

export default class AllergyInfo extends Component {

    render() {
        if (this.props.allergy == null) {
            return null;
        }
        if (this.props.allergy.none) {
            return (
                <>
                    <div className={"font-weight-bold mb-1"}>Alerts:</div>
                    <div style={{display: "inline-block", fontWeight: "bold"}}>
                        Allergy: <span style={{paddingLeft: 5, color: "#7BAC16"}}>None Known</span>
                    </div>
                </>
            )
        }

        let allergyDivs = [];
        this.addDivs(allergyDivs, "Food", this.props.allergy.food);
        this.addDivs(allergyDivs, "Pet", this.props.allergy.pet);
        this.addDivs(allergyDivs, "Seasonal", this.props.allergy.seasonal);
        this.addDivs(allergyDivs, "Environmental", this.props.allergy.environmental);
        this.addDivs(allergyDivs, "Other", this.props.allergy.other);
        this.addDivs(allergyDivs, "Medication", this.props.allergy.ingredients);

        if (allergyDivs.length == 0) {
            return null;
        }

        return (
            <>
                <div className={"font-weight-bold mb-1"}>Alerts:</div>
                <div style={{display: "inline-block", color: "red", fontWeight: "bold"}}>
                    Allergy Alert
                </div>
                {allergyDivs}
           </>

        );
    }

    addDivs(allergyDivs, title, list) {
        if (list == null || list.length === 0) {
            return;
        }
        allergyDivs.push(
            <div key={title} className="d-flex">
                <div className="font-weight-bold d-inline-block text-nowrap" style={{minWidth: 110, color: "#B6B6B6"}}>
                    {`${title} : `}
                </div>
                <div className={"d-flex flex-column"}>
                    {
                        list.map(item =>
                            <Tooltip key={item} placement="bottom" title={item}>
                                <div>{StringUtil.shorten(item, 20)}</div>
                            </Tooltip>
                        )
                    }
                </div>
            </div>
        );
    }
}