import "./MedicalReports.css"
import "./ReportHeader.css"
import React, {Component} from "react";
import DateUtil from "../../../../utils/DateUtil";
import StringUtil from "../../../../utils/StringUtil";
import PatientUtil from "../../../../utils/PatientUtil";
import EnumGender from "../../../../enums/EnumGender";

export default class ReportHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            insuranceData: {}
        };

        this.getContents = this.getContents.bind(this);
        this.fillInsuranceData = this.fillInsuranceData.bind(this);
    }

    componentDidMount() {
        this.setState({insuranceData: this.fillInsuranceData()});
    }


    render() {
        const emr = this.props.emr;
        const patient = emr.patient;
        const staff = this.props.staff;

        let contents = this.getContents(patient,staff);

        return (
            <div className={"report-header-content-wrapper"}>
                {contents}
            </div>
        );
    }

    getContents(patient, staff) {
        const visitMetadata = this.props.visit.metaData;

        if (visitMetadata !== null) {
            return (
                <div className={"row"}>
                    <div className={"col-2"}></div>
                    <div className={"col-3"}>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> Patient Name </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName)}
                            </div>
                        </div>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> Gender </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {patient.gender ? EnumGender[patient.gender] : "-"}
                            </div>
                        </div>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> DOB/Age </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {patient.birthday ? DateUtil.formatDateMMM(patient.birthday) : "-"}
                                {" /"}
                                {patient.birthday ? DateUtil.getAge(patient.birthday) : "-"}
                            </div>
                        </div>
                    </div>
                    <div className={"col-3"}>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> ID No. </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {patient.eid ? PatientUtil.getPatientId(patient) : "-"}
                            </div>
                        </div>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> Nationality </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {patient.nationality ? patient.nationality.nameInEnglish : "-"}
                            </div>
                        </div>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> Provider </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {staff ? staff.name : "-"}
                            </div>
                        </div>
                    </div>
                    <div className={"col-3"}>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> MRN </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {StringUtil.mrn(patient.mrn)}
                            </div>
                        </div>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> Phone </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {patient.mobilePhone ? patient.mobilePhone : "-"}
                            </div>
                        </div>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> Email </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {patient.email ? patient.email : "-"}
                            </div>
                        </div>
                        <div className={"row report-header-row"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}> Address </div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                <b>:</b>
                                {" "}
                                {patient.address ? patient.address : "-"}
                            </div>
                        </div>
                    </div>
                    <div className={"col-1"}></div>
                </div>
            )
        }
    }

    fillInsuranceData () {
        let insuranceData = {
            insurance: null,
            policyNo: null,
            policyName: null,
            insuranceApprovalCode: null
        };
        const insurance = this.props.emr.patient.insurance;

        if (insurance) {
            insuranceData.insurance = insurance.tpa ? insurance.tpa.name : null;
            insuranceData.policyNo = insurance.number ? insurance.number : null;
            insuranceData.policyName = insurance.network ? insurance.network.name : null;
            insuranceData.insuranceApprovalCode = insurance.memberId ? insurance.memberId : null; // TODO: Not sure about this, can be changed.
        }

        return insuranceData;
    }

}
