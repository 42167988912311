import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";
import RelationshipEnum from "../enums/history/RelationshipEnum";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import BorderedRadioInput from "../../../../../components/radio-input/BorderedRadioInput";
import DiagnosisEnum from "../enums/history/DiagnosisEnum";
import DateUtil from "../../../../../utils/DateUtil";
import VaccinationHistoryEnum from "../enums/history/VaccinationHistoryEnum";
import YesNoNotAvailableEnum from "../../../../genericenums/YesNoNotAvailableEnum";
import YesNoEnum from "../../../../genericenums/YesNoEnum";
import BirthTypeEnum from "../enums/history/BirthTypeEnum";
import BooleanCheckboxInput from "../../../../../components/checkbox-input/BooleanCheckboxInput";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import {FamilyHistoryChecker} from "../checkers/FamilyHistoryChecker";
import {MedicalHistoryChecker} from "../checkers/MedicalHistoryChecker";
import {SurgicalHistoryChecker} from "../checkers/SurgicalHistoryChecker";
import StringUtil from "../../../../../utils/StringUtil";
import CurrentPastEnum from "../enums/history/CurrentPastEnum";
import {DateInput} from "../../../../../components";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import MaterialRadioButton from "../../../../../components/radio-input/MaterialRadioButton";
import PrimeInputNumber from "../../../../../components/number-input/PrimeInputNumber";


export default class HistoryModal extends Component {

    state = {}

    render() {
        let familyHistoryDivs = this.getFamilyHistoryDivs();
        let medicalHistoryDivs = this.getMedicalHistoryDivs();
        let surgicalHistoryDivs = this.getSurgicalHistoryDivs();
        let birthHistoryDivs = this.getBirthHistoryDivs();

        let familyToggleOn = FamilyHistoryChecker.isToggleOn(this.props.subjective.familyHistory);
        let familyIsNone = FamilyHistoryChecker.isNone(this.props.subjective.familyHistory);
        let medicalToggleOn = MedicalHistoryChecker.isToggleOn(this.props.subjective.medicalHistory);
        let medicalIsNone = MedicalHistoryChecker.isNone(this.props.subjective.medicalHistory);
        let surgicalToggleOn = SurgicalHistoryChecker.isToggleOn(this.props.subjective.surgicalHistory);
        let surgicalIsNone = SurgicalHistoryChecker.isNone(this.props.subjective.surgicalHistory);

        let isPregnancyNA = this.props.subjective.birthHistory
            && (YesNoNotAvailableEnum[this.props.subjective.birthHistory.currentlyPregnant] === YesNoNotAvailableEnum.NA);

        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div className={"col-6 no-margin"} style={{padding: "5px 20px"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>
                                <span>Family History <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>
                            </div>
                            <GreenSwitchWithMessage
                                message={familyIsNone ? "No Family History" : ""}
                                checked={familyToggleOn}
                                onChange={() => {
                                    if (familyToggleOn) {
                                        let id = this.props.subjective.familyHistory.id;
                                        this.props.subjective.familyHistory = {id: id, none: false, items: []};
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div style={{display: "flex", marginLeft: "5px"}}>
                            <div>
                                <CheckboxInput value={familyIsNone}
                                               onChange={(value) => {
                                                   if (this.props.subjective.familyHistory == null) {
                                                       this.props.subjective.familyHistory = {items: []};
                                                   }
                                                   if (value) {
                                                       let id = this.props.subjective.familyHistory.id;
                                                       this.props.subjective.familyHistory = {id: id, items: []};
                                                   }
                                                   this.props.subjective.familyHistory.none = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>No Family History
                            </div>
                        </div>

                        <br/>

                        <div className={familyIsNone ? "not-allowed" : ""}>
                            <div className={familyIsNone ? "disable-pointer" : ""}>
                                <div className={"row no-margin"} style={{width: "100%"}}>
                                    <div className={"col-6"}>
                                        <div style={{
                                            fontWeight: "bold",
                                            marginBottom: "0.2rem"
                                        }}>Condition
                                        </div>
                                        <TextInput value={this.state.familyHistoryCondition || ''}
                                                   style={{width: '-webkit-fill-available'}}
                                                   onChange={value => this.setState(state => {
                                                       state.familyHistoryCondition = value;
                                                       return state;
                                                   })
                                                   }
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                    <div className={"col-6"}>
                                        <div style={{
                                            fontWeight: "bold",
                                            marginBottom: "0.2rem"
                                        }}>Relationship
                                        </div>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Relationship"}
                                            value={this.state.familyHistoryRelationship != null ?
                                                {
                                                    value: RelationshipEnum[this.state.familyHistoryRelationship].key,
                                                    label: RelationshipEnum[this.state.familyHistoryRelationship].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState((state) => {
                                                    state.familyHistoryRelationship = r;
                                                    return state;
                                                });
                                            }}
                                            options={RelationshipEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={"row m-0 w-100"}>
                                    <div className={"col-11"}>
                                        <TextInput value={this.state.familyHistoryDetail || ''}
                                                   placeholder={"Details"}
                                                   onChange={value => this.setState({familyHistoryDetail: value})}
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                    <div className={"col-1 d-flex flex-row-reverse"}>
                                        <Button className={"emr-plus-button"}
                                                variant="success"
                                                disabled={!(this.state.familyHistoryRelationship != null &&
                                                    (this.state.familyHistoryCondition != null && this.state.familyHistoryCondition != ""))}
                                                style={{fontSize:"12px"}}
                                                onClick={this.addFamilyHistory.bind(this)}>
                                            <FontAwesomeIcon icon={["fas", "plus"]}/>
                                        </Button>
                                    </div>
                                </div>
                                <br/>
                                <div className={"row no-margin"} style={{width: "100%", marginTop: "10px !important"}}>
                                    <div className={"div-list-wrapper"}>
                                        {familyHistoryDivs}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "30px"}}>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>
                                <span>Medical History <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>
                            </div>
                            <GreenSwitchWithMessage
                                message={medicalIsNone ? "No Medical History" : ""}
                                checked={medicalToggleOn}
                                onChange={() => {
                                    if (medicalToggleOn) {
                                        let id = this.props.subjective.medicalHistory.id;
                                        let vaccinationHistory = this.props.subjective.medicalHistory.vaccinationHistory;
                                        let vaccinationHistoryDetail = this.props.subjective.medicalHistory.vaccinationHistoryDetail;
                                        this.props.subjective.medicalHistory = {
                                            id: id,
                                            none: false,
                                            items: [],
                                            vaccinationHistory: vaccinationHistory,
                                            vaccinationHistoryDetail: vaccinationHistoryDetail
                                        };
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div style={{display: "flex", marginLeft: "5px"}}>
                            <div>
                                <CheckboxInput value={medicalIsNone}
                                               onChange={(value) => {
                                                   if (this.props.subjective.medicalHistory == null) {
                                                       this.props.subjective.medicalHistory = {items: []};
                                                   }
                                                   if (value) {
                                                       let id = this.props.subjective.medicalHistory.id;
                                                       let vaccinationHistory = this.props.subjective.medicalHistory.vaccinationHistory;
                                                       let vaccinationHistoryDetail = this.props.subjective.medicalHistory.vaccinationHistoryDetail;
                                                       this.props.subjective.medicalHistory = {
                                                           id: id,
                                                           items: [],
                                                           vaccinationHistory: vaccinationHistory,
                                                           vaccinationHistoryDetail: vaccinationHistoryDetail
                                                       };
                                                   }
                                                   this.props.subjective.medicalHistory.none = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>No Medical History
                            </div>
                        </div>

                        <br/>

                        <div className={medicalIsNone ? "not-allowed" : ""}>
                            <div className={medicalIsNone ? "disable-pointer" : ""}>
                                <div>
                                    <MaterialRadioButton name="currentPast"
                                                         value={this.state.currentPast}
                                                         onChange={(value) => {
                                                             this.setState({currentPast: value});
                                                         }}>
                                        <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                          value="CURRENT" control={<Radio/>}
                                                          label="Current"

                                        />
                                        <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                          value="PAST" control={<Radio/>}
                                                          label="Past"

                                        />
                                    </MaterialRadioButton>
                                </div>
                                <div style={{fontWeight: "bold", marginBottom: "0.2rem"}}>Diagnosis
                                </div>
                                <BorderedRadioInput
                                    values={DiagnosisEnum.toArray()}
                                    selected={this.state.stableActive}
                                    onSelect={(value) => {
                                        this.setState({stableActive: value});
                                    }}
                                />

                                <div className={"row m-0 w-100"}>
                                    <div className={"col-9"}>
                                        <TextInput value={this.state.medicalHistoryDiagnosis || ''}
                                                   placeholder={"Diagnosis"}
                                                   onChange={value => this.setState({medicalHistoryDiagnosis: value})}
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                    <div className={"col-3"}>
                                        <DateInput id={"medical-history-date"}
                                                   className={"treatment-schedule-date"}
                                                   variant="dialog"
                                                   emptyLabel="Date..."
                                                   disableFuture={true}
                                                   value={this.state.medicalHistoryDate ? this.state.medicalHistoryDate : null}
                                                   onChange={date => {
                                                       this.setState({medicalHistoryDate: date});
                                                   }}
                                        />
                                    </div>
                                </div>

                                <div className={"row m-0 w-100"}>
                                    <div className={"col-11"}>
                                        <TextInput value={this.state.medicalHistoryDetail || ''}
                                                   placeholder={"Details"}
                                                   onChange={value => this.setState({medicalHistoryDetail: value})}
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                    <div className={"col-1"}
                                         style={{flexFlow: "row-reverse", display: "flex"}}>
                                        <Button className={"emr-plus-button"}
                                                variant="success"
                                                disabled={this.state.currentPast == null
                                                || StringUtil.isNullOrEmpty(this.state.medicalHistoryDiagnosis)
                                                }
                                                style={{fontSize:"12px"}}
                                                onClick={this.addMedicalHistory.bind(this)}>
                                            <FontAwesomeIcon icon={["fas", "plus"]}/>
                                        </Button>
                                    </div>
                                </div>

                                <br/>
                                <div className={"row no-margin"} style={{width: "100%", marginTop: "10px !important"}}>
                                    <div className={"div-list-wrapper"}>
                                        {medicalHistoryDivs}
                                    </div>
                                </div>

                                <br/>
                            </div>
                        </div>

                        <div>
                            <div style={{fontWeight: "bold", marginBottom: "0.2rem"}}>Vaccination
                                History
                            </div>
                            <BorderedRadioInput
                                values={VaccinationHistoryEnum.toArray()}
                                selected={this.props.subjective.medicalHistory != null ?
                                    this.props.subjective.medicalHistory.vaccinationHistory : null}
                                onSelect={(value) => {
                                    this.createMedicalHistoryIfNull();
                                    this.props.subjective.medicalHistory.vaccinationHistory = value;
                                    this.forceUpdate();
                                }}
                            />

                            <div className={"row m-0 w-100"}>
                                <div className={"col-12"}>
                                    <TextInput value={this.props.subjective.medicalHistory == null ? '' :
                                        this.props.subjective.medicalHistory.vaccinationHistoryDetail || ''}
                                               placeholder={"Details"}
                                               onChange={value => {
                                                   this.createMedicalHistoryIfNull();
                                                   this.props.subjective.medicalHistory.vaccinationHistoryDetail = value;
                                                   this.forceUpdate();
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-6 no-margin"} style={{padding: "5px 20px"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>
                                <span>Surgical History <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>
                            </div>
                            <GreenSwitchWithMessage
                                message={surgicalIsNone ? "No Surgical History" : ""}
                                checked={surgicalToggleOn}
                                onChange={() => {
                                    if (surgicalToggleOn) {
                                        let id = this.props.subjective.surgicalHistory.id;
                                        this.props.subjective.surgicalHistory = {id: id, none: false, items: []};
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div style={{display: "flex", marginLeft: "5px"}}>
                            <div>
                                <CheckboxInput value={surgicalIsNone}
                                               onChange={(value) => {
                                                   if (this.props.subjective.surgicalHistory == null) {
                                                       this.props.subjective.surgicalHistory = {items: []};
                                                   }
                                                   if (value) {
                                                       let id = this.props.subjective.surgicalHistory.id;
                                                       this.props.subjective.surgicalHistory = {id: id, items: []};
                                                   }
                                                   this.props.subjective.surgicalHistory.none = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>No Surgical
                                History
                            </div>
                        </div>

                        <br/>

                        <div className={surgicalIsNone ? "not-allowed" : ""}>
                            <div className={surgicalIsNone ? "disable-pointer" : ""}>
                                <div className={"row no-margin"} style={{width: "100%"}}>
                                    <div className={"col-4"}>
                                        <div style={{
                                            fontWeight: "bold",
                                            marginBottom: "0.2rem"
                                        }}>Conditions
                                        </div>
                                        <TextInput value={this.state.surgicalHistoryCondition || ''}
                                                   style={{width: '-webkit-fill-available'}}
                                                   onChange={value => this.setState(state => {
                                                       state.surgicalHistoryCondition = value;
                                                       return state;
                                                   })
                                                   }
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                    <div className={"col-5"}>
                                        <div style={{
                                            fontWeight: "bold",
                                            marginBottom: "0.2rem"
                                        }}>Procedure
                                        </div>
                                        <TextInput value={this.state.surgicalHistoryProcedure || ''}
                                                   style={{width: '-webkit-fill-available'}}
                                                   onChange={value => this.setState(state => {
                                                       state.surgicalHistoryProcedure = value;
                                                       return state;
                                                   })
                                                   }
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>

                                    <div className={"col-3"}>
                                        <div
                                            style={{fontWeight: "bold", marginBottom: "0.2rem"}}>Date
                                        </div>

                                        <DateInput id={"medical-history-date"}
                                                   className={"treatment-schedule-date"}
                                                   variant="dialog"
                                                   emptyLabel="Date..."
                                                   disableFuture={true}
                                                   value={this.state.surgicalHistoryDate ? this.state.surgicalHistoryDate : null}
                                                   onChange={date => {
                                                       this.setState({surgicalHistoryDate: date});
                                                   }}
                                        />
                                    </div>
                                </div>

                                <div className={"row no-margin"} style={{width: "100%"}}>
                                    <div className={"col-11"}>
                                        <TextInput value={this.state.surgicalHistoryDetail || ''}
                                                   placeholder={"Details"}
                                                   onChange={value => this.setState({surgicalHistoryDetail: value})}
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                    <div className={"col-1"}
                                         style={{flexFlow: "row-reverse", display: "flex"}}>
                                        <Button className={"emr-plus-button"}
                                                variant="success"
                                                disabled={
                                                    StringUtil.isNullOrEmpty(this.state.surgicalHistoryCondition) &&
                                                    StringUtil.isNullOrEmpty(this.state.surgicalHistoryProcedure) &&
                                                    StringUtil.isNullOrEmpty(this.state.surgicalHistoryDate) &&
                                                    StringUtil.isNullOrEmpty(this.state.surgicalHistoryDetail)
                                                }
                                                style={{fontSize:"12px"}}
                                                onClick={this.addSurgicalHistory.bind(this)}>
                                            <FontAwesomeIcon icon={["fas", "plus"]}/>
                                        </Button>
                                    </div>
                                </div>

                                <br/>
                                <div className={"row no-margin"} style={{width: "100%", marginTop: "10px !important"}}>
                                    <div className={"div-list-wrapper"}>
                                        {surgicalHistoryDivs}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "30px"}}>
                        <div style={{display: "flex", width: "100%"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Birth History
                            </div>
                        </div>

                        <br/>
                        <div>
                            <div className={"p-fluid p-grid p-formgrid children-number-input"}>
                                <div className={"p-field p-col-2 p-md-2"}
                                     style={{marginTop: "auto", marginBottom: "auto", flex: "0 0 12%"}}>
                                    <div style={{fontWeight: "bold"}}>Children</div>
                                </div>
                                <div className={"col-3"}>
                                    <PrimeInputNumber value={this.props.subjective.birthHistory == null ? null :
                                        (this.props.subjective.birthHistory.children || null)}
                                                      placeholder={"Children"}
                                                      inputStyle={{height: "25px", fontSize:"14px"}}
                                                      onChange={value => {
                                                          this.createBirthHistoryIfNull();
                                                          this.props.subjective.birthHistory.children = value;
                                                          this.forceUpdate();
                                                      }}
                                                      allowNegativeNumber={false}
                                                      allowOnlyIntegers={true}/>
                                </div>
                            </div>
                        </div>

                        <br/>

                        <div>
                            <div className={"row no-margin"} style={{width: "100%"}}>
                                <div className={"col-3 no-padding"}
                                     style={{marginTop: "auto", marginBottom: "auto"}}>
                                    <div style={{fontWeight: "bold"}}>Currently Pregnant</div>
                                </div>
                                <div className={"col-6 no-padding"}>
                                    <BorderedRadioInput
                                        values={YesNoNotAvailableEnum.toArray()}
                                        selected={this.props.subjective.birthHistory != null ?
                                            this.props.subjective.birthHistory.currentlyPregnant : null}
                                        onSelect={(value) => {
                                            this.createBirthHistoryIfNull();
                                            this.props.subjective.birthHistory.currentlyPregnant = value;
                                            this.resetBirthHistoryIfNA(value);
                                            this.forceUpdate();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={isPregnancyNA ? "not-allowed" : ""}>
                            <div className={isPregnancyNA ? "disable-pointer" : ""}>
                                <div>
                                    <div className={"row m-0 w-100"}>
                                        <div className={"col-12"}>
                                            <TextInput value={this.props.subjective.birthHistory == null ? '' :
                                                (this.props.subjective.birthHistory.currentlyPregnantDetail || '')}
                                                       placeholder={"Details"}
                                                       onChange={value => {
                                                           this.createBirthHistoryIfNull();
                                                           this.props.subjective.birthHistory.currentlyPregnantDetail = value;
                                                           this.forceUpdate();
                                                       }}
                                                       style={{fontSize: "14px", width: "100%"}}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <br/>
                                <div className={"row no-margin"} style={{width: "100%"}}>
                                    <div className={"col-5"}>
                                        <div
                                            style={{fontWeight: "bold", arginBottom: "0.2rem"}}>Birth
                                            Type
                                        </div>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Birth Type"}
                                            value={this.state.birthHistoryType != null ?
                                                {
                                                    value: BirthTypeEnum[this.state.birthHistoryType].key,
                                                    label: BirthTypeEnum[this.state.birthHistoryType].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState((state) => {
                                                    state.birthHistoryType = r;
                                                    return state;
                                                });
                                            }}
                                            options={BirthTypeEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className={"col-6"}>
                                        <div style={{
                                            fontWeight: "bold",
                                            marginBottom: "0.2rem"
                                        }}>Details
                                        </div>
                                        <TextInput value={this.state.birthHistoryDetail || ''}
                                                   onChange={value => this.setState(state => {
                                                       state.birthHistoryDetail = value;
                                                       return state;
                                                   })
                                                   }
                                                   style={{fontSize: "14px", width: '-webkit-fill-available'}}
                                        />
                                    </div>
                                    <div className={"col-1"}
                                         style={{flexFlow: "row-reverse", display: "flex"}}>
                                        <Button className={"emr-plus-button"}
                                                variant="success"
                                                disabled={!(this.state.birthHistoryType != null)}
                                                style={{fontSize: "12px", marginTop: "17.6px"}}
                                                onClick={this.addBirthHistory.bind(this)}>
                                            <FontAwesomeIcon icon={["fas", "plus"]}/>
                                        </Button>
                                    </div>
                                </div>
                                <br/>
                                <div className={"row no-margin"} style={{width: "100%", marginTop: "10px !important"}}>
                                    <div className={"div-list-wrapper"}>
                                        {birthHistoryDivs}
                                    </div>
                                </div>

                                <br/>
                                <div>
                                    <div className={"row m-0 w-100"} >
                                        <div className={"col-3 no-padding"}
                                             style={{marginTop: "auto", marginBottom: "auto"}}>
                                            <div style={{fontWeight: "bold"}}>Birth Complications
                                            </div>
                                        </div>
                                        <div className={"col-3 no-padding"}>
                                            <BorderedRadioInput
                                                values={YesNoEnum.toArray()}
                                                selected={this.props.subjective.birthHistory != null ?
                                                    this.props.subjective.birthHistory.birthComplication : null}
                                                onSelect={(value) => {
                                                    this.createBirthHistoryIfNull();
                                                    this.props.subjective.birthHistory.birthComplication = value;
                                                    this.forceUpdate();
                                                }}
                                            />
                                        </div>
                                        <div className={"col-6 no-padding"} style={{margin: "auto"}}>
                                            <TextInput value={this.props.subjective.birthHistory == null ? '' :
                                                (this.props.subjective.birthHistory.birthComplicationDetail || '')}
                                                       placeholder={"Details"}
                                                       onChange={value => {
                                                           this.createBirthHistoryIfNull();
                                                           this.props.subjective.birthHistory.birthComplicationDetail = value;
                                                           this.forceUpdate();
                                                       }}
                                                       style={{fontSize: "14px", width: "100%"}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <div className={"row m-0 w-100"}>
                                        <div className={"col-2 p-0 pr-1"}
                                             style={{marginTop: "auto", marginBottom: "auto"}}>
                                            <BooleanCheckboxInput label={"Other"}
                                                                  value={this.props.subjective.birthHistory == null ? null :
                                                                      this.props.subjective.birthHistory.other}
                                                                  style={{width: "100%"}}
                                                                  onChange={(event) => {
                                                                      this.createBirthHistoryIfNull();
                                                                      this.props.subjective.birthHistory.other = event;
                                                                      this.forceUpdate();
                                                                  }}/>
                                        </div>
                                        <div className={"col-10 p-0 pl-2"} style={{margin: "auto"}}>
                                            <TextInput value={this.props.subjective.birthHistory == null ? '' :
                                                (this.props.subjective.birthHistory.otherDetail || '')}
                                                       placeholder={"Details"}
                                                       onChange={value => {
                                                           this.createBirthHistoryIfNull();
                                                           this.props.subjective.birthHistory.otherDetail = value;
                                                           this.forceUpdate();
                                                       }}
                                                       style={{fontSize: "14px", width: "100%"}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    createFamilyHistoryIfNull() {
        if (this.props.subjective.familyHistory == null) {
            this.props.subjective.familyHistory = {};
        }
        if (this.props.subjective.familyHistory.items == null) {
            this.props.subjective.familyHistory.items = [];
        }
        this.setState({
            familyHistorySwitch: true
        });
    }

    addFamilyHistory() {
        this.createFamilyHistoryIfNull();
        let familyHistory = this.props.subjective.familyHistory;
        familyHistory.items.push({
            condition: this.state.familyHistoryCondition,
            relationship: this.state.familyHistoryRelationship,
            detail: this.state.familyHistoryDetail
        });
        this.setState({
            familyHistoryCondition: null,
            familyHistoryRelationship: null,
            familyHistoryDetail: null
        });
    }

    getFamilyHistoryDivs() {
        if (this.props.subjective.familyHistory == null
            || this.props.subjective.familyHistory.items == null) {
            return;
        }
        let divs = [];
        this.props.subjective.familyHistory.items.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"familyHistoryItem" + index}>
                <div>
                    {item.condition}{item.relationship ? ", " + RelationshipEnum[item.relationship]?.name: ""}{item.detail == null ? "" : (", " + item.detail)}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.props.subjective.familyHistory.items.splice(index, 1);
                            if (this.props.subjective.familyHistory.items.length == 0) {
                                this.props.subjective.familyHistory.items = null;
                            }
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    createMedicalHistoryIfNull() {
        if (this.props.subjective.medicalHistory == null) {
            this.props.subjective.medicalHistory = {};
        }
        if (this.props.subjective.medicalHistory.diagnosisItems == null) {
            this.props.subjective.medicalHistory.diagnosisItems = [];
        }

        this.setState({
            medicalHistorySwitch: true
        });
    }

    addMedicalHistory() {
        this.createMedicalHistoryIfNull();
        let medicalHistory = this.props.subjective.medicalHistory;
        medicalHistory.diagnosisItems.push({
            currentPast: this.state.currentPast,
            stableActive: this.state.stableActive,
            diagnosis: this.state.medicalHistoryDiagnosis,
            date: this.state.medicalHistoryDate,
            detail: this.state.medicalHistoryDetail
        });
        this.setState({
            stableActive: null,
            medicalHistoryDiagnosis: null,
            medicalHistoryDate: null,
            medicalHistoryDetail: null
        });
    }

    getMedicalHistoryDivs() {
        if (this.props.subjective.medicalHistory == null
            || this.props.subjective.medicalHistory.diagnosisItems == null) {
            return;
        }
        let divs = [];
        this.props.subjective.medicalHistory.diagnosisItems.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"medicalHistoryItem" + index}>
                <div>
                    {[CurrentPastEnum[item.currentPast]?.name,
                        item.stableActive ? DiagnosisEnum[item.stableActive].name : null,
                        item.diagnosis,
                        item.date == null ? "" : (DateUtil.formatDate(item.date)),
                        item.detail == null ? "" : (item.detail)].filter(Boolean).join(", ")}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.props.subjective.medicalHistory.diagnosisItems.splice(index, 1);
                            if (this.props.subjective.medicalHistory.diagnosisItems.length == 0) {
                                this.props.subjective.medicalHistory.diagnosisItems = null;
                            }
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }


    createSurgicalHistoryIfNull() {
        if (this.props.subjective.surgicalHistory == null) {
            this.props.subjective.surgicalHistory = {};
        }
        if (this.props.subjective.surgicalHistory.items == null) {
            this.props.subjective.surgicalHistory.items = [];
        }
        this.setState({
            surgicalHistorySwitch: true
        });
    }

    addSurgicalHistory() {
        this.createSurgicalHistoryIfNull();
        let surgicalHistory = this.props.subjective.surgicalHistory;
        surgicalHistory.items.push({
            condition: this.state.surgicalHistoryCondition,
            procedure: this.state.surgicalHistoryProcedure,
            date: this.state.surgicalHistoryDate,
            detail: this.state.surgicalHistoryDetail
        });
        this.setState({
            surgicalHistoryCondition: null,
            surgicalHistoryProcedure: null,
            surgicalHistoryDate: null,
            surgicalHistoryDetail: null
        });
    }

    getSurgicalHistoryDivs() {
        if (this.props.subjective.surgicalHistory == null
            || this.props.subjective.surgicalHistory.items == null) {
            return;
        }
        let divs = [];
        this.props.subjective.surgicalHistory.items.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"surgicalHistoryItem" + index}>
                <div>
                    {
                        [
                            item.condition,
                            item.procedure,
                            item.date != null ? DateUtil.formatDate(item.date) : null,
                            item.detail
                        ].filter(Boolean).join(", ")
                    }
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.props.subjective.surgicalHistory.items.splice(index, 1);
                            if (this.props.subjective.surgicalHistory.items.length == 0) {
                                this.props.subjective.surgicalHistory.items = null;
                            }
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    createBirthHistoryIfNull() {
        if (this.props.subjective.birthHistory == null) {
            this.props.subjective.birthHistory = {};
        }
        if (this.props.subjective.birthHistory.items == null) {
            this.props.subjective.birthHistory.items = [];
        }
    }

    resetBirthHistoryIfNA(value) {
        if (YesNoNotAvailableEnum[value] === YesNoNotAvailableEnum.NA) {
            this.props.subjective.birthHistory.birthComplication = null
            this.props.subjective.birthHistory.birthComplicationDetail = null
            this.props.subjective.birthHistory.currentlyPregnantDetail = null
            this.props.subjective.birthHistory.other = null
            this.props.subjective.birthHistory.otherDetail = null
            this.props.subjective.birthHistory.items = []
        }
    }

    addBirthHistory() {
        this.createBirthHistoryIfNull();
        let birthHistory = this.props.subjective.birthHistory;
        birthHistory.items.push({
            birthType: this.state.birthHistoryType,
            detail: this.state.birthHistoryDetail
        });
        this.setState({
            birthHistoryType: null,
            birthHistoryDetail: null
        });
    }

    getBirthHistoryDivs() {
        if (this.props.subjective.birthHistory == null
            || this.props.subjective.birthHistory.items == null) {
            return;
        }
        let divs = [];
        this.props.subjective.birthHistory.items.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"birthHistoryItem" + index}>
                <div>
                    {BirthTypeEnum[item.birthType].name}{item.detail == null ? "" : (", " + item.detail)}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.props.subjective.birthHistory.items.splice(index, 1);
                            if (this.props.subjective.birthHistory.items.length == 0) {
                                this.props.subjective.birthHistory.items = null;
                            }
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>));
        return divs;
    }
}