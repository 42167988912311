import "../MedicalReports.css"
import React, {Component} from "react";
import ReportTemplate from "../ReportTemplate";
import ReportSection from "../ReportSection";
import ComplaintReportView from "../view/ComplaintReportView";
import DateUtil from "../../../../../utils/DateUtil";
import LabImagingReportView from "../view/LabImagingReportView";
import AllergiesReportView from "../view/AllergiesReportView";
import AssessmentReportView from "../view/AssessmentReportView";
import {isEmpty} from "../../../../../utils/ArrayUtil";
import ProcedureReportTreatmentView from "./ProcedureReportTreatmentView";
import ReportViewUtils from "../view/ReportViewUtils";
import PreviousTreatmentReceivedReportView from "../view/PreviousTreatmentReceivedReportView";
import SymptomPainReferralReportView from "../view/SymptomPainReferralReportView";
import DiagnosisReportView from "../view/DiagnosisReportView";
import ActivityLimitationReportView from "../view/ActivityLimitationReportView";

export default class ProcedureReportTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {visit: this.props.emr.visits[this.props.emr.visits.length - 1]}; // TODO remove
    }

    render() {
        const emr = this.props.emr;
        const firstVisit = emr.visits[0];
        const visit = this.state.visit;
        const subjective = visit.subjective;
        const assessment = visit.assessment;
        const diagnosis = visit.diagnosis;
        const treatment = visit.treatment;
        const procedures = isEmpty(treatment.treatmentReceived) || isEmpty(treatment.treatmentReceived.procedures) ? null : treatment.treatmentReceived.procedures;

        return (
            <ReportTemplate {...this.props} generateUrl={'api/medical-report/generate/procedure'}
                            onVisitSelected={(visit) => {
                                this.setState({visit})
                            }}>

                <ReportSection header1={"Complaint"}>
                    {!ReportViewUtils.isComplaintNull(subjective.complaint) && <ComplaintReportView complaint={subjective.complaint}/>}
                </ReportSection>

                <ReportSection header1={"Symptoms"} header2={"Date Of First Visit"}>
                    {!ReportViewUtils.isSymptomsPainReferralNull(subjective.complaint) && <SymptomPainReferralReportView complaint={subjective.complaint}/>}
                    {firstVisit.appointment.startTime != null && <div>{DateUtil.formatDateDoMMYY(firstVisit.appointment.startTime)}</div>}
                </ReportSection>

                <ReportSection header1={"Previous Treatment Received"} header2={"Labs/Imaging"}>
                    {!ReportViewUtils.isPreviousTreatmentReceivedNull(subjective.treatmentReceived) && <PreviousTreatmentReceivedReportView treatmentReceived={subjective.treatmentReceived}/>}
                    {!ReportViewUtils.isLabImagingNull(subjective.labsImaging) && <LabImagingReportView labsImaging={subjective.labsImaging}/>}
                </ReportSection>

                <ReportSection header1={"Activity Limitations"}>
                    {!ReportViewUtils.isActivityLimitationNull(subjective.activityLimitation) && <ActivityLimitationReportView activityLimitation={subjective.activityLimitation}/>}
                </ReportSection>

                <ReportSection header1={"Allergies"}>
                    {!ReportViewUtils.isAllergyNull(subjective.allergy) && <AllergiesReportView allergy={subjective.allergy}/>}
                </ReportSection>

                <ReportSection header1={"Assessment"}>
                    {!ReportViewUtils.isAssessmentNull(assessment) && <AssessmentReportView assesment={assessment}/>}
                </ReportSection>

                <ReportSection header1={"Diagnosis"}>
                    {!ReportViewUtils.isDiagnosisNull(visit.diagnosis) && !ReportViewUtils.isDiagnosisItemsNull(visit.diagnosis.diagnosisGroups[0].diagnosisItems) && <DiagnosisReportView diagnosis={visit.diagnosis}/>}
                </ReportSection>

                <ReportSection header1={"Treatment"}>
                    <ProcedureReportTreatmentView procedures={procedures}/>
                </ReportSection>

            </ReportTemplate>
        );
    }

}
