import React, {Component} from "react";
import '../../EMR.css';
import TypeEnum from "../enums/result/TypeEnum";
import Select from "react-select";
import {DateInput, TextareaInput} from "../../../../../components";
import TextInput from "../../../../../components/text-input/TextInput";
import GreenSwitch from "../../../../../components/switch/GreenSwitch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import ActionMenuItem from "../../../../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../../../../components/action-menu/icons/ViewIcon";
import DeleteIcon from "../../../../../components/action-menu/icons/DeleteIcon";
import ActionMenu from "../../../../../components/action-menu/ActionMenu";
import Modal from "../../../../../components/modal/Modal";
import ReduxService from "../../../../../services/redux-service/ReduxService";
import AttachmentUtils from "../utils/AttachmentUtils";
import CommunicationMethod from "../../../../../components/radio-input/CommunicationMethod";
import DateUtil from "../../../../../utils/DateUtil";
import AddButton from "../../common/table/AddButton";
import RemoveButton from "../../common/table/RemoveButton";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import StringUtil from "../../../../../utils/StringUtil";
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import moment from "moment";

export default class ResultModal extends Component {

    constructor(props) {
        super(props);
        this.customStyles = {
            option: (provided, state) => ({
                ...provided,
                textAlign:'left',
                whiteSpace:'nowrap',

            }),

            menu:(provided, state)=>({
                ...provided,
                position:'absolute',
                width:'100wv',

            }),
            menuList: (provided, state) => ({
                ...provided,
            }),
        }
    }

    state = {
        activeResult: {},
        viewerVisible: false,
        viewerHeader: null,
        blobUrl: null,
        visits: null,
        formattedVisitDates: null
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/visit_history', {emrId: this.props.emrId}, (result) => {
            let formattedVisits = result.sort((a1, a2) => moment(a2.date).diff(moment(a1.date))).map(visit => DateUtil.formatDateMMM(visit.date))
            this.setState({visits: result, formattedVisitDates: Array.from(new Set(formattedVisits))});
        });
    }

    render() {
        return (
            <div className={"emr-modal content-row emr-background w-100"}>
                <div className="w-100 my-3 mx-5 mb-5">
                    <div style={{display: "flex"}}>
                        <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Results</div>
                        <GreenSwitch checked={this.props.results != null && this.props.results.length > 0}
                                     onChange={() => {
                                         let currentValue = this.props.results != null && this.props.results.length > 0;
                                         if (currentValue) {
                                             this.props.results.splice(0, this.props.results.length);
                                             this.forceUpdate();
                                         }
                                     }}/>
                    </div>
                    <h6 className="mt-3 font-weight-bold">Interventions</h6>
                      <table className={"emr-table no-margin py-1 mt-2 w-100"}>
                          <tbody>
                              <tr>
                                  <th>Type</th>
                                  <th>Details</th>
                                  <th>Findings</th>
                                  <th>Remarks</th>
                                  <th>Date Ordered</th>
                                  <th>Ordered By</th>
                                  <th>Result Received</th>
                                  <th>Patient Informed</th>
                                  <th>Attach</th>
                                  <th style={{color:"red"}} >ⓧ</th>
                              </tr>
                              {(this.props.historicResults == null || this.props.historicResults.length == 0)
                                  ? null : this.props.historicResults.map((m, index) => {
                                      return (<tr key={"medicationUseModal" + index} style={{backgroundColor: "white"}}>
                                          <td>{m.type == null ? "" : TypeEnum[m.type].name}</td>
                                          <td>{m.detail}</td>
                                          <td>{m.findings}</td>
                                          <td>{m.remarks}</td>
                                          <td>{m.dateOrdered == null ? "" : DateUtil.formatDateMMM(m.dateOrdered)}</td>
                                          <td>{m.orderedBy}</td>
                                          <td>{m.resultReceived == null ? "" : DateUtil.formatDateMMM(m.resultReceived)}</td>
                                          <td>
                                              <CommunicationMethod value={m.patientInformed}
                                                                   informedDate={m.informedDate}
                                                                   disabled={true}/>
                                          </td>
                                          <td style={{width: 180, padding:"5px 0px"}}>
                                              {this.attachment(m)}
                                              {this.attachmentTable(m.files)}
                                          </td>
                                          <td></td>
                                      </tr>)
                                  })}
                              {(this.props.results == null || this.props.results.length == 0)
                                  ? null : this.props.results.map((m, index) => {
                                      return (<tr key={"medicationUseModal" + index} style={{backgroundColor: "white"}}>
                                          <td>{m.type == null ? "" : TypeEnum[m.type].name}</td>
                                          <td>{m.detail}</td>
                                          <td>{m.findings}</td>
                                          <td>{m.remarks}</td>
                                          <td>{m.dateOrdered == null ? "" : DateUtil.formatDateMMM(m.dateOrdered)}</td>
                                          <td>{m.orderedBy}</td>
                                          <td>{m.resultReceived == null ? "" : DateUtil.formatDateMMM(m.resultReceived)}</td>
                                          <td>
                                              <CommunicationMethod value={m.patientInformed}
                                                                   informedDate={m.informedDate}
                                                                   disabled={true}/>
                                          </td>
                                          <td style={{width: 180, padding:"5px 0px"}}>
                                              {this.attachment(m)}
                                              {this.attachmentTable(m.files)}
                                          </td>
                                          <td>
                                              <RemoveButton
                                                  onClick={()=> {
                                                      this.props.results.splice(index, 1);
                                                      this.forceUpdate();
                                                  }}
                                              />
                                          </td>
                                      </tr>)
                                  })}
                              <tr style={{backgroundColor: "white"}}>
                                  <td style={{minWidth: "80px"}}>
                                      <Select
                                          className={"emr-in-table-select-container"}
                                          classNamePrefix={"emr-in-table-select"}
                                          placeholder={"Type"}
                                          styles={this.customStyles}
                                          maxMenuHeight={180}
                                          value={this.state.activeResult.type != null ?
                                              {
                                                  value: this.state.activeResult.type,
                                                  label: TypeEnum[this.state.activeResult.type].name
                                              }: null}
                                          onChange={(event) => {
                                              this.setState((state) => {
                                                  state.activeResult.type = event.value;
                                                  return state;
                                              });
                                          }}
                                          options={TypeEnum.toArray().map(v=> {
                                                  return {value: v.key, label:v.name}
                                              }
                                          )}
                                      />
                                  </td>
                                  <td style={{width: "90px"}}>
                                      <TextInput className="m-0 p-0"
                                                 style={{width: "95%"}}
                                                 value={this.state.activeResult.detail || ''}
                                                 placeholder={""}
                                                 onChange={value =>  {
                                                     this.setState((state) => {
                                                         state.activeResult.detail = value;
                                                         return state;
                                                     });
                                                 }}
                                      />
                                  </td>
                                  <td>
                                      <TextareaInput className="m-0 p-0"
                                                     style={{width: "95%", height: "4rem"}}
                                                     value={this.state.activeResult.findings || ''}
                                                     maxLength={10000}
                                                     onChange={value => {
                                                         this.setState((state) => {
                                                             state.activeResult.findings = value;
                                                             return state;
                                                         });
                                                     }}/>
                                  </td>
                                  <td style={{width: "90px"}}>
                                      <TextInput className="m-0 p-0"
                                                 style={{width: "95%"}}
                                                 value={this.state.activeResult.remarks || ''}
                                                 placeholder={""}
                                                 onChange={value =>  {
                                                     this.setState((state) => {
                                                         state.activeResult.remarks = value;
                                                         return state;
                                                     });
                                                 }}
                                      />
                                  </td>
                                  <td style={{width: "110px"}}>
                                      <PrimeDropdown options={this.state.formattedVisitDates}
                                                     value={this.state.activeResult.dateOrdered}
                                                     onChange={event =>  {
                                                         this.setState((state) => {
                                                             state.activeResult.dateOrdered = event.value;
                                                             return state;
                                                         });
                                                     }}/>
                                  </td>
                                  <td>
                                      <AsyncSelect
                                          className="emr-in-table-select-container"
                                          classNamePrefix="emr-in-table-select"
                                          noOptionsMessage={this.noOptionsMessage.bind(this)}
                                          loadOptions={this.getStaffsByName.bind(this)}
                                          maxMenuHeight={180}
                                          styles={this.customStyles}
                                          value={this.state.activeResult.orderedBy != null ?
                                              {
                                                  value: this.state.activeResult.orderedBy,
                                                  label: this.state.activeResult.orderedBy
                                              } : null}
                                          onChange={(event) => {
                                              this.state.activeResult.orderedBy = event.value;
                                              this.forceUpdate();
                                          }}
                                      />
                                  </td>
                                  <td style={{width: "140px"}}>
                                      <DateInput className="mx-2"
                                                 disableFuture={true}
                                                 disableToolbar="true" variant="dialog"
                                                 value={this.state.activeResult.resultReceived || null}
                                                 onChange={date => {
                                                     this.setState((state) => {
                                                         state.activeResult.resultReceived = date;
                                                         return state;
                                                     });
                                                 }}
                                      />
                                  </td>
                                  <td>
                                      <CommunicationMethod value={this.state.activeResult.patientInformed}
                                                           onChange={value => {
                                                               this.setState((state) => {
                                                                   state.activeResult.patientInformed = value;
                                                                   return state;
                                                               });
                                                           }}
                                                           informedDate={this.state.activeResult.informedDate}
                                                           onInformedDateChanged={value => {
                                                               this.setState((state) => {
                                                                   state.activeResult.informedDate = value;
                                                                   return state;
                                                               });
                                                           }}/>
                                  </td>
                                  <td style={{width: 180, padding:"5px 0px"}}>
                                      {this.attachment(this.state.activeResult)}
                                      {this.attachmentTable(this.state.activeResult.files)}
                                  </td>
                                  <td>
                                      <AddButton
                                          disabled={
                                              StringUtil.isNullOrEmpty(this.state.activeResult.type) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.detail) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.findings) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.remarks) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.dateOrdered) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.orderedBy) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.resultReceived) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.patientInformed) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.informedDate) &&
                                              StringUtil.isNullOrEmpty(this.state.activeResult.files)
                                          }
                                          onClick={() => {
                                              let activeResult = this.state.activeResult;
                                              activeResult.dateOrdered = moment(this.state.activeResult.dateOrdered);
                                              this.props.results.push(activeResult);
                                              this.setState({activeResult: {}});
                                          }}
                                      />
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                </div>

                {this.state.viewerVisible &&
                <Modal visible={true}
                       header={this.state.viewerHeader}
                       closeAction={() => this.setState({
                           viewerVisible: false
                       })}>
                    <iframe id="documentViewer" src={this.state.blobUrl} width="800px" height="600px"></iframe>
                </Modal>
                }
            </div>
        );
    }

    attachment = (resultItem) => {
        return (
            <label className={"attachment-label"}>
                <FontAwesomeIcon icon={["fas", "paperclip"]} size={"lg"} style={{marginLeft: '5px'}}/>
                <input type="file"
                       id="upload-attachment"
                       accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .txt"
                       onChange={(e) => {
                           if (e == null
                               || e.target == null
                               || e.target.files == null
                               || e.target.files[0] == null) {
                               return;
                           }
                           let fileName = e.target.files[0].name;
                           RemotingService.uploadFile(e.target.files[0], (response, err) => {
                               if (err != null) {
                                   return;
                               }
                               if (resultItem.files == null) {
                                   resultItem.files = [];
                               }
                               resultItem.files.push({name: fileName, uuid: response.data});
                               this.forceUpdate();
                           });
                       }}
                       onClick={(e) => e.target.value = null}/>
            </label>
        );
    }

    attachmentTable = (files) => {
        if (files == null || files.length == 0) {
            return null;
        }
        return (
            <table className={"attachment-list"}>
                <tbody>
                {files.map((f, index) => {
                    return (
                        <tr>
                            <td className={"link-column"}
                                onClick={() => {
                                    this.downloadOrView(f);
                                }}>
                                {f.name}
                            </td>
                            <td>
                                <ActionMenu>
                                    <ActionMenuItem text="View" icon={<ViewIcon/>} onClick={() => this.view(f)}
                                                    disabled={!AttachmentUtils.viewEnable(f.name)}/>
                                    <ActionMenuItem text="Download"
                                                    icon={<FontAwesomeIcon icon={["fas", "file-download"]}/>}
                                                    onClick={() => AttachmentUtils.download(f)}/>
                                    <ActionMenuItem text="Delete" icon={<DeleteIcon/>} onClick={() => {
                                        files.splice(index, 1);
                                        this.forceUpdate();
                                    }}/>
                                </ActionMenu>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }

    downloadOrView = (file) => {
        if (AttachmentUtils.viewEnable(file.name)) {
            return this.view(file);
        }
        return AttachmentUtils.download(file);
    }

    view = (file) => {
        ReduxService.incrementRemotingOperationCount();

        RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
            .then((response) => {
                let blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                this.setState({
                    viewerVisible: true,
                    viewerHeader: file.name,
                    blobUrl: blobUrl
                });
            })
            .finally(() => {
                ReduxService.decrementRemotingOperationCount();
            });
    }

    noOptionsMessage(input) {
        if(input.inputValue == null || input.inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
        }
        else {
            return <div>No options</div>;
        }
    }

    getStaffsByName(text, callback) {
        if (text.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }

        RemotingService.getRemoteCall(`api/staff/list-base?name=${text}`, null, (result) => {
            const staffs = result.items;

            let filteredStaffs = this.state.activeResult.dateOrdered ?
                staffs.filter((staff) => this.state.visits.filter(visit => visit.provider === staff.name && DateUtil.isSameDay(moment(visit.date),moment(this.state.activeResult.dateOrdered))).length > 0) :
                staffs.filter((staff) => this.state.visits.filter(visit => visit.provider === staff.name).length > 0);

            if(filteredStaffs == null || filteredStaffs.length === 0) {
                return callback([{value: text, label: text}]);
            }
            let options = filteredStaffs.map(s => {return {value: s.name, label: s.name}});
            return callback(options);
        });
    }
}
