import React, {Component} from "react";
import '../../EMR.css';
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Select from "react-select";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ManipulationEnum from "../enums/treatmentrecieved/ManipulationEnum";
import {NumberInput} from "../../../../../components";
import MobilizationEnum from "../enums/treatmentrecieved/MobilizationEnum";
import StatefulTextInput from "../../../../../components/text-input/StatefulTextInput";
import ThermalTherapyEnum from "../enums/treatmentrecieved/ThermalTherapyEnum";
import ProceduresEnum from "../enums/treatmentrecieved/ProceduresEnum";
import TherapeuticExerciseEnum from "../enums/treatmentrecieved/TherapeuticExerciseEnum";
import ExpansionPanel from "../../../../../components/expansion-panel/CustomExpansionPanel";
import ShortTermGoalEnum from "../enums/goal/ShortTermGoalEnum";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import TreatmentInfoEnum from "./TreatmentInfoEnum";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import TreatmentInfoUtils from "../utils/TreatmentInfoUtils";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import PrimeInputNumber from "../../../../../components/number-input/PrimeInputNumber";
import DiagnosisGroup from "../views/DiagnosisGroup";
import confirmDialog from "../../../../../components/dialog/ConfirmDialog";
import * as ArrayUtil from "../../../../../utils/ArrayUtil";
import NotificationService from "../../../../../services/notification-service/NotificationService";
import {Dropdown} from "primereact/dropdown";

export default class TreatmentInfoModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            treatmentReceived: {},
            treatmentInfo: props.treatmentInfo,
            diagnosisGroups: this.getDiagnosisGroups(props),
            procedureTypes: []
        }
        this.label = this.props.label;
        this.MINIMUM_SEARCH_LENGTH = 1;
        this.customStyles = {
            option: (provided, state) => ({
                ...provided,
                textAlign:'left',
                whiteSpace:'nowrap',

            }),
            menu:(provided, state)=>({
                ...provided,
                position:'absolute',
                width:'100wv',

            }),
            menuList: (provided, state) => ({
                ...provided,
            })
        }
    }

    componentDidMount() {
        this.getCptCodeList();
    }

    getDiagnosisGroups(props) {
        const diagnosisGroups = [];
        props.visit.diagnosis.diagnosisGroups.forEach(diagnosisGroup => {
            if (diagnosisGroup.id) { //DiagnosisGroup must be persisted
                diagnosisGroups.push({
                    label: <DiagnosisGroup diagnosisGroup={diagnosisGroup} showSecondaryItems/>,
                    value: diagnosisGroup
                })
            }
        })
        return diagnosisGroups;
    }

    render() {
        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px", minHeight: "20vw"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{
                                margin: "auto",
                                marginLeft: "0px"
                            }}>{this.props.type.title} {this.props.type == TreatmentInfoEnum.TREATMENT_RECEIVED &&
                            <RedAsterisk renderForAllTypes/>}
                                {this.props.type == TreatmentInfoEnum.TREATMENT_PLAN &&
                                <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/>}
                            </div>
                        </div>
                        {this.props.type == TreatmentInfoEnum.TREATMENT_PLAN && <div className="d-flex flex-row-reverse">
                            <Button variant="success" onClick={this.onCarryButtonClick}>
                                {'  Copy Treatment Received '}
                            </Button>
                        </div>}
                        <br/>
                        <div>
                            {this.wrapperPanel("Manipulation", this.treatmentReceivedTypeAContent("manipulation", ManipulationEnum))}
                            {this.wrapperPanel("Mobilization", this.treatmentReceivedTypeAContent("mobilization", MobilizationEnum))}
                            {this.wrapperPanel("Stretching", this.treatmentReceivedTypeBContent("stretching"))}
                            {this.wrapperPanel("Dry Needling", this.treatmentReceivedTypeBContent("dryNeedling"))}
                            {this.wrapperPanel("Acupuncture", this.treatmentReceivedTypeBContent("acupuncture"))}
                            {this.wrapperPanel("Electrotherapy", this.treatmentReceivedTypeBContent("electrotherapy"))}
                            {this.wrapperPanel("Shockwave", this.treatmentReceivedTypeBContent("shockwave", this.props.type === TreatmentInfoEnum.TREATMENT_RECEIVED))}
                            {this.wrapperPanel("Cupping", this.treatmentReceivedTypeBContent("cupping"))}
                            {this.wrapperPanel("Taping", this.treatmentReceivedTypeBContent("taping"))}
                            {this.wrapperPanel("Therapeutic Massage", this.treatmentReceivedTypeBContent("therapeuticMassage"))}
                            {this.wrapperPanel("Lymphatic Drainage", this.treatmentReceivedTypeBContent("lymphaticDrainage"))}
                            {this.wrapperPanel("Thermal Therapy", this.treatmentReceivedTypeAContent("thermalTherapy", ThermalTherapyEnum))}
                            {this.wrapperPanel("Therapeutic Exercise", this.treatmentReceivedTypeCContent("therapeuticExercise", TherapeuticExerciseEnum))}
                            {this.wrapperPanel("Gait Training", this.treatmentReceivedTypeBContent("gaitTraining"))}
                            {this.wrapperPanel("Breathing Patterns", this.treatmentReceivedTypeBContent("breathingPatterns"))}
                            {this.props.type == TreatmentInfoEnum.TREATMENT_RECEIVED && this.wrapperPanel("Procedures", this.treatmentReceivedProcedureContent("procedures", ProceduresEnum))}
                            {this.props.type == TreatmentInfoEnum.TREATMENT_PLAN && this.wrapperPanel("Procedures", this.treatmentPlanProcedureContent())}
                            {this.wrapperPanel("Other", this.treatmentReceivedTypeBContent("other"))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    wrapperPanel = (title, content) => {
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography className="font-weight-bold">{title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {content}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    approvalRequiredChanged = (value) => {
        this.setState((state) => {
            state.treatmentReceived.procedures.approvalRequired = value;
            return state;
        }, () => this.getCptCodeList(() => this.keepProcedureTypeSelection()));
    }

    keepProcedureTypeSelection() {
        const selectedCptId = this.state.selectedCpt?.id;
        const selectedCpt = this.state.procedureTypes.map(p => p.value).find(p => !!selectedCptId && p.id == selectedCptId);
        this.selectedProcedureTypeChanged(selectedCpt);
    }

    selectedProcedureTypeChanged = (selectedType) => {
        this.setState((state) => {
            const isCpt = !!selectedType?.cptCode;

            state.selectedProcedureType = selectedType;
            state.selectedCpt = isCpt ? selectedType : null;
            state.treatmentReceived.procedures.type = isCpt ? (selectedType.cptCode + " - " + selectedType.description) : selectedType;
            state.treatmentReceived.procedures.cptId = isCpt ? selectedType.id : null;
            return state;
        });
    }

    getCptCodeList(callback = () => {}) {
        const approvalRequired = this.state.treatmentReceived.procedures.approvalRequired;

        let options = approvalRequired ? [] : ProceduresEnum.toArray().map(v => {
            return {value: v.key, label: v.name}
        });

        const params = {
            insurance: approvalRequired ? this.props.insurance?.tpa?.name : null,
            insuranceCompanyId: (approvalRequired && !this.props.insurance?.tpa?.name) ? this.props.insurance?.company?.id : null,
            speciality: this.props.visit.appointment.staff.speciality.name,
            scopes: ["ALL", approvalRequired ? "DIRECT_BILLING" : "SELF_PAY"]
        };

        RemotingService.getRemoteCall(`api/cpt/code-or-description/${this.props.visit.clinic.country}`, params, cptCodes => {
            let selectedCptList = this.state.treatmentInfo.procedures ? [...this.state.treatmentInfo.procedures.map(procedure => procedure.cptId)] : [];
            let cptCodesList = cptCodes.filter(cpt => !selectedCptList.includes(cpt.id)).map(s => {
                return {value: s, label: s.cptCode + " - " + s.description}
            })
            options = [...cptCodesList, ...options];

            this.setState({procedureTypes: options}, callback());
        });
    }

    noOptionsMessage(input) {
        if (input.inputValue == null || input.inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
        } else {
            return <div>No options</div>;
        }
    }

    treatmentReceivedTypeAContent = (field, dropdownEnum = ManipulationEnum) => {
        if (this.state.treatmentReceived[field] == null) {
            this.state.treatmentReceived[field] = {};
        }

        let itemDivs = this.getItemDivForTypeA(field, dropdownEnum)

        return (
            <div className={"emr-modal content-row"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-5"}>
                            <div className="font-weight-bold">Type</div>
                        </div>
                        <div className={"col-6"}>
                            <div className="font-weight-bold">Area of Focus</div>
                        </div>
                    </div>
                    <div className={"row no-margin"}>
                        <div className={"col-5 pl-0"}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                isClearable={true}

                                placeholder={"Type"}
                                options={dropdownEnum.toArray().map(v => {
                                        return {value: v.key, label: v.name}
                                    }
                                )}
                                value={this.state.treatmentReceived[field].type != null ?
                                    {
                                        value: dropdownEnum[this.state.treatmentReceived[field].type].key,
                                        label: dropdownEnum[this.state.treatmentReceived[field].type].name
                                    } : null}
                                onChange={(event) => {
                                    let r = event == null ? null : event.value;
                                    this.setState((state) => {
                                        state.treatmentReceived[field].type = r;
                                        return state;
                                    });
                                }}
                            />
                        </div>
                        <div className={"col-6"}>
                            <StatefulTextInput value={this.state.treatmentReceived[field].areaOfFocus || ''}
                                               placeholder={"Area of Focus"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].areaOfFocus == null || this.state.treatmentReceived[field].areaOfFocus.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].areaOfFocus = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].areaOfFocus = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                    </div>

                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-11"}>
                            <div className="font-weight-bold">Details</div>
                        </div>
                    </div>

                    <div className={"row no-margin"}>
                        <div className={"col-11 pl-0"}>
                            <StatefulTextInput value={this.state.treatmentReceived[field].details || ''}
                                               placeholder={"Details"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].details == null || this.state.treatmentReceived[field].details.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].details = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].details = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex", padding: "5px 0px"}}>
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={this.state.treatmentReceived[field].type == null ||
                                    this.state.treatmentReceived[field].areaOfFocus == null || this.state.treatmentReceived[field].areaOfFocus.length == 0}
                                    style={{fontSize: "12px"}}
                                    onClick={() => this.addItemToTypeA(field)}
                            >
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                    </div>
                    <div className={"row no-margin"}
                         style={{marginLeft: "5px", marginRight: "30px", marginTop: "10px"}}>
                        <div className={"div-list-wrapper"} style={{minHeight: "75px"}}>
                            {itemDivs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addItemToTypeA = (field) => {
        if (this.state.treatmentInfo[field] == null) {
            this.state.treatmentInfo[field] = [];
        }

        this.state.treatmentInfo[field].push({
            type: this.state.treatmentReceived[field].type,
            areaOfFocus: this.state.treatmentReceived[field].areaOfFocus,
            details: this.state.treatmentReceived[field].details,
        })
        this.setState(state => {
            state.treatmentReceived[field].type = null;
            state.treatmentReceived[field].areaOfFocus = null;
            state.treatmentReceived[field].details = null;
            return state;
        })
    }

    getItemDivForTypeA = (field, dropdownEnum) => {
        if (this.state.treatmentInfo[field] == null || this.state.treatmentInfo[field].length == 0) {
            return;
        }

        let divs = [];

        this.state.treatmentInfo[field].forEach((item, index) => {
            let principalItem = null;
            if (item.diagnosisGroup && item.diagnosisGroup.diagnosisItems) {
                principalItem = item.diagnosisGroup.diagnosisItems.find(i => i.level == "PRINCIPAL");
            }

            divs.push(
                <div className={"div-list-item"} key={field + "Item" + index}>
                    <div>
                        {
                            [
                                principalItem?.code,
                                dropdownEnum[item.type] != null ? dropdownEnum[item.type].name : item.type,
                                item.areaOfFocus,
                                item.details
                            ].filter(i => !!i).join(", ")
                        }
                    </div>
                    <Button className={"emr-transparent-delete-button"}
                            variant="warning"
                            style={{fontSize: "10px", marginLeft: "2px"}}
                            onClick={() => {
                                this.state.treatmentInfo[field].splice(index, 1);
                                this.forceUpdate();
                            }}>
                        <FontAwesomeIcon icon={["fas", "times"]}/>
                    </Button>
                </div>
            )
        });
        return divs;
    }

    treatmentReceivedTypeBContent = (field, patientConsent = false) => {
        if (this.state.treatmentReceived[field] == null) {
            this.state.treatmentReceived[field] = {};
        }

        let itemDivs = this.getItemDivForTypeB(field, patientConsent)

        return (
            <div className={"emr-modal content-row"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-4"}>
                            <div className="font-weight-bold">Area of Focus</div>
                        </div>
                        <div className={"col-7"}>
                            <div className="font-weight-bold">Details</div>
                        </div>
                    </div>
                    <div className={"row no-margin"}>
                        <div className={"col-4"}>
                            <StatefulTextInput value={this.state.treatmentReceived[field].areaOfFocus || ''}
                                               placeholder={"Area of Focus"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].areaOfFocus == null || this.state.treatmentReceived[field].areaOfFocus.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].areaOfFocus = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].areaOfFocus = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-7"}>
                            <StatefulTextInput value={this.state.treatmentReceived[field].details || ''}
                                               placeholder={"Details"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].details == null || this.state.treatmentReceived[field].details.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].details = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].details = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex", padding: "5px 0px"}}>
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={this.state.treatmentReceived[field].areaOfFocus == null || this.state.treatmentReceived[field].areaOfFocus.length == 0}
                                    style={{fontSize: "12px"}}
                                    onClick={() => this.addItemToTypeB(field)}
                            >
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                        {patientConsent && <div className={"row mx-0 my-3"}>
                            <div style={{display: "flex"}}>
                                <CheckboxInput className="align-self-center"
                                               value={this.state.treatmentReceived[field].patientConsent}
                                               onChange={(value) => {
                                                   this.setState((state) => {
                                                       state.treatmentReceived[field].patientConsent = value
                                                       return state;
                                                   })
                                               }}/>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Patient consent.
                                    Written consent was obtained after discussion of risks, benefits and alternatives.
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className={"row no-margin"}
                         style={{marginLeft: "5px", marginRight: "30px", marginTop: "10px"}}>
                        <div className={"div-list-wrapper"} style={{minHeight: "75px"}}>
                            {itemDivs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getItemDivForTypeB = (field, patientConsent) => {
        if (this.state.treatmentInfo[field] == null || this.state.treatmentInfo[field].length == 0) {
            return;
        }

        let divs = [];
        this.state.treatmentInfo[field].forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={field + "Item" + index}>
                <div>
                    {`${item.areaOfFocus}${item.details ? ", " + item.details : ""}${patientConsent ? ", Patient Consent: " + (item.patientConsent ? "Yes" : "No") : ""}`}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.state.treatmentInfo[field].splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    addItemToTypeB = (field) => {
        if (this.state.treatmentInfo[field] == null) {
            this.state.treatmentInfo[field] = [];
        }

        this.state.treatmentInfo[field].push({
            areaOfFocus: this.state.treatmentReceived[field].areaOfFocus,
            details: this.state.treatmentReceived[field].details,
            patientConsent: this.state.treatmentReceived[field].patientConsent != null ? this.state.treatmentReceived[field].patientConsent : null
        })
        this.setState(state => {
            state.treatmentReceived[field].areaOfFocus = null;
            state.treatmentReceived[field].details = null;
            state.treatmentReceived[field].patientConsent = null;
            return state;
        })
    }

    treatmentReceivedTypeCContent = (field, dropdownEnum = ManipulationEnum) => {
        if (this.state.treatmentReceived[field] == null) {
            this.state.treatmentReceived[field] = {};
        }

        let itemDivs = this.getItemDivForTypeC(field, dropdownEnum)

        return (
            <div className={"emr-modal content-row"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-5"}>
                            <div className="font-weight-bold">Focus</div>
                        </div>
                        <div className={"col-4"}>
                            <div className="font-weight-bold">Exercise</div>
                        </div>
                        <div className={"col-1"}>
                            <div className="font-weight-bold">Weight</div>
                        </div>
                        <div className={"col-1"}>
                            <div className="font-weight-bold">Sets</div>
                        </div>
                        <div className={"col-1"}>
                            <div className="font-weight-bold">Reps</div>
                        </div>
                    </div>
                    <div className={"row no-margin"}>
                        <div className={"col-5"}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                isClearable={true}
                                placeholder={"Type"}
                                options={dropdownEnum.toArray().map(v => {
                                        return {value: v.key, label: v.name}
                                    }
                                )}
                                value={this.state.treatmentReceived[field].focus != null ?
                                    {
                                        value: dropdownEnum[this.state.treatmentReceived[field].focus].key,
                                        label: dropdownEnum[this.state.treatmentReceived[field].focus].name
                                    } : null}
                                onChange={(event) => {
                                    let r = event == null ? null : event.value;
                                    this.setState((state) => {
                                        state.treatmentReceived[field].focus = r;
                                        return state;
                                    });
                                }}
                            />
                        </div>
                        <div className={"col-4"}>
                            <StatefulTextInput value={this.state.treatmentReceived[field].exercise || ''}
                                               placeholder={"Exercise"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].exercise == null || this.state.treatmentReceived[field].exercise.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].exercise = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].exercise = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-1"}>
                            <PrimeInputNumber
                                inputClassName="treatment-info-input-number"
                                value={this.state.treatmentReceived[field].weight}
                                placeholder={"0"}
                                id={field + "weightInput"}
                                min="0"
                                step="1"
                                allowNegativeNumber={false}
                                allowOnlyIntegers={true}
                                onChange={(value) => {
                                    this.setState(state => {
                                        state.treatmentReceived[field].weight = value;
                                        return state;
                                    })
                                }}
                            />
                        </div>
                        <div className={"col-1"}>
                            <PrimeInputNumber
                                inputClassName="treatment-info-input-number"
                                value={this.state.treatmentReceived[field].sets}
                                placeholder={"0"}
                                id={field + "setsInput"}
                                min="0"
                                step="1"
                                allowNegativeNumber={false}
                                allowOnlyIntegers={true}
                                onChange={(value) => {
                                    this.setState(state => {
                                        state.treatmentReceived[field].sets = value;
                                        return state;
                                    })
                                }}
                            />
                        </div>
                        <div className={"col-1"}>
                            <PrimeInputNumber
                                inputClassName="treatment-info-input-number"
                                value={this.state.treatmentReceived[field].reps}
                                placeholder={"0"}
                                id={field + "repsInput"}
                                min="0"
                                step="1"
                                allowNegativeNumber={false}
                                allowOnlyIntegers={true}
                                onChange={(value) => {
                                    this.setState(state => {
                                        state.treatmentReceived[field].reps = value;
                                        return state;
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-11"}>
                            <div className="font-weight-bold">Details</div>
                        </div>
                    </div>
                    <div className={"row no-margin"}>
                        <div className={"col-12"} style={{position: "relative"}}>
                            <StatefulTextInput value={this.state.treatmentReceived[field].details || ''}
                                               placeholder={"Details"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].details == null || this.state.treatmentReceived[field].details.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].details = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].details = value
                                               }}
                                               style={{fontSize: "14px", width: "95%"}}
                            />
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={
                                        this.state.treatmentReceived[field].focus == null &&
                                        (
                                            this.state.treatmentReceived[field].exercise == null ||
                                            this.state.treatmentReceived[field].exercise.length === 0
                                        )
                                    }
                                    style={{fontSize: "12px", position: "absolute", right: "5px", bottom: "5px"}}
                                    onClick={() => this.addItemToTypeC(field)}
                            >
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                    </div>
                    <div className={"row"} style={{marginLeft: "5px", marginRight: "30px", marginTop: "10px"}}>
                        <div className={"div-list-wrapper"} style={{minHeight: "75px"}}>
                            {itemDivs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getLabelForTypeC = (item) => {
        const fields = [];

        if (item.focus) {
            fields.push("Focus: " + TherapeuticExerciseEnum[item.focus].name);
        }
        if (item.exercise) {
            fields.push("Exercise: " + item.exercise);
        }
        if (item.weight) {
            fields.push("Weight: " + item.weight);
        }
        if (item.sets) {
            fields.push("Sets: " + item.sets);
        }
        if (item.reps) {
            fields.push("Reps: " + item.reps);
        }
        if (item.details) {
            fields.push("Details: " + item.details);
        }

        return fields.reduce((accumulator, item) => accumulator === "" ? item : accumulator + ", " + item, "");
    }

    getItemDivForTypeC = (field) => {
        if (this.state.treatmentInfo[field] == null || this.state.treatmentInfo[field].length == 0) {
            return;
        }

        let divs = [];
        this.state.treatmentInfo[field].forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={field + "Item" + index}>
                <div>
                    {this.getLabelForTypeC(item)}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.state.treatmentInfo[field].splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    addItemToTypeC = (field) => {
        if (this.state.treatmentInfo[field] == null) {
            this.state.treatmentInfo[field] = [];
        }

        this.state.treatmentInfo[field].push({
            focus: this.state.treatmentReceived[field].focus,
            exercise: this.state.treatmentReceived[field].exercise,
            weight: this.state.treatmentReceived[field].weight,
            sets: this.state.treatmentReceived[field].sets,
            reps: this.state.treatmentReceived[field].reps,
            details: this.state.treatmentReceived[field].details,
        })
        this.setState(state => {
            state.treatmentReceived[field].focus = null;
            state.treatmentReceived[field].exercise = null;
            state.treatmentReceived[field].weight = "";
            state.treatmentReceived[field].sets = "";
            state.treatmentReceived[field].reps = "";
            state.treatmentReceived[field].details = null;
            return state;
        })
    }

    treatmentPlanProcedureContent = () => {
        if (this.state.treatmentReceived.procedures == null) {
            this.state.treatmentReceived.procedures = {};
        }

        let itemDivs = this.getItemDivForTypeA("procedures", ProceduresEnum)

        return (
            <div className={"emr-modal content-row"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-2"}>
                            <div className="font-weight-bold">Diagnosis</div>
                        </div>
                        <div className={"col-4"}>
                            <div className="font-weight-bold">Type</div>
                        </div>
                        <div className={"col-3"}>
                            <div className="font-weight-bold">Area of Focus</div>
                        </div>
                        <div className={"col-2"}>
                            <div className="font-weight-bold">Number of Sessions</div>
                        </div>
                        <div className={"col-1"}>
                            <div className="font-weight-bold">Approval Required</div>
                        </div>
                    </div>
                    <div className={"row no-margin"}>
                        <div className={"col-2 pl-0"}>
                            <Select
                                className="emr-white-select-container"
                                classNamePrefix="emr-white-select"
                                placeholder="Code"
                                value={{
                                    label: <DiagnosisGroup diagnosisGroup={this.state.treatmentReceived.procedures.diagnosisGroup}/>,
                                    value: this.state.treatmentReceived.procedures.diagnosisGroup
                                }}
                                onChange={(event) => {
                                    this.state.treatmentReceived.procedures.diagnosisGroup = event.value;
                                    this.forceUpdate();
                                }}
                                options={this.state.diagnosisGroups}
                            />
                        </div>
                        <div className={"col-4 pl-0"}>
                            <Dropdown
                                className="p-dropdown-label-py-0"
                                style={{width: "inherit", maxHeight: 25}}
                                filter filterBy="label" dataKey="id"
                                placeholder="Select..."
                                optionLabel="label"
                                options={this.state.procedureTypes}
                                value={this.state.selectedProcedureType}
                                onChange={event => this.selectedProcedureTypeChanged(event.value)}
                            />
                        </div>
                        <div className={"col-3"}>
                            <StatefulTextInput value={this.state.treatmentReceived.procedures.areaOfFocus || ''}
                                               placeholder={"Area of Focus"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived.procedures.areaOfFocus == null || this.state.treatmentReceived.procedures.areaOfFocus.length === 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived.procedures.areaOfFocus = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived.procedures.areaOfFocus = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-2"}>
                            <NumberInput value={this.state.treatmentReceived.procedures.noOfSessions || ''}
                                         className="w-100"
                                         onChange={value => {
                                             this.state.treatmentReceived.procedures.noOfSessions = value;
                                             this.forceUpdate();
                                         }}/>
                        </div>
                        <div className={"col-1 text-center align-self-center"}>
                            <CheckboxInput value={this.state.treatmentReceived.procedures.approvalRequired}
                                           onChange={this.approvalRequiredChanged}/>
                        </div>
                    </div>

                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-11"}>
                            <div className="font-weight-bold">Details</div>
                        </div>
                    </div>

                    <div className={"row no-margin"}>
                        <div className={"col-11 pl-0"}>
                            <StatefulTextInput value={this.state.treatmentReceived.procedures.details || ''}
                                               placeholder={"Details"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived.procedures.details == null || this.state.treatmentReceived.procedures.details.length === 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived.procedures.details = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived.procedures.details = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex", padding: "5px 0px"}}>
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={this.state.treatmentReceived.procedures.diagnosisGroup == null
                                    || this.state.treatmentReceived.procedures.type == null
                                    || this.state.treatmentReceived.procedures.areaOfFocus == null
                                    || this.state.treatmentReceived.procedures.areaOfFocus.length === 0}
                                    style={{fontSize: "12px"}}
                                    onClick={() => this.addItemToTreatmentPlanProcedure()}
                            >
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                    </div>
                    <div className={"row no-margin"}
                         style={{marginLeft: "5px", marginRight: "30px", marginTop: "10px"}}>
                        <div className={"div-list-wrapper"} style={{minHeight: "75px"}}>
                            {itemDivs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    treatmentReceivedProcedureContent = (field, dropdownEnum = ManipulationEnum) => {
        if (this.state.treatmentReceived[field] == null) {
            this.state.treatmentReceived[field] = {};
        }

        let itemDivs = this.getItemDivForTreatmentReceivedProcedure(field, dropdownEnum)

        return (
            <div className={"emr-modal content-row"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-5"}>
                            <div className="font-weight-bold">Type</div>
                        </div>
                        <div className={"col-6"}>
                            <div className="font-weight-bold">Area of Focus</div>
                        </div>
                    </div>
                    <div className={"row no-margin"}>
                        <div className={"col-5 pl-0"}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                isClearable={true}
                                placeholder={"Type"}
                                styles={this.customStyles}

                                options={dropdownEnum.toArray().map(v => {
                                        return {value: v.key, label: v.name}
                                    }
                                )}
                                value={this.state.treatmentReceived[field].type != null ?
                                    {
                                        value: dropdownEnum[this.state.treatmentReceived[field].type].key,
                                        label: dropdownEnum[this.state.treatmentReceived[field].type].name
                                    } : null}
                                onChange={(event) => {
                                    let r = event == null ? null : event.value;
                                    this.setState((state) => {
                                        state.treatmentReceived[field].type = r;
                                        return state;
                                    });
                                }}
                            />
                        </div>
                        <div className={"col-6"}>
                            <StatefulTextInput value={this.state.treatmentReceived[field].areaOfFocus || ''}
                                               placeholder={"Area of Focus"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].areaOfFocus == null || this.state.treatmentReceived[field].areaOfFocus.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].areaOfFocus = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].areaOfFocus = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                    </div>

                    <div className={"row mx-0 my-3"}>
                        <div style={{display: "flex"}}>
                            <CheckboxInput className="align-self-center"
                                           value={this.state.treatmentReceived[field].patientConsent}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   state.treatmentReceived[field].patientConsent = value
                                                   return state;
                                               })
                                           }}/>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Patient consent.
                                Written consent was obtained after discussion of risks, benefits and alternatives.
                            </div>
                        </div>
                    </div>
                    <div className={"row mx-0 my-3"}>
                        <div className={"row w-100 mx-0"}>
                            <div className={"col d-flex p-0"}>
                                <CheckboxInput className="align-self-center"
                                               value={this.state.treatmentReceived[field].vitalSigns}
                                               onChange={(value) => {
                                                   this.setState((state) => {
                                                       state.treatmentReceived[field].vitalSigns = value
                                                       return state;
                                                   })
                                               }}/>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Vital Signs:
                                </div>
                            </div>
                        </div>
                        <div className={"row mx-3"}>
                            <div className={"col-2"}>Pre-Procedure:</div>

                            <div className={"col-1"}>Temp:</div>
                            <div className={"col-2"}>
                                <StatefulTextInput value={this.state.treatmentReceived[field].preProcedureTemp || ''}
                                                   onChange={value => {
                                                       if (value.length == 0 || this.state.treatmentReceived[field].preProcedureTemp == null || this.state.treatmentReceived[field].preProcedureTemp.length == 0) {
                                                           this.setState((state) => {
                                                               state.treatmentReceived[field].preProcedureTemp = value
                                                               return state;
                                                           })
                                                       } else
                                                           this.state.treatmentReceived[field].preProcedureTemp = value
                                                   }}
                                                   style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>

                            <div className={"col-1"}>BP:</div>
                            <div className={"col-2"}>
                                <StatefulTextInput value={this.state.treatmentReceived[field].preProcedureBP || ''}
                                                   onChange={value => {
                                                       if (value.length == 0 || this.state.treatmentReceived[field].preProcedureBP == null || this.state.treatmentReceived[field].preProcedureBP.length == 0) {
                                                           this.setState((state) => {
                                                               state.treatmentReceived[field].preProcedureBP = value
                                                               return state;
                                                           })
                                                       } else
                                                           this.state.treatmentReceived[field].preProcedureBP = value
                                                   }}
                                                   style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>

                            <div className={"col-1"}>Pulse:</div>
                            <div className={"col-2"}>
                                <StatefulTextInput value={this.state.treatmentReceived[field].preProcedurePulse || ''}
                                                   onChange={value => {
                                                       if (value.length == 0 || this.state.treatmentReceived[field].preProcedurePulse == null || this.state.treatmentReceived[field].preProcedurePulse.length == 0) {
                                                           this.setState((state) => {
                                                               state.treatmentReceived[field].preProcedurePulse = value
                                                               return state;
                                                           })
                                                       } else
                                                           this.state.treatmentReceived[field].preProcedurePulse = value
                                                   }}
                                                   style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                        </div>
                        <div className={"row mx-3"}>
                            <div className={"col-2"}>Post-Procedure:</div>

                            <div className={"col-1"}>Temp:</div>
                            <div className={"col-2"}>
                                <StatefulTextInput value={this.state.treatmentReceived[field].postProcedureTemp || ''}
                                                   onChange={value => {
                                                       if (value.length == 0 || this.state.treatmentReceived[field].postProcedureTemp == null || this.state.treatmentReceived[field].postProcedureTemp.length == 0) {
                                                           this.setState((state) => {
                                                               state.treatmentReceived[field].postProcedureTemp = value
                                                               return state;
                                                           })
                                                       } else
                                                           this.state.treatmentReceived[field].postProcedureTemp = value
                                                   }}
                                                   style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>

                            <div className={"col-1"}>BP:</div>
                            <div className={"col-2"}>
                                <StatefulTextInput value={this.state.treatmentReceived[field].postProcedureBP || ''}
                                                   onChange={value => {
                                                       if (value.length == 0 || this.state.treatmentReceived[field].postProcedureBP == null || this.state.treatmentReceived[field].postProcedureBP.length == 0) {
                                                           this.setState((state) => {
                                                               state.treatmentReceived[field].postProcedureBP = value
                                                               return state;
                                                           })
                                                       } else
                                                           this.state.treatmentReceived[field].postProcedureBP = value
                                                   }}
                                                   style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>

                            <div className={"col-1"}>Pulse:</div>
                            <div className={"col-2"}>
                                <StatefulTextInput value={this.state.treatmentReceived[field].postProcedurePulse || ''}
                                                   onChange={value => {
                                                       if (value.length == 0 || this.state.treatmentReceived[field].postProcedurePulse == null || this.state.treatmentReceived[field].postProcedurePulse.length == 0) {
                                                           this.setState((state) => {
                                                               state.treatmentReceived[field].postProcedurePulse = value
                                                               return state;
                                                           })
                                                       } else
                                                           this.state.treatmentReceived[field].postProcedurePulse = value
                                                   }}
                                                   style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"row mx-0 my-3"}>
                        <div style={{display: "flex"}}>
                            <CheckboxInput className="align-self-center"
                                           value={this.state.treatmentReceived[field].requiredEquipment}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   state.treatmentReceived[field].requiredEquipment = value
                                                   return state;
                                               })
                                           }}/>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Required equipment
                                and materials prepared (syringe, needles, centrifuge machine)
                            </div>
                        </div>
                    </div>
                    <div className={"row mx-0 my-2"}>
                        <div style={{display: "flex"}}>
                            <CheckboxInput className="align-self-center"
                                           value={this.state.treatmentReceived[field].patientPrepared}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   state.treatmentReceived[field].patientPrepared = value
                                                   return state;
                                               })
                                           }}/>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Patient prepared for
                                procedure.
                            </div>
                        </div>
                    </div>
                    <div className={"row mx-0"}>
                        <div className="col-5 p-0 align-self-center">
                            <div className="mx-3">Position of patient during procedure</div>
                        </div>
                        <div className="col-7">
                            <StatefulTextInput value={this.state.treatmentReceived[field].positionOfPatient || ''}
                                               className="w-100"
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].positionOfPatient == null || this.state.treatmentReceived[field].positionOfPatient.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].positionOfPatient = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].positionOfPatient = value
                                               }}
                                               style={{fontSize: "14px"}}
                            />
                        </div>
                    </div>
                    <div className={"row mx-0 mb-3"}>
                        <div className="col-6 d-flex p-0">
                            <CheckboxInput className="align-self-center"
                                           value={this.state.treatmentReceived[field].areaDisinfected}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   state.treatmentReceived[field].areaDisinfected = value
                                                   return state;
                                               })
                                           }}/>
                            <div className="align-self-center"
                                 style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Area of focus was
                                disinfected. Material used to disinfect
                            </div>
                        </div>
                        <div className="col-6">
                            <StatefulTextInput value={this.state.treatmentReceived[field].usedMaterial || ''}
                                               className="w-100"
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].usedMaterial == null || this.state.treatmentReceived[field].usedMaterial.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].usedMaterial = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].usedMaterial = value
                                               }}
                                               style={{fontSize: "14px"}}
                            />
                        </div>
                    </div>
                    <div className={"row mx-0 my-3"}>
                        <div className="col-2 d-flex p-0">
                            <CheckboxInput className="align-self-center"
                                           value={this.state.treatmentReceived[field].bloodDrawn}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   state.treatmentReceived[field].bloodDrawn = value
                                                   return state;
                                               })
                                           }}/>
                            <div className="align-self-center"
                                 style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Blood drawn.
                            </div>
                        </div>
                        <div className="col-1">Site:</div>
                        <div className="col-2">
                            <StatefulTextInput value={this.state.treatmentReceived[field].bloodDrawnSite || ''}
                                               className="w-100"
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].bloodDrawnSite == null || this.state.treatmentReceived[field].bloodDrawnSite.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].bloodDrawnSite = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].bloodDrawnSite = value
                                               }}
                                               style={{fontSize: "14px"}}
                            />
                        </div>
                        <div className="col-1">Amount:</div>
                        <div className="col-2">
                            <StatefulTextInput value={this.state.treatmentReceived[field].bloodDrawnAmount || ''}
                                               className="w-100"
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].bloodDrawnAmount == null || this.state.treatmentReceived[field].bloodDrawnAmount.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].bloodDrawnAmount = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].bloodDrawnAmount = value
                                               }}
                                               style={{fontSize: "14px"}}
                            />
                        </div>
                    </div>
                    <div className={"row mx-0 my-3"}>
                        <div style={{display: "flex"}}>
                            <CheckboxInput className="align-self-center"
                                           value={this.state.treatmentReceived[field].procedureGuided}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   state.treatmentReceived[field].procedureGuided = value
                                                   return state;
                                               })
                                           }}/>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Procedure was guided
                                by ultrasound for direct and accurate visualization of the treatment site
                            </div>
                        </div>
                    </div>
                    <div className={"row mx-0 my-3"}>
                        <div style={{display: "flex"}}>
                            <CheckboxInput className="align-self-center"
                                           value={this.state.treatmentReceived[field].patientTolerated}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   state.treatmentReceived[field].patientTolerated = value
                                                   return state;
                                               })
                                           }}/>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Patient tolerated
                                the procedure well and was discharged in stable condition
                            </div>
                        </div>
                    </div>
                    <div className={"row mx-0 my-3"}>
                        <div style={{display: "flex"}}>
                            <CheckboxInput className="align-self-center"
                                           value={this.state.treatmentReceived[field].patientInstructed}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   state.treatmentReceived[field].patientInstructed = value
                                                   return state;
                                               })
                                           }}/>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Patient was
                                instructed to call if any problems or reactions occur (pain, swelling)
                            </div>
                        </div>
                    </div>
                    <div className={"row no-margin"} style={{marginBottom: "10px"}}>
                        <div className={"col-11"}>
                            <div className="font-weight-bold">Details</div>
                        </div>
                    </div>

                    <div className={"row no-margin"}>
                        <div className={"col-11 pl-0"}>
                            <StatefulTextInput value={this.state.treatmentReceived[field].details || ''}
                                               placeholder={"Details"}
                                               onChange={value => {
                                                   if (value.length == 0 || this.state.treatmentReceived[field].details == null || this.state.treatmentReceived[field].details.length == 0) {
                                                       this.setState((state) => {
                                                           state.treatmentReceived[field].details = value
                                                           return state;
                                                       })
                                                   } else
                                                       this.state.treatmentReceived[field].details = value
                                               }}
                                               style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex", padding: "5px 0px"}}>
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={this.state.treatmentReceived[field].type == null ||
                                    this.state.treatmentReceived[field].areaOfFocus == null || this.state.treatmentReceived[field].areaOfFocus.length === 0}
                                    style={{fontSize: "12px"}}
                                    onClick={() => this.addItemToTreatmentReceivedProcedure(field)}
                            >
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                    </div>
                    <div className={"row no-margin"}
                         style={{marginLeft: "5px", marginRight: "30px", marginTop: "10px"}}>
                        <div className={"div-list-wrapper"} style={{minHeight: "75px"}}>
                            {itemDivs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addItemToTreatmentReceivedProcedure = (field) => {
        if (this.state.treatmentInfo[field] == null) {
            this.state.treatmentInfo[field] = [];
        }

        this.state.treatmentInfo[field].push({
            type: this.state.treatmentReceived[field].type,
            areaOfFocus: this.state.treatmentReceived[field].areaOfFocus,
            details: this.state.treatmentReceived[field].details,
            patientConsent: this.state.treatmentReceived[field].patientConsent,
            vitalSigns: this.state.treatmentReceived[field].vitalSigns,
            preProcedureTemp: this.state.treatmentReceived[field].preProcedureTemp,
            preProcedureBP: this.state.treatmentReceived[field].preProcedureBP,
            preProcedurePulse: this.state.treatmentReceived[field].preProcedurePulse,
            postProcedureTemp: this.state.treatmentReceived[field].postProcedureTemp,
            postProcedureBP: this.state.treatmentReceived[field].postProcedureBP,
            postProcedurePulse: this.state.treatmentReceived[field].postProcedurePulse,
            requiredEquipment: this.state.treatmentReceived[field].requiredEquipment,
            patientPrepared: this.state.treatmentReceived[field].patientPrepared,
            positionOfPatient: this.state.treatmentReceived[field].positionOfPatient,
            areaDisinfected: this.state.treatmentReceived[field].areaDisinfected,
            usedMaterial: this.state.treatmentReceived[field].usedMaterial,
            bloodDrawn: this.state.treatmentReceived[field].bloodDrawn,
            bloodDrawnSite: this.state.treatmentReceived[field].bloodDrawnSite,
            bloodDrawnAmount: this.state.treatmentReceived[field].bloodDrawnAmount,
            procedureGuided: this.state.treatmentReceived[field].procedureGuided,
            patientTolerated: this.state.treatmentReceived[field].patientTolerated,
            patientInstructed: this.state.treatmentReceived[field].patientInstructed,

        })
        this.setState(state => {
            state.treatmentReceived[field].type = null;
            state.treatmentReceived[field].areaOfFocus = null;
            state.treatmentReceived[field].details = null;
            state.treatmentReceived[field].patientConsent = null;
            state.treatmentReceived[field].vitalSigns = null;
            state.treatmentReceived[field].preProcedureTemp = null;
            state.treatmentReceived[field].preProcedureBP = null;
            state.treatmentReceived[field].preProcedurePulse = null;
            state.treatmentReceived[field].postProcedureTemp = null;
            state.treatmentReceived[field].postProcedureBP = null;
            state.treatmentReceived[field].postProcedurePulse = null;
            state.treatmentReceived[field].requiredEquipment = null;
            state.treatmentReceived[field].patientPrepared = null;
            state.treatmentReceived[field].positionOfPatient = null;
            state.treatmentReceived[field].areaDisinfected = null;
            state.treatmentReceived[field].usedMaterial = null;
            state.treatmentReceived[field].bloodDrawn = null;
            state.treatmentReceived[field].bloodDrawnSite = null;
            state.treatmentReceived[field].bloodDrawnAmount = null;
            state.treatmentReceived[field].procedureGuided = null;
            state.treatmentReceived[field].patientTolerated = null;
            state.treatmentReceived[field].patientInstructed = null;
            return state;
        })
    }

    addItemToTreatmentPlanProcedure = () => {
        if (this.state.treatmentInfo.procedures == null) {
            this.state.treatmentInfo.procedures = [];
        }

        this.state.treatmentInfo.procedures.push({
            cptId: this.state.treatmentReceived.procedures.cptId,
            type: this.state.treatmentReceived.procedures.type,
            diagnosisGroup: this.state.treatmentReceived.procedures.diagnosisGroup,
            areaOfFocus: this.state.treatmentReceived.procedures.areaOfFocus,
            details: this.state.treatmentReceived.procedures.details,
            approvalRequired: this.state.treatmentReceived.procedures.approvalRequired,
            noOfSessions: this.state.treatmentReceived.procedures.noOfSessions,
        })
        this.setState(state => {
            state.treatmentReceived.procedures.type = null;
            state.treatmentReceived.procedures.diagnosisGroup = null;
            state.treatmentReceived.procedures.areaOfFocus = null;
            state.treatmentReceived.procedures.details = null;
            state.treatmentReceived.procedures.approvalRequired = null;
            state.treatmentReceived.procedures.noOfSessions = null;
            state.treatmentReceived.procedures.cptId = null;
            state.treatmentReceived.procedures.type = null;
            state.selectedCpt = null;
            state.selectedProcedureType = null;
            return state;
        })
    }

    getItemDivForTreatmentReceivedProcedure = (field, dropdownEnum) => {
        if (this.state.treatmentInfo[field] == null || this.state.treatmentInfo[field].length == 0) {
            return;
        }

        let divs = [];

        this.state.treatmentInfo[field].forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={field + "Item" + index}>
                <div>
                    {`${dropdownEnum[item.type] != null ? dropdownEnum[item.type].name : item.type}, ${item.areaOfFocus}`}
                    {TreatmentInfoUtils.getTreatmentReceivedProcedureList(item)}
                    {`${item.details ? "Details: " + item.details : ""}`}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.state.treatmentInfo[field].splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    onCarryButtonClick = () => {
        confirmDialog("Are you sure?",
            "You are about to copy changes made in Treatment Received to the Treatment Plan. Do you want to continue?",
            () => {
                this.carryTreatmentReceivedToTreatmentPlan()
            });
    }

    carryTreatmentReceivedToTreatmentPlan = () => {
        let {treatmentReceived, treatmentPlan} = this.props.visit.treatment;

        if (!treatmentReceived) {
            NotificationService.showNotification({
                severity: 'warn',
                summary: 'No Data',
                detail: 'There is no data to copy from treatment received.'
            });
            return;
        }

        TreatmentInfoUtils.treatmentInfoSections.forEach(section => {
            const {fieldName} = section;
            treatmentPlan[fieldName] = []; //Clearing the old items

            if (!ArrayUtil.isEmpty(treatmentReceived[fieldName])) {
                treatmentReceived[fieldName].forEach(item => {
                    treatmentPlan[fieldName].push(TreatmentInfoUtils.getTreatmentPlanItem(item))
                })
            }
        })
        this.forceUpdate();
    }
}
