import React, {Component} from "react";
import {Modal} from "../../../../components";
import DateUtil from "../../../../utils/DateUtil";
import {InputNumber} from "primereact/inputnumber";
import EnumBreakTimeAM from "../../enums/EnumBreakTimeAM";
import EnumBreakTimePM from "../../enums/EnumBreakTimePM";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import "./UpdateStaffPreference.css"
import {extract, isEmpty} from "../../../../utils/ArrayUtil";
import UpdateGeneralAdminPreferences from "./UpdateGeneralAdminPreferences";
import {MultiSelect} from "primereact/multiselect";
import {FormField} from "../../../../components/form";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {cloneDeep, noop, range} from "lodash-es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SchedulingService from "../../../../services/scheduling-service/SchedulingService";
import confirmDialog from "../../../../components/dialog/ConfirmDialog";
import Checkbox from "@material-ui/core/Checkbox";
import NotificationService from "../../../../services/notification-service/NotificationService";

export default class UpdateAdminPreference extends Component {

    constructor(props) {
        super(props);
        this.state = {
            staffPreference: {
                allowedRooms: []
            }
        }
    }

    componentDidMount() {
        RemotingService.getRemoteCall(`api/staff/${this.props.staffId}/preference`, null, staffPreference => {
            staffPreference = staffPreference || {};
            staffPreference.dayPreferences = isEmpty(staffPreference.dayPreferences) ? this.createDummyDayPreferences() : staffPreference.dayPreferences;
            staffPreference.allowedRoomIds = extract(staffPreference.allowedRooms, "id");
            staffPreference.rotationWithStaffIds = extract(staffPreference.rotationWith, "id");
            this.setState({
                staffPreference: staffPreference,
                sundayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "SUNDAY"),
                mondayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "MONDAY"),
                tuesdayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "TUESDAY"),
                wednesdayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "WEDNESDAY"),
                thursdayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "THURSDAY"),
                fridayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "FRIDAY") ??
                    {...cloneDeep(staffPreference.dayPreferences.find(item => item.dayOfWeek === "MONDAY")), dayOfWeek: "FRIDAY"},
                saturdayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "SATURDAY")
            }, () => ValidationUtil.clear(this.formFields));
        });
    }

    createDummyDayPreferences() {
        return [
            {dayOfWeek: "SUNDAY"},
            {dayOfWeek: "MONDAY"},
            {dayOfWeek: "TUESDAY"},
            {dayOfWeek: "WEDNESDAY"},
            {dayOfWeek: "THURSDAY"},
            {dayOfWeek: "FRIDAY"},
            {dayOfWeek: "SATURDAY"}
        ];
    }

    savePreferences(forceUpdate = false) {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }
        const dayPreferences = [this.state.sundayPreference, this.state.mondayPreference, this.state.tuesdayPreference, this.state.wednesdayPreference, this.state.thursdayPreference, this.state.fridayPreference, this.state.saturdayPreference];

        if (!this.isNumberOfCheckedDaysValid(this.state.staffPreference.rotationPreference, dayPreferences, this.state.staffPreference.allowedNoOfDaysPerWeeks)) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please select ' + (this.state.staffPreference.rotationPreference == 'FIXED' ?
                    'as many days as the minimum of allowed days.' : 'at least as many days as the maximum of allowed days')
            });
            return;
        }

        let staffPreference = {
            ...this.state.staffPreference,
            dayPreferences: [
                {...this.state.sundayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.sundayPreference)},
                {...this.state.mondayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.mondayPreference)},
                {...this.state.tuesdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.tuesdayPreference)},
                {...this.state.wednesdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.wednesdayPreference)},
                {...this.state.thursdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.thursdayPreference)},
                {...this.state.fridayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.fridayPreference)},
                {...this.state.saturdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.saturdayPreference)}
            ]
        };

        const successCallback = (staffPreference) => {
            this.props.closeAction(staffPreference);
            this.props.onStaffProfileUpdate();
        };

        const messageCallback = messages => {
            const infoMessages = RemotingService.extractMessages(messages, "INFO");
            const warningMessages = RemotingService.extractMessages(messages, "WARNING");
            const errorMessages = RemotingService.extractMessages(messages, "ERROR");

            if (isEmpty(errorMessages)) {
                RemotingService.showInfos(infoMessages);
                RemotingService.showWarnings(warningMessages);
            } else {
                const confirmationText =
                    <div style={{width: 400}} className="d-flex flex-column">
                        <ul>
                            {errorMessages.map(errorMessage => <li>{errorMessage}</li>)}
                        </ul>
                        <span>The changes will not take effect until the provider update.</span>
                        <span>Do you want to continue anyway?</span>
                    </div>;

                confirmDialog("Confirm Preferences", confirmationText, () => this.savePreferences(true, false));
            }
        };

        RemotingService.postRemoteCall(`api/staff/${this.props.staffId}/admin-preference?forceUpdate=${forceUpdate}`, staffPreference, staffPreference => {
            successCallback(staffPreference)
        }, noop, true, false, messageCallback);
    }

    isNumberOfCheckedDaysValid = (rotationPreference, dayPreferences, noOfDaysPerWeek) => {
        const numberOfCheckedDays = dayPreferences.map(preference => preference.isPreferredDay).filter(item => item).length;

        if (rotationPreference === "FIXED") {
            return numberOfCheckedDays == Math.min(...noOfDaysPerWeek)
        } else if (rotationPreference === "FLOATING") {
            return numberOfCheckedDays >= Math.max(...noOfDaysPerWeek)
        } else return true;
    }

    canSelectPreferredDay = (rotationPreference, dayPreferences, noOfDaysPerWeek, increment) => {
        const updatedDayPreferences = [this.state.sundayPreference, this.state.mondayPreference, this.state.tuesdayPreference, this.state.wednesdayPreference, this.state.thursdayPreference, this.state.fridayPreference, this.state.saturdayPreference];
        return (rotationPreference === "FLOATING") || (rotationPreference === "FIXED" &&
            updatedDayPreferences.map(preference => preference.isPreferredDay).filter(item => item).length + increment <= Math.min(...noOfDaysPerWeek));
    }

    copySundayToEveryDay() {
        this.setState({
            mondayPreference: {...cloneDeep(this.state.sundayPreference), dayOfWeek: "MONDAY"},
            tuesdayPreference: {...cloneDeep(this.state.sundayPreference), dayOfWeek: "TUESDAY"},
            wednesdayPreference: {...cloneDeep(this.state.sundayPreference), dayOfWeek: "WEDNESDAY"},
            thursdayPreference: {...cloneDeep(this.state.sundayPreference), dayOfWeek: "THURSDAY"},
            fridayPreference: {...cloneDeep(this.state.sundayPreference), dayOfWeek: "FRIDAY"},
            saturdayPreference: {...cloneDeep(this.state.sundayPreference), dayOfWeek: "SATURDAY"}
        });
    }

    render() {
        this.formFields = [];
        return <Modal visible={true}
                      fullScreen
                      header="Preference"
                      submitContent="Save"
                      submitAction={() => this.savePreferences(false, false)}
                      closeAction={() => this.props.closeAction()}>
            <div className="container-fluid">
                <UpdateGeneralAdminPreferences ref={formField => this.formFields.push(formField)}
                                               staffPreference={this.state.staffPreference}
                                               staffId={this.props.staffId}
                                               onChange={(pref) => {
                                                   this.setState({staffPreference: {...this.state.staffPreference, ...pref}})
                                               }}/>
                <table id="dayPrefTable" className="table mt-3">
                    <thead>
                    <tr className={"text-center first-line align-middle"}>
                        <th colSpan={3} className={"bg-white"}></th>
                        <th colSpan={4}>Lunch Time</th>
                        <th colSpan={2} maxWidth={150}>Break Time</th>
                        <th rowSpan={2} width={100}>Number of Patient <br/> <small className={"text-muted"}>(Per
                            Day)</small></th>
                        <th colSpan={2} width={150} className="pb-0">New Patient / New Complaint <br/><small
                            className={"text-muted"}>(Per Day)</small></th>
                        <th colSpan={2} width={200}>Duration Of Session</th>
                    </tr>
                    <tr className={"second-line text-center"}>
                        <th>Preferred Day</th>
                        <th className="merge-cell"></th>
                        <th>Start Time</th>
                        <th>Time</th>
                        <th>Duration</th>
                        <th>Time</th>
                        <th>Duration</th>
                        <th>AM</th>
                        <th>PM</th>
                        <th>%</th>
                        <th><span className={"font-weight-light"}>(% of) </span> Extra Time</th>
                        <th><FontAwesomeIcon className="new-complaint-color" icon={["fas", "circle"]}/> New Patient</th>
                        <th><FontAwesomeIcon className="follow-up-color" icon={["fas", "circle"]}/> Follow Up</th>
                    </tr>
                    </thead>
                    <tbody>
                    <StaffDayPreferenceRow name="Sunday" staffPreference={this.state.staffPreference}
                                           ref={formField => this.formFields.push(formField)}
                                           dayPreference={this.state.sundayPreference}
                                           onChange={(pref) => {
                                               this.state.sundayPreference = pref
                                           }}
                                           canSelectPreferredDay={this.canSelectPreferredDay}
                                           isNumberOfCheckedDaysValidByRotationPreference={this.isNumberOfCheckedDaysValid}/>
                    <StaffDayPreferenceRow name="Monday" staffPreference={this.state.staffPreference}
                                           ref={formField => this.formFields.push(formField)}
                                           dayPreference={this.state.mondayPreference}
                                           onChange={(pref) => {
                                               this.state.mondayPreference = pref
                                           }}
                                           canSelectPreferredDay={this.canSelectPreferredDay}
                                           isNumberOfCheckedDaysValidByRotationPreference={this.isNumberOfCheckedDaysValid}/>
                    <StaffDayPreferenceRow name="Tuesday" staffPreference={this.state.staffPreference}
                                           ref={formField => this.formFields.push(formField)}
                                           dayPreference={this.state.tuesdayPreference}
                                           onChange={(pref) => {
                                               this.state.tuesdayPreference = pref
                                           }}
                                           canSelectPreferredDay={this.canSelectPreferredDay}
                                           isNumberOfCheckedDaysValidByRotationPreference={this.isNumberOfCheckedDaysValid}/>
                    <StaffDayPreferenceRow name="Wednesday" staffPreference={this.state.staffPreference}
                                           ref={formField => this.formFields.push(formField)}
                                           dayPreference={this.state.wednesdayPreference}
                                           onChange={(pref) => {
                                               this.state.wednesdayPreference = pref
                                           }}
                                           canSelectPreferredDay={this.canSelectPreferredDay}
                                           isNumberOfCheckedDaysValidByRotationPreference={this.isNumberOfCheckedDaysValid}/>
                    <StaffDayPreferenceRow name="Thursday" staffPreference={this.state.staffPreference}
                                           ref={formField => this.formFields.push(formField)}
                                           dayPreference={this.state.thursdayPreference}
                                           onChange={(pref) => {
                                               this.state.thursdayPreference = pref
                                           }}
                                           canSelectPreferredDay={this.canSelectPreferredDay}
                                           isNumberOfCheckedDaysValidByRotationPreference={this.isNumberOfCheckedDaysValid}/>
                    <StaffDayPreferenceRow name="Friday" staffPreference={this.state.staffPreference}
                                           ref={formField => this.formFields.push(formField)}
                                           dayPreference={this.state.fridayPreference}
                                           onChange={(pref) => {
                                               this.state.fridayPreference = pref
                                           }}
                                           canSelectPreferredDay={this.canSelectPreferredDay}
                                           isNumberOfCheckedDaysValidByRotationPreference={this.isNumberOfCheckedDaysValid}/>
                    <StaffDayPreferenceRow name="Saturday" staffPreference={this.state.staffPreference}
                                           ref={formField => this.formFields.push(formField)}
                                           dayPreference={this.state.saturdayPreference}
                                           onChange={(pref) => {
                                               this.state.saturdayPreference = pref
                                           }}
                                           canSelectPreferredDay={this.canSelectPreferredDay}
                                           isNumberOfCheckedDaysValidByRotationPreference={this.isNumberOfCheckedDaysValid}/>
                    </tbody>
                </table>
                <div onClick={() => this.copySundayToEveryDay()} style={{cursor: 'pointer', width: 'fit-content'}}>
                    <FontAwesomeIcon icon={["fas", "copy"]}/>
                    <a> Copy sunday's preferences to everyday</a>
                </div>
            </div>
        </Modal>
    }

}

class StaffDayPreferenceRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dayPreference: props.dayPreference || {},
            lunch1DateTime: (props.dayPreference && props.dayPreference.lunchBreak) ? DateUtil.parseTime(props.dayPreference.lunchBreak.lunch1Time) : null,
            lunch2DateTime: (props.dayPreference && props.dayPreference.lunchBreak) ? DateUtil.parseTime(props.dayPreference.lunchBreak.lunch2Time) : null,
            startTimes: ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00"],
            lunchDurations: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
            noOfPatientPerDays: range(6, 41)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dayPreference != this.props.dayPreference) {
            this.setState({
                dayPreference: this.props.dayPreference,
                lunch1DateTime: (this.props.dayPreference && this.props.dayPreference.lunchBreak) ? DateUtil.parseTime(this.props.dayPreference.lunchBreak.lunch1Time) : null,
                lunch2DateTime: (this.props.dayPreference && this.props.dayPreference.lunchBreak) ? DateUtil.parseTime(this.props.dayPreference.lunchBreak.lunch2Time) : null,
            });
        }
    }

    handleFieldChange = (field, value) => {
        this.state.dayPreference[field] = value;
        this.setState({dayPreference: this.state.dayPreference}, () => {
            this.props.onChange(this.state.dayPreference);
        });
    }

    canSelectDayPreference = (value, rotationPreference, dayPreferences, noOfDaysPerWeek) => {
        return !value || this.props.canSelectPreferredDay(rotationPreference, dayPreferences, noOfDaysPerWeek, value ? 1 : -1);
    }

    validate() {
        return ValidationUtil.isValid(this.formFields, false);
    }

    clear() {
        ValidationUtil.clear(this.formFields);
    }

    render() {
        const {
            newPatient,
            followUp
        } = SchedulingService.calculateSessionDurations(this.props.staffPreference, this.state.dayPreference);
        const {staffPreference} = this.props;
        const isPreferredDaySelectable = staffPreference?.rotationPreference == "FIXED" || staffPreference?.rotationPreference == "FLOATING";
        this.formFields = [];
        return <tr className={"preference-day-row"}>
            <td>
                {isPreferredDaySelectable && <Checkbox
                    onClick={event => {
                        const value = event.target.checked;
                        if (this.canSelectDayPreference(value, staffPreference.rotationPreference, staffPreference.dayPreferences, staffPreference.allowedNoOfDaysPerWeeks)) {
                            this.handleFieldChange("isPreferredDay", value)
                        }
                    }}
                    checked={this.state.dayPreference.isPreferredDay ? this.state.dayPreference.isPreferredDay : false}
                />}
            </td>
            <td className={"text-left"}>{this.props.name}</td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           validateOn={this.state.dayPreference.allowedStartTimes}>
                    <MultiSelect selectedItemsLabel="{0} selected" maxSelectedLabels={1}
                                 options={this.state.startTimes}
                                 value={this.state.dayPreference.allowedStartTimes}
                                 onChange={event => this.handleFieldChange("allowedStartTimes", event.value)}/>
                </FormField>
            </td>
            <td>
                {this.state.dayPreference.lunchBreak ? this.state.dayPreference.lunchBreak.lunch1Time : null}
            </td>
            <td>
                {this.state.dayPreference.lunchBreak ? this.state.dayPreference.lunchBreak.lunch1Duration : null}
            </td>
            <td>
                {this.state.dayPreference.lunchBreak ? this.state.dayPreference.lunchBreak.lunch2Time : null}
            </td>
            <td>
                {this.state.dayPreference.lunchBreak ? this.state.dayPreference.lunchBreak.lunch2Duration : null}
            </td>
            <td>
                {EnumBreakTimeAM[this.state.dayPreference.breakTimeAM]}
            </td>
            <td>
                {EnumBreakTimePM[this.state.dayPreference.breakTimePM]}
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           validateOn={this.state.dayPreference.allowedNoOfPatientPerDays}>
                    <MultiSelect selectedItemsLabel="{0} selected"
                                 options={this.state.noOfPatientPerDays}
                                 value={this.state.dayPreference.allowedNoOfPatientPerDays}
                                 onChange={event => this.handleFieldChange("allowedNoOfPatientPerDays", event.value)}/>
                </FormField>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <FormField ref={formField => this.formFields.push(formField)}
                               required min={0}
                               validateOn={this.state.dayPreference.minPercentageOfNewPatientPerDay}>
                        <InputNumber value={this.state.dayPreference.minPercentageOfNewPatientPerDay}
                                     onChange={event => this.handleFieldChange("minPercentageOfNewPatientPerDay", event.value)}/>
                    </FormField>
                    -
                    <FormField ref={formField => this.formFields.push(formField)}
                               required max={100} min={this.state.dayPreference.minPercentageOfNewPatientPerDay}
                               validateOn={this.state.dayPreference.maxPercentageOfNewPatientPerDay}>
                        <InputNumber value={this.state.dayPreference.maxPercentageOfNewPatientPerDay}
                                     onChange={event => this.handleFieldChange("maxPercentageOfNewPatientPerDay", event.value)}/>
                    </FormField>
                </div>
            </td>
            <td>
                <div className="d-flex">
                    <FormField ref={formField => this.formFields.push(formField)}
                               required min={0}
                               validateOn={this.state.dayPreference.minPercentageOfExtraTimeForPatient}>
                        <InputNumber value={this.state.dayPreference.minPercentageOfExtraTimeForPatient}
                                     onChange={event => this.handleFieldChange("minPercentageOfExtraTimeForPatient", event.value)}/>
                    </FormField>
                    -
                    <FormField ref={formField => this.formFields.push(formField)}
                               required max={100} min={this.state.dayPreference.minPercentageOfExtraTimeForPatient}
                               validateOn={this.state.dayPreference.maxPercentageOfExtraTimeForPatient}>
                        <InputNumber value={this.state.dayPreference.maxPercentageOfExtraTimeForPatient}
                                     onChange={event => this.handleFieldChange("maxPercentageOfExtraTimeForPatient", event.value)}/>
                    </FormField>
                </div>
            </td>

            <td>{newPatient}</td>
            <td>{followUp}</td>
        </tr>
    }

}
