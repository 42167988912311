import React from 'react';
import StringUtil from "../../../../utils/StringUtil";
import DateUtil from "../../../../utils/DateUtil";
import PersonalInformationSpecialStatus from './specialstatus/PersonalInformationSpecialStatus';
import EmergencyContact from './emergencycontact/EmergencyContact';
import IdCardStatus from "../../../insurance/IdCardStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PersonalInformationUpdateModal from "./PersonalInformationUpdateModal";
import EnumGender from "../../../../enums/EnumGender";
import EnumVisaStatus from "./enums/EnumVisaStatus";
import PatientProfileImage from "./PatientProfileImage";
import PatientUtil from "../../../../utils/PatientUtil";
import PatientProfileStatus from "./PatientProfileStatus";
import dialog from "../../../../components/dialog/Dialog";
import PhoneNumberUtil from "../../../../utils/PhoneNumberUtil";

class PersonalInformation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            personalInformation: this.props.personalInformation
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.personalInformation) !== JSON.stringify(this.props.personalInformation)){
            this.setState({...this.state, personalInformation: this.props.personalInformation})
        }
    }

    showUpdateModal() {
        dialog({
            title: "Personal Information",
            component: <PersonalInformationUpdateModal personalInformation={this.state.personalInformation}
                                                       onUpdated={this.handlePersonalInformationUpdate}/>
        });
    }

    handlePersonalInformationUpdate = () => {
        this.props.onPatientInformationUpdate();
    };

    render() {
        const personalInformation = this.state.personalInformation;
        const editable = this.props.editable;

        return (
            <>
                <div className="card" style={{minWidth:"380px", maxWidth:"915px", minHeight:"502px"}}>
                    <div className="card-header">
                        <div className="card-header-title">
                            <strong style={{verticalAlign: "middle"}}>Personal Information</strong>
                        </div>
                        <div className="card-header-actions">
                            <IdCardStatus patientId={personalInformation.id}
                                          hasIdDocument={personalInformation.hasIdDocument}
                                          editable={editable}/>
                            {
                                editable && <FontAwesomeIcon icon={["fas", "edit"]} onClick={() => this.showUpdateModal()}/>
                            }
                        </div>
                    </div>
                    <div className="card-body line-wrapper">
                            <span style={{fontWeight: 500}}>
                                MRN:
                                <strong style={{color: '#97cd31', fontSize: '1.25em', marginLeft: "5px"}}>
                                    {StringUtil.mrn(personalInformation.mrn)}
                                </strong>
                            </span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3" style={{minWidth: '80px !important', textAlign: "center"}}>
                                <PatientProfileImage patientId={this.state.personalInformation.id}/>
                                <PatientProfileStatus patient={this.state.personalInformation} formUpdateSwitch={this.props.formUpdateSwitch}/>
                            </div>
                            <div className="col-md-9 form-horizontal">
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Name:</label>
                                    <div className="col-sm-8">
                                        {PatientUtil.getPatientName(personalInformation.firstName, personalInformation.middleName, personalInformation.lastName)}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">ID No:</label>
                                    <div className="col-sm-8">
                                        {PatientUtil.getPatientId(personalInformation)}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Date of Birth:</label>
                                    <div className="col-sm-8">
                                        {
                                            personalInformation.birthday ?
                                                `${DateUtil.formatDateMMM(personalInformation.birthday)} / ${DateUtil.getAge(personalInformation.birthday)}`
                                                : '-'
                                        }
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Gender:</label>
                                    <div className="col-sm-8">
                                        {personalInformation.gender ? EnumGender[personalInformation.gender] : '-'}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Nationality:</label>
                                    <div className="col-sm-8" style={{whiteSpace:"normal"}}>
                                        {personalInformation.nationality ? personalInformation.nationality.nameInEnglish : '-'}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Visa Status:</label>
                                    <div className="col-sm-8">
                                        {personalInformation.visaStatus ? EnumVisaStatus[personalInformation.visaStatus] : '-'}
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Phone:</label>
                                    <div className="col-sm-8">
                                        {PhoneNumberUtil.formatInternational(personalInformation.mobilePhone)}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">E-mail:</label>
                                    <div className="col-sm-8">
                                        {personalInformation.email}
                                    </div>
                                </div>
                                <div className="form-group row" style={{whiteSpace: "normal"}}>
                                    <label className="control-label col-sm-4">Address:</label>
                                    <div className="col-sm-8">
                                        {personalInformation.address}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PersonalInformationSpecialStatus specialStatusList={personalInformation.specialStatusList}
                                                      patientId={personalInformation.id}
                                                      editable={editable}/>
                    <EmergencyContact emergencyContact={personalInformation.emergencyContact}
                                      patientId={personalInformation.id}
                                      editable={editable}/>
                </div>
            </>
        );
    }

}

export default PersonalInformation;