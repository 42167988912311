import Enum from "../../../../../../enums/Enum";

const RelationshipEnum = new Enum({
        MOTHER: {name: "Mother", nameInArabic: "الأم"},
        FATHER: {name: "Father", nameInArabic: "الأب"},
        SISTER: {name: "Sister", nameInArabic: "الاخت"},
        BROTHER: {name: "Brother", nameInArabic: "الأخ"},
        GRANDMOTHER: {name: "Grandmother", nameInArabic: "الجدة"},
        GRANDFATHER: {name: "Grandfather", nameInArabic: "الجد"},
        AUNT: {name: "Aunt", nameInArabic: "العمة/ الخالة"},
        UNCLE: {name: "Uncle", nameInArabic: "العم/الخال"}
});

export default RelationshipEnum;