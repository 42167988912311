import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Checkbox} from "primereact/checkbox";
import {MultipleHeaderAccordion} from "../../../../components/accordion/MultipleHeaderAccordion";
import "./VisitHistory.css"
import AllergyViewHistory from "./views/subjective/AllergyViewHistory";
import DiagnosisViewHistory from "./views/DiagnosisViewHistory";
import VitalSignsViewHistory from "./views/subjective/VitalSignsViewHistory";
import EnumAppointmentType from "../../../appointment/enums/EnumAppointmentType";
import {Column} from "primereact/column";
import {TreatmentHistory} from "./views/treatment/TreatmentHistory";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import DateUtil from "../../../../utils/DateUtil";
import LegacyVisitHistory from "./views/LegacyVisitHistory";
import {AssessmentHistory} from "./views/assessment/AssessmentHistory";
import {SubjectiveHistory} from "./views/subjective/SubjectiveHistory";
import VisitForms from "../visitforms/VisitForms";
import BillingViewHistory from "./views/BillingViewHistory";
import moment from "moment";

const NUMBER_OF_ROWS = 10;

export default class VisitHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedVisitIndex: null,
            selectedVisit: null,
            selectedLegacyVisit: null,
            checked: false,
            visitData: [],
            tableEntries: [],
            hideData: true,

            paginatorStartIndex: 0,
            paginatorData: [],
            isPaginatorLoading: false,
            sortField : "date",
            sortOrder: -1
        };

        this.onRowSelect = this.onRowSelect.bind(this);
        this.checkboxTemplate = this.checkboxTemplate.bind(this);
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/visit_history', {patientId: this.props.patient.id}, (result) => {
            this.setState({
                    visitData: result,
                    paginatorData: result.slice(this.state.paginatorStartIndex, this.state.paginatorStartIndex + NUMBER_OF_ROWS)
            });
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (!prevProps.selected && this.props.selected) {
            this.setState({selectedVisit: null, selectedLegacyVisit: null, selectedVisitIndex: null});
        }

        if( JSON.stringify(this.props.visitMetaData) !== JSON.stringify(prevProps.visitMetaData)){
            this.setState({
                visitData: this.props.visitMetaData,
                paginatorData: this.props.visitMetaData.slice(this.state.paginatorStartIndex, this.state.paginatorStartIndex + NUMBER_OF_ROWS
                )});
        }
    }

    onPage = (event) => {
        this.setState({isPaginatorLoading: true})

        //imitate delay of a backend call
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + NUMBER_OF_ROWS;

            this.setState({
                paginatorStartIndex: startIndex,
                paginatorData: this.state.visitData.slice(startIndex, endIndex),
                isPaginatorLoading: false
            })
        }, 150);
    }

    onSort = (event) => {
        const {sortOrder , sortField} = event;
        let sorted = this.sortVisitHistory(this.state.visitData , sortField , sortOrder);
        this.setState({
            paginatorData:sorted,
            sortOrder: sortOrder,
            sortField : sortField,
        });
    }

    render() {
        return (
            <div className={"visit-history px-4"}>

                <div style={{marginTop: "10px"}}>
                    <DataTable responsive autoLayout
                               emptyMessage="No records"
                               value={this.state.paginatorData} loading={this.state.isPaginatorLoading}
                               rows={NUMBER_OF_ROWS} totalRecords={this.state.visitData.length} first={this.state.paginatorStartIndex}
                               onPage={this.onPage} onSort={this.onSort} sortOrder={this.state.sortOrder} sortField={this.state.sortField}
                               lazy paginator paginatorPosition="bottom"
                               rowClassName={visit => {
                                   return {'legacy-visit': visit.legacyVisit};
                               }}>
                        <Column field="checkbox" header=" " body={this.checkboxTemplate}/>
                        <Column field="type" header="Type" body={this.typeTemplate}/>
                        <Column field="date" header="Date" body={this.dateTemplate} sortable={true}/>
                        <Column field="time" header="Time" body={this.timeTemplate}/>
                        <Column field="providerName" header="Provider Name" body={this.providerNameTemplate}/>
                        <Column field="diagnosis" header="Diagnosis" body={this.diagnosisTemplate}/>
                        <Column field="department" header="Department" body={this.departmentTemplate}/>
                        <Column field="location" header="Location" body={this.locationTemplate}/>
                        <Column field="emrStatus" header="EMR Status" body={this.emrStatusTemplate}/>
                    </DataTable>
                </div>

                {this.state.selectedLegacyVisit &&
                <LegacyVisitHistory legacyVisit={this.state.selectedLegacyVisit}/>}

                {!this.state.selectedLegacyVisit && this.state.selectedVisit &&
                <div className={"visit-history-body"}>
                    <MultipleHeaderAccordion
                        headerText={{firstHeaderText: "Allergies", secondHeaderText: "Vital Signs"}}
                        headerExist={{secondHeader: true, thirdHeader: false}}
                        secondHeaderAlignment={"left"}
                        flexBasis={"50%"}
                        alignItems="flex-start"
                        expanded={true}
                        hideIcon>

                        <div className={"col-6"}>
                            <AllergyViewHistory
                                allergy={this.state.selectedVisit?.subjective?.allergy}/>
                        </div>

                        <div className={"col-6 pl-3"}>
                            <VitalSignsViewHistory
                                vitalSigns={this.state.selectedVisit?.assessment?.vitalSigns}/>
                        </div>

                    </MultipleHeaderAccordion>

                    <MultipleHeaderAccordion
                        headerText={{firstHeaderText: "Diagnosis", secondHeaderText: "Billing"}}
                        headerExist={{secondHeader: true, thirdHeader: false}}
                        secondHeaderAlignment={"left"}
                        flexBasis={"50%"}
                        alignItems="flex-start"
                        expanded={true}
                        hideIcon>

                        <div className={"col-6"}>
                            <DiagnosisViewHistory
                                diagnosis={this.state.selectedVisit?.diagnosis}/>
                        </div>

                        <div className={"col-6 pl-3"}>
                            <BillingViewHistory diagnosis={this.state.selectedVisit?.diagnosis}
                                                insurance={this.props.patient.insurance}
                                                visit={this.state.selectedVisit}/>
                        </div>

                    </MultipleHeaderAccordion>

                    <MultipleHeaderAccordion
                        headerText={{firstHeaderText: "Clinical Impression"}}
                        headerExist={{secondHeader: false, thirdHeader: false}}
                        flexBasis={"50%"}
                        alignItems="flex-start"
                        expanded={true}
                        hideIcon>

                        <div>
                            <div className="pl-2">
                                {this.state.selectedVisit?.diagnosis.clinicalImpression ||
                                "No information available"}
                            </div>
                        </div>

                    </MultipleHeaderAccordion>

                    <SubjectiveHistory visit={this.state.selectedVisit}/>
                    <AssessmentHistory visit={this.state.selectedVisit}/>
                    <TreatmentHistory visit={this.state.selectedVisit}/>

                    <MultipleHeaderAccordion
                        headerText={{firstHeaderText: "Forms"}}
                        headerExist={{secondHeader: false, thirdHeader: false}}
                        defaultExpanded={true}>
                        <div className="col-12">
                            <VisitForms visitId={this.state.selectedVisit.id}
                                        patientId={this.props.patient.id}
                                        viewOnly/>
                        </div>
                    </MultipleHeaderAccordion>
                </div>
                }
            </div>

        );
    }

    onRowSelect(event, index, visitId, isLegacy) {
        if (event.checked) {
            if (isLegacy) {
                RemotingService.getRemoteCall('api/visit_history/legacy_visit/' + visitId, null, (result) => {
                    this.setState({selectedLegacyVisit: result, selectedVisit: null, selectedVisitIndex: index});
                });
            } else {
                RemotingService.getRemoteCall('api/visit_history/visit/' + visitId, null, (result) => {
                    this.setState({selectedLegacyVisit: null, selectedVisit: result, selectedVisitIndex: index});
                });
            }
        } else {
            this.setState({selectedVisit: null, selectedLegacyVisit: null, selectedVisitIndex: null});
        }

    }

    checkboxTemplate(data, column) {
        return <> <Checkbox inputId={"entry" + column.rowIndex}
                            checked={this.state.selectedVisitIndex === column.rowIndex}
                            onChange={e => this.onRowSelect(e, column.rowIndex, data.id, data.legacyVisit)}/> </>
    }

    typeTemplate(data, column) {
        return data.type ? <> {EnumAppointmentType[data.type].name} </> : ""
    }

    dateTemplate(data, column) {
        return data.date ? DateUtil.formatDateMMM(data.date) : "";
    }

    timeTemplate(data, column) {
        return data.date ? DateUtil.getHourMinute12H(data.date) : "";
    }

    providerNameTemplate(data, column) {
        return <> {data.provider} </>
    }

    diagnosisTemplate(data, column) {
        return <> {data.diagnosis} {data.diagnosisCode}  </>
    }

    departmentTemplate(data, column) {
        return <> {data.department} </>
    }

    locationTemplate(data, column) {
        return <> {data.location} </>
    }

    emrStatusTemplate(data, column) {
        return <> {data.status} </>
    }

    sortVisitHistory(historyList , field , order) {
        if(order === -1)
             return historyList.sort((a, b) => (moment(a[field]).isAfter(moment(b[field]))) ? -1 : 1);
        else
            return historyList.sort((a, b) => (moment(a[field]).isBefore(moment(b[field]))) ? -1 : 1);
    }
}
