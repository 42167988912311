import React, {Component} from "react";
import MobilizationEnum from "../../../treatment/enums/treatmentrecieved/MobilizationEnum";
import ThermalTherapyEnum from "../../../treatment/enums/treatmentrecieved/ThermalTherapyEnum";
import TherapeuticExerciseEnum from "../../../treatment/enums/treatmentrecieved/TherapeuticExerciseEnum";
import ProceduresEnum from "../../../treatment/enums/treatmentrecieved/ProceduresEnum";
import ManipulationEnum from "../../../treatment/enums/treatmentrecieved/ManipulationEnum";
import TreatmentInfoUtils from "../../../treatment/utils/TreatmentInfoUtils";
import TreatmentInfoEnum from "../../../treatment/modals/TreatmentInfoEnum";
import DiagnosisGroup from "../../../treatment/views/DiagnosisGroup";

export default class TreatmentInfoHistoryView extends Component {

    render() {
        if (!this.props.treatmentInfo) {
            return null;
        }

        const tables = [
            this.treatmentInfoTypeATable("Manipulation", "manipulation", ManipulationEnum),
            this.treatmentInfoTypeATable("Mobilization", "mobilization", MobilizationEnum),
            this.treatmentInfoTypeBTable("Stretching", "stretching"),
            this.treatmentInfoTypeBTable("Dry Needling", "dryNeedling"),
            this.treatmentInfoTypeBTable("Acupuncture", "acupuncture"),
            this.treatmentInfoTypeBTable("Electrotherapy", "electrotherapy"),
            this.treatmentInfoTypeBTable("Shockwave", "shockwave"),
            this.treatmentInfoTypeBTable("Cupping", "cupping"),
            this.treatmentInfoTypeBTable("Taping", "taping"),
            this.treatmentInfoTypeBTable("Therapeutic Massage", "therapeuticMassage"),
            this.treatmentInfoTypeBTable("Lymphatic Drainage", "lymphaticDrainage"),
            this.treatmentInfoTypeATable("Thermal Therapy", "thermalTherapy", ThermalTherapyEnum),
            this.treatmentInfoTypeCTable("Therapeutic Exercise", "therapeuticExercise", TherapeuticExerciseEnum),
            this.treatmentInfoTypeBTable("Gait Training", "gaitTraining"),
            this.treatmentInfoTypeBTable("Breathing Patterns", "breathingPatterns"),
            this.props.type == TreatmentInfoEnum.TREATMENT_RECEIVED && this.treatmentReceivedProcedureTable("Procedures", "procedures", ProceduresEnum),
            this.props.type == TreatmentInfoEnum.TREATMENT_PLAN && this.treatmentPlanProcedureTable("Procedures", "procedures", ProceduresEnum),
            this.treatmentInfoTypeBTable("Other", "other")
        ].filter(table => !!table);

        return (
            <div className="container" style={{maxWidth: '100%'}}>
                <div className="row">
                    {
                        tables.map((table, index) => {
                                return <>
                                    <div className={index === (tables.length - 1) ? "col-6" : "col"}>
                                        {table}
                                    </div>
                                    {(index + 1) % 2 === 0 && <div className="w-100"></div>}
                                </>;
                            }
                        )
                    }
                </div>
            </div>
        )
    }

    treatmentInfoTypeATable = (title, field, dropdownEnum) => {
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length == 0) {
            return null;
        }

        return <div className="pt-2">
            <h5 className="font-weight-bold pl-2">{title}</h5>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Type</th>
                    <th>Area of Focus</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{dropdownEnum[item.type].name}</td>
                                    <td>{item.areaOfFocus}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
        </div>
    }

    treatmentInfoTypeBTable = (title, field) => {
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length == 0) {
            return null;
        }

        return <div className="pt-2">
            <h5 className="font-weight-bold pl-2">{title}</h5>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Area of Focus</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{item.areaOfFocus}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
        </div>
    }

    treatmentInfoTypeCTable = (title, field, dropdownEnum) => {
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length == 0) {
            return null;
        }

        return <div className="pt-2">
            <h5 className="font-weight-bold pl-2">{title}</h5>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Focus</th>
                    <th>Exercise</th>
                    <th>Weight</th>
                    <th>Sets</th>
                    <th>Reps</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{item.focus ? dropdownEnum[item.focus].name : ''}</td>
                                    <td>{item.exercise}</td>
                                    <td>{item.weight}</td>
                                    <td>{item.sets}</td>
                                    <td>{item.reps}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
        </div>
    }

    treatmentPlanProcedureTable = (title, field, dropdownEnum) => {
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length == 0) {
            return null;
        }

        return <div className="pt-2">
            <h5 className="font-weight-bold pl-2">{title}</h5>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Type</th>
                    <th>Area of Focus</th>
                    <th>Details</th>
                    <th>Number of Sessions</th>
                    <th>Approval Required</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{dropdownEnum[item.type] == null ? item.type : dropdownEnum[item.type].name}</td>
                                    <td>{item.areaOfFocus}</td>
                                    <td>{item.details}</td>
                                    <td>{item.noOfSessions}</td>
                                    <td>{item.approvalRequired ? "Yes" : "No"}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
        </div>
    }

    treatmentReceivedProcedureTable = (title, field, dropdownEnum) => {
        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length == 0) {
            return;
        }

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-4 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-8"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length != 1 &&
                            index != this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    {item.diagnosisGroup != null && <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Diagnosis</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}><DiagnosisGroup diagnosisGroup={item.diagnosisGroup} showSecondaryItems/></div>
                                    </div>}
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Type</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{dropdownEnum[item.type] != null ? dropdownEnum[item.type].name : item.type}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        {TreatmentInfoUtils.getTreatmentReceivedProcedureList(item)}
                                    </div>
                                    {(item.details != null && item.details.length != 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

}
