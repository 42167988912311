import React, {Component} from "react";
import {FileUploader, Modal} from "../../../../components";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {Card, Image} from "react-bootstrap";

class StaffProfileImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photoModalVisible: false,
            photoValue: null,
            newPhotoValue: null,
            photoChanged: false
        };
    }

    componentDidMount() {
        this.retrievePhoto();
    }

    retrievePhoto = () => {
        RemotingService.getRemoteCall('api/staff/' + this.props.staffId + '/photo', null, (dto) => {
            this.setState({photoValue: dto, newPhotoValue: dto});
        });
    }

    showPhotoUpdate = () => {
        if (this.props.editable) {
            this.setState({photoModalVisible: true, photoChanged: false});
        }
    }

    renderPhotoUpdateModal = () => {
        return this.state.photoModalVisible ?
            (<Modal visible={this.state.photoModalVisible}
                    header="Photo"
                    submitContent="Upload"
                    submitAction={this.handlePhotoUpdate}
                    closeAction={() => this.setState({
                        photoModalVisible: false,
                        newPhotoValue: this.state.photoValue
                    })}>
                {this.photoUpdateModalBody()}
            </Modal>)
            : null;
    }

    photoUpdateModalBody = () => {
        return (
            <div style={{paddingTop: '5px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'space-between',
                        justifyContent: 'center'
                    }}>
                        <FileUploader
                            value={this.state.newPhotoValue}
                            onChange={(value) => this.handlePhotoChange(value)}/>

                    </div>
                </div>
            </div>
        );
    }

    handlePhotoChange = (value) => {
        this.setState({newPhotoValue: value, photoChanged: true});
    };

    handlePhotoUpdate = () => {
        if (this.state.photoChanged) {
            this.uploadFile(this.state.newPhotoValue, (fileValue) => {
                this.setState({photoValue: fileValue})
            });
        }

        this.setState({photoModalVisible: false});
    }

    uploadFile = (fileValue, callBack = () => {
    }) => {
        if (fileValue) {
            RemotingService.postRemoteCall('api/staff/' + this.props.staffId + '/photo', fileValue, () => {
                this.setState({photoModalVisible: false});
                callBack(fileValue);
            });
        } else {
            RemotingService.deleteRemoteCall('api/staff/' + this.props.staffId + '/photo', null, () => {
                this.setState({photoModalVisible: false});
                callBack(fileValue);
            });
        }
    }

    render() {
        let photoSrc = process.env.PUBLIC_URL + '/assets/user.png';
        if (this.state.photoValue && this.state.photoValue.content) {
            photoSrc = 'data:image/jpeg;base64,' + this.state.photoValue.content;
        }
        return (
            <>
                <div style={{paddingTop: '20px', display: 'flex', justifyContent: 'center'}}>
                    <Image width='80px' rounded src={photoSrc} onmouseover=''
                           style={this.props.editable ? {cursor: 'pointer'} : null}
                           onClick={this.showPhotoUpdate}/>
                </div>
                {this.renderPhotoUpdateModal()}
            </>
        );
    }

}

export default StaffProfileImage;