import React from "react";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import {downloadBlob} from "../../../../../utils/FileDownloader";
import dialog from "../../../../../components/dialog/Dialog";
import ReduxService from "../../../../../services/redux-service/ReduxService";
import {noop} from "lodash-es";

const download = (file) => {
    RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
        .then((response) => {
            downloadBlob(new Blob([response.data]), file.name);
        });
}

const downloadAsBlob = (uuid, successCallback = noop) => {
    ReduxService.incrementRemotingOperationCount();

    RemotingService.instance.get(`api/file/download/${uuid}`, {responseType: 'blob'})
        .then((response) => {
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            successCallback(blob);
        })
        .finally(() => {
            ReduxService.decrementRemotingOperationCount();
        });
}

const viewEnable = (fileName) => {
    return isPdf(fileName) || isImage(fileName);
}

function viewFile({fileName, blob, id = "appointmentDocumentViewer", windowWidth = "800px", windowHeight = "600px"}) {
    const blobUrl = window.URL.createObjectURL(blob);
    if (viewEnable(fileName)) {
        dialog({
            title: fileName,
            component: <>
                {
                    isImage(fileName) ?
                        <img src={blobUrl} alt="" style={{maxWidth: 900}}/>
                        : <iframe id={id} src={blobUrl} width={windowWidth} height={windowHeight}></iframe>
                }
            </>
        });
    }
}

const isImage = (fileName) => {
    return fileName.endsWith('.jpg')
        || fileName.endsWith('.jpeg')
        || fileName.endsWith('.png');
}

const isPdf = (fileName) => {
    return fileName.endsWith('.pdf');
}

export default {download, downloadAsBlob, viewFile, viewEnable, isImage, isPdf};