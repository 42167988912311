import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import * as ArrayUtil from "../../../../../utils/ArrayUtil";

export default class AllergyView extends Component {

    render() {
        if(this.props.allergy == null) {
            return (<></>);
        }
        return(
                <table className={"emr-table"}>
                    <tbody>
                    <tr>
                        <th style={{textAlign: "left"}}>Allergen</th>
                        <th>Medication</th>
                        <th>Food</th>
                        <th>Seasonal</th>
                        <th>Pet</th>
                        <th>Environmental</th>
                    </tr>
                    <tr>
                        <th style={{textAlign: "left"}}>Allergen Type</th>
                        <td>{ArrayUtil.isEmpty(this.props.allergy.ingredients) ? "" : this.props.allergy.ingredients.join(', ')}</td>
                        <td>{ArrayUtil.isEmpty(this.props.allergy.food) ? "" : this.props.allergy.food.join(', ')}</td>
                        <td>{ArrayUtil.isEmpty(this.props.allergy.seasonal) ? "" : this.props.allergy.seasonal.join(', ')}</td>
                        <td>{ArrayUtil.isEmpty(this.props.allergy.pet) ? "" : this.props.allergy.pet.join(', ')}</td>
                        <td>{ArrayUtil.isEmpty(this.props.allergy.environmental) ? "" : this.props.allergy.environmental.join(', ')}</td>
                    </tr>
                    <tr style={{backgroundColor: "white", textAlign: "left"}}>
                        <td colSpan={"6"} style={{paddingLeft: "6px"}}>
                            <strong>Other Allergies / Sensitivities: </strong>
                            {ArrayUtil.isEmpty(this.props.allergy.other) ? "" : this.props.allergy.other.join(', ')}
                        </td>
                    </tr>
                    </tbody>
                </table>


        );
    }
}