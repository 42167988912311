import React, {Component} from 'react';
import PropTypes from "prop-types";
import {fabric} from "fabric"
import omit from "lodash-es/omit";
import EnumBodySilhouetteSignTypes from "./EnumBodySilhouetteSignTypes";

class CanvasLabel extends Component {
    static propTypes = {
        canvas: PropTypes.object,
        label: PropTypes.string,
        top: PropTypes.number,
        left: PropTypes.number,
        width: PropTypes.number,
        fontSize: PropTypes.number,
    }

    static defaultProps = {
        top: 535,
        left: 25,
        fontWeight: "bold",
        fontSize: 24,
        fontFamily: 'Lato',
        selectable: false,
        evented: false,
        type: EnumBodySilhouetteSignTypes.LABEL,
    }

    componentDidMount() {
        const options = omit(this.props, ['canvas'])
        const text = new fabric.Textbox(this.props.label, options);
        this.props.canvas.add(text);
    }

    render() {
        return null;
    }
}

export default CanvasLabel;