import React, {Component} from "react";
import "../../visithistory/VisitHistory.css";
import RelationshipEnum from "../../subjective/enums/history/RelationshipEnum";
import ReportViewUtils from "./ReportViewUtils";

export default class FamilyHistoryReportView extends Component {
    render() {
        const familyHistory = this.props.familyHistory;

        if (ReportViewUtils.isFamilyHistoryNull(familyHistory)) {
            return null;
        }

        if(familyHistory.none){
            return (<div>No Family History</div>)
        }

        return (
            <div style={{paddingLeft: 10}}>
                {familyHistory.items &&
                familyHistory.items.map(item =>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <b>{item.condition}</b>
                        </div>
                        <div className={"col-9"}>
                            {RelationshipEnum[item.relationship] ? ": " + RelationshipEnum[item.relationship]?.name : ""}
                            {item.detail == null ? "" : (", " + item.detail)}
                        </div>
                    </div>
                )}
            </div>
        );
    }

}
