import React, {Component} from 'react';
import Modal from '../../components/modal/Modal';
import {Badge, Button} from 'react-bootstrap';
import FileUploader from '../../components/file-uploader/FileUploader';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from "../../services/remoting-service/RemotingService";

class IdCardStatus extends Component {

    static ID_FRONT = 'ID_FRONT';
    static ID_BACK = 'ID_BACK';

    state = {
        showIdModalVisible: false,
        idFrontfileValue: null,
        idFrontfileNewValue: null,
        idBackfileValue: null,
        idBackfileNewValue: null,
        idFrontfileChanged: false,
        idBackfileChanged: false,
        hasIdDocument: this.props.hasIdDocument
    };

    retrieveFile(isUploaded, documentType, callBack) {
        if (isUploaded) {
            RemotingService.getRemoteCall('api/patient/' + this.props.patientId + '/document/' + documentType, null, callBack);
        }
    };

    showIdModal() {
        if (!(this.state.idFrontfileValue && this.state.idBackfileValue)) {
            RemotingService.getRemoteCall('api/patient/' + this.props.patientId + '/card-status', null, (cardStatusDTO) => {
                this.retrieveFile(cardStatusDTO.idFrontUploaded, IdCardStatus.ID_FRONT,
                    (dto) => this.setState({idFrontfileValue: dto, idFrontfileNewValue: dto}));

                this.retrieveFile(cardStatusDTO.idBackUploaded, IdCardStatus.ID_BACK,
                    (dto) => this.setState({idBackfileValue: dto, idBackfileNewValue: dto}));

                this.setState({...cardStatusDTO});
            });
        }

        this.setState({
            showIdModalVisible: true,
            idFrontfileChanged: false,
            idBackfileChanged: false,
            idFrontfileNewValue: this.state.idFrontfileValue,
            idBackfileNewValue: this.state.idBackfileValue
        });
    }

    render() {
        return (
            <>
                <Button variant="outline-primary" onClick={() => this.showIdModal()} style={{margin: '10px'}}>
                    <FontAwesomeIcon icon={["fas", "address-card"]}/>
                    {' ID'}
                    {
                        this.state.hasIdDocument ?
                            <Badge pill variant="success" size="sm" style={{marginLeft: '5px'}}> ✓</Badge>
                            :
                            <Badge pill variant="danger" size="sm" style={{marginLeft: '5px'}}> X</Badge>
                    }
                </Button>
                {this.renderIdModal()}
            </>
        );
    }

    renderIdModal() {
        return this.state.showIdModalVisible ?
            (<Modal visible={this.state.showIdModalVisible}
                    header="ID Card"
                    submitContent="Upload"
                    submitAction={() => this.handleUpdateIdModal()}
                    submitDisabled={!this.props.editable}
                    closeAction={() => this.setState({showIdModalVisible: false})}>
                {this.renderIdModalBody()}
            </Modal>)
            : null;
    }

    renderIdModalBody() {
        return (
            <div style={{paddingTop: '5px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'space-between',
                        justifyContent: 'center'
                    }}>
                        <FileUploader
                            value={this.state.idFrontfileNewValue}
                            onChange={(newValue) => this.handleidFrontfileChange(newValue)}/>

                    </div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'space-between',
                        justifyContent: 'center',
                        paddingTop: '15px'
                    }}>
                        <FileUploader
                            value={this.state.idBackfileNewValue}
                            onChange={(newValue) => this.handleidBackfileChange(newValue)}/>
                    </div>
                </div>
            </div>
        );
    }

    handleidFrontfileChange(newValue) {
        this.setState({idFrontfileNewValue: newValue, idFrontfileChanged: true});
    };

    handleidBackfileChange(newValue) {
        this.setState({idBackfileNewValue: newValue, idBackfileChanged: true});
    };

    handleUpdateIdModal() {
        if (this.state.idFrontfileChanged) {
            this.uploadFile(IdCardStatus.ID_FRONT, this.state.idFrontfileNewValue,
                () => {
                    this.setState({
                        idFrontfileValue: this.state.idFrontfileNewValue,
                        hasIdDocument: this.state.idBackfileValue || !!this.state.idFrontfileNewValue
                    })
                });
        }
        if (this.state.idBackfileChanged) {
            this.uploadFile(IdCardStatus.ID_BACK, this.state.idBackfileNewValue,
                () => {
                    this.setState({
                        idBackfileValue: this.state.idBackfileNewValue,
                        hasIdDocument: this.state.idFrontfileValue || !!this.state.idBackfileNewValue
                    })
                });
        }
        this.setState({showIdModalVisible: false});
    }

    uploadFile(documentType, fileValue, callBack) {
        if (fileValue) {
            RemotingService.postRemoteCall('api/patient/' + this.props.patientId + '/document/' + documentType, fileValue, () => {
                this.setState({showIdModalVisible: false});
                callBack(fileValue);
            });
        } else {
            RemotingService.deleteRemoteCall('api/patient/' + this.props.patientId + '/document/' + documentType, null, () => {
                this.setState({showIdModalVisible: false});
                callBack(fileValue);
            });
        }
    }
}

export default IdCardStatus;