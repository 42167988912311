import React, {Component} from "react";
import "./PublicForm.css";
import {NumberInput, TextInput, Wizard,} from "../../../components";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import {YesNoRadioInput} from "./YesNoRadioInput";
import Logo from "./Logo";
import RemotingService from "../../../services/remoting-service/RemotingService";
import DateUtil from "../../../utils/DateUtil";
import moment from "moment";
import FormField from "../../../components/form/FormField";
import {Arrow, Circle, Cross} from "../BodySilhouette";
import BodySilhouettePatientCanvas from "../BodySilhouette/BodySilhouettePatientCanvas";
import EnumBodySilhouetteSignTypes from "../BodySilhouette/EnumBodySilhouetteSignTypes";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import CheckboxInput from "../../../components/checkbox-input/CheckboxInput";
import FamilySelect from "../FamilySelect";
import ConsentFormPartsDxb from "../utils/ConsentFormPartsDxb";
import FormLanguages from "../enum/FormLanguages";
import PublicIntakeFormPatientInformation from "../intakeform/public/PublicIntakeFormPatientInformation";
import PublicIntakeFormReferralInformation from "../intakeform/public/PublicIntakeFormReferralInformation";
import PublicIntakeFormContactInformation from "../intakeform/public/PublicIntakeFormContactInformation";
import PublicIntakeFormEmergencyContactInformation
    from "../intakeform/public/PublicIntakeFormEmergencyContactInformation";
import PublicIntakeFormComplaint from "../intakeform/public/PublicIntakeFormComplaint";
import PublicIntakeFormCurrentPatientCondition from "../intakeform/public/PublicIntakeFormCurrentPatientCondition";
import PublicIntakeFormDescribeSymptoms from "../intakeform/public/PublicIntakeFormDescribeSymptoms";
import PublicSymptomsWorseIn from "../intakeform/public/PublicSymptomsWorseIn";
import PublicMakesSymptomsWorse from "../intakeform/public/PublicMakesSymptomsWorse";
import PublicMakesSymptomsBetter from "../intakeform/public/PublicMakesSymptomsBetter";
import PublicSymptomsInterfereWith from "../intakeform/public/PublicSymptomsInterfereWith";
import PublicHadAnyXray from "../intakeform/public/PublicHadAnyXray";
import PublicIntakeFormHaveYouExperienced from "../intakeform/public/PublicIntakeFormHaveYouExperienced";
import PublicIntakeFormHealthHistory from "../intakeform/public/PublicIntakeFormHealthHistory";
import PublicIntakeFormHealthHistoryPage2 from "../intakeform/public/PublicIntakeFormHealthHistoryPage2";
import PublicIntakeFormCurrentHealth from "../intakeform/public/PublicIntakeFormCurrentHealth";
import PublicIntakeFormCurrentHealthPage2 from "../intakeform/public/PublicIntakeFormCurrentHealthPage2";
import InformedConsentFormDxbTextEnum from "../informedconsent/enum/InformedConsentFormDxbTextEnum";
import InformedConsentSignatureInputs from "../informedconsent/InformedConsentSignatureInputs";
import IntakeFormTextEnum from "../intakeform/enum/IntakeFormTextEnum";
import CancellationPolicyPartsDxb from "../cancellationpolicy/CancellationPolicyPartsDxb";
import CancellationPolicyDxbTextEnum from "../cancellationpolicy/enum/CancellationPolicyDxbTextEnum";

export default class PublicNewPatientFormDxb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bodySilhouetteChilds: [],
            bodySilhouetteObjects: [],
            patientSignature: null,
        };
    }

    componentDidMount() {
        this.retrieveNationalities();
        this.retrievePatientPhoto();

        const {form} = this.props;

        if (form.consentForIntakeForm.dob && !form.consentForIntakeForm.age) {
            form.consentForIntakeForm.age = DateUtil.getAgeWithMonths(moment(form.consentForIntakeForm.dob));
        }

        if (form.consentForIntakeForm.bodySilhouette && form.consentForIntakeForm.bodySilhouette.length) {
            const childs = form.consentForIntakeForm.bodySilhouette.map((mark) => {
                return this.findMarkType(mark);
            });
            this.state.bodySilhouetteChilds = [...childs];
        }

        if (form.consentForInformedConsent.patientSignature) {
            this.setState({patientSignature: form.consentForInformedConsent.patientSignature});
        }

        if (form.consentForIntakeForm.describeMainProblem) {
            this.setState({describeMainProblem: form.consentForIntakeForm.describeMainProblem});
        }

        this.forceUpdate();
    }

    retrieveNationalities = () => {
        RemotingService.getRemoteCall(
            "api/public/reference/nationality",
            null,
            (nationalities) => {
                this.setState({nationalities});
            }
        );
    };

    retrievePatientPhoto = () => {
        const {consentFormCode: code} = this.props;

        RemotingService.getRemoteCall(
            `api/public/consent-form/code/${code}/patient-photo`,
            null,
            (profilePhoto) => {
                this.setState({
                    photoValue: profilePhoto,
                    newPhotoValue: profilePhoto,
                });
            }
        );
    };

    close = () => {
        confirmDialog(
            "Confirmation",
            "Are you sure you want to close this form? All input will be lost.",
            this.props.close
        );
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            this.updateFormBodySilhouette(form);
            this.updatePatientSignature(form);
            RemotingService.postRemoteCall(
                `api/public/consent-form/code/${code}/new-patient-form-dxb`,
                form
            );
            this.props.onFormSubmit(form);
        }
    };

    updatePatientSignature = (form) => {
        form.consentForIntakeForm.patientSignature = this.state.patientSignature;
        form.consentForInformedConsent.patientSignature = this.state.patientSignature;
        form.cancellationPolicy.patientSignature = this.state.patientSignature;
    };

    createHeader = (form, selectedLanguage) => {
        let headerClass = selectedLanguage === "Arabic"
            ? "arabic-form d-flex justify-content-between align-items-center"
            : "d-flex justify-content-between align-items-center";
        return (
            <div style={{padding: "0px 20px 0px 20px"}}>
                <div className="row text-left">
                    <div className="col-12 no-padding">
                        <Logo/>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12 text-center no-padding"
                        style={{fontSize: 20, fontWeight: "bold"}}
                    >
                        {IntakeFormTextEnum.New_Patient_Form_Title[selectedLanguage]}
                    </div>
                </div>
                <div className={headerClass} style={{fontSize: 11}}>
                    <div className="mb-0">
                        <b>{IntakeFormTextEnum.Date[selectedLanguage]}: </b> <span
                        dir="ltr">{DateUtil.formatDateMMM(form.consentForIntakeForm.consentDate)}</span>
                    </div>
                    <div className="mb-0">
                        <b>{IntakeFormTextEnum.MRN[selectedLanguage]}: </b> {form.consentForIntakeForm.patientMrn}
                    </div>
                </div>
            </div>
        );
    };

    fieldChanged = (formName, fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    fieldInformedConsentFormChanged = (fieldName, value) => {
        this.props.form.consentForInformedConsent[fieldName] = value;
        this.forceUpdate();
    };

    fieldIntakeFormChanged = (fieldName, value) => {
        this.props.form.consentForIntakeForm[fieldName] = value;
        this.forceUpdate();
    };

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }


    textField = (form, fieldName) => {
        return (
            <TextInput
                className="form-control"
                value={form[fieldName]}
                onChange={(value) => fieldName !== "postalCode" || !isNaN(value) ? this.fieldIntakeFormChanged(fieldName, value) : null}
            />
        );
    };

    validatedTextField = (form, fieldName) => {
        return (
            <FormField
                ref={(ref) => this.pushFormFieldRef(ref)}
                required
                validateOn={form[fieldName]}
            >
                <TextInput
                    className="form-control"
                    value={form[fieldName]}
                    alphabeticOnly={fieldName === "emergencyContactName"}
                    onChange={(value) => this.fieldIntakeFormChanged(fieldName, value)}
                />
            </FormField>
        );
    };

    pushFormFieldRef = (ref) => this.formFields.push(ref);

    numberInput = (form, fieldName, props= {}) => {
        return (
            <NumberInput
                className="form-control"
                min={0}
                value={form[fieldName]}
                {...props}
                onChange={(value) => this.fieldIntakeFormChanged(fieldName, value)}/>
        )
    }

    checkBoxField = (form, fieldName, label) => {
        return (
            <div className="form-check form-check-inline">
                <CheckboxInput
                    className="form-check-input"
                    value={form[fieldName]}
                    onChange={(value) => this.fieldIntakeFormChanged(fieldName, value)}
                />
                <label className="form-check-label">{label}</label>
            </div>
        );
    };

    updateFormBodySilhouette = (form) => {
        const bodySilhouetteMarkList = [];
        const bodySilhouetteObject = [...this.state.bodySilhouetteObjects];

        bodySilhouetteObject.filter(obj => obj.type !== "textbox").forEach((mark) =>
            bodySilhouetteMarkList.push({
                type: mark.type,
                topDistance: mark.top,
                leftDistance: mark.left,
                angle: mark.angle,
                scaleX: mark.scaleX,
                scaleY: mark.scaleY,
            })
        );

        form.consentForIntakeForm.bodySilhouette = bodySilhouetteMarkList;
    };

    addBodyMark = (item) => {
        const bodySilhouetteComponents = [...this.state.bodySilhouetteChilds];
        bodySilhouetteComponents.push(item);
        const bodySilhouetteChilds = bodySilhouetteComponents.map((child) =>
            child != null ? React.cloneElement(child, {}) : null
        );
        this.setState({bodySilhouetteChilds: bodySilhouetteChilds});
    };

    deleteBodyMark = () => {
        const bodySilhouetteComponents = [...this.state.bodySilhouetteChilds];
        bodySilhouetteComponents.pop();
        const bodySilhouetteChilds = bodySilhouetteComponents.map((child) =>
            child != null ? React.cloneElement(child, {}) : null
        );
        this.setState({bodySilhouetteChilds: bodySilhouetteChilds});
    };

    findMarkType = (mark) => {
        const props = {
            top: mark.topDistance ? mark.topDistance : mark.top,
            left: mark.leftDistance ? mark.leftDistance : mark.left,
            angle: mark.angle,
            scaleX: mark.scaleX,
            scaleY: mark.scaleY,
        };
        if (mark.type === EnumBodySilhouetteSignTypes.CROSS)
            return <Cross {...props} />;
        else if (mark.type === EnumBodySilhouetteSignTypes.ARROW)
            return <Arrow {...props} />;
        else if (mark.type === EnumBodySilhouetteSignTypes.CIRCLE)
            return <Circle {...props} />;
    };

    forceFormUpdate = () => {
        this.forceUpdate();
    }

    render() {
        this.formFields = [];

        const {form} = this.props;
        const {photoValue, nationalities} = this.state;
        const photoSrc = photoValue
            ? `data:image/jpeg;base64,${photoValue.content}`
            : `${process.env.PUBLIC_URL}/assets/patient_profile.png`;

        const selectedLanguage = this.props.language;
        const formBodyClass = selectedLanguage == FormLanguages.ARABIC.name ? "arabic-form" : "";
        const enumLabel = selectedLanguage == FormLanguages.ARABIC.name ? "nameInArabic" : "name";
        const nationalityLabel = selectedLanguage == FormLanguages.ARABIC.name ? "nameInArabic" : "nameInEnglish";
        const commonIntakeFormProps = {
            selectedLanguage,
            enumLabel,
            nationalityLabel,
            form: form.consentForIntakeForm, // TODO: This should split to the components
            textField: this.textField,
            validatedTextField: this.validatedTextField,
            pushFormFieldRef: this.pushFormFieldRef,
            onChange: this.fieldIntakeFormChanged,
            formFields: this.formFields,
            forceFormUpdate: this.forceFormUpdate,
            patientId: this.props.patientId
        }

        const commonInformedConsentProps = {
            selectedLanguage,
            pushFormFieldRef: this.pushFormFieldRef
        }

        return (
            <Wizard
                show={true}
                fullScreen
                footerStyle={{backgroundColor: "#f5f5f5"}}
                buttonStyle={{backgroundColor: "#84BE56", border: "none"}}
                previousButtonStyle={{
                    backgroundColor: "#fff",
                    color: "rgb(132, 190, 86)",
                    borderColor: "rgb(132, 190, 86)"
                }}
                header={this.createHeader(form, selectedLanguage)}
                close={this.close}
                onSubmit={this.submitForm}
                previousBtnText={IntakeFormTextEnum.Wizard_Previous[selectedLanguage]}
                nextBtnText={IntakeFormTextEnum.Wizard_Next[selectedLanguage]}
                saveBtnText={IntakeFormTextEnum.Wizard_Save[selectedLanguage]}
            >
                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormPatientInformation {...commonIntakeFormProps}
                                                            photoSrc={photoSrc}
                                                            nationalities={nationalities}
                                                            numberInput={this.numberInput}/>
                        <PublicIntakeFormReferralInformation {...commonIntakeFormProps}
                                                             consentFormCode={this.props.consentFormCode}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormContactInformation {...commonIntakeFormProps}
                                                            nationalities={nationalities}/>
                        <PublicIntakeFormEmergencyContactInformation {...commonIntakeFormProps}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + ' m-4'}>
                        <h3 className={'text-center mb-4'}>
                            <b>{CancellationPolicyDxbTextEnum.Cancellation_Policy_Public_Title[selectedLanguage]}</b>
                        </h3>

                        <CancellationPolicyPartsDxb.Part1 selectedLanguage={selectedLanguage}/>

                        <div style={{backgroundColor: "white"}}>
                            <CancellationPolicyPartsDxb.Part2 selectedLanguage={selectedLanguage}/>
                        </div>

                        <CancellationPolicyPartsDxb.Part3 selectedLanguage={selectedLanguage}
                                                          patientName={form.cancellationPolicy.patientFullName}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + ' m-4'}>
                        <h3 className={'text-center mb-4'}>
                            <b>{InformedConsentFormDxbTextEnum.Informed_Consent[selectedLanguage]}</b>
                        </h3>

                        <ConsentFormPartsDxb.Introduction {...commonInformedConsentProps}/>
                        <ConsentFormPartsDxb.Benefits {...commonInformedConsentProps}/>
                        <ConsentFormPartsDxb.Risks {...commonInformedConsentProps}/>
                        <ConsentFormPartsDxb.Alternatives {...commonInformedConsentProps}/>
                        <ConsentFormPartsDxb.InjectionAndMedication {...commonInformedConsentProps}/>
                        <ConsentFormPartsDxb.DataCollectionAndPrivacy {...commonInformedConsentProps}/>
                        <ConsentFormPartsDxb.QuestionsOfConcerns {...commonInformedConsentProps}/>
                        <ConsentFormPartsDxb.Complaints {...commonInformedConsentProps}/>
                        <ConsentFormPartsDxb.ConsentTable {...commonInformedConsentProps}
                                                          formFields={this.formFields}
                                                          readOnly={false}
                                                          traineeConsent={form.consentForInformedConsent.traineeConsent}
                                                          onChange={this.fieldInformedConsentFormChanged}
                                                          enumLabel={enumLabel}/>
                        <ConsentFormPartsDxb.Conclusion {...commonInformedConsentProps}/>
                        <InformedConsentSignatureInputs {...commonInformedConsentProps}
                                                        readOnly={false}
                                                        formFields={this.formFields}
                                                        textEnum={InformedConsentFormDxbTextEnum}
                                                        consentDate={DateUtil.formatDateDo(form.consentForInformedConsent.consentDate)}
                                                        patientName={form.consentForInformedConsent.patientFullName}
                                                        providerName={form.consentForInformedConsent.providerName}
                                                        guardianName={form.consentForInformedConsent.guardianName}
                                                        guardianRelationship={form.consentForInformedConsent.guardianRelationship}
                                                        patientSignature={this.state.patientSignature}
                                                        providerSignature={form.consentForInformedConsent.providerSignature}
                                                        onSavePatientSignature={this.onSavePatientSignature}
                                                        onChange={this.fieldInformedConsentFormChanged}/>

                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormComplaint {...commonIntakeFormProps} />
                        <div className="panel bg-white">
                            <p>
                                {IntakeFormTextEnum.Please_Mark_Where_It_Hurts[selectedLanguage]}
                            </p>
                            <BodySilhouettePatientCanvas
                                selectedLanguage={selectedLanguage}
                                disabled={false}
                                onClickAddCross={(top, left) =>
                                    this.addBodyMark(<Cross top={top} left={left}/>)
                                }
                                onClickAddCircle={(top, left) =>
                                    this.addBodyMark(<Circle top={top} left={left}/>)
                                }
                                onClickAddArrow={(top, left) =>
                                    this.addBodyMark(<Arrow top={top} left={left}/>)
                                }
                                onRemoveItem={() => this.deleteBodyMark()}
                                onPageChange={(objects) => {
                                    let bodySilhouetteChilds = objects.map((mark) => {
                                        return this.findMarkType(mark);
                                    });
                                    this.setState({
                                        bodySilhouetteObjects: [...objects],
                                        bodySilhouetteChilds: bodySilhouetteChilds,
                                    });
                                }}
                            >
                                {this.state.bodySilhouetteChilds}
                            </BodySilhouettePatientCanvas>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormCurrentPatientCondition {...commonIntakeFormProps} />
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormDescribeSymptoms {...commonIntakeFormProps}
                                                          checkBoxField={this.checkBoxField}/>
                        <PublicSymptomsWorseIn {...commonIntakeFormProps}/>
                        <PublicMakesSymptomsWorse {...commonIntakeFormProps} checkBoxField={this.checkBoxField}/>
                        <PublicMakesSymptomsBetter {...commonIntakeFormProps} checkBoxField={this.checkBoxField}/>
                        <PublicSymptomsInterfereWith {...commonIntakeFormProps} checkBoxField={this.checkBoxField}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicHadAnyXray {...commonIntakeFormProps}/>
                        <PublicIntakeFormHaveYouExperienced {...commonIntakeFormProps}
                                                            yesNoDescribeField={this.yesNoDescribeField}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormHealthHistory {...commonIntakeFormProps}
                                                       selfFamilyDescribeField={this.selfFamilyDescribeField}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormHealthHistoryPage2 {...commonIntakeFormProps}
                                                            yesNoDescribeFieldEqualWidth={this.yesNoDescribeFieldEqualWidth}/>
                        <PublicIntakeFormCurrentHealth {...commonIntakeFormProps}
                                                       yesNoDescribeFieldEqualWidth={this.yesNoDescribeFieldEqualWidth}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormCurrentHealthPage2 {...commonIntakeFormProps}
                                                            yesNoDescribeFieldShort={this.yesNoDescribeFieldShort}/>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }

    yesNoDescribeField = (form, fieldName, label, enumLabel) => {
        return (
            <div className="row">
                <div className="col-8 d-flex justify-content-start align-items-center">
                    <label className="col-form-label">{label}</label>
                </div>
                <div class="col d-flex align-items-center">
                    <YesNoRadioInput
                        value={form[fieldName]}
                        onValueChange={(value) => {
                            this.fieldIntakeFormChanged(fieldName, value);
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
            </div>
        );
    };

    yesNoDescribeFieldEqualWidth = (form, fieldName, label, enumLabel) => {
        return (
            <div className="row">
                <div className="col-8 d-flex justify-content-start align-items-center">
                    <label className="col-form-label">{label}</label>
                </div>
                <div class="col d-flex align-items-center">
                    <YesNoRadioInput
                        value={form[fieldName]}
                        onValueChange={(value) => {
                            this.fieldIntakeFormChanged(fieldName, value);
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
            </div>
        );
    };

    yesNoDescribeFieldShort = (form, fieldName, label, enumLabel) => {
        return (
            <div className="row">
                <div className="col-3 d-flex justify-content-start align-items-center">
                    <label className="col-form-label">{label}</label>
                </div>
                <div class="col d-flex align-items-center">
                    <YesNoRadioInput
                        value={form[fieldName]}
                        onValueChange={(value) => {
                            this.fieldIntakeFormChanged(fieldName, value);
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
            </div>
        );
    };

    selfFamilyDescribeField = (form, selfFieldName, familyFieldName, familyWhoFieldName, label, enumLabel) => {
        return (
            <div className="row">
                <div className="col-6 d-flex justify-content-start align-items-center">
                    <label className="col-form-label">{label}</label>
                </div>
                <div class="col-2 d-flex align-items-center justify-content-center">
                    <YesNoRadioInput
                        value={form[selfFieldName]}
                        onValueChange={(value) => {
                            this.fieldIntakeFormChanged(selfFieldName, value);
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
                <div class="col-2 d-flex align-items-center justify-content-center">
                    <YesNoRadioInput
                        value={form[familyFieldName]}
                        onValueChange={(value) => {
                            this.fieldIntakeFormChanged(familyFieldName, value);
                            if (value === "No") {
                                this.fieldIntakeFormChanged(familyWhoFieldName, null);
                            }
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
                <div className="col-2">
                    {form[familyFieldName] === "Yes" &&
                    <FamilySelect value={form[familyWhoFieldName]}
                                  style={{width: 150}}
                                  enumLabel={enumLabel}
                                  onChange={(value) => {
                                      this.fieldIntakeFormChanged(familyWhoFieldName, value);
                                  }}/>
                    }
                </div>
            </div>
        );
    };
}
