import React, {Component} from "react";
import PropTypes from "prop-types";
import "./SelectInput.css";
import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";

export default class SelectInput extends Component {
    static defaultProps = {
        id: null,
        className: "",
        value: "",
        disabled: false,
        placeholder: "",
        hidden: false,
        onChange: null,
        onClose: null,
        style: null,
        options: null,
        showClear: true,
        searchEnabled: true,
        displayProperty: null,
        valueProperty: null,
        multiSelect: false,
        appendTo: document.body
    };

    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        value: PropTypes.any,
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
        hidden: PropTypes.bool,
        onChange: PropTypes.func,
        style: PropTypes.object,
        options: PropTypes.array,
        showClear: PropTypes.bool,
        searchEnabled: PropTypes.bool,
        displayProperty: PropTypes.string,
        valueProperty: PropTypes.string,
        multiSelect: PropTypes.bool,
        appendTo: PropTypes.element || PropTypes.string
    };

    handleOnChange = (e) => {
        this.props.onChange(e.value);
    };

    isNullOrEmpty = (value) => {
        return value === null || value === undefined || value === "";
    }
    render() {
        setTimeout(() => {
            // Prime Dropdown's search option was not working inside the mui dialog because of the "tabIndex = -1" attribute
            // on the MuiDialog-container. So we need to remove that attribute to work them together.
            // document.getElementsByClassName(className).item(n) is not returning an element without timeout.
            const muiContainers = ((document.getElementsByClassName('MuiDialog-container MuiDialog-scrollBody')));
            if(muiContainers?.length > 0) {
                const muiContainer = muiContainers.item(muiContainers.length - 1);
                if(muiContainer != null) {
                    muiContainer.removeAttribute("tabindex");
                }
            }
        }, 0);

        return (
            this.props.multiSelect ?
                <MultiSelect style={this.props.style}
                             panelStyle={this.props.panelStyle}
                             className={this.props.className}
                             panelClassName={this.props.panelClassName}
                             placeholder={this.props.placeholder}
                             disabled={this.props.disabled}
                             showClear={this.props.showClear}
                             filter={this.props.searchEnabled}
                             options={this.props.options}
                             optionLabel={this.props.displayProperty}
                             optionValue={this.props.valueProperty}
                             value={!this.isNullOrEmpty(this.props.value) ? this.props.value : null}
                             appendTo={this.props.appendTo}
                             onChange={(e) => this.handleOnChange(e)}/> :
                <Dropdown style={this.props.style}
                          panelStyle={this.props.panelStyle}
                          className={this.props.className}
                          panelClassName={this.props.panelClassName}
                          placeholder={this.props.placeholder}
                          disabled={this.props.disabled}
                          showClear={this.props.showClear}
                          filter={this.props.searchEnabled}
                          options={this.props.options}
                          optionLabel={this.props.displayProperty}
                          optionValue={this.props.valueProperty}
                          value={!this.isNullOrEmpty(this.props.value) ? this.props.value : null}
                          appendTo={this.props.appendTo}
                          onChange={(e) => this.handleOnChange(e)}/>
        )
    }
}
