import React, {Component} from "react";
import {AutoComplete} from "primereact/autocomplete";
import RemotingService from "../../../../services/remoting-service/RemotingService";

export default class CptCodeSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {cptList: this.props.cptList || []};
    }

    add = (cpt) => {
        const cptList = [...this.state.cptList, cpt]
        this.setState({cptList});

        this.props.onCptSelected(cptList);
    }

    remove = (cpt) => {
        const cptList = this.state.cptList.filter(i => i.id !== cpt.id);
        this.setState({cptList});

        this.props.onCptSelected(cptList);
    }

    cptCodeSearch = (event) => {
        RemotingService.getRemoteCall(`api/cpt/code/${event.query}`, null, cptCodes => {
            cptCodes.forEach(c =>
                c.description = c.insuranceList?.length > 0 ? c.description.toUpperCase() : c.description.toLowerCase());
            this.setState({cptCodeSuggestions: cptCodes});
        });
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <AutoComplete placeholder="Search..."
                              minLength={2}
                              multiple
                              field="cptCode"
                              value={this.props.cptList}
                              onChange={event => {
                                  this.setState({cpt: event.value})
                              }}
                              onSelect={event => {
                                  this.add(event.value);
                              }}
                              onUnselect={event => {
                                  this.remove(event.value);
                              }}
                              suggestions={this.state.cptCodeSuggestions}
                              completeMethod={this.cptCodeSearch}
                              itemTemplate={cpt =>
                                  <span>{cpt.cptCode} - {cpt.description}</span>}/>
            </div>
        );
    }
}
