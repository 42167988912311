import React, {Component} from 'react'
import Logo from "./Logo";
import moment from "moment";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {RadioInput, TextInput, Wizard} from "../../../components";
import FormField from "../../../components/form/FormField";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import ConsentFormsUtil from "../utils/ConsentFormsUtil";
import DateUtil from "../../../utils/DateUtil";

export default class PublicTeleconsultationConsentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            educationalPurposesAnonymously: null,
            educationalPurposesDisclosingName: null,
            socialMediaPurposesAnonymously: null,
            socialMediaPurposesDisclosingName: null
        }
    }

    componentDidMount() {
        const form = this.props.form;

        if (form.participateInTeleconsultation !== null) {
            this.setState({participateInTeleconsultation: form.participateInTeleconsultation});
        }
    }

    createHeader = (form) => {
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    TELECONSULTATION CONSENT FORM
                </div>
            </div>
            <div className="row" style={{fontSize: 11}}>
                <div className="col-4 text-left no-padding">
                    <b>Patient Name: </b> {form.patientFullName}
                </div>
                <div className="col-4 text-center no-padding">
                    <b>MRN No: </b> {form.patientMrn}
                </div>

                <div className="col-4 text-right no-padding">
                    <b>D.O.B: </b> {DateUtil.formatDate(form.patientDOB)}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    assignPurposeValues = (form) => {
        this.fieldChanged("participateInTeleconsultation", this.state.participateInTeleconsultation);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            this.assignPurposeValues(form);
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/teleconsultation-consent-form`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        return (
            <Wizard
                show={true} fullScreen
                footerStyle={{backgroundColor: '#f5f5f5'}}
                buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                header={this.createHeader(form)}
                close={this.close}
                onSubmit={this.submitForm}>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div style={{margin: "20px 20px 20px 20px"}}>
                        <p>
                            <b>The following consent is to be filled & signed electronically. If you were unable to do so, kindly reply to the same email with the following:</b>
                            I {form.patientFullName}, born on {DateUtil.formatDate(form.patientDOB)}, consent to participate in the
                            teleconsultation for the service(s)/procedure(s) mentioned above.
                        </p>

                        <p>
                            It is important for you to consider the benefits, risks and alternatives to the teleconsultation treatment offered by our licensed healthcare professionals in order to make an informed decision about proceeding with treatment.
                            These services include diagnostic procedures, exercise and prescribing medication. To be able to provide you these services, we would like to obtain your consent to access your medical records & have a live audio and video interaction
                        </p>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>
                        <p><b>Electronic systems:</b><br/>
                            Electronic systems used will incorporate network and software security protocols to protect confidentiality of patient identification and imaging data and will include measures to safeguard the data and to ensure its integrity against intentional and unintentional corruption in accordance with the Laws and Regulations.</p>

                        <p><b>Nature of Teleconsultation:</b><br/>
                            Upon booking, you will be receiving a payment link to collect the payment before the scheduled teleconsultation session. During the teleconsultation
                            <ul className={"list-unstyled"}>
                                <li>
                                    <p><span className={"mr-2 d-inline font-weight-bold"}>a.</span>Details of your medical history, examinations, x-rays and tests may be discussed with other healthcare professionals with interactive videos, audio and telecommunication technology.</p>
                                </li>
                                <li>
                                    <p><span className={"mr-2 d-inline font-weight-bold"}>b.</span>Upon your approval, audio and/or photo recordings may be taken for accurate diagnosis, treatment and quality control.</p>
                                </li>
                            </ul>
                        </p>

                        <p><b>Medical Information and Records: </b>All existing federal laws and local regulations/policies/guidelines regarding access to medical information and copies of your Health Records apply to this teleconsultation.
                            Dissemination of any patient identifiable images or information for this telehealth interaction to other entities will not take place without your consent.
                        </p>

                        <p><b>Confidentiality: </b>Responsible and appropriate efforts have been made to eliminate any confidentiality risks associated with the teleconsultation and all existing confidentiality
                            protections under the federal laws and local regulation apply to information disclosed during this teleconsultation.
                        </p>

                        <p><b>Rights: </b>You may withhold or withdraw consent to teleconsultation at any time without affecting your right to future care or treatment.
                        </p>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>
                        <p><b>Questions of concerns</b><br/>
                            You are encouraged to ask questions at any time regarding your assessment and treatment.
                            Bring any concerns you have to your Doctor or Therapist’s attention.
                            If you are not comfortable, you may stop treatment at any time.
                            You have the right to have your concerns heard and resolved when possible.
                        </p>

                        <p><b>Disputes: </b>You agree that any disputes that arise from the teleconsultation will be resolved as per the local laws and regulations and will be raised with the telehealth provider in the first instance.
                        </p>

                        <p><b>Expected Benefits:</b><br/>
                            <ul className={"list-unstyled"}>
                                <li>
                                    <p><span className={"mr-2 d-inline font-weight-bold"}>a.</span>Improve access to medical care by enabling a patient to remain in their home, office (or a remote site) while your licensed healthcare provider offers you the needed care.</p>
                                </li>
                                <li>
                                    <p><span className={"mr-2 d-inline font-weight-bold"}>b.</span>More efficient medical evaluation and management.</p>
                                </li>
                            </ul>
                        </p>

                        <p><b>Possible Risks:</b><br/>
                            As with any medical procedures there are potential risks associated with the use of Telehealth, which may include, but not limited to the following:
                            <ul className={"list-unstyled"}>
                                <li>
                                    <p><span className={"mr-2 d-inline font-weight-bold"}>a.</span>Information transmission may not be sufficient (e.g. poor resolution of images) to allow appropriate decision making by the consulted physician.</p>
                                </li>
                                <li>
                                    <p><span className={"mr-2 d-inline font-weight-bold"}>b.</span>Delays in medical evaluation and treatment could occur due to deficiencies or failure of internet.</p>
                                </li>
                                <li>
                                    <p><span className={"mr-2 d-inline font-weight-bold"}>c.</span>In rare instances, security protocol could fail causing a breach of privacy of personal medical information. </p>
                                </li>
                                <li>
                                    <p><span className={"mr-2 d-inline font-weight-bold"}>d.</span>In rare cases, a lack of access to complete health records may result in adverse drug interactions, allergic reactions, or other judgement errors.  </p>
                                </li>
                            </ul>
                        </p>


                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <p><b>
                            You have been advised of all the risks, consequences and benefits of telehealth. Your treating physician has discussed with you the information provided in a language you can understand.
                            You have had the opportunity to ask questions about the information presented in this form and about the teleconsultation.
                            All your questions have been answered and you understand the written information provided above.
                        </b></p>
                        <div className="row" style={{marginLeft: "0px"}}>
                            <div className="display-flex">
                                I
                                <FormField ref={formField => this.formFields.push(formField)} required
                                           validateOn={this.state.participateInTeleconsultation}>
                                    <RadioInput id="participateInTeleconsultation"
                                                value={this.state.participateInTeleconsultation}
                                                options={["agree", "refuse"]}
                                                onChange={(value) => this.setState({participateInTeleconsultation: value})}/>
                                </FormField>
                                to participate in the teleconsultation for the service(s)/procedure(s)
                                mentioned above.
                            </div>

                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            Date
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + moment(new Date(form.patientDate)).format('MMMM DD, YYYY')}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            Name
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + form.patientFullName}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Patient Signature
                                    </div>

                                    <div className="col-md-12">
                                        <SignatureInput signature={form.patientSignature}
                                                        onClear={() => {
                                                            form.patientSignature = null;
                                                            this.forceUpdate();
                                                        }}
                                                        onSave={(signature) => {
                                                            form.patientSignature = signature;
                                                            this.forceUpdate();
                                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <p>
                            A witness should sign below if the patient is unable to sign but has indicated his or her consent <br/>
                            Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent
                            or Guardian sign here.
                        </p>

                        <div style={{border: "1px solid", borderRadius: "5px", padding: "30px"}}>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                Print Name
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                <TextInput value={form.guardianName} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('guardianName', value)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                Relationship to Patient
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                <TextInput value={form.guardianRelationship} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                Date/Time
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + moment(new Date(form.guardianDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                Name
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + form.patientFullName}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="col-md-12 dim14-form-label">
                                            Signature of Witness/Parent or Guardian
                                        </div>
                                        <div className="col-md-12">
                                            <FormField ref={formField => this.formFields.push(formField)}
                                                       disabled={form.patientSignature != null}
                                                       required requiredMessage="Signature required"
                                                       validateOn={form.guardianSignature}>
                                                <SignatureInput signature={form.guardianSignature}
                                                                onClear={() => {
                                                                    form.guardianSignature = null;
                                                                    this.forceUpdate();
                                                                }}
                                                                onSave={(signature) => {
                                                                    form.guardianSignature = signature;
                                                                    this.forceUpdate();
                                                                }}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Wizard.Page>
            </Wizard>
        )
    }
}