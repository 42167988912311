import Enum from "../../../../../enums/Enum";

const UOMEnum = new Enum({
    AMP: {name: "Amp"},
    BOX: {name: "Box"},
    BOT: {name: "Bot"},
    DROPS: {name: "Drops"},
    EACH: {name: "Each"},
    MG: {name: "mg"},
    ML: {name: "ml"},
    PACK: {name: "Pack"},
    VIAL: {name: "Vial"},
    ROLL: {name: "Roll"},
    BLIST: {name: "Blist"},
    POD: {name: "Pod"},
    TUBE: {name: "Tube"}
});

export default UOMEnum;