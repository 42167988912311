import React, {Component} from "react";
import {isEmpty} from "../../../../../utils/ArrayUtil";
import ReportViewUtils from "./ReportViewUtils";

export default class AllergiesReportView extends Component {
    render() {
        const allergy = this.props.allergy;

        if (ReportViewUtils.isAllergyNull(allergy)) {
            return null;
        }

        if(allergy.none){
            return (<div>None Known</div>)
        }

        return (
            <div className={"visit-history-multiple-content-wrapper"}>
                <div key={"allergyReportView"}>
                    {!isEmpty(allergy.ingredients) &&
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                            Medication: {allergy.ingredients.join(', ')}</div>
                    </div>}
                    {!isEmpty(allergy.food) &&
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                            Food: {allergy.food.join(', ')}</div>
                    </div>}
                    {!isEmpty(allergy.seasonal) &&
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                            Seasonal: {allergy.seasonal.join(', ')}</div>
                    </div>}
                    {!isEmpty(allergy.pet) &&
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                            Pet: {allergy.pet.join(', ')}</div>
                    </div>}
                    {!isEmpty(allergy.environmental) &&
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                            Environmental: {allergy.environmental.join(', ')}</div>
                    </div>}
                </div>
            </div>
        );
    }

}
