import React from "react";
import DateUtil from "../../utils/DateUtil";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import EnumLeaveStatus from "./EnumLeaveStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AttachmentUtils from "../emr/recordpage/treatment/utils/AttachmentUtils";
import EnumLeaveType from "./EnumLeaveType";
import {Dropdown} from "primereact/dropdown";
import ActionMenu from "../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../components/action-menu/ActionMenuItem";
import HRRemoteService from "./HRRemoteService";
import DeleteIcon from "../../components/action-menu/icons/DeleteIcon";
import ConfirmIcon from "../../components/action-menu/icons/ConfirmIcon";
import RejectIcon from "../../components/action-menu/icons/RejectIcon";
import ApproveIcon from "../../components/action-menu/icons/ApproveIcon";

export default class LeaveCalendarAgenda extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    typeTemplate(leave) {
        return (
            <div className="d-flex align-items-center">
                <span className="circle mr-1" style={{backgroundColor: EnumLeaveType[leave.type].color}}></span>
                <span>{EnumLeaveType[leave.type].name}</span>
            </div>
        )
    }

    attachmentTemplate(leave) {
        if (!leave.attachment) {
            return "-";
        }

        return (
            <div style={{cursor: "pointer"}} onClick={() => AttachmentUtils.download(leave.attachment)}>
                <FontAwesomeIcon icon={["fas", "file-download"]} size={"lg"}/>
                <span>{leave.attachment.name}</span>
            </div>
        )
    }

    dateTemplate(date) {
        return DateUtil.formatDate(date);
    }

    approve = (leave) => {
        const id = leave.id;
        const params = {description: null};
        //TODO: We need to implement description

        if (leave.attachment == null && "SICK" == leave.type) {
            HRRemoteService.approveSickLeaveWithoutAttachment(id, params, (leave) => {
                this.props.refreshCalendarData()
            });
        } else {
            HRRemoteService.approve(id, params, (leave) => {
                this.props.refreshCalendarData()
            });
        }
    }

    reject = (leave) => {
        const id = leave.id;
        const params = {description: null};
        //TODO: We need to implement description

        HRRemoteService.reject(id, params, (leave) => {
            this.props.refreshCalendarData()
        });
    }

    delete = (leave) => {
        HRRemoteService.delete(leave, () => {
            this.props.refreshCalendarData()
        })
    }

    actionsTemplate(leave) {
        if(leave.status != EnumLeaveStatus.SUBMITTED.key && leave.status != EnumLeaveStatus.APPROVED.key &&
            leave.status != EnumLeaveStatus.REJECTED.key)
            return null;

        return (
            <ActionMenu>
                {(leave.status == "SUBMITTED" || leave.status == "REJECTED") &&
                    <ActionMenuItem text="Approve" disabled={leave.type == "EXTRA"} icon={<ApproveIcon/>} onClick={() => this.approve(leave)}/>}
                {(leave.status == "SUBMITTED" || leave.status == "APPROVED") &&
                    <ActionMenuItem text="Reject" icon={<RejectIcon/>} onClick={() => this.reject(leave)}/>}
                {(leave.status == "SUBMITTED" || leave.status == "APPROVED" || leave.status == "REJECTED") &&
                    <ActionMenuItem text="Delete" icon={<DeleteIcon/>} onClick={() => this.delete(leave)}/>}
            </ActionMenu>
        )
    }

    render() {
        const {date} = this.props;

        const typeFilter = <Dropdown className="w-100 text-left"
                                     options={EnumLeaveType.toArray()}
                                     optionLabel="name" optionValue="key" showClear
                                     value={this.state.typeFilter}
                                     onChange={e => {
                                         this.leavesTable.filter(e.value, "type", "equals");
                                         this.setState({typeFilter: e.value});
                                     }}/>

        const statusFilter = <Dropdown className="w-100 text-left"
                                       options={EnumLeaveStatus.toArray()}
                                       optionLabel="name" optionValue="key" showClear
                                       value={this.state.statusFilter}
                                       onChange={e => {
                                           this.leavesTable.filter(e.value, "status", "equals");
                                           this.setState({statusFilter: e.value});
                                       }}/>

        return (
            <DataTable ref={el => this.leavesTable = el}
                       value={this.props.leaves}
                       emptyMessage={"There are no appointments for selected filters on day " + DateUtil.formatDateDo(date)}>
                <Column header="Name" field="staffName" sortable={true} filter={true} filterMatchMode="contains"/>
                <Column header="Type" field="type" body={leave => this.typeTemplate(leave)} sortable={true} filter={true}
                        filterElement={typeFilter}/>
                <Column header="Status" field="status" body={leave => EnumLeaveStatus[leave.status].name} sortable={true} filter={true}
                        filterElement={statusFilter}/>
                <Column header="Start" field="startTime" body={leave => this.dateTemplate(leave.startTime)} sortable={true}/>
                <Column header="End" field="endTime" body={leave => this.dateTemplate(leave.endTime)} sortable={true}/>
                <Column header="Description" field="latestComment.comment" sortable={true} filter={true} filterMatchMode="contains"/>
                <Column header="Attachment" body={leave => this.attachmentTemplate(leave)} sortable={true}/>
                <Column header={"Actions"} headerStyle={{width: '80px'}} body={leave => this.actionsTemplate(leave)}/>
            </DataTable>
        )
    }

}

LeaveCalendarAgenda.range = date => {
    return [date];
}

LeaveCalendarAgenda.navigate = (date, action) => {
    switch (action) {
        case 'PREV':
            return DateUtil.addDays(date, -1);

        case 'NEXT':
            return DateUtil.addDays(date, 1);

        default:
            return date
    }
}

LeaveCalendarAgenda.title = (date, {localizer , length}) => {
    return localizer.format({date , length}, 'agendaHeaderFormat')
}