import React, {Component} from "react";
import "../../EMR.css";
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import MedicationTypeEnum from "../enums/medicationuse/MedicationTypeEnum";
import DateUtil from "../../../../../utils/DateUtil";
import UnitEnum from "../enums/medicationuse/UnitEnum";
import FrequencyEnum from "../enums/medicationuse/FrequencyEnum";
import {DateInput, NumberInput} from "../../../../../components";
import {MedicationUseChecker} from "../checkers/MedicationUseChecker";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import RemoveButton from "../../common/table/RemoveButton";
import AddButton from "../../common/table/AddButton";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import FormField from "../../../../../components/form/FormField";
import ValidationUtil from "../../../../../components/form/validator/ValidationUtil";

export default class MedicationUseModal extends Component {
  state = {
    switch: true,
    activeMedicationUse: {},
  };

  constructor(props) {
    super(props);
    this.MINIMUM_SEARCH_LENGTH = 2;
      this.customStyles = {
          option: (provided, state) => ({
              ...provided,
              textAlign:'left',
              whiteSpace:'nowrap',

          }),

          menu:(provided, state)=>({
              ...provided,
              position:'absolute',
              width:'100wv',

          }),
          menuList: (provided, state) => ({
              ...provided,


          }),


      }
  }

  render() {
     this.medicationUseFields = [];
    let toggleOn = MedicationUseChecker.isToggleOn(
      this.props.subjective.medicationUse
    );
    let isNone = MedicationUseChecker.isNone(
      this.props.subjective.medicationUse
    );

    return (
      <div
        className={"emr-modal content-row emr-background"}
        style={{ width: "100%" }}
      >
        <div style={{ width: "100%", margin: "20px" }}>
          <div style={{ display: "flex" }}>
            <div
              className="content-label"
              style={{ margin: "auto", marginLeft: "0px" }}
            >
              Medications <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/>
            </div>
            <GreenSwitchWithMessage
              message={isNone ? "No Medication" : ""}
              checked={toggleOn}
              onChange={(e) => {
                if (!e.target.checked) {
                  this.props.subjective.medicationUse = {
                      id: this.props.subjective.medicationUse?.id,
                      none: false,
                      items: []
                  };
                  this.forceUpdate();
                }
              }}
            />
          </div>

            <div className="d-flex align-items-center ml-1">
                <div>
                    <CheckboxInput
                      value={isNone}
                      onChange={(value) => {
                        if (this.props.subjective.medicationUse == null) {
                          this.props.subjective.medicationUse = {};
                        }
                        if (value) {
                          this.props.subjective.medicationUse.items = [];
                        }
                        this.props.subjective.medicationUse.none = value;
                        this.forceUpdate();
                      }}
                    />
                </div>
            <div
              style={{
                marginTop: "-2px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              No Medication
            </div>
          </div>

            <br/>

                    <div className={isNone ? "not-allowed" : ""}>
                        <div className={isNone ? "disable-pointer" : ""}>
                            <div className={"content-card"}>
                                <table className={"emr-table text-left"}>
                                    <tbody>
                                    <tr>
                                        <th style={{width: "10%"}}>
                                            Type <span className="text-danger">*</span>
                                        </th>
                                        <th style={{width: "30%"}}>Name</th>
                                        <th style={{width: "5%"}}>Start Date</th>
                                        <th style={{width: "10%"}}>Prescribing Doctor</th>
                                        <th style={{width: "10%"}}>Condition</th>
                                        <th style={{width: "3%"}}>Dose</th>
                                        <th style={{width: "10%"}}>Unit</th>
                                        <th style={{width: "10%"}}>Frequency</th>
                                        <th style={{width: "10%"}}>Comment</th>
                                        <th style={{color: "red", width: "2%", textAlign:"center"}}>ⓧ</th>
                                    </tr>
                                    {(this.props.subjective.medicationUse == null || this.props.subjective.medicationUse.items == null)
                                        ? null : this.props.subjective.medicationUse.items.map((m, index) => {
                                            return (<tr key={"medicationUseModal" + index}
                                                        style={{backgroundColor: "white"}}>
                                                <td>{m.type == null ? "" : MedicationTypeEnum[m.type].name}</td>
                                                <td>{m.name}</td>
                                                <td>{m.startDate == null ? "" : DateUtil.formatDateDoMMYY(m.startDate)}</td>
                                                <td>{m.prescribingDoctor}</td>
                                                <td>{m.condition}</td>
                                                <td>{m.dose}</td>
                                                <td>{m.unit == null ? "" : UnitEnum[m.unit].name}</td>
                                                <td>{m.frequency == null ? "" : FrequencyEnum[m.frequency].name}</td>
                                                <td>
                                                    <div style={{minHeight: "30px"}}>
                                                        <div>{m.comment == null ? " " : m.comment}</div>
                                                    </div>
                                                </td>
                                                <td style={{textAlign:"center"}}>
                                                    <RemoveButton
                                                        onClick={() => {
                                                            this.props.subjective.medicationUse.items.splice(index, 1);
                                                            this.forceUpdate();
                                                        }}
                                                    />
                                                </td>
                                            </tr>)
                                        })}
                                    <tr style={{backgroundColor: "white"}}>
                                        <td >
                                            <Select
                                                className={"emr-in-table-select-container text-left"}
                                                classNamePrefix={"emr-in-table-select"}
                                                styles={this.customStyles}
                                                placeholder={"Type"}
                                                value={this.state.activeMedicationUse.type != null ?
                                                    {
                                                        value: this.state.activeMedicationUse.type,
                                                        label: MedicationTypeEnum[this.state.activeMedicationUse.type].name
                                                    } : null}
                                                onChange={(event) => {
                                                    this.state.activeMedicationUse.type = event.value;
                                                    this.forceUpdate();
                                                }}
                                                options={MedicationTypeEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                            />
                                        </td>
                                        <td >
                                            <AsyncSelect
                                                className={"emr-in-table-select-container text-left"}
                                                classNamePrefix={"emr-in-table-select"}
                                                noOptionsMessage={this.noOptionsMessage.bind(this)}
                                                styles={this.customStyles}
                                                loadOptions={this.loadMedicationAndIngredientOptions}
                                                value={this.state.activeMedicationUse.name != null ?
                                                    {
                                                        value: this.state.activeMedicationUse.name,
                                                        label: this.state.activeMedicationUse.name,
                                                    } : null}
                                                onChange={(event) => {
                                                    this.state.activeMedicationUse.name = event.value;
                                                    this.forceUpdate();
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <DateInput id="medicationusedate"
                                                       disableFuture={true}
                                                       disableToolbar="true" variant="dialog"
                                                       value={this.state.activeMedicationUse.startDate}
                                                       onChange={date => {
                                                           this.state.activeMedicationUse.startDate = date;
                                                           this.forceUpdate();
                                                       }}
                                            />
                                        </td>
                                        <td>
                                            <TextInput value={this.state.activeMedicationUse.prescribingDoctor || ''}
                                                       placeholder={""}

                                                       onChange={value => {
                                                           this.state.activeMedicationUse.prescribingDoctor = value;
                                                           this.forceUpdate();
                                                       }}
                                            />
                                        </td>
                                        <td>
                                            <TextInput value={this.state.activeMedicationUse.condition || ''}
                                                       placeholder={""}

                                                       onChange={value => {
                                                           this.state.activeMedicationUse.condition = value;
                                                           this.forceUpdate();
                                                       }}
                                            />
                                        </td>
                                        <td>
                                            {this.state.activeMedicationUse.unit !== 'UNSURE' && <FormField
                                                ref={formField => this.medicationUseFields.push(formField)}
                                                min={0}
                                                validateOn={this.state.activeMedicationUse.dose}>
                                                <NumberInput value={this.state.activeMedicationUse.dose || ''}
                                                             placeholder={""}
                                                             min={0}
                                                             onChange={value => {
                                                                 this.state.activeMedicationUse.dose = value;
                                                                 this.forceUpdate();
                                                             }}
                                                />
                                            </FormField>}
                                        </td>
                                        <td style={{width: "100px"}}>
                                            <Select
                                                className={"emr-in-table-select-container text-left"}
                                                classNamePrefix={"emr-in-table-select"}
                                                styles={this.customStyles}
                                                value={this.state.activeMedicationUse.unit != null ?
                                                    {
                                                        value: this.state.activeMedicationUse.unit,
                                                        label: UnitEnum[this.state.activeMedicationUse.unit].name
                                                    } : null}
                                                onChange={(event) => {
                                                    this.state.activeMedicationUse.unit = event.value;
                                                    if(event.value === 'UNSURE') {
                                                        this.state.activeMedicationUse.dose = null;
                                                    }
                                                    this.forceUpdate();
                                                }}
                                                options={UnitEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                className={"emr-in-table-select-container text-left"}
                                                classNamePrefix={"emr-in-table-select"}
                                                styles={this.customStyles}
                                                value={this.state.activeMedicationUse.frequency != null ?
                                                    {
                                                        value: this.state.activeMedicationUse.frequency,
                                                        label: FrequencyEnum[this.state.activeMedicationUse.frequency].name
                                                    } : null}
                                                onChange={(event) => {
                                                    this.state.activeMedicationUse.frequency = event.value;
                                                    this.forceUpdate();
                                                }}
                                                options={FrequencyEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                            />
                                        </td>
                                        <td >
                                            <TextInput value={this.state.activeMedicationUse.comment || ''}
                                                       placeholder={""}

                                                       onChange={value => {
                                                           this.state.activeMedicationUse.comment = value;
                                                           this.forceUpdate();
                                                       }}
                                            />
                                        </td>
                                        <td style={{textAlign:"center"}}>

                                            <AddButton
                                                onClick={() => {
                                                    if (this.props.subjective.medicationUse.items == null) {
                                                        this.props.subjective.medicationUse.items = [];
                                                    }
                                                    if (!ValidationUtil.checkWithNotification(this.medicationUseFields)) {
                                                        return;
                                                    }
                                                    this.props.subjective.medicationUse.items.push(this.state.activeMedicationUse);
                                                    this.setState({activeMedicationUse: {}});
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    loadMedicationAndIngredientOptions = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/medication/', {name: inputValue}, (medicationResponse) => {
            RemotingService.getRemoteCall('api/ingredient/', {name: inputValue}, (ingredientResponse) => {
                let mergedList = medicationResponse.map(m => m.name).concat(ingredientResponse).sort((a, b) => a - b);
                let options = mergedList.map(r => {
                    return {value: r, label: r}
                });
                options = [{value: inputValue, label: inputValue}, ...options]
                callback(options);
            });
        });
    }

  noOptionsMessage(input) {
    if (
      input.inputValue == null ||
      input.inputValue.length < this.MINIMUM_SEARCH_LENGTH
    ) {
      return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
    } else {
      return <div>No options</div>;
    }
  }
}