import React, {Component} from "react";
import '../../EMR.css';
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import '../Assessment.css';
import FurtherAssessmentEnum from "../enums/morseFallRisk/FurtherAssessmentEnum";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import MorseFallRiskUtils from "../utils/MorseFallRiskUtils";

export default class MorseFallRiskView extends Component {

    render() {
        let FurtherAssessmentDivs = [];
        let riskLevel = this.props.fallRisk ? MorseFallRiskUtils.getRiskLevel(this.props.fallRisk.riskScore) : null;

        return(
            <div className="px-2">
                <div style={{marginLeft:"5px"}}>
                    {riskLevel != null ?
                        <div className={"row riskLevelDiv w-100"}>
                            <div className={"col " + riskLevel.className}>
                                <div className="riskLevelText">
                                    Risk Level: {riskLevel.name}
                                </div>
                            </div>
                        </div> : null
                    }
                    <div className={"row "}>
                        <div className={"col-6"}>
                            <div className="content-label" style={{marginLeft:"-5px"}}>
                               Physiotherapist referral required?
                            </div>
                        </div>
                        <div className={"col- riskReferralSwitchDiv d-flex"}>
                            <div className="align-self-center">{!this.props.fallRisk?.referralRequired ? "No" : "Yes"}</div>
                            <GreenSwitchWithEmptyMessage checked={this.props.fallRisk?.referralRequired}/>
                        </div>
                    </div>
                    <div className={"row "}>
                        <div className={"col-6"}>
                            <div className="content-label" style={{marginLeft:"-5px"}}>
                                Further Assessment
                            </div>
                        </div>
                        {this.props.fallRisk != null?
                            <div className={"col- riskReferralSwitchDiv"}>
                            <GreenSwitchWithEmptyMessage checked={this.props.fallRisk.furtherAssessmentItem.length > 0}
                            />
                           </div> :
                            <div className={"col- riskReferralSwitchDiv"}>
                                <GreenSwitchWithEmptyMessage checked = {false}/>
                            </div>
                        }
                    </div>

                    {
                        this.props.fallRisk != null?
                        this.props.fallRisk.furtherAssessmentItem ?
                        this.props.fallRisk.furtherAssessmentItem.forEach((item) => {
                            FurtherAssessmentDivs.push(
                                <div className={"row "}>
                                    <div  className={"riskViewFurtherAssessmentText"}>
                                        Test: {FurtherAssessmentEnum[item.furtherAssessment].name}
                                    </div>
                                </div>);
                            FurtherAssessmentDivs.push(
                                <div className={"row "}>
                                    <div  className={"riskViewFurtherAssessmentText"}>
                                        Result: {item.result}
                                    </div>
                                </div>
                            );
                            FurtherAssessmentDivs.push(
                                item.details && (<div className={"row riskViewFurtherAssessmentDiv"}>
                                    <div className={"riskViewFurtherAssessmentText"}>
                                        Details: {item.details}
                                    </div>
                                </div>)
                            );

                        }) : '' : ''
                    }
                    {FurtherAssessmentDivs}


                </div>
            </div>
            );
    }
}