import React from "react";
import EnumGender from "../../../../enums/EnumGender";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {DateInput, PhoneInput, SelectInput, TextareaInput, TextInput} from "../../../../components";
import FormField from "../../../../components/form/FormField";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InputMask} from "primereact/inputmask";
import PatientUtil from "../../../../utils/PatientUtil";
import {InputText} from "primereact/components/inputtext/InputText";
import AuthService from "../../../../services/auth-service/AuthService";
import EnumCountry from "../../../../enums/EnumCountry";
import {DialogActions} from "@material-ui/core";

class PersonalInformationUpdateModal extends React.Component {

    static RESIDENT = "RESIDENT";
    static CITIZEN = "CITIZEN";
    static VISITING = "VISITING";
    static FEMALE = "FEMALE";
    static MALE = "MALE";
    static UAE_PHONE_NUM_LENGTH = 9;

    constructor(props) {
        super(props);
        this.state = {
            nationalities: [],
            countries: AuthService.getCountries().map(c => EnumCountry[c]),
            updateMode: props.personalInformation.id !== null && props.personalInformation.id !== undefined,

            personalInformation: Object.assign({}, props.personalInformation)
        };

        if (this.state.countries.length === 1 && !this.state.updateMode) {
            this.state.personalInformation.clinicCountry = this.state.countries[0];
        }
    }

    componentDidMount() {
        this.retrieveNationalities();
    }

    getVisaStatusFromCode(idtype) {
        return {
            'ID': PersonalInformationUpdateModal.CITIZEN,
            'IR': PersonalInformationUpdateModal.RESIDENT,
            'IL': PersonalInformationUpdateModal.RESIDENT
        }[idtype];
    }

    formatAddress(idData) {
        const address = idData.homeAddress ? idData.homeAddress : idData.workAddress;
        return address ? [address.areaDescEnglish, address.streetEnglish, address.pobox, address.cityDescEnglish].join(' ') : '';
    }

    getPhoneNumber(idData, country) {
        const address = idData.homeAddress ? idData.homeAddress : idData.workAddress;

        return country === 'UAE' ?
            '+971' + (address ? address.mobilePhoneNumber.trim().slice(address.mobilePhoneNumber.length - PersonalInformationUpdateModal.UAE_PHONE_NUM_LENGTH) : '')
            : '+965' + (address ? address.mobilePhoneNumber.trim() : '');
    }

    //get country from mrn
    //if no mrn, get it from clinicCountry, otherwise default to UAE
    getCountryFromPersonalInfo() {
       return this.props.personalInformation.mrn !== null ? this.props.personalInformation.mrn.country :
           (this.props.personalInformation.clinicCountry == "KUWAIT" ? "Kuwait" : "UAE")
    }

    //DSC-153 if emirates code exists then country is UAE
    //otherwise get the country from mrn
    getIdReadFrom(idData){
      return   idData?.homeAddress?.emiratesCode ? "UAE" : this.getCountryFromPersonalInfo();
    }

    getEmail(idData) {
        const address = idData.homeAddress ? idData.homeAddress : idData.workAddress;

        return address ? address.email.trim() : '';
    }

    overrideIdData(currentData, idData) {
        const fullnameSplit = idData.nonModifiablePublicData.fullNameEnglish.split(',').filter(n => n);
        currentData.firstName = fullnameSplit[0];
        currentData.middleName = fullnameSplit.slice(1, fullnameSplit.length - 1).join(' ');
        currentData.lastName = fullnameSplit[fullnameSplit.length - 1];

        currentData.eid = idData.idNumber;
        currentData.gender = idData.nonModifiablePublicData.gender === 'M' ? PersonalInformationUpdateModal.MALE : PersonalInformationUpdateModal.FEMALE;
        currentData.birthday = DateUtil.parse(idData.nonModifiablePublicData.dateOfBirth, 'DD/MM/YYYY').utc(true).toDate();
        currentData.nationality = this.state.nationalities.find(n => (n.alpha3Code?.toUpperCase() === idData.nonModifiablePublicData?.nationalityCode?.toUpperCase()) ||
            (n.nameInEnglish?.toUpperCase() === idData.nonModifiablePublicData?.nationalityEnglish?.toUpperCase()));
        currentData.visaStatus = this.getVisaStatusFromCode(idData.nonModifiablePublicData.idtype);
        currentData.patientIdReadFrom = this.getIdReadFrom(idData);
        const phoneNumberFromCard = this.getPhoneNumber(idData, currentData.patientIdReadFrom);
        if (phoneNumberFromCard) {
            currentData.mobilePhone = phoneNumberFromCard;
        }
        const emailFromCard = this.getEmail(idData);
        if (emailFromCard) {
            currentData.email = emailFromCard;
        }
        currentData.address = this.formatAddress(idData);


        return currentData;
    }

    getTodaysPassword() {
        return (DateUtil.getUtcDateAtStartOfDay(new Date()).getTime() / 1000).toString(16);
    }

    onScanCard = () => {
        RemotingService.getRemoteCall('http://localhost:12121/api/id-card', {code: this.getTodaysPassword()}, (idData) => {
            this.setState({personalInformation: this.overrideIdData(this.state.personalInformation, idData)});

            RemotingService.postRemoteCall('api/id-card', idData, (idData) => {
            });
        });
    };

    retrieveNationalities = () => {
        RemotingService.getRemoteCall('api/reference/nationality', null, (nationalities) => {
            this.setState({nationalities: nationalities});
        });
    };

    fieldChanged = (fieldName, value) => {
        const {personalInformation} = this.state;
        personalInformation[fieldName] = value;
        this.setState({personalInformation});
    };

    enumFieldChanged = (fieldName, value) => {
        const {personalInformation} = this.state;
        personalInformation[fieldName] = value.key;
        this.setState({personalInformation});
    };

    submit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const url = this.state.updateMode ? "api/patient/update" : "api/patient/create";
        RemotingService.postRemoteCall(url, this.state.personalInformation, () => {
            this.props.close();
            this.props.onUpdated();
        });
    };

    render() {
        const {personalInformation} = this.state;
        this.formFields = [];

        return (
            <div style={{minWidth: 500}}>
                <div className={"dim14-form-row-align mb-2"}>
                    <div className={"container"} style={{maxWidth: '100%'}}>
                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <Button variant="secondary" onClick={this.onScanCard}>
                                    <FontAwesomeIcon icon={["fas", "search"]}/> Read Patient ID
                                </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                First Name
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <FormField ref={formField => this.formFields.push(formField)} required
                                           validateOn={personalInformation.firstName}>
                                    <TextInput value={personalInformation.firstName} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('firstName', value)}/>
                                </FormField>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                Middle Name
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <TextInput value={personalInformation.middleName} alphabeticOnly
                                           onChange={(value) => this.fieldChanged('middleName', value)}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                Last Name
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <FormField ref={formField => this.formFields.push(formField)} required
                                           validateOn={personalInformation.lastName}>
                                    <TextInput value={personalInformation.lastName} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('lastName', value)}/>
                                </FormField>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                Gender
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <SelectInput value={personalInformation.gender}
                                             options={EnumGender.toArray()}
                                             displayProperty="name" valueProperty="key" searchEnabled={false}
                                             onChange={(val) => {
                                                 this.fieldChanged('gender', val)
                                             }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                Date of Birth
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <DateInput value={personalInformation.birthday} disableFuture="true"
                                               onChange={(val) => this.fieldChanged('birthday', val)}/>
                                    <b>&nbsp;&nbsp;Age&nbsp;&nbsp;</b>
                                    {personalInformation.birthday ? DateUtil.getAgeWithMonths(personalInformation.birthday) : "Select birthday."}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                Nationality
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <SelectInput value={personalInformation.nationality}
                                             options={this.state.nationalities}
                                             displayProperty={"nameInEnglish"}
                                             onChange={(val) => {
                                                 this.fieldChanged('nationality', val)
                                             }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                ID No
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                {PatientUtil.isPatientIdReadFromUAE(personalInformation) ?
                                    (<InputMask mask={"999-9999-9999999-9"} value={personalInformation.eid}
                                              unmask autoClear={false} maxlength={15}
                                              onChange={(e) => this.fieldChanged('eid', e.value)}/>) :
                                    (<InputText value={personalInformation.eid}
                                                onChange={(e) => this.fieldChanged('eid', e.target.value)}/>)
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                Visa Status
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <SelectInput value={personalInformation.visaStatus} searchEnabled={false}
                                             options={[
                                                 {label: 'Citizen', value: 'CITIZEN'},
                                                 {label: 'Resident', value: 'RESIDENT'},
                                                 {label: 'Visiting', value: 'VISITING'}
                                             ]}
                                             displayProperty="label" valueProperty="value"
                                             onChange={(val) => {
                                                 this.fieldChanged('visaStatus', val)
                                             }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                Phone
                            </div>
                            <div id={"phone-input-personal-info"} className="col-md-8 dim14-base-input">
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={personalInformation.mobilePhone}>
                                    <PhoneInput value={personalInformation.mobilePhone}
                                                onChange={(value) => this.fieldChanged('mobilePhone', value)}/>
                                </FormField>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                E-mail
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <FormField ref={formField => this.formFields.push(formField)}
                                           email validateOn={personalInformation.email}>
                                    <TextInput value={personalInformation.email}
                                               onChange={(val) => this.fieldChanged('email', val)}/>
                                </FormField>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                Address
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                <TextareaInput value={personalInformation.address}
                                               onChange={(value) => this.fieldChanged('address', value)}/>
                            </div>
                        </div>
                        {
                            !this.state.updateMode && this.state.countries.length > 1 &&
                            <div className="row">
                                <div className="col-md-4 dim14-form-label">
                                    Clinic Country
                                </div>
                                <div className="col-md-8 dim14-base-input">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               required validateOn={personalInformation.clinicCountry}>
                                        <SelectInput value={personalInformation.clinicCountry}
                                                     options={this.state.countries}
                                                     displayProperty="name" valueProperty="key" searchEnabled={false}
                                                     onChange={(val) => {
                                                         this.fieldChanged('clinicCountry', val)
                                                     }}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <DialogActions>
                    <Button variant="success" onClick={() => this.submit()}>{this.state.updateMode ? "Update" : "Save"}</Button>
                </DialogActions>
            </div>
        );
    }
}

export default PersonalInformationUpdateModal;
