import React, {Component} from 'react';
import PropTypes from "prop-types";
import {fabric} from "fabric";
import omit from "lodash-es/omit";
import EnumBodySilhouetteSignTypes from "./EnumBodySilhouetteSignTypes";

class Cross extends Component {

    static propTypes = {
        url: PropTypes.string,
        scale: PropTypes.number,
        top: PropTypes.number,
        left: PropTypes.number,
        scaleX: PropTypes.number,
        scaleY: PropTypes.number,
        angle: PropTypes.number
    }

    static defaultProps = {
        type: EnumBodySilhouetteSignTypes.CROSS,
        scaleX: 0.04,
        scaleY: 0.04,
        top: 385,
        left: 808,
    }


    componentDidMount() {
        const options = omit(this.props)
        fabric.Image.fromURL(process.env.PUBLIC_URL + '/assets/forms/cross.png', img => {
            this.props.canvas.add(img)
        }, options)
    }

    render() {
        return null;
    }
}

export default Cross;