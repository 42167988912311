import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import FormField from "../../../components/form/FormField";
import {DateInput, NumberInput, RadioInput, TextInput} from "../../../components";
import DateUtil from "../../../utils/DateUtil";
import {Dropdown} from "primereact/dropdown";
import IntakeFormGenderEnum from "./enum/IntakeFormGenderEnum";
import OccupationEnum from "../../emr/recordpage/subjective/enums/lifestyle/OccupationEnum";
import JobPhysicalityEnum from "../../emr/recordpage/subjective/enums/lifestyle/JobPhysicalityEnum";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";
import FormAllergiesSection from "../FormAllergiesSection";

class IntakeFormPatientInformation extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, nationalityLabel, onChange, formFields,
            firstName, middleName, lastName, dob, gender, eid, nationality, nationalities, occupation,
            jobPhysicality, haveAllergies, allergy, height, weight
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <h3 className="text-center mb-3">
                    <b>{IntakeFormTextEnum.Patient_Information[selectedLanguage]}</b>
                </h3>
                <div className="row">
                    <div className="col-4 d-flex">
                        <p className={"dim14-form-label mr-3 mb-0"}>
                            {IntakeFormTextEnum.First_Name[selectedLanguage]}:
                        </p>
                        <FormField
                            ref={(formField) => formFields.push(formField)}
                            required
                            validateOn={readOnly ? true : firstName}
                        >
                            <TextInput
                                value={firstName}
                                alphabeticOnly
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("firstName", value)
                                }
                            />
                        </FormField>
                    </div>
                    <div className="col-4 d-flex">
                        <p className={"dim14-form-label mr-3 mb-0"}>
                            {IntakeFormTextEnum.Middle_Name[selectedLanguage]}:
                        </p>
                        <TextInput
                            value={middleName}
                            alphabeticOnly
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("middleName", value)
                            }
                        />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <p className={"dim14-form-label mr-3 mb-0"}>
                            {IntakeFormTextEnum.Last_Name[selectedLanguage]}:
                        </p>
                        <FormField
                            ref={(formField) => formFields.push(formField)}
                            required
                            validateOn={readOnly ? true : lastName}
                        >
                            <TextInput
                                value={lastName}
                                alphabeticOnly
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("lastName", value)
                                }
                            />
                        </FormField>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Date_of_Birth[selectedLanguage]} :
                    </div>
                    <div className="col-8">
                        <div className="d-flex">
                            <div className="col-3">
                                <FormField
                                    ref={(formField) => formFields.push(formField)}
                                    required
                                    validateOn={readOnly ? true : dob}
                                >
                                    <DateInput
                                        className={
                                            readOnly
                                                ? "dim14-datepicker DateInputDisabledBackground"
                                                : "dim14-datepicker"
                                        }
                                        disableFuture="true"
                                        disabled={readOnly}
                                        value={dob ? dob : new Date()}
                                        onChange={(val) => {
                                            onChange("dob", val);
                                            onChange("age", DateUtil.getAgeWithMonths(val));
                                        }}
                                    />
                                </FormField>
                            </div>

                            <div className="col-4 d-flex">
                                <b className="mx-2">
                                    {IntakeFormTextEnum.Age[selectedLanguage]}
                                </b>
                                <div>
                                    {dob
                                        ? DateUtil.getAgeWithMonths(dob)
                                        : "Select birthday."}
                                </div>
                            </div>

                            <div className="col-5 d-flex justify-content-end">
                                <div className="dim14-form-label mx-2">
                                    {IntakeFormTextEnum.Gender[selectedLanguage]}:
                                </div>

                                <FormField
                                    ref={(formField) => formFields.push(formField)}
                                    required
                                    validateOn={readOnly ? true : gender}
                                >
                                    <Dropdown
                                        className={`GenderDropdown ${
                                            readOnly
                                                ? "DropdownDisabledBackground"
                                                : "DropdownBorderColor"
                                        }`}
                                        value={gender}
                                        disabled={readOnly}
                                        options={IntakeFormGenderEnum.toArray().map(v => {
                                            return {value: v.key, label: v[enumLabel]}
                                        })}
                                        displayProperty="label"
                                        valueProperty="value"
                                        onChange={(event) =>
                                            onChange("gender", event.value)
                                        }
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.ID_No[selectedLanguage]}:</div>
                    <div className="col-8">
                        <TextInput
                            className="w-100"
                            value={eid}
                            disabled={readOnly}
                            onChange={(value) => onChange("eid", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Nationality[selectedLanguage]}:
                    </div>
                    <div className="col-8">
                        <FormField
                            ref={(formField) => formFields.push(formField)}
                            required
                            validateOn={readOnly ? true : nationality}>
                            <Dropdown
                                className={`DropdownBorderColor ${
                                    readOnly ? "DropdownDisabledBackground" : ""
                                }`}
                                value={nationality}
                                disabled={readOnly}
                                dataKey={"code"}
                                options={nationalities}
                                optionLabel={nationalityLabel}
                                filter
                                filterLocale={"en-US"}
                                filterBy={nationalityLabel}
                                onChange={(event) =>
                                    onChange("nationality", event.value)
                                }
                            />
                        </FormField>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Occupation[selectedLanguage]}:</div>
                    <div className="col-8">
                        <Dropdown
                            className={`DropdownBorderColor w-100 ${
                                readOnly
                                    ? "DropdownDisabledBackground"
                                    : "DropdownBorderColor"
                            }`}
                            value={occupation}
                            disabled={readOnly}
                            options={OccupationEnum.toArray().map(v => {
                                return {value: v.key, label: v[enumLabel]}
                            })}
                            optionLabel={"label"}
                            onChange={(event) =>
                                onChange("occupation", event.value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div
                        className="col-4 dim14-form-label">{IntakeFormTextEnum.Job_Physicality[selectedLanguage]}:
                    </div>
                    <div className="col-8">
                        <Dropdown
                            className={`DropdownBorderColor w-100 ${
                                readOnly
                                    ? "DropdownDisabledBackground"
                                    : "DropdownBorderColor"
                            }`}
                            value={jobPhysicality}
                            disabled={readOnly}
                            options={JobPhysicalityEnum.toArray().map(v => {
                                return {value: v.key, label: v[enumLabel]}
                            })}
                            optionLabel={"label"}
                            onChange={(event) =>
                                onChange("jobPhysicality", event.value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Any_Allergies_or_Sensitivities[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <div className="d-flex">
                            <FormField
                                ref={(formField) => formFields.push(formField)}
                                required
                                validateOn={readOnly ? true : haveAllergies}
                            >
                                <RadioInput
                                    id="allergiesRadioInput"
                                    value={haveAllergies}
                                    disabled={readOnly}
                                    options={IntakeFormYesNoEnum.toArray()}
                                    displayProperty={enumLabel}
                                    valueProperty={"key"}
                                    onChange={(value) =>
                                        onChange("haveAllergies", value)
                                    }
                                />
                            </FormField>
                        </div>
                    </div>
                </div>
                {haveAllergies === "Yes" ? (
                    <div className="container">
                        <FormField
                            ref={(formField) =>
                                formFields.push(formField)
                            }
                            required
                            validateOn={readOnly ? true : allergy}>
                            <FormAllergiesSection allergy={allergy} readOnly={readOnly}
                                                  selectedLanguage={selectedLanguage}/>
                        </FormField>
                    </div>
                ) : null}

                <div className="row">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Height[selectedLanguage]}:</div>
                    <div className="col-8">
                        <NumberInput
                            className="w-100"
                            min={0}
                            maxFractionDigits={2}
                            step=".1"
                            allowDecimal
                            value={height}
                            disabled={readOnly}
                            onChange={(value) => onChange('height', value)}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Weight[selectedLanguage]}:</div>
                    <div className="col-8">
                        <NumberInput
                            className="w-100"
                            min={0}
                            maxFractionDigits={2}
                            step=".1"
                            allowDecimal
                            value={weight}
                            disabled={readOnly}
                            onChange={(value) => onChange('weight', value)}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default IntakeFormPatientInformation;