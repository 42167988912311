import axios from 'axios';
import noop from "lodash-es/noop";
import AuthService from "../auth-service/AuthService";
import ReduxService from '../redux-service/ReduxService';
import NotificationService from '../notification-service/NotificationService';

const CODE_401 = "401";
const CODE_403 = "403";
const CODE_NETWORK = "network";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const REQUEST_METHOD = {
    GET: "get",
    POST: "post",
    DELETE: "delete"
};

const instance = axios.create({
    baseURL: BASE_URL
});

const getApiBaseUrl = () => {
    return BASE_URL;
};

// Authentication interceptor
instance.interceptors.request.use(config => {
    const token = AuthService.getAuthToken();
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    const clinics = AuthService.getClinics();
    if (clinics && clinics.length > 0) {
        config.headers['Clinics'] = clinics;
    }

    const countries = AuthService.getCountries();
    if (countries && countries.length > 0) {
        config.headers['Countries'] = countries;
    }

    return config;
}, error => {
    Promise.reject(error)
});

const timedMessages = {};
const enoughMessageGapExists = (code) => {
    let last = timedMessages[code];
    return !last || (new Date().getTime() - last.getTime()) > 6000; // 6000 is default time for message display
};

// Common error handling interceptor
instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (!error) {
        return;
    }
    if (error.response) {
        if (error.response.status === 401 && enoughMessageGapExists(CODE_401)) {
            NotificationService.showNotification({
                severity: 'warn', summary: 'Authentication Required',
                detail: 'Please login to continue.'
            });
            timedMessages[CODE_401] = new Date();
            ReduxService.redirectToLogin();
        }
        if (error.response.status === 403 && enoughMessageGapExists(CODE_403)) {
            NotificationService.showNotification({
                severity: 'error', summary: 'Insufficient Permissions',
                detail: 'You do not have required permissions to complete this operation!'
            });
            timedMessages[CODE_403] = new Date();
        }
    } else {
        if (enoughMessageGapExists(CODE_NETWORK)) {
            NotificationService.showNotification({
                severity: 'error', summary: 'Network Error',
                detail: 'API server is not available, try again after a while.'
            });
            timedMessages[CODE_NETWORK] = new Date();
        }
    }
    return error.response;
});

const getRemoteCall = (targetURL, params, successCallBack, failCallBack, showLoadingIndicator, showMessages, messageCallBack) => {
    if (params) {
        targetURL += serializeObject(params);
    }
    return makeRemotingOperation(targetURL, null, REQUEST_METHOD.GET, successCallBack, failCallBack, showLoadingIndicator, showMessages, messageCallBack);
};

const deleteRemoteCall = (targetURL, params, successCallBack, failCallBack, showLoadingIndicator, showMessages, messageCallBack) => {
    if (params) {
        targetURL += serializeObject(params);
    }
    return makeRemotingOperation(targetURL, null, REQUEST_METHOD.DELETE, successCallBack, failCallBack, showLoadingIndicator, showMessages, messageCallBack);
};

const postRemoteCall = (targetURL, params, successCallBack, failCallBack, showLoadingIndicator, showMessages, messageCallBack) => {
    return makeRemotingOperation(targetURL, params, REQUEST_METHOD.POST, successCallBack, failCallBack, showLoadingIndicator, showMessages, messageCallBack);
};

const extractMessages = (messages, type) => {
    if (!messages) {
        return [];
    }

    let filteredMessages = messages.filter((message) => {
        return message.type === type;
    });

    return filteredMessages && filteredMessages.length > 0 ?
        filteredMessages.map(msg => msg.text) :
        filteredMessages;
};

const filterMessagesByCode = (messages, code) => {
    return messages.filter(message => message.code === code);
}

const makeRemotingOperation = (targetURL, params, requestMethod, successCallBack = noop, failCallBack = noop, showLoadingIndicator = true, showMessages = true, messageCallBack = noop) => {
    if (showLoadingIndicator) {
        ReduxService.incrementRemotingOperationCount();
    }

    const promise = instance[requestMethod](targetURL, params);

    promise.then((response) => {
        if (response === undefined || response === null) {
            failCallBack(response);
            return;
        }

        let success = response.status === 200;

        if (response.data?.messages?.length > 0) {
            const messages = response.data.messages;

            const infoMessages = extractMessages(messages, "INFO");
            const warningMessages = extractMessages(messages, "WARNING");
            const errorMessages = extractMessages(messages, "ERROR");

            success = errorMessages.length === 0;

            if (showMessages) {
                showInfos(infoMessages);
                showWarnings(warningMessages);
                showErrors(errorMessages);
            }
        }

        // const result = response.data.data ? response.data.data : response.data; // Note This is different than the logic below

        let result = response.data?.data;

        if (success && successCallBack) {
            successCallBack(result);
        }

        if (!success && failCallBack) {
            failCallBack(response);
        }

        if (messageCallBack && response.data?.messages) {
            messageCallBack(response.data.messages);
        }

    }).catch((err) => {
        console.error(err);
        NotificationService.showNotification({
            severity: 'error',
            summary: 'Operation Unsuccessful',
            detail: `Operation unsuccessful! (${targetURL}) ${err.message}`
        });
        failCallBack(err);

    }).finally(() => {
        if (showLoadingIndicator) {
            ReduxService.decrementRemotingOperationCount();
        }
    });

    return promise;
};

const serializeObject = (obj) => {
    if (obj) {
        const result = [];
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (Array.isArray(obj[prop])) {
                    obj[prop].forEach((val) => {
                        if (val !== null && val !== undefined) {
                            result.push(encodeURIComponent(prop) + "=" + encodeURIComponent(val));
                        }
                    });
                } else {
                    if (obj[prop] !== null && obj[prop] !== undefined) {
                        result.push(encodeURIComponent(prop) + "=" + encodeURIComponent(obj[prop]));
                    }
                }
            }
        }
        if (result.length > 0) {
            return "?" + result.join("&")
        }
    }
    return "";
};

const uploadFile = (file, callback) => {
    ReduxService.incrementRemotingOperationCount();

    let formData = new FormData();
    formData.append("file", file);

    instance.post("/api/file/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then((response) => {
        if(response.status > 200) {
            console.log(response.statusText);
            NotificationService.showNotification({
                severity: 'error',
                summary: 'File Upload Unsuccessful',
                detail: `File Upload unsuccessful!`
            });
            callback(null);
        } else {
            callback(response);
        }
    }).catch((err) => {
        console.error(err);
        NotificationService.showNotification({
            severity: 'error',
            summary: 'File Upload Unsuccessful',
            detail: `File Upload unsuccessful! ${err.message}`
        });
        callback(null, err);
    }).finally(() => {
        ReduxService.decrementRemotingOperationCount();
    });
}

const showInfos = (infoMessages) => {
    if (infoMessages?.length > 0) {
        NotificationService.showNotification({
            severity: 'success',
            summary: 'Operation Successful',
            detail: `${infoMessages.join('\n')}`
        });
    }
}

const showWarnings = (warningMessages) => {
    if (warningMessages?.length > 0) {
        NotificationService.showNotification({
            severity: 'warn',
            summary: 'Warnings',
            detail: `${warningMessages.join('\n')}`
        });
    }
}

const showErrors = (errorMessages) => {
    if (errorMessages?.length > 0) {
        NotificationService.showNotification({
            severity: 'error',
            summary: 'Operation Unsuccessful',
            detail: `${errorMessages.join('\n')}`
        });
    }
}

export default {instance, getApiBaseUrl, getRemoteCall, postRemoteCall, deleteRemoteCall, serializeObject, extractMessages,
    filterMessagesByCode, uploadFile, showInfos, showWarnings, showErrors};