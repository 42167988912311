import React, {Component} from "react";
import RemotingService from "../../services/remoting-service/RemotingService";
import DateUtil from "../../utils/DateUtil";

const KUWAIT_CLINIC_ID = 3;
export default class LeavePolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const {staffId} = this.props;

        RemotingService.getRemoteCall('api/staff/' + staffId, null, (staff) => {
            const isDubaiStaff = staff.departments.find(d => d.clinic.id === KUWAIT_CLINIC_ID) === undefined;
            this.setState({staff, isDubaiStaff})
        });

        RemotingService.getRemoteCall('api/hr/leave/parameters', null, (leaveParameters) => {
            this.setState({leaveParameters})
        });

        RemotingService.getRemoteCall('api/hr/leave/meta', {staffIds: [staffId]}, (leaveMetaDatas) => {
            this.setState({leaveMetaDatas})
        });
    }

    getQuotaForLeaveType = (type) => {
        const leaveMeta = this.state.leaveMetaDatas.find(m => m.type === type);
        return leaveMeta ? leaveMeta.quota : '';
    }

    render() {
        const {staff, leaveParameters, leaveMetaDatas, isDubaiStaff} = this.state;

        if (!staff || !leaveParameters || !leaveMetaDatas) {
            return <></>;
        }

        const annual = this.getQuotaForLeaveType('ANNUAL') + ' calendar days';
        const notice = isDubaiStaff ?
            `${leaveParameters.dubai7DaysOrLessAnnualLeaveNotificationPeriod} days for 7 or less days of annual leaves ` +
            `and ${leaveParameters.dubaiMoreThan7DaysAnnualLeaveNotificationPeriod} days for 8 or more days of annual leaves` :
            `${leaveParameters.kuwaitAnnualLeaveNotificationPeriod} days`;
        const publicHolidays = isDubaiStaff ? leaveParameters.dubaiPublicHolidays : leaveParameters.kuwaitPublicHolidays;
        const sick = this.getQuotaForLeaveType('SICK') + ' calendar days';
        const maternityQuota = this.getQuotaForLeaveType('MATERNITY');
        const maternity = maternityQuota > 0 ? maternityQuota + ' calendar days' : '-';
        const paternityQuota = this.getQuotaForLeaveType('PATERNITY');
        const paternity = paternityQuota > 0 ? paternityQuota + ' calendar days' : '-';
        const course = this.getQuotaForLeaveType('COURSE') + ' calendar days';
        const unpaid = this.getQuotaForLeaveType('UNPAID') + ' calendar days';
        const annualLeaveStatic = this.getAnnualLeaveStatic(isDubaiStaff);
        const doctorStatic = this.getDoctorStatic(isDubaiStaff);




        return (
            <div className="container" style={{fontSize: 'small', marginTop: 10, marginLeft: 10}}>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className=" col-4 dim14-form-label">
                                {`${isDubaiStaff ? 'UAE' : 'Kuwait'} `}
                            </div>
                            <div className="col-8">{publicHolidays.map((h, index) => <div>
                                {index === 0}{h.name}
                            </div>)}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    getDoctorStatic(isDubaiStaff){
        if(isDubaiStaff){
            return(
                <ul className={"list-unstyled"}>
                    <li><span>Additional 30 Days @ 50% Pay</span></li>
                </ul>
            )
        }else{
            return(
                <ul className={"list-unstyled"}>
                    <li><span>Additional 10 Days @ 75% Pay</span></li>
                    <li><span>Additional 10 Days @ 50% Pay</span></li>
                    <li><span>Additional 10 Days @ 25% Pay</span></li>
                    <li><span>Additional 30 Days without Pay</span></li>
                </ul>
            )
        }
    }

    getAnnualLeaveStatic(isDubaiStaff) {
        if (isDubaiStaff) {
            return(
                <ul className={'list-unstyled'}>
                    <li><span>26 Workdays @ 48hrs/6 work days/week</span></li>
                    <li><span>22 Workdays @ 40hrs/5 work days/week</span></li>
                    <li><span>17 Workdays @ 40hrs/4 work days/week</span></li>
                </ul>
            )

        } else {
            return(
                <ul className={'list-unstyled'}>
                    <li><span>30 Workdays @ 48hrs/6 work days/week</span></li>
                    <li><span>25 Workdays @ 40hrs/5 work days/week</span></li>
                    <li><span>20 Workdays @ 40hrs/4 work days/week</span></li>
                </ul>
            )
        }
    }
}


