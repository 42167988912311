import "./InsuranceDirectPay.css"
import React, {Component} from 'react'
import {DateNavigation} from "../../../../components/datenavigation/DateNavigation";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import PageTitle from "../../../../layout/PageTitle";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import moment from "moment";
import {Button, Col, Container, Dropdown, Row, SplitButton} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateInput, FileUploader, Modal, NumberInput, TextareaInput, TextInput} from "../../../../components";
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import dialog from "../../../../components/dialog/Dialog";
import AttachmentModal from "../../../emr/recordpage/treatment/utils/AttachmentModal";
import {noop, pick} from "lodash-es";
import InsuranceDocuments from "../InsuranceDocuments";
import EnumDirectBillingType from "./EnumDirectBillingType";
import InsuranceDocumentLegend from "../InsuranceDocumentLegend";
import NotificationService, {showNotification} from "../../../../services/notification-service/NotificationService";
import LevelEnum from "../../../emr/recordpage/diagnosis/enums/LevelEnum";
import ObservationsModal from "../observation/ObservationsModal";
import ViewObservationsModal from "../observation/ViewObservationsModal";
import DirectBillingItemStatusEnum from "../../enums/DirectBillingItemStatusEnum";
import DirectBillingItemAuthorizationStatusEnum from "../../enums/DirectBillingItemAuthorizationStatusEnum";
import Tooltip from "../../../../components/tooltip/Tooltip";
import ActionMenu from "../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../components/action-menu/ActionMenuItem";
import ResubmissionsModal from "../resubmission/ResubmissionsModal";
import VersionModal from "../component/VersionModal";
import InsuranceReferralModal from "../referral/InsuranceReferralModal";
import DirectBillingReferralTypeEnum from "../../enums/DirectBillingReferralTypeEnum";
import DirectBillingStatus from "../../enums/DirectBillingStatus";
import EnumCoPayUnit from "../../enums/EnumCoPayUnit";
import confirmDialog from "../../../../components/dialog/ConfirmDialog";
import ObjectUtil from "../../../../utils/ObjectUtil";

export default class InsuranceDirectPay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sendToOptions: [],
            items: [],
            expandedItems: [],
            selectedItem: null,
            errorMessages: [],
            selectedDate: null,
            dbItems: []
        };
    }

    componentDidMount() {
        this.retrieveStaffs();
        this.retrieveClinics();
        if (this.props.selected) {
            this.retrieveItems();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.selected && this.props.selected) {
            this.retrieveItems(this.state.selectedDate == null ? undefined : this.state.selectedDate);
        }
    }

    handleDateStateChange = (date) => {
        this.retrieveItems(date);
        this.setState({selectedDate: date});
    }

    retrieveStaffs() {
        RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
            const staffs = result.items;
            staffs.forEach(i => {
                i.key = "" + i.id;
            });
            const sendToOptions = [
                {name: 'Insurance', key: 'INSURANCE'},
                {name: 'Patient', key: 'PATIENT'},
                ...staffs,
                {name: 'Other', key: 'OTHER'}];
            this.setState({sendToOptions});
        });
    }

    retrieveClinics = () => {
        RemotingService.getRemoteCall('api/clinic/list', null, (result) => {
            this.setState({clinicOptions: result.items});
        });
    }

    retrieveItems(date = DateUtil.today()) {
        RemotingService.getRemoteCall(
            'api/insurance/coordinator/direct-billing',
            {
                date: moment(date).format('YYYY-MM-DD'),
            },
            (items) => {
                let dbItems = [];
                items.map(item => item.directBillingItemList.forEach(directBillingItem => dbItems.push(ObjectUtil.clone(directBillingItem))));
                this.setState({items, dbItems});
            });
    }

    openNotesModal = (item) => {
        this.setState({
            selectedItem: item,
            notes: item.notes,
            notesModalVisible: true
        });
    }

    checkForValidation = (item) => {
        const result = [];
        if(item.referenceNo == null) {
            result.push("Reference No");
        }
        if(item.coPay == null) {
            result.push("Co-Pay");
        }
        if(item.coPayUnit == null) {
            result.push("Co-Pay Unit");
        }
        if(item.validTo == null) {
            result.push("Valid To");
        }
        if(item.authSessions == null) {
            result.push("Auth. Sessions");
        }
        return result;
    }

    updateItem = (item, updatedField, newValue, callback = noop) => {
        if(updatedField === 'status' && newValue === 'APPROVED') {
            let shouldReturn = false;

            let attachedItems = this.state.dbItems.filter(dbItem => item.directBillingItemList.some(item => dbItem.id === item.id));
            attachedItems && attachedItems.map(dbItem => {
                const emptyFields = this.checkForValidation(dbItem);
                if(emptyFields.length > 0) {
                    const validationMessage = `Missing fields: ${Array.prototype.join.call(emptyFields, ", ")}`
                    showNotification({summary: 'Validation Error', detail: validationMessage, severity: 'warn'})
                    shouldReturn = true;
                }
            })

            if(shouldReturn){
                return;
            }
        }
        const updateDto = pick(item,
            ['claimFormStatus', 'medicalReportStatus', 'speciality', 'status', 'sendTo', 'date', 'referenceNo',
                'coPay', 'coPayMax', 'coPayUnit', 'validUntil', 'notes', 'attachments']);

        updateDto[updatedField] = newValue;

        RemotingService.postRemoteCall(
            `api/insurance/coordinator/direct-billing/${item.id}`,
            updateDto,
            () => {
                item[updatedField] = newValue && Array.isArray(newValue) ? [...newValue] : newValue;
                callback();
                this.forceUpdate();
            }, null, true, false);
    }

    updateItemNotes = () => {
        const {selectedItem: item, notes} = this.state;
        this.updateItem(item, 'notes', notes, () => {
            this.setState({notesModalVisible: false});
        });
    }

    documentsTemplate = (item) => {
        return <InsuranceDocuments
            item={item}
            onClaimFormStatusChange={(newStatus) => this.updateItem(item, 'claimFormStatus', newStatus)}
            onMedicalReportStatusChange={(newStatus) => this.updateItem(item, 'medicalReportStatus', newStatus)}
        />
    }

    cptTemplate(item) {
        return item.referral.cptList?.map(cpt => cpt.cptCode).join(", ");
    }

    sessionsTemplate(item) {
        return `${item.noOfUsedSessions}/${item.noOfSessions}`;
    }

    notesTemplate = (item) => {
        return <Button variant={"link"} style={{color: "#84BE56", fontSize: "12px"}}
                       onClick={() => this.openNotesModal(item)}>
            <FontAwesomeIcon icon={["fas", "edit"]} size={"sm"}/> Add Note
        </Button>;
    }

    openAttachmentsModal = (item) => {
        dialog({
            title: "Attachments",
            component: <AttachmentModal
                visitId={item.visitId}
                files={item.attachments || []}
                saveAction={(files, dialogCloseFunc) => {
                    this.updateItem(item, 'attachments', files, () => {
                        dialogCloseFunc();
                        this.forceUpdate();
                    })
                }}/>
        });
    }

    dateTimeTemplate(data) {
         return DirectBillingReferralTypeEnum[data.referralType] === DirectBillingReferralTypeEnum.INTERNAL ?
           data.referral.startTime ? DateUtil.formatDate(data.referral.startTime, "Do MMMM YYYY / hh:mm A") : ""
        : "N/A"
    }

    dateInputTemplate = (item, field) => {
        return (
            <DateInput className={"whiteBackground"} value={item[field]}
                       onChange={(value) => this.updateItem(item, field, value)}/>
        )
    }

    listInputTemplate = (item, field, options) => {
        if (DirectBillingReferralTypeEnum[item.referralType] === DirectBillingReferralTypeEnum.EXTERNAL) {
            return null;
        }

        return (
                <PrimeDropdown optionLabel="name" optionValue="key"
                               options={options}
                               filter={true}
                               tooltip={options.find(option => option.key === item[field])?.name}
                               tooltipOptions={{position: 'bottom'}}
                               appendTo={document.body}
                               value={item[field]}
                               onChange={event => this.updateItem(item, field, event.value)}
                               style={{height: 25}}/>
        )
    }

    cptTemplate(item) {
       return DirectBillingReferralTypeEnum[item.referralType] === DirectBillingReferralTypeEnum.INTERNAL ?
            item.referral ? item.referral.cptList.map(cpt => <div>{cpt.cptCode} {cpt.description}</div>) : '' :
            item.externalReferral ? item.externalReferral.cptList.map(cpt => <div>{cpt.cptCode} {cpt.description}</div>) : '';
    }



    diagnosisItemTemplate(item) {
        return item.diagnosisItem ?
            item.diagnosisItem.code + ' ' + (item.diagnosisItem.description ? item.diagnosisItem.description : '')
            : '';
    }

    referralTypeTemplate(directBillingItem) {
        return DirectBillingReferralTypeEnum[directBillingItem.referralType]?.name;
    }

    renderNotesModal = () => {
        return (
            <Modal visible={this.state.notesModalVisible}
                   header="Notes"
                   submitContent="Save"
                   submitAction={() => this.updateItemNotes()}
                   closeAction={() => this.setState({notesModalVisible: false})}
                   submitDisabled={this.state.errorMessages.length > 0}
                   contentStyle={{minWidth: 400}}>

                <Container className={"InputModal"}>
                    <Row>
                        <Col xs={12}>
                            <TextareaInput style={{width: "100%", maxHeight: "100px"}}
                                           value={this.state.notes}
                                           onChange={(value) => this.setState({notes: value})}/>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        )
    }

    renderDirectBillingActions = (rowData) => {
        return this.isEditable(rowData) ? (<ActionMenu id="packagepanelactionmenu">
            <ActionMenuItem text="Edit Referral"
                            onClick={() => {
                               this.openExternalReferralModal(rowData.externalReferral , true , this.state.selectedDate)
                            }}/>
        </ActionMenu>)
            :
            null;
    }

    isEditable (rowData) {
        return DirectBillingReferralTypeEnum[rowData.referralType] === DirectBillingReferralTypeEnum.EXTERNAL;
    }

    rowClass(data) {
        const resultReturned = data.directBillingItemList ?
            data.directBillingItemList.filter(dbItem => dbItem.status === 'RESPONDED') : [];

        const approvedExternalReferral = DirectBillingReferralTypeEnum[data.referralType] === DirectBillingReferralTypeEnum.EXTERNAL &&  data.status === "APPROVED";

        return {
            'row-responded': resultReturned.length > 0 || approvedExternalReferral
        }
    }

    openExternalReferralModal = (externalReferral = null, isEditMode = false , selectedDate= new Date()) => {
        let date = selectedDate ? selectedDate : DateUtil.today();
        dialog({
            title: "External Referral",
            component: <InsuranceReferralModal
                editMode={isEditMode}
                selectedDate={date}
                referralItem = {externalReferral}
                typeOptions ={EnumDirectBillingType.directPaySelectableTypes}
                sendToOptions={this.state.sendToOptions}
                clinicOptions={this.state.clinicOptions}
                onInsuranceReferralSaved={(date) => this.handleDateStateChange(date)}/>
        });
    }

    render() {
        let date = this.state.selectedDate ? this.state.selectedDate : DateUtil.today();

        return (
            <>

                <div style={{padding: '10px'}} className="data-table-header-wrapper">
                    <div className="float-left">
                        <PageTitle title="Pre Approval" className="bg-white"/>
                    </div>
                    <div className="float-right">
                        <DateNavigation date={date} onDateChange={this.handleDateStateChange} displayDate={true}/>
                    </div>
                </div>
                <div style={{padding: '10px'}} className="data-table-header-wrapper">
                    <div className="float-right" style={{display: "flex"}}>
                        <Button variant="success"
                                onClick={() => this.openExternalReferralModal()}>
                            Add External Referral
                        </Button>
                    </div>
                </div>
                <div style={{padding: '10px'}}>
                    <DataTable className="InsuranceTable"
                               emptyMessage={"No items found"}
                               value={this.state.items}
                               rows={20}
                               expandedRows={this.state.expandedItems}
                               rowExpansionTemplate={(item) => <PreApprovalDetail directBilling={item} retrieveItems={() => this.retrieveItems(date)} sendToOptions={this.state.sendToOptions}/>}
                               onRowToggle={(e) => this.setState({expandedItems: e.data})}
                               columnResizeMode="fit"
                               paginator={true}
                               scrollable
                               paginatorPosition="bottom"
                               style={{ maxWidth: '100%' }}
                               rowClassName={this.rowClass}
                    >
                        <Column expander headerStyle={{width: 20}} />
                        <Column header=" " body={this.documentsTemplate} headerStyle={{ width: '40px' }} />
                        <Column header="Referral Type" headerStyle={{ width: '60px' }} body={(item) => this.referralTypeTemplate(item)}/>
                        <Column header="Visit Date/Time" field="referral.startTime" sortable body={this.dateTimeTemplate} headerStyle={{ width: '120px' }}/>
                        <Column header="Type" field="speciality" style={{width: '100px'}} sortable body={(item) => this.listInputTemplate(item, 'speciality', EnumDirectBillingType.directPaySelectableTypes)}/>
                        <Column header="ICD Code" headerStyle={{width: '130px'}} body={(item) => this.diagnosisItemTemplate(item)}/>
                        <Column header="Level" headerStyle={{width: 80}} body={(item) => LevelEnum[item.diagnosisItem?.level]?.name}/>
                        <Column header="CPT Codes" body={this.cptTemplate} headerStyle={{ width: '180px' }}/>
                        <Column header="Sessions" body={this.sessionsTemplate} style={{width: '100px'}}/>
                        <Column header="Status" field="status" headerStyle={{ width: '100px' }} sortable body={(item) => this.listInputTemplate(item, 'status', DirectBillingStatus.toArray())}/>
                        <Column header="Send To" field="sendTo" sortable style={{width: '100px'}} body={(item) => this.listInputTemplate(item, 'sendTo', this.state.sendToOptions)}/>
                        <Column header="Date" field="date" headerStyle={{ width: '80px' }}  sortable body={(item) => this.dateInputTemplate(item, 'date')}/>
                        <Column header="Patient" field="patientName" filter={true} filterMatchMode="contains" sortable headerStyle={{ width: '100px' }}/>
                        <Column header="ID Number" field="patientEid" headerStyle={{ width: '130px' }}/>
                        <Column header="MRN" field="patientMrn" filter={true} filterMatchMode="contains" sortable headerStyle={{ width: '90px' }}/>
                        <Column header="Insurance" field="patientInsurance" filter={true} filterMatchMode="contains" sortable headerStyle={{ width: '150px' }}/>
                        <Column header="Member Id" field="patientInsuranceMemberId" filter={true} filterMatchMode="contains" sortable headerStyle={{ width: '150px' }}/>
                        <Column header="Denial Code" headerStyle={{width: 120}}/>
                        <Column header="Comments" field="comments" headerStyle={{width: 200}}/>
                        <Column header="Actions" body={this.renderDirectBillingActions.bind(this)} style={{textAlign: "center", width:"75px"}}/>
                    </DataTable>
                    <InsuranceDocumentLegend/>
                </div>

                {this.renderNotesModal()}
            </>
        )
    }
}

class PreApprovalDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            claim: null,
            denialCodeList : [],
            internalComplaintModalVisible: false,
            correctionModalVisible: false,
            resubmission: {},
            resubmissionDbItem: {},
            resubmissionAttachment: null,
            currentVersion: null,
            dbItemConfirmationDTO: {}
        };

        this.confirmDetailsFields = [];
    }

    componentDidMount() {
        RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing/${this.props.directBilling.id}/retrieve-claim`, null, (claim) => {
            this.setState({claim})
        });

        RemotingService.getRemoteCall(`api/insurance/coordinator/denial-code/list`, null, (result) => {
            this.setState({denialCodeList : result})
        });
    }

    denialCodeDetails(code){
        for (let denialCode of this.state.denialCodeList) {
            if(denialCode.code === code)
                return denialCode.description;
        }
    }

    submitPriorRequest() {
        const dbItemIds = this.state.selectedItems.map(dbItem => dbItem.id);
        RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing/${this.props.directBilling.id}/prior-request`, dbItemIds, () => {
            this.props.retrieveItems();
        });
    }

    extendPriorRequest() {
        const dbItemIds = this.state.selectedItems.map(dbItem => dbItem.id);
        RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing/${this.props.directBilling.id}/extend-prior-request`, dbItemIds, () => {
            this.props.retrieveItems();
        });
    }

    cancelPriorRequest() {
        const dbItemIds = this.state.selectedItems.map(dbItem => dbItem.id);
        RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing/${this.props.directBilling.id}/cancel-prior-request`, dbItemIds, () => {
            this.props.retrieveItems();
        });
    }

    submitNewRequest() {
        const dbItemIds = this.state.selectedItems.map(dbItem => dbItem.id);
        RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing/${this.props.directBilling.id}/submit-new-request`, dbItemIds, () => {
            this.props.retrieveItems();
        });
    }

    observationTemplate(directBillingItem) {
        return (
            <div onClick={() => this.openObservationDialog(directBillingItem.id)}>
                <span className="text-break cursor-pointer mr-1"><u>Observations</u></span><FontAwesomeIcon icon="paperclip"/>
            </div>
        )
    }

    resubmisssionTemplate(directBillingItem) {
        return (
            <div onClick={() => this.openResubmissionDialog(directBillingItem.id)}>
                <span className="text-break cursor-pointer mr-1"><u>Resubmissions</u></span>
            </div>
        )
    }

    denialCodeTemplate(directBillingItem) {
        return (
            <Tooltip title={this.denialCodeDetails(directBillingItem.denialCode)}>
                <span>{directBillingItem.denialCode}</span>
            </Tooltip>
        )
    }

    referrerNameTemplate() {
        const {directBilling} = this.props;
        return DirectBillingReferralTypeEnum[directBilling.referralType] === DirectBillingReferralTypeEnum.INTERNAL ?
            directBilling.referral ? directBilling.referral.referrerName : '' :
            directBilling.externalReferral ? directBilling.externalReferral.referredBy : '';
    }

    providerNameTemplate() {
        const {directBilling} = this.props;
        return DirectBillingReferralTypeEnum[directBilling.referralType] === DirectBillingReferralTypeEnum.INTERNAL ?
            directBilling.referral.providerName
            : this.props.sendToOptions.filter(o => o.key === directBilling.externalReferral?.sendTo)[0]?.name;
    }

    observationFromInsuranceTemplate(directBillingItem) {
        return (
            <div onClick={() => this.openObservationFromInsuranceDialog(directBillingItem.id)}>
                <span className="text-break cursor-pointer mr-1"><u>Observations</u></span><FontAwesomeIcon
                icon="paperclip"/>
            </div>
        )
    }

    requestVersionTemplate(directBillingItem) {
        return (
            <div onClick={() => this.openVersionModal(directBillingItem.requestVersionList)}>
                <span className="text-break cursor-pointer mr-1"><u>Versions</u></span><FontAwesomeIcon icon="paperclip"/>
            </div>
        )
    }

   authorizationVersionTemplate(directBillingItem) {
        return (
            <div onClick={() => this.openVersionModal(directBillingItem.authorizationVersionList)}>
                <span className="text-break cursor-pointer mr-1"><u>Versions</u></span><FontAwesomeIcon icon="paperclip"/>
            </div>
        )
    }

    statusTemplate(directBillingItem) {
        return DirectBillingItemStatusEnum[directBillingItem.status]?.name;
    }

    openObservationDialog(directBillingItemId) {
        dialog({
            title: "Observations",
            component: <ObservationsModal directBillingItemId={directBillingItemId}/>
        });
    }

    openResubmissionDialog(directBillingItemId) {
        dialog({
            title: "Resubmissions",
            component: <ResubmissionsModal directBillingItemId={directBillingItemId}/>
        });
    }

    openObservationFromInsuranceDialog(directBillingItemId) {
        RemotingService.getRemoteCall(`api/insurance/coordinator/direct-billing-item/${directBillingItemId}/observation-from-insurance`, null, observations => {
            dialog({
                title: "Observations",
                component: <ViewObservationsModal observations={observations}/>
            });
        });
    }

    openVersionModal(versions) {
        dialog({
            title: "Versions",
            component: <VersionModal versions={versions}/>
        });
    }

    renderActions = (rowData) => {
        return (<ActionMenu id="packagepanelactionmenu">
            <ActionMenuItem text={"Internal Complaint"}
                            onClick={() => {
                                let updatedResubmission = this.state.resubmission;
                                updatedResubmission.type = 'INTERNAL_COMPLAINT';
                                this.setState({
                                    resubmission: updatedResubmission,
                                    internalComplaintModalVisible: true,
                                    resubmissionDbItem: rowData,
                                    currentVersion:
                                        rowData?.requestVersionList.length > 0 ?
                                            rowData.requestVersionList[rowData.requestVersionList.length-1] :
                                            null
                                })
                            }}/>
            <ActionMenuItem text="Correction"
                            onClick={() => {
                                let updatedResubmission = this.state.resubmission;
                                updatedResubmission.type = 'CORRECTION';
                                this.setState({
                                    resubmission: updatedResubmission,
                                    correctionModalVisible: true,
                                    resubmissionDbItem: rowData
                                })
                            }}/>
            {this.isDetailFieldEditable(rowData) === true &&
                <ActionMenuItem text="Confirm Details"
                                onClick={() => {
                                    this.confirmDirectBillingItemDetails(rowData);
                                }}/>}
        </ActionMenu>)
    }

    submitInternalComplaint = () => {
        const parameters = {
            type: this.state.resubmission.type,
            comments: this.state.resubmission.comments,
            attachment: this.state.resubmissionAttachment,
            currentVersion: this.state.currentVersion
        }

        RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing/${this.props.directBilling.id}/submit-internal-complaint/${this.state.resubmissionDbItem.id}`, parameters, () => {
            this.props.retrieveItems();
            this.setState({resubmission: null});
        });
    };

    handleVersionChange = (currentVersion) => {
        this.setState({currentVersion})
    }

    renderInternalComplaintModal = () => {
        let versionOptions = this.state.resubmissionDbItem?.requestVersionList?.map(item => {
            return{
                ...item,
                label: "v 1."+item.version
            }
        })

        return (
            <>
                <Modal visible={this.state.internalComplaintModalVisible}
                       header="Prior Authorization Internal Complaint"
                       submitContent="Submit Prior Request"
                       submitAction={() => this.submitInternalComplaint()}
                       closeAction={() => this.setState({internalComplaintModalVisible: false})}
                       submitDisabled={this.state.resubmission?.comments == null || this.state.resubmission?.comments?.length <= 0 || this.state.currentVersion == null}
                       contentStyle={{width: "500px", height: "300px"}}>

                    <Container className={"InputModal"}>
                        <Row>
                            <Col xs={3} className="dim14-form-label">
                                Version
                            </Col>
                            <Col xs={9}>
                                <PrimeDropdown dataKey="id" optionLabel="label"
                                               options={versionOptions}
                                               value={this.state.currentVersion}
                                               appendTo={document.body}
                                               onChange={e => this.handleVersionChange(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} className="dim14-form-label">
                                Comments
                            </Col>
                            <Col xs={9}>
                                <TextareaInput style={{width: "100%", maxHeight: "100px"}}
                                               value={this.state.resubmission?.comments}
                                               onChange={(value) => {
                                                   let updatedResubmission = this.state.resubmission;
                                                   updatedResubmission.comments = value;
                                                   this.setState({resubmission: updatedResubmission})
                                               }}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3} className="dim14-form-label">
                                File Attachment
                            </Col>
                            <Col xs={9}>
                                <FileUploader basic accept=".pdf"
                                              value={this.state.resubmissionAttachment}
                                              onChange={(file) => {
                                                  this.state.resubmissionAttachment = file;
                                                  this.state.resubmissionAttachment.contentType = file.mimeType;
                                                  this.state.resubmissionAttachment.data = file.content;
                                                  this.forceUpdate();
                                              }}/>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </>
        )
    }

    confirmDetailFieldChange = (directBillingItem, field, value) => {
        directBillingItem[field] = value === "" ? null : value;
        this.setState({dbItemConfirmationDTO: directBillingItem});
    }

    dateTemplate(directBillingItem, fieldName) {
        return this.isDetailFieldEditable(directBillingItem) !== true ? DateUtil.formatDate(directBillingItem[fieldName])  :
            <div>
                <DateInput className={"whiteBackground"} value={directBillingItem[fieldName]}
                           onChange={(value) => this.confirmDetailFieldChange(directBillingItem, fieldName, value)}/>

            </div>
    }

    numberTemplate = (directBillingItem, fieldName) => {
        return this.isDetailFieldEditable(directBillingItem) !== true ? directBillingItem[fieldName] :
            <div>
                <NumberInput value={directBillingItem[fieldName]} min={0} style={{width: '100%'}}
                             onChange={(value) => this.confirmDetailFieldChange(directBillingItem, fieldName, value)}/>

            </div>
    }

    dropdownTemplate = (directBillingItem, fieldName) => {
        if(fieldName === 'coPayUnit'){
            return this.isDetailFieldEditable(directBillingItem) !== true ? EnumCoPayUnit[directBillingItem[fieldName]] :
                <div>
                    <PrimeDropdown optionLabel="name" optionValue="key" style={{width: '100%'}}
                                   options={EnumCoPayUnit.toArray()}
                                   appendTo={document.body}
                                   value={directBillingItem[fieldName]}
                                   onChange={e => this.confirmDetailFieldChange(directBillingItem, 'coPayUnit', e.target.value)}/>
                </div>
        }

        if(fieldName === 'authorizationStatus'){
            return this.isDetailFieldEditable(directBillingItem) !== true ? DirectBillingItemAuthorizationStatusEnum[directBillingItem.authorizationStatus]?.name :
                <div>
                    <PrimeDropdown optionLabel="name" optionValue="key" style={{width: '100%'}}
                                   options={DirectBillingItemAuthorizationStatusEnum.toArray()}
                                   value={directBillingItem[fieldName]}
                                   appendTo={document.body}
                                   onChange={e => this.confirmDetailFieldChange(directBillingItem, 'authorizationStatus', e.target.value)}/>
                </div>
        }
    }

    textInputTemplate = (directBillingItem, fieldName) => {
        return this.isDetailFieldEditable(directBillingItem) !== true ? directBillingItem[fieldName] :
            <div>
                <TextInput value={directBillingItem[fieldName]} style={{width: '100%'}}
                           onChange={value => this.confirmDetailFieldChange(directBillingItem, fieldName, value)}/>
            </div>
    }

    isDetailFieldEditable = (directBillingItem) => {
        const {directBilling} = this.props;

        let respondedDirectBillingItems = directBilling.directBillingItemList ?
            directBilling.directBillingItemList.filter(dbItem => dbItem.status === 'RESPONDED') : [];

        return !((directBillingItem.isConfirmed != null && directBillingItem.isConfirmed === true) || respondedDirectBillingItems.length > 0);
    }

    confirmDirectBillingItemDetails = (directBillingItem) => {
        const validationResult =
            (
                directBillingItem.authorizationStatus != null &&
                directBillingItem.coPay != null &&
                directBillingItem.coPayUnit != null &&
                directBillingItem.referenceNo != null &&
                directBillingItem.validTo != null &&
                directBillingItem.authSessions != null
            );

        if (!validationResult) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Missing Detail Fields',
                detail: 'Enter missing fields to confirm'
            });

            return;
        }

        confirmDialog("Confirm Details",
            "You're about the confirm direct billing item details, are you sure?",
            () => {
                RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing-item/${directBillingItem.id}/confirm-details`,
                    directBillingItem, () => {
                    this.props.retrieveItems();
                });
            });
    }

    render() {
        const {directBilling} = this.props;
        let submitNewRequestIsVisible = this.state.claim?.invoice?.status !== "BILLED";

        return (
            <div className="p-4">
                <DataTable emptyMessage={"No items found"}
                           className="pre-approval-table"
                           value={directBilling.directBillingItemList}
                           columnResizeMode="fit"
                           scrollable
                           autoLayout
                           dataKey="id"
                           selection={this.state.selectedItems}
                           onSelectionChange={e => this.setState({selectedItems: e.value})}>
                    <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
                    <Column header="ID" field="id"/>
                    <Column header="CPT Code" body={item => <div className="text-break">{item.cpt.cptCode} {item.cpt.description}</div>}/>
                    <Column header="Req. Session" field="requestedSessions"/>
                    <Column header="Auth. Sessions" body={(directBillingItem) => this.numberTemplate(directBillingItem, 'authSessions')}/>
                    <Column header="Status" field="status" body={(directBillingItem) => this.statusTemplate(directBillingItem)}/>
                    <Column header="Co-Pay" body={(directBillingItem) => this.numberTemplate(directBillingItem, 'coPay')}/>
                    <Column header="Co-Pay Unit" body={(directBillingItem) => this.dropdownTemplate(directBillingItem, 'coPayUnit')}/>
                    <Column header="Max Co-Pay" body={(directBillingItem) => this.numberTemplate(directBillingItem, 'coPayMax')}/>
                    <Column header="Valid From" body={(directBillingItem) => this.dateTemplate(directBillingItem, 'validFrom')}/>
                    <Column header="Valid To" body={(directBillingItem) => this.dateTemplate(directBillingItem, 'validTo')}/>
                    <Column header="Referred By" body={() => this.referrerNameTemplate()}/>
                    <Column header="Performing Clinician" body={() => this.providerNameTemplate()}/>
                    <Column header="Ref No." className="text-break" body={(directBillingItem) => this.textInputTemplate(directBillingItem, 'referenceNo')}/>
                    <Column header="Observation" body={(directBillingItem) => this.observationTemplate(directBillingItem)} headerStyle={{ width: '100px' }}/>
                    <Column header="Resubmission" body={(directBillingItem) => this.resubmisssionTemplate(directBillingItem)} headerStyle={{ width: '100px' }}/>
                    <Column header="Insurance Response Status" field="authorizationStatus" body={(directBillingItem) => this.dropdownTemplate(directBillingItem, 'authorizationStatus')}/>
                    <Column header="Denial Code" body={(directBillingItem) => this.denialCodeTemplate(directBillingItem)}/>
                    <Column header="Observation From Insurance" body={(directBillingItem) => this.observationFromInsuranceTemplate(directBillingItem)} headerStyle={{ width: '100px' }} />
                    <Column header="Comments" field="comments"/>
                    <Column header="Note" field=""/>
                    <Column header="Request Version" body={(directBillingItem) => this.requestVersionTemplate(directBillingItem)}/>
                    <Column header="Authorization Version" body={(directBillingItem) => this.authorizationVersionTemplate(directBillingItem)}/>
                    <Column header="Attachment" field=""/>
                    <Column header="Actions" body={this.renderActions} style={{textAlign: "center", width:"75px"}}/>
                </DataTable>
                <SplitButton
                    id="preApprovalActions"
                    className="mt-1"
                    variant="success"
                    title="Submit Prior Request"
                    disabled={this.state.selectedItems.length === 0}
                    onClick={() => this.submitPriorRequest()}>
                    <Dropdown.Item onClick={() => this.extendPriorRequest()}>Extend Validity</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.cancelPriorRequest()}>Cancel Prior Request</Dropdown.Item>
                    {submitNewRequestIsVisible && <Dropdown.Item onClick={() => this.submitNewRequest()}>Submit New Request</Dropdown.Item>}
                </SplitButton>
                {this.renderInternalComplaintModal()}
            </div>
        )
    }

}