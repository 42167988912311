import React from "react";
import ToolbarText from '@material-ui/pickers/_shared/ToolbarText';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import {convertToMeridiem, getMeridiem} from '@material-ui/pickers/_helpers/time-utils';
import {makeStyles} from '@material-ui/core/styles';
import {useUtils} from "@material-ui/pickers"
import "./DateTimeInput.css"

const useMeridiemMode = (date, ampm, onChange) => {
    const utils = useUtils();
    const meridiemMode = getMeridiem(date, utils);

    const handleMeridiemChange = React.useCallback((mode) => {
            const timeWithMeridiem = convertToMeridiem(date, mode, Boolean(ampm), utils);
            onChange(timeWithMeridiem, false);
        },
        [ampm, date, onChange, utils]
    );

    return {meridiemMode, handleMeridiemChange};
};

export const DateTimeInputToolbar = function (props) {

    const {
        date,
        openView,
        setOpenView,
        ampm,
        title,
        onChange
    } = props;

    const classes = makeStyles({
            toolbar: {
                paddingLeft: 16,
                paddingRight: 16,
                justifyContent: 'space-around',
            },
            separator: {
                margin: '0 4px 0 2px',
                cursor: 'default',
            },
            timeContainer: {
                display: 'flex',
            },
            ampmContainer: {
                display: 'flex',
            },
            dateContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
            timeTypography: {},
            penIcon: {
                position: 'absolute',
                top: 8,
                right: 8,
            },
        },
        {name: 'MuiPickerDTToolbar'}
    )();

    const {meridiemMode, handleMeridiemChange} = useMeridiemMode(date, ampm, onChange);

    return (
        <>
            <PickerToolbar
                title={title}
                isLandscape={false}
                penIconClassName={classes.penIcon}
                className={`dim14-datetimepicker-toolbar ${classes.toolbar}`}
            >
                <div className={classes.dateContainer}>
                    <ToolbarButton
                        tabIndex={-1}
                        variant="h6"
                        label={date.format("MMM DD")}
                        onClick={() => setOpenView('date')}
                        selected={openView === 'date'}
                        className="dim14-datetimepicker-date"
                    />
                </div>

                <div className={`dim14-datetimepicker-time ${classes.timeContainer}`}>
                    <ToolbarButton
                        tabIndex={-1}
                        variant="h4"
                        onClick={() => setOpenView('hours')}
                        selected={openView === 'hours'}
                        label={date.format("HH")}
                        typographyClassName={classes.timeTypography}
                    />

                    <ToolbarText variant="h5" label=":" className={classes.separator}/>

                    <ToolbarButton
                        tabIndex={-1}
                        variant="h4"
                        onClick={() => setOpenView('minutes')}
                        selected={openView === 'minutes'}
                        label={date.format("mm")}
                        typographyClassName={classes.timeTypography}
                    />
                </div>

                {ampm && (
                    <div className={`dim14-datetimepicker-ampm ${classes.ampmContainer}`}>
                        <ToolbarButton
                            label="AM"
                            variant="h7"
                            selected={meridiemMode === 'am'}
                            onClick={() => handleMeridiemChange('am')}
                        />
                        <ToolbarText variant="h7" label="|" className={classes.separator}/>
                        <ToolbarButton
                            label="PM"
                            variant="h7"
                            selected={meridiemMode === 'pm'}
                            onClick={() => handleMeridiemChange('pm')}
                        />
                    </div>
                )}
            </PickerToolbar>
        </>
    );
};