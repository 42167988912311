import Enum from "../../../enums/Enum";
import React from "react";
import ConsentFormsUtil from "../utils/ConsentFormsUtil";

const MedicalPhotographyConsentFormTextEnum = new Enum({
    Medical_Photography_Consent_Title: {English: "Consent to Medical Photography", Arabic: "الموافقة على التصوير الفوتوغرافي"},
    Medical_Photography_Consent_Form: {English: "MEDICAL PHOTOGRAPHY CONSENT FORM", Arabic: "استمارة الموافقة على التصوير الفوتوغرافي"},

    MRN: {English: "MRN", Arabic: "السجل الطبي الإلكتروني"},
    Name: {English: "Name", Arabic: "الاسم"},
    DOB: {English: "D.O.B", Arabic: "تاريخ الميلاد"},

    Patient_Information: {English: "Patient Information", Arabic: "معلومات عن المريض"},

    Paragraph1: {
        English: "Your doctor/therapist has determined that it is helpful to obtain a photograph/recording of your condition to assist with treatment. This form will be part of your medical record and will be held and used strictly in accordance with your wishes defined below.",
        Arabic: "قرر طبيبك \\ الاخصائي المعالج ٬ أنه من المفيد الحصول على صورة فوتوغرافية / تسجيل فيديو لحالتك للمساعدة في العلاج وسيشكل هذا جزءًا من سجلك الطبي وسيتم الاحتفاظ به واستخدامه فقط وفقًا لرغباتك والتي يمكن تحديدها أدناه."
    },

    Paragraph2: {
        English: "Photographs will only be taken and used with your consent. This can be refused or limited by you and you may withdraw this or change it in the future.",
        Arabic: "الصور التي سوف تؤخذ سيتم استخدامها بموافقتك فقط، والتي يمكن رفضها أو ابقائها فقط لك، ويمكنك أيضًا مسحها أو تغييرها في المستقبل."
    },

    Proceed_Filling_Below: {
        English: "If yes, kindly proceed to filling the below",
        Arabic: "إذا كنت موافقا،ً الرجاء ملء أدناه"
    },

    Anonymously: {English: "Anonymously", Arabic: "ابق اسمي مجھول"},
    Disclosing_My_Name: {English: "Disclosing my name", Arabic: "الإفصاح عن اسمي"},

    List_Item_1: {
        English: "I consent to the photographs being used for educational purposes and made available to other clinicians involved in my treatment.",
        Arabic: "أوافق على مشاركة صوري مع الأخصائيين الآخرين المشاركين في علاجي وإستخدام الصور الخاصة بي لأهداف تعليمية"
    },

    List_Item_2: {
        English: "I consent to my photographs being used for the clinic’s website (www.disc-me.com) and social media pages (Facebook and Instagram).",
        Arabic: "أوافق على استخدام الصور الخاصة بي لموقع العيادة (www.disc-me.com) ولصفحات التواصل الاجتماعي الخاصة بالعيادة (فيسبوك وانستغرام)"
    },

    List_Item_3: {
        English: "I require the following restrictions to be applied to my images / recordings",
        Arabic: "اطلب تطبیق القیود أو التعدیلات التالیة على الصور/التسجیلات الخاصة بي"
    },

    Please_Sign_This_Form: {
        English: "Please sign this form only once you are happy with the above aspects and that they have been fully explained to you.",
        Arabic: "يرجى التوقيع على هذا النموذج إن كنت راض على جميع الجوانب المذكورة أعلاه التي قد تم شرحها لك بالكامل."
    },

    Date_Time: {English: "DATE/TIME", Arabic: "التاريخ / الوقت"},
    Patient_Name: {English: "PATIENT NAME", Arabic: "اسم المريض"},
    Patient_Signature: {English: "PATIENT SIGNATURE", Arabic: "توقيع المريض"},

    Witness_Paragraph1: {
        English: "A witness should sign below if the patient is unable to sign but has indicated his or her consent.",
        Arabic: "في حال تعذر المريض عن التوقيع لأي سبب من الاسباب و لكنه أقر بما هو مذكور اعلاه، يمكن لشاهد بالتوقيع بالإنابة عنه"
    },

    Witness_Paragraph2: {
        English: `Young people (under ${ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent or Guardian sign here.`,
        Arabic: `كما للشباب ما دون (سن 18 عامًا) و للأطفال موافقة الاهل او وصي والامضاء على ما هو مذكور اعلاه.`
    },

    Print_Name: {English: "PRINT NAME", Arabic: "الاسم المطبوع"},
    Relationship_To_Patient: {English: "RELATIONSHIP TO PATIENT", Arabic: "الصلة بالمريض"},
    Signature_Of_Witness: {English: "SIGNATURE OF WITNESS/PARENT OR GUARDIAN", Arabic: "توقيع الشاهد/الوالد أو الولي"},
    Signature_input_Clear: {English: "Clear", Arabic: "حذف"},
    Wizard_Previous: {English: "Previous", Arabic: "السابق"},
    Wizard_Next: {English: "Next", Arabic: "التالي"},
    Wizard_Save: {English: "Save", Arabic: "حفظ"}

});

export default MedicalPhotographyConsentFormTextEnum;