import React, {Component} from "react";
import '../../EMR.css';
import '../Assessment.css';
import YesNoEnum from "../enums/morseFallRisk/YesNoEnum";
import BorderedRadioInput from "../../../../../components/radio-input/BorderedRadioInput";
import AmbulatoryAidEnum from "../enums/morseFallRisk/AmbulatoryAidEnum";
import GaitEnum from "../enums/morseFallRisk/GaitEnum";
import MentalStatusEnum from "../enums/morseFallRisk/MentalStatusEnum";
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FurtherAssessmentEnum from "../enums/morseFallRisk/FurtherAssessmentEnum";
import StringUtil from "../../../../../utils/StringUtil";
import AuthService from "../../../../../services/auth-service/AuthService";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import MorseFallRiskUtils from "../utils/MorseFallRiskUtils";

export default class MorseFallRiskModal extends Component {

    state = {
        fallRisk:{
            fallingHistory: null,
            secondaryDiagnosis: null,
            ambulatoryAid: null,
            heparinLockIVTherapy: null,
            gait: null,
            mentalStatus: null,
            riskScore: 0,
            referralRequired: null,
            riskLevel: null,
            furtherAssessmentItem: [],
            isPhysiotherapist : null

        },
        scores:{
            fallingHistory: 0,
            secondaryDiagnosis: 0,
            ambulatoryAid: 0,
            heparinLockIVTherapy: 0,
            gait: 0,
            mentalStatus: 0
        },
        furtherAssessment: null,
        result: null,
        details: null,
        isUpdate : null,
        heparinLockIVTherapy: null,
        fallingHistory : null,
        secondaryDiagnosis: null,
    };

    constructor(props) {
        super(props);
        if (props.assessment.fallRisk != null) {
            this.state.fallRisk = props.assessment.fallRisk;
            this.state.isUpdate = true;
        }
        else{
            this.createFallRiskIfNecessary();
            this.state.isUpdate = false;
        }
        this.state.fallingHistory = JSON.stringify(this.state.fallRisk.fallingHistory);
        this.state.secondaryDiagnosis = JSON.stringify(this.state.fallRisk.secondaryDiagnosis);
        this.state.heparinLockIVTherapy = JSON.stringify(this.state.fallRisk.heparinLockIVTherapy);
        this.state.fallRisk.staffSpeciality = AuthService.getStaffSpeciality() === "Physiotherapist";
    }



    render() {
        let furtherAssessmentDivs = this.getFurtherAssessmentDivs();
        this.calculateScore();
        return (

            <div className={"emr-modal content-row emr-background p-4 w-100"}>
                <div className="w-100 m-3">
                    <div>
                        <div className={"MorseFallRiskAssessmentdiv"} style={{display: "flex"}}>
                            <div className="content-label" style={{marginLeft:"-10px", fontWeight:"bold"}}>Morse Fall Risk Assessment <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-4 "}>
                                <div style={{fontWeight: "bold", marginTop: "10px"}}>History of Falling <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            </div>
                            <div className={"col-6 radioChoiceDiv"}>
                                <BorderedRadioInput
                                    values={YesNoEnum.toArray().reverse()}
                                    style={{width: "40px",marginLeft:"10px" }}
                                    selected={this.state.fallingHistory }
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.fallRisk.fallingHistory = value;
                                            state.fallingHistory = value;
                                            return state;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-4 "}>
                                <div style={{fontWeight: "bold", marginTop: "10px"}}>Secondary Diagnosis <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            </div>
                            <div className={"col-6 radioChoiceDiv"}>
                                <BorderedRadioInput
                                    values={YesNoEnum.toArray().reverse()}
                                    style={{width: "40px",marginLeft:"10px" }}
                                    selected={ this.state.secondaryDiagnosis }
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.fallRisk.secondaryDiagnosis =value;
                                            state.secondaryDiagnosis = value;
                                            return state;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-4 "}>
                                <div style={{fontWeight: "bold", marginTop: "10px"}}>Ambulatory Aid <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            </div>
                            <div className={"col-8 radioChoiceDiv"}>
                                <BorderedRadioInput
                                    values={AmbulatoryAidEnum.toArray()}
                                    style={{marginLeft:"10px"}}
                                    selected={this.state.fallRisk.ambulatoryAid }
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.fallRisk.ambulatoryAid = value;
                                            return state;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-4 "}>
                                <div style={{fontWeight: "bold", marginTop: "10px"}}>IV Therapy / Heparin Lock <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            </div>
                            <div className={"col-6 radioChoiceDiv"}>
                                <BorderedRadioInput
                                    values={YesNoEnum.toArray().reverse()}
                                    style={{width: "40px",marginLeft:"10px" }}
                                    selected={this.state.heparinLockIVTherapy }
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.fallRisk.heparinLockIVTherapy =value;
                                            state.heparinLockIVTherapy = value;
                                            return state;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-4 "}>
                                <div style={{fontWeight: "bold", marginTop: "10px"}}>Gait <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            </div>
                            <div className={"col-8 radioChoiceDiv"}>
                                <BorderedRadioInput
                                    values={GaitEnum.toArray()}
                                    style={{marginLeft:"10px" }}
                                    selected={this.state.fallRisk.gait }
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.fallRisk.gait = value;
                                            return state;
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-4 "}>
                                <div style={{fontWeight: "bold", marginTop: "10px"}}>Mental Status <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            </div>
                            <div className={"col-8 radioChoiceDiv"}>
                                <BorderedRadioInput
                                    values={MentalStatusEnum.toArray()}
                                    style={{marginLeft:"10px" }}
                                    selected={this.state.fallRisk.mentalStatus }
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.fallRisk.mentalStatus = value;
                                            return state;
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"row riskLevelDiv"}>
                            <div className={"col riskLevelNumberDiv"}>
                                <div className={"riskLevelNumberText"}>
                                     Total:{this.state.fallRisk.riskScore}
                                </div>
                            </div>
                            {this.getRisk()}
                        </div>
                        <div className={"row riskLevelNoteDiv"}>
                            <div>
                                In case of Moderate or High Risk, a comprehensive Risk of Fall Assessment has to be performed by a licensed Physiotherapist. Results are documented below:
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-6"}>
                                <div style={{ fontWeight: "bold", marginTop: "10px"}}>
                                    <div className="content-label">
                                        Physiotherapist referral required?
                                    </div>
                                </div>
                            </div>
                            <div className={"col- riskReferralSwitchDiv d-flex"}>
                                <div className="align-self-center">{!this.state.fallRisk.referralRequired ? "No" : "Yes"}</div>
                                <GreenSwitchWithEmptyMessage checked={this.state.fallRisk.referralRequired}
                                                             onChange={(event) => {
                                                                 if (this.state.fallRisk.referralRequired == null){
                                                                     this.setState((state) => {
                                                                         state.fallRisk.referralRequired = true;
                                                                         return state;
                                                                     })
                                                                 }
                                                                 else{
                                                                 this.setState((state) => {
                                                                     state.fallRisk.referralRequired = !this.state.fallRisk.referralRequired;
                                                                     return state;
                                                                 })
                                                                 }
                                                             }}
                                />
                           </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-6"}>
                                <div style={{fontSize: "25px", fontWeight: "bold", marginTop: "10px"}}>
                                    <div className="content-label" >
                                    Further Assessment
                                    </div>
                                </div>
                            </div>
                            <div className={"col- riskReferralSwitchDiv"}>
                                <GreenSwitchWithEmptyMessage checked={this.state.fallRisk.furtherAssessmentItem.length !== 0}
                                                             onChange={(event) => {
                                                                 this.setState((state) => {
                                                                     state.fallRisk.furtherAssessmentItem = [];
                                                                     return state;
                                                                 })
                                                             }}
                                />
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col"}>
                                <div style={{fontWeight: "bold"}}>Further Assessment</div>
                            </div>
                            <div className={"col"}>
                                <div style={{fontWeight: "bold"}}>Result</div>
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col"}>
                                <Select
                                    className={"emr-white-select-container"}
                                    classNamePrefix={"emr-white-select"}
                                    menuPlacement="top"
                                    options =
                                    {FurtherAssessmentEnum.toArray().map(v=> {

                                        return {value: v.key, label:v.name}}
                                    )}
                                    value={this.state.furtherAssessment == null ? null :
                                        {
                                            value: FurtherAssessmentEnum[this.state.furtherAssessment].key,
                                            label: FurtherAssessmentEnum[this.state.furtherAssessment].name
                                        }
                                    }
                                    onChange={(event) => {
                                        let r = event.value;
                                        this.setState((state) => {
                                            state.furtherAssessment = r;
                                            return state;
                                        })
                                    }}
                                    placeholder={"Further Assessment"}/>
                            </div>
                            <div className={"col"}>
                                <TextInput value={this.state.result || ''}
                                           placeholder={"Result"}
                                           onChange={value =>  this.setState({result: value})}
                                           style={{height:"26px", maxHeight: "26px",width: "100%"}}
                                />
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-11"}>
                                <TextInput value={this.state.details || '' }
                                           placeholder={"Details"}
                                           onChange={value =>  this.setState({details: value})}
                                           style={{width: "100%",marginRight: "auto",marginLeft:"0"}}
                                />
                            </div>
                            <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                <Button className={"emr-plus-button"}
                                        variant="success"
                                        disabled={StringUtil.isNullOrEmpty(this.state.furtherAssessment)
                                        || this.state.result == null}
                                        style={{fontSize:"12px"}}
                                        onClick={this.addFurtherAssessment}>
                                    <FontAwesomeIcon icon={["fas", "plus"]}/>
                                </Button>
                            </div>
                        </div>
                        <div className={"row riskFurtherAssessmentResults"} style={{width: "103%", marginTop: "10px !important"}}>
                            <div className={"div-list-wrapper"}>
                                {furtherAssessmentDivs}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    getFurtherAssessmentDivs = () => {
        if (this.state.fallRisk.furtherAssessmentItem == null
            || this.state.fallRisk.furtherAssessmentItem.length == 0) {
            return;
        }
        let divs = [];
        this.props.assessment.fallRisk.furtherAssessmentItem.forEach((item, index) =>{
            divs.push(
            <div className={"div-list-item"} key={"furtherAssessmentItem" + index}>
                <div>
                    {FurtherAssessmentEnum[item.furtherAssessment].name} , {item.result} , {item.details}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize:"10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.setState((state) => {
                                this.props.assessment.fallRisk.furtherAssessmentItem.splice(index, 1);
                                return state;
                            })
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas","times"]}/>
                </Button>
            </div>
        )});
        return divs;
    }
    addFurtherAssessment = () =>{
        this.state.fallRisk.furtherAssessmentItem.push({
            furtherAssessment: this.state.furtherAssessment,
            result: this.state.result,
            details: this.state.details
        });
        this.setState({
            furtherAssessment: null,
            result: null,
            details: null
        })

    }
    createFallRiskIfNecessary = () =>{
        if(this.props.assessment.fallRisk == null){
            this.props.assessment.fallRisk = this.state.fallRisk;
        }
        this.forceUpdate();
    }

    getRisk = () => {
        const riskLevel = MorseFallRiskUtils.getRiskLevel(this.state.fallRisk.riskScore);
        this.state.fallRisk.riskLevel = riskLevel.key;

        return (
            <div className={"col " + riskLevel.className}>
                <div className="riskLevelText">
                    Risk Level: {riskLevel.name}
                </div>
            </div>
        );
    }

    calculateScore = () =>{

        let score = 0;
        if (this.state.fallRisk.fallingHistory != null) {
            if(this.state.fallRisk.fallingHistory == "true" ||
                this.state.fallRisk.fallingHistory == true){
                score += 25;
            }
        }
        if (this.state.fallRisk.secondaryDiagnosis != null) {
            if(this.state.fallRisk.secondaryDiagnosis == "true" ||
                this.state.fallRisk.secondaryDiagnosis == true ){
                score += 15;
            }
        }
        if (this.state.fallRisk.ambulatoryAid != null) {
            if(this.state.fallRisk.ambulatoryAid == "CRUTCHES_CANE_WALKER"){
                score += 15;
            }
            if(this.state.fallRisk.ambulatoryAid == "FURNITURE"){
                score += 30;
            }
        }
        if (this.state.fallRisk.heparinLockIVTherapy != null) {
            if(this.state.fallRisk.heparinLockIVTherapy == "true" ||
                this.state.fallRisk.heparinLockIVTherapy == true){
                score += 20;
            }
        }
        if (this.state.fallRisk.gait != null) {
            if(this.state.fallRisk.gait == "WEAK"){
                score += 10;
            }
            if(this.state.fallRisk.gait == "IMPAIRED"){
                score += 20;
            }
        }
        if (this.state.fallRisk.mentalStatus != null) {
            if(this.state.fallRisk.mentalStatus == "OVERESTIMATES_OR_FORGETS_LIMITS"){
                score += 15;
            }
        }
        this.state.fallRisk.riskScore = score;
    }

}