import React, {Component} from "react";
import '../../VisitHistory.css'
import TypeEnum from "../../../treatment/enums/result/TypeEnum";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateUtil from "../../../../../../utils/DateUtil";
import CommunicationMethod from "../../../../../../components/radio-input/CommunicationMethod";
import AttachmentUtils from "../../../treatment/utils/AttachmentUtils";
import ReduxService from "../../../../../../services/redux-service/ReduxService";
import RemotingService from "../../../../../../services/remoting-service/RemotingService";
import Modal from "../../../../../../components/modal/Modal";

export default class TreatmentResultHistory extends Component {

    state = {
        viewerVisible: false,
        viewerHeader: null,
        blobUrl: null
    }

    render() {
        if (!this.props.results
            || this.props.results.length === 0) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <div>
                <h5 className="font-weight-bold">Interventions</h5>
                <table className={"visit-history-table"}>
                    <tbody>
                    <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th>Type</th>
                        <th>Details</th>
                        <th>Findings</th>
                        <th>Remarks</th>
                        <th>Date Ordered</th>
                        <th>Ordered By</th>
                        <th>Result Received</th>
                        <th>Patient Informed</th>
                        <th>Attach</th>
                    </tr>
                    {this.props.results.map((m, index) => {
                        return (<tr key={"resultView" + index} style={{backgroundColor: "white"}}>
                            <td>{m.type == null ? "" : TypeEnum[m.type].name}</td>
                            <td>{m.detail}</td>
                            <td>{m.findings}</td>
                            <td>{m.remarks}</td>
                            <td>{m.dateOrdered == null ? "" : DateUtil.formatDateMMM(m.dateOrdered)}</td>
                            <td>{m.orderedBy}</td>
                            <td>{m.resultReceived}</td>
                            <td>
                                <CommunicationMethod value={m.patientInformed}
                                                     informedDate={m.informedDate}
                                                     disabled={true}/>
                            </td>
                            <td style={{width: 180, padding: "5px 0px"}}>
                                {this.attachmentTable(m.files)}
                            </td>
                        </tr>)
                    })}
                    </tbody>
                </table>

                {this.state.viewerVisible &&
                <Modal visible={true}
                       header={this.state.viewerHeader}
                       closeAction={() => this.setState({
                           viewerVisible: false
                       })}>
                    <iframe id="documentViewer" src={this.state.blobUrl} width="800px" height="600px"></iframe>
                </Modal>
                }
            </div>
        );
    }

    downloadOrView = (file) => {
        if (AttachmentUtils.viewEnable(file.name)) {
            return this.view(file);
        }
        return AttachmentUtils.download(file);
    }

    view = (file) => {
        ReduxService.incrementRemotingOperationCount();

        RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
            .then((response) => {
                let blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                this.setState({
                    viewerVisible: true,
                    viewerHeader: file.name,
                    blobUrl: blobUrl
                });
            })
            .finally(() => {
                ReduxService.decrementRemotingOperationCount();
            });
    }

    attachmentTable = (files) => {
        if (files == null || files.length == 0) {
            return null;
        }
        return (
            <div>
                <FontAwesomeIcon icon={["fas", "paperclip"]} size={"lg"} style={{marginLeft: '5px'}}/>
                <table className={"attachment-list"}>
                    <tbody>
                    {files.map((f) => {
                        return (
                            <tr>
                                <td className={"link-column"}
                                    onClick={() => {
                                        this.downloadOrView(f);
                                    }}>
                                    {f.name}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}
