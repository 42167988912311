import React, {Component} from "react";
import SignatureCanvas from "react-signature-canvas";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IntakeFormTextEnum from "../../modules/consentform/intakeform/enum/IntakeFormTextEnum";

export default class SignatureInput extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.signature) {
            this.sigCanvas.fromDataURL(this.props.signature);
        }
        if (this.props.readOnly) {
            this.sigCanvas.off();
        }
    }

    render() {
        const {textEnum, selectedLanguage} = this.props;
        const clearTextButton = textEnum ? textEnum.Signature_input_Clear[selectedLanguage] : "Clear" ;
        return (
            <div className="signature-canvas">
                <SignatureCanvas
                    ref={(ref) => {
                        this.sigCanvas = ref;
                    }}
                    clearOnResize={false}
                    canvasProps={{
                        height: 100,
                        className: "sigCanvas",
                        width:400,
                        style: {
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                        }
                    }}
                    onEnd={event => {
                        this.props.onSave(this.sigCanvas.toDataURL());
                    }}
                />

                {!this.props.readOnly &&
                    <div className="col-auto p0">
                        <Button
                            size="sm"
                            className="btn btn-danger"
                            onClick={() => {
                                this.sigCanvas.clear();
                                this.props.onSave(null);
                                if (this.props.onClear) {
                                    this.props.onClear();
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={["fas", "trash"]} size="lg"/> {clearTextButton}
                        </Button>
                    </div>
                }
            </div>
        );
    }
}
