import React, {Component} from "react";
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import NotificationService from "../../services/notification-service/NotificationService";
import SignatureInput from "../../components/signature-input/SignatureInput";
import Logo from "../../layout/Logo";
import FormField from "../../components/form/FormField";
import PatientUtil from "../../utils/PatientUtil";
import ConsentFormsUtil from "./utils/ConsentFormsUtil";

class ShockwaveTherapyConsentForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            healthProfessionalDate: new Date(),
            patientDate: new Date(),
            guardianDate: new Date(),
            providerName: this.props.staff.name,
            providerSpeciality: this.props.staff.speciality.name
        };
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();
        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(
                patient.firstName,
                patient.middleName,
                patient.lastName
            );
            let patientMrn = StringUtil.mrn(patient.mrn);
            let patientDOB = patient.birthday;

            const {
                healthProfessionalDate,
                patientDate,
                guardianDate,
                guardianName,
                guardianRelationship,
                patientSignature,
                guardianSignature,
                providerSignature,
                providerName, providerSpeciality
            } = this.state;

            RemotingService.postRemoteCall('api/visit/form/shockwave-therapy-consent-form/' + this.props.visitId,
                {
                    patientDate,
                    guardianDate,
                    guardianName,
                    guardianRelationship,
                    patientSignature,
                    guardianSignature,
                    patientFullName,
                    patientMrn,
                    patientDOB,
                    healthProfessionalDate,
                    providerSignature,
                    providerName, providerSpeciality
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                }
            );
        }
    };

    onSaveGuardianSignature = (signature) => {
        this.setState({guardianSignature: signature});
    };

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature});
    };

    onSaveProviderSignature = (signature) => {
        this.setState({providerSignature: signature});
    };

    render() {
        if (!this.props.show) return null;

        const {providerName, providerSpeciality} = this.state;

        let {patient, form} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(
            patient.firstName,
            patient.middleName,
            patient.lastName
        );
        this.formFields = [];

        return (
            <Wizard
                show={this.props.show}
                close={this.props.close}
                title="Shockwave Therapy Consent Form"
                onSubmit={this.onSubmit}
                isReadOnly={readOnly}
            >
                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div style={{maxWidth: "950px"}}>
                        <div
                            style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                                width: "100%",
                                display: "flex",
                                flexFlow: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Logo/>
                            <div>
                                <h2>SHOCKWAVE THERAPY CONSENT FORM</h2>
                            </div>
                        </div>

                        <hr
                            style={{
                                borderTop: "2px solid #000000",
                                marginTop: "-10px",
                                boxShadow: "0px 2px 2px #797979",
                            }}
                        />

                        <div style={{border: "1px solid #000000"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h5>
                                    <b>Patient information:</b>
                                </h5>
                                <div
                                    className={"row"}
                                    style={{marginTop: "10px", marginLeft: "0px"}}
                                >
                                    <div className="col-md-5">
                                        <b>Name:</b> {patientName}
                                    </div>
                                    <div className="col-md-4">
                                        <b>MRN:</b> {mrnNo}
                                    </div>
                                    <div className="col-md-3">
                                        <b>D.O.B:</b> {patient.birthday}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h6>
                                    <b>Shockwave Therapy</b>
                                </h6>
                                <p>
                                    Shockwave therapy is a non-invasive treatment in which a
                                    device is used to pass acoustic shockwaves through the skin to
                                    the affected area, it can be utilized in the treatment of
                                    tendon pathology.
                                </p>
                            </div>
                        </div>

                        <div className="p-3" style={{border: "1px solid #000000"}}>
                            <h6>
                                <b>Statement of Health Professional: </b>
                            </h6>
                            <p>
                                (to be filled in by health professional with appropriate
                                knowledge of proposed investigation){" "}
                            </p>
                            <p>
                                I have explained the procedure to the patient, in particular:
                            </p>
                            <p>
                                Shockwave therapy is a non-invasive treatment that involves
                                delivery of shock waves to injured soft tissue to reduce pain
                                and promote healing It stimulates the body’s own healing
                                mechanisms to facilitate repair. The treatment produces an
                                inflammatory response and the body responds by increasing
                                metabolic activity around the site of pain. This stimulates and
                                accelerates the healing process. Shockwave breaks down scar
                                tissue and possibly calcification as well and the transmission
                                of pain is blocked.
                            </p>
                            <p>
                                It is a short treatment (usually five to twenty minutes) that
                                may be uncomfortable. However, the procedure is usually
                                tolerated well by most people
                            </p>
                            <p>
                                There may or may not be immediate pain, but some discomfort may
                                be experienced 2-4 hours after the treatment. In some cases, it
                                can last up to 48 hours and in very rare cases, the pain may
                                last up to 5 days.
                            </p>
                            <h6>
                                <b>Benefits</b>
                            </h6>
                            <p>
                                There may be immediate reduction of pain and improved ease of
                                movement.
                            </p>

                            <h6>
                                <b>Side effects</b>
                            </h6>
                            <article className="d-flex w-75 ">
                                <u className="mr-2">Common:</u>
                                <p>
                                    <b>
                                        Mild redness, bruising, swelling, pain, numbness, skin
                                        damage
                                    </b>{" "}
                                    or tingling in the treated area. An increase in pain in the
                                    treated area.
                                </p>
                            </article>
                            <article className="d-flex w-75 ">
                                <u className="mr-2">Very Rare:</u>
                                <p>Tendon tear or rupture</p>
                            </article>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <p>
                                    <label>
                                        <b>PROVIDER NAME: </b>
                                    </label>
                                    {providerName}
                                </p>
                                <p>
                                    <label>
                                        <b>JOB TITLE: </b>
                                    </label>
                                    {providerSpeciality}
                                </p>
                                <p>
                                    <label>
                                        <b>DATE/TIME: </b>
                                    </label>
                                    {moment(
                                        readOnly && form.healthProfessionalDate
                                            ? new Date(form.healthProfessionalDate)
                                            : this.state.healthProfessionalDate
                                    ).format("MMMM DD YYYY, h:mm:ss a")}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-7">
                                <label>
                                    <b>PROVIDER SIGNATURE: </b>
                                </label>
                                <FormField
                                    ref={(formField) => this.formFields.push(formField)}
                                    disabled={readOnly}
                                    required requiredMessage="Signature required"
                                    validateOn={this.state.providerSignature}
                                >
                                    <SignatureInput
                                        signature={
                                            readOnly
                                                ? form.providerSignature
                                                : this.state.providerSignature
                                        }
                                        onSave={this.onSaveProviderSignature}
                                        readOnly={readOnly}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{maxWidth: "950px"}}>
                        <div className="p-3" style={{border: "1px solid #000000"}}>
                            <h6>
                                <b>Statement of patient:</b>
                            </h6>
                            <p>
                                Please read this form carefully. If you have any further
                                questions, do ask. You have the right to change your mind at any
                                time, including after you have signed this form.
                            </p>
                            <h6>
                                <b>
                                    I <b>understand</b> and agree to the following:
                                </b>
                            </h6>
                            <ul>
                                <li>
                                    <p>
                                        I <b>understand</b> the investigation which has been
                                        explained to me.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        I <b>understand</b> that I can terminate the procedure at
                                        any time.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        I <b>understand</b> that I am responsible for immediately
                                        telling the examiner if I am having any discomfort or
                                        unusual symptoms during the procedure and if I wish to
                                        terminate the examination or treatment.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        I <b>understand</b> that I may experience pain after the
                                        procedure. The shockwave will trigger an inflammatory
                                        response, which is the body’s natural process of healing.
                                        For this reason, <b>I have been instructed</b> to not use
                                        anti-inflammatory medications. Not use ice. The pain should
                                        subside within 24 hours.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        I <b>verify</b> that I have not had a steroid injection in
                                        the past month or take anti-coagulant therapy
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        I <b>understand</b> that the person performing the
                                        investigation has the appropriate experience or is training
                                        under direct supervision.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        I <b>agree</b> that I have had a chance to ask appropriate
                                        questions.
                                    </p>
                                </li>
                            </ul>

                            <p>
                                I have <b>informed</b> my Clinician that I <b>DO NOT HAVE</b> a
                                tendency to hemorrhage, Hemophilia, acute inflammation of the
                                treatment area, tumor at the treatment site, infection of the
                                treatment area, pregnant, severe circulatory disorder, nerve
                                disorder at treatment site, open wound at the treatment site,
                                prosthesis or any other condition that may contraindicate this
                                treatment.
                            </p>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <p>
                                    <label>
                                        <b>PATIENT NAME: </b>
                                    </label>
                                    {patientName}
                                </p>
                                <p>
                                    <label>
                                        <b>DATE/TIME: </b>
                                    </label>
                                    {moment(
                                        readOnly && form.healthProfessionalDate
                                            ? new Date(form.healthProfessionalDate)
                                            : this.state.healthProfessionalDate
                                    ).format("MMMM DD YYYY, h:mm:ss a")}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-7">
                                <label>
                                    <b>PATIENT SIGNATURE: </b>
                                </label>
                                <SignatureInput
                                    signature={
                                        readOnly
                                            ? form.patientSignature
                                            : this.state.patientSignature
                                    }
                                    onSave={this.onSavePatientSignature}
                                    readOnly={readOnly}

                                />
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <p>
                                    <b>
                                        A witness should sign below if the patient is unable to
                                        sign but has indicated his or her consent. <br/>
                                        Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should
                                        also have a Parent or Guardian sign here.
                                    </b>
                                </p>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">PRINT NAME:</div>
                                <div className="col-md-3">
                                    <TextInput
                                        value={
                                            readOnly ? form.guardianName : this.state.guardianName
                                        }
                                        disabled={readOnly} alphabeticOnly
                                        style={{textAlign: "center"}}
                                        onChange={(val) => this.setState({guardianName: val})}
                                    />
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput
                                        value={
                                            readOnly
                                                ? form.guardianRelationship
                                                : this.state.guardianRelationship
                                        }
                                        disabled={readOnly} alphabeticOnly
                                        style={{textAlign: "center"}}
                                        onChange={(val) =>
                                            this.setState({guardianRelationship: val})
                                        }
                                    />
                                </div>
                            </div>

                            <div className={"row"} style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">DATE/TIME:</div>

                                <div className="col-md-3">
                                    {moment(
                                        readOnly && form.guardianDate
                                            ? new Date(form.guardianDate)
                                            : this.state.guardianDate
                                    ).format("MMMM DD YYYY, h:mm:ss a")}
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    SIGNATURE OF WITNESS/PARENT OR GUARDIAN:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <FormField
                                        ref={(formField) => this.formFields.push(formField)}
                                        disabled={readOnly || this.state.patientSignature != null}
                                        required requiredMessage="Signature required"
                                        validateOn={this.state.guardianSignature}
                                    >
                                        <SignatureInput
                                            signature={
                                                readOnly
                                                    ? form.guardianSignature
                                                    : this.state.guardianSignature
                                            }
                                            onSave={this.onSaveGuardianSignature}
                                            readOnly={readOnly}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }
}

export default ShockwaveTherapyConsentForm;