import {GenericChecker} from "../../subjective/GenericChecker";

export class RiskChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }

        return entity.medical != 0
            || entity.social != 0
            || entity.surgical != 0
            || entity.activity != 0;
    }
}