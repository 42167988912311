import React, {Component} from "react";
import "../../visithistory/VisitHistory.css";
import ReportViewUtils from "./ReportViewUtils";
import ExaminationEnum from "../../assessment/enums/examination/ExaminationEnum";
import Modal from "../../../../../components/modal/Modal";
import MSKDiagnosisFindingsModal from "../../assessment/modals/MSKDiagnosisFindingsModal";

export default class MSKDiagnosisReportView extends Component {
    state = {
        MSKDiagnosisFindingsModalVisible: false
    }

    render() {
        const mskDiagnosis = this.props.mskDiagnosis;

        if (ReportViewUtils.isMskDiagnosisNull(mskDiagnosis)) {
            return null;
        }

        return (
            <>
                {mskDiagnosis.items.map((item) => {
                    const examinationLocation = item.notApplicableBool? "Not Applicable" : (item.leftBool? "Left" : item.rightBool? "Right" : null) ;
                    return (
                        <div style={{paddingLeft: 10, paddingBottom: 10}}>
                            <div className={"row"}>
                                <div className={"col-2"}>
                                    <b>Presenting Complaint</b>
                                </div>
                                <div className={"col-10"}>
                                    : {item.presentingComplaint}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-2"}>
                                    <b>Examination</b>
                                </div>
                                {item.examination || item.examinationComment ?
                                    <div className={"col-9"}>
                                        {(item.examination ? ExaminationEnum[item.examination].name : item.examinationComment) + (examinationLocation? " - " +examinationLocation : "") }
                                    </div>
                                    :
                                    ''}
                            </div>
                            <div className={"row"}>
                                <div className={"col-2"}>
                                    <b>Findings</b>
                                </div>
                                <div className={"col-10"}>
                                    : {item.findings?.length > 1024 ?
                                    <div>
                                        {item.findings?.substring(0, 1024)}
                                        {" ... "}
                                        <span
                                            style={{color: "green"}}
                                            onClick={() => this.setState({MSKDiagnosisFindingsModalVisible: true})}>
                                                    Show more
                                            </span>
                                    </div> : item.findings
                                }
                                    <Modal visible={this.state.MSKDiagnosisFindingsModalVisible}
                                           submitContent="OK"
                                           contentStyle={{maxHeight: 600, overflowY: 'auto'}}
                                           submitAction={() => this.setState({MSKDiagnosisFindingsModalVisible: false})}
                                           closeAction={() => this.setState({MSKDiagnosisFindingsModalVisible: false})}
                                           header='MSK Diagnosis Findings'
                                           maxWidth={"xl"}
                                           contentStyle={{maxHeight: "140%", width: "75vw", overflowY: 'auto'}}>
                                        <MSKDiagnosisFindingsModal findings={item.findings}/>
                                    </Modal>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-2"}>
                                    <b>Impression</b>
                                </div>
                                <div className={"col-10"}>
                                    : {item.impression}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-2"}>
                                    <b>Diagnosis</b>
                                </div>
                                <div className={"col-10"}>
                                    : {item.diagnosisCode} - {item.diagnosisDescription}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-2"}>
                                    <b>Recommendations</b>
                                </div>
                                <div className={"col-10"}>
                                    : {item.recommendations}
                                </div>
                            </div>
                        </div>

                    );
                })
                }
            </>
        );

    }

}
