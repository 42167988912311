import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {NumberInput, TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import SignatureInput from "../../components/signature-input/SignatureInput";
import Logo from "../../layout/Logo";
import FormField from "../../components/form/FormField";
import PatientUtil from "../../utils/PatientUtil";
import ConsentFormsUtil from "./utils/ConsentFormsUtil";
import FormLanguages from "./enum/FormLanguages";
import InjectionConsentFormTextEnum from "./injectionconsent/InjectionConsentFormTextEnum";

class InjectionConsentForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            healthProfessionalDate: new Date(),
            patientDate: new Date(),
            guardianDate: new Date(),
            providerName: this.props.staff.name,
            providerSpeciality: this.props.staff.speciality.name
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            let patientDOB = patient.birthday;
            const {
                feeForProposedProcedure,
                nameOfProposedInvestigation,
                patientDate,
                healthProfessionalDate,
                guardianDate,
                guardianName,
                guardianRelationship,
                patientSignature,
                guardianSignature,
                providerSignature,
                selfPayingClientCost,
                providerName, providerSpeciality
            } = this.state;

            RemotingService.postRemoteCall('api/visit/form/injection-consent-form/' + this.props.visitId,
                {
                    nameOfProposedInvestigation,
                    feeForProposedProcedure,
                    patientDate,
                    guardianDate,
                    healthProfessionalDate,
                    guardianName,
                    guardianRelationship,
                    patientSignature,
                    guardianSignature,
                    providerSignature,
                    selfPayingClientCost,
                    patientFullName,
                    patientMrn,
                    patientDOB,
                    providerName, providerSpeciality
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSaveGuardianSignature = (signature) => {
        this.setState({guardianSignature: signature})
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    onSaveProviderSignature = (signature) => {
        this.setState({providerSignature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        const {providerName, providerSpeciality} = this.state;

        let {patient, form} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []
        const country = form ? form.patientCountry : patient.mrn.country;

        const selectedLanguage = this.props.selectedLanguage.name;
        const formBodyClass = this.props.selectedLanguage == FormLanguages.ARABIC ? "arabic-form" : "";

        return (
            <>
                <Wizard
                    show={this.props.show}
                    close={this.props.close}
                    title={InjectionConsentFormTextEnum.Injection_Consent_Title[selectedLanguage]}
                    onSubmit={this.onSubmit}
                    isReadOnly={readOnly}
                    previousBtnText={InjectionConsentFormTextEnum.Wizard_Previous[selectedLanguage]}
                    nextBtnText={InjectionConsentFormTextEnum.Wizard_Next[selectedLanguage]}
                    saveBtnText={InjectionConsentFormTextEnum.Wizard_Save[selectedLanguage]}
                >
                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                        <div className={formBodyClass} style={{width: '1000px'}}>
                            <div style={{
                                marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                                flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                            }}>
                                <Logo/>
                                <div>
                                    <h2>{InjectionConsentFormTextEnum.Injection_Consent_Form[selectedLanguage]}</h2>
                                </div>
                            </div>

                            <hr style={{
                                borderTop: "2px solid #000000",
                                marginTop: "-10px",
                                boxShadow: "0px 2px 2px #797979"
                            }}/>

                            <div style={{border: "1px solid #000000"}}>
                                <div style={{margin: "15px 15px"}}>
                                    <h5><u>{InjectionConsentFormTextEnum.Patient_Information[selectedLanguage]}:</u></h5>
                                    <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                        <div className="col-md-5">
                                            <b>{InjectionConsentFormTextEnum.Name[selectedLanguage]}:</b> {patientName}
                                        </div>
                                        <div className="col-md-4">
                                            <b>{InjectionConsentFormTextEnum.MRN[selectedLanguage]}:</b> {mrnNo}
                                        </div>
                                        <div className="col-md-3">
                                            <b>{InjectionConsentFormTextEnum.DOB[selectedLanguage]}:</b> {patient.birthday}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                                <div className={"row"} style={{margin: "15px 15px", flexDirection: "column"}}>
                                    <h5>{InjectionConsentFormTextEnum.Name_Of_Proposed_Procedure[selectedLanguage]}:</h5>
                                    <FormField ref={formField => this.formFields.push(formField)} required
                                               validateOn={readOnly ? true : this.state.nameOfProposedInvestigation}>
                                        <TextInput
                                            value={readOnly ? form.nameOfProposedInvestigation : this.state.nameOfProposedInvestigation}
                                            disabled={readOnly} style={{width: "100%"}}
                                            maxLength={255}
                                            onChange={(val) => this.setState({nameOfProposedInvestigation: val})}/>
                                    </FormField>
                                </div>
                            </div>

                            <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                                <div className={"row"} style={{marginLeft: "0px", margin: "15px 15px"}}>
                                    <p>
                                        <b>{InjectionConsentFormTextEnum.Fee_Of_Proposed_Procedure[selectedLanguage]}:</b>
                                    </p>
                                    <NumberInput
                                        value={readOnly ? form.feeForProposedProcedure : this.state.feeForProposedProcedure}
                                        disabled={readOnly} style={{textAlign: "center"}}
                                        style={{marginLeft: "10px"}}
                                        min={0}
                                        allowDecimal
                                        maxFractionDigits={patient.mrn.country === "UAE" ? 2 : 3}
                                        onChange={(val) => this.setState({feeForProposedProcedure: val})}/>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                        onPrevious={() => this.formFields = []}>
                        <div className={formBodyClass} style={{width: '1000px'}}>
                            <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                                <div style={{margin: "10px 15px"}}>
                                    <h5>
                                        <u> {country == "UAE" ? InjectionConsentFormTextEnum.Statement_Of_Health_Professional_DXB[selectedLanguage] : InjectionConsentFormTextEnum.Statement_Of_Health_Professional_KWI[selectedLanguage]}:</u>
                                    </h5>
                                    <p style={{
                                        fontSize: "12px",
                                        marginTop: "-5px"
                                    }}>{InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Paragraph_In_Parentheses[selectedLanguage]}</p>
                                    <p> {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Paragraph1[selectedLanguage]}:<br/>
                                        <u>{InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item1_Underlined[selectedLanguage]}:</u> {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item1_Paragraph[selectedLanguage]}<br/>
                                        <u>{InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item2_Underlined[selectedLanguage]}:</u> {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item2_Paragraph[selectedLanguage]}<br/>
                                        <u>{InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item3_Underlined[selectedLanguage]}:</u> {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item3_Paragraph[selectedLanguage]}<br/>
                                    </p>
                                    <p>
                                        {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Paragraph4[selectedLanguage]}
                                        <br/>
                                        {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Paragraph5[selectedLanguage]}
                                    </p>
                                </div>
                            </div>
                            <div className={"container"}>
                                <div className={"row"} style={{marginTop: "10px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Provider_Name[selectedLanguage]}:
                                    </div>
                                    <div className="col-md-3">
                                        {providerName}
                                    </div>

                                    <div className="col-md-3 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Job_Title[selectedLanguage]}:
                                    </div>
                                    <div className="col-md-3 dim14-base-input">
                                        {providerSpeciality}
                                    </div>
                                </div>

                                <div className="row" style={{marginTop: "15px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Date_Time[selectedLanguage]}:
                                    </div>

                                    <div className="col-md-3">
                                        {moment(readOnly && form.healthProfessionalDate ?
                                            new Date(form.healthProfessionalDate)
                                            :
                                            this.state.healthProfessionalDate).format('MMMM DD YYYY, h:mm:ss a')
                                        }
                                    </div>

                                    <div className="col-md-6 dim14-form-label">
                                        {country == "UAE" ? InjectionConsentFormTextEnum.Provider_Signature_DXB[selectedLanguage] : InjectionConsentFormTextEnum.Provider_Signature_KWI[selectedLanguage]}:
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            disabled={readOnly}
                                            required requiredMessage="Signature required"
                                            validateOn={this.state.providerSignature}
                                        >
                                            <SignatureInput
                                                selectedLanguage={selectedLanguage}
                                                textEnum={InjectionConsentFormTextEnum}
                                                signature={readOnly ? form.providerSignature : this.state.providerSignature}
                                                onSave={this.onSaveProviderSignature} readOnly={readOnly}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                        onPrevious={() => this.formFields = []}>
                        <div className={formBodyClass} style={{width: '1000px'}}>
                            <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                                <div style={{margin: "10px 15px"}}>
                                    <h5>
                                        <u>{InjectionConsentFormTextEnum.Statement_Of_Patient[selectedLanguage]}:</u>
                                    </h5>
                                    <p style={{
                                        fontSize: "12px",
                                        marginTop: "-5px"
                                    }}>{InjectionConsentFormTextEnum.Please_Read_Carefully[selectedLanguage]}</p>
                                    <p>
                                        <div className="display-flex">
                                            <p>
                                                <b>{InjectionConsentFormTextEnum.For_Self_Paying_Clients_I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item1[selectedLanguage]}
                                            </p>
                                            <NumberInput
                                                value={readOnly ? form.selfPayingClientCost : this.state.selfPayingClientCost}
                                                disabled={readOnly} style={{textAlign: "center"}}
                                                style={{marginLeft: "10px"}}
                                                onChange={(val) => this.setState({selfPayingClientCost: val})}/>
                                        </div>
                                        <b>{InjectionConsentFormTextEnum.I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item2[selectedLanguage]}<br/>
                                        <b>{InjectionConsentFormTextEnum.I_Agree[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item3[selectedLanguage]}<br/>
                                        <b>{InjectionConsentFormTextEnum.I_Agree[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item4[selectedLanguage]}<br/>
                                        <b>{InjectionConsentFormTextEnum.I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item5[selectedLanguage]}<br/>
                                        <b>{InjectionConsentFormTextEnum.I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item6[selectedLanguage]}<br/>
                                        <b>{InjectionConsentFormTextEnum.I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item7[selectedLanguage]}<br/>
                                        <b>{InjectionConsentFormTextEnum.I_Agree[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item8[selectedLanguage]}<br/>
                                        <b>{InjectionConsentFormTextEnum.I_Agree[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item9[selectedLanguage]}
                                    </p>
                                </div>
                            </div>
                            <div className={"container"}>
                                <div className={"row"} style={{marginTop: "10px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Date_Time[selectedLanguage]}:
                                    </div>

                                    <div className="col-md-3">
                                        {moment(readOnly && form.patientDate ?
                                            new Date(form.patientDate)
                                            :
                                            this.state.patientDate).format('MMMM DD YYYY, h:mm:ss a')
                                        }
                                    </div>
                                </div>

                                <div className="row" style={{marginTop: "15px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Patient_Name[selectedLanguage]}:
                                    </div>
                                    <div className="col-md-3">
                                        {patientName}
                                    </div>

                                    <div className="col-md-6 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Patient_Signature[selectedLanguage]}:
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <SignatureInput
                                            selectedLanguage={selectedLanguage}
                                            textEnum={InjectionConsentFormTextEnum}
                                            signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                            onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page onPrevious={() => this.formFields = []}>
                        <div className={formBodyClass} style={{width: '1000px'}}>
                            <div className={"container"}>
                                <div className={"row"} style={{marginTop: "10px"}}>
                                    <p><b>{InjectionConsentFormTextEnum.Witness_Paragraph1[selectedLanguage]}<br/>
                                        {InjectionConsentFormTextEnum.Witness_Paragraph2[selectedLanguage]}</b></p>
                                </div>

                                <div className={"row"} style={{marginTop: "10px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Print_Name[selectedLanguage]}:
                                    </div>
                                    <div className="col-md-3">
                                        <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                                   alphabeticOnly disabled={readOnly} style={{textAlign: "center"}}
                                                   onChange={(val) => this.setState({guardianName: val})}/>
                                    </div>

                                    <div className="col-md-3 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Relationship_To_Patient[selectedLanguage]}:
                                    </div>
                                    <div className="col-md-3 dim14-base-input">
                                        <TextInput
                                            value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                            disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                            onChange={(val) => this.setState({guardianRelationship: val})}/>
                                    </div>
                                </div>


                                <div className={"row"} style={{marginTop: "15px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Date_Time[selectedLanguage]}:
                                    </div>

                                    <div className="col-md-3">
                                        {moment(readOnly && form.guardianDate ?
                                            new Date(form.guardianDate)
                                            :
                                            this.state.guardianDate).format('MMMM DD YYYY, h:mm:ss a')
                                        }
                                    </div>

                                    <div className="col-md-6 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Signature_Of_Witness[selectedLanguage]}:
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            disabled={readOnly || this.state.patientSignature != null}
                                            required requiredMessage="Signature required"
                                            validateOn={this.state.guardianSignature}
                                        >
                                        <SignatureInput
                                                selectedLanguage={selectedLanguage}
                                                textEnum={InjectionConsentFormTextEnum}
                                                signature={readOnly ? form.guardianSignature : this.state.guardianSignature}
                                                onSave={this.onSaveGuardianSignature} readOnly={readOnly}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>
                </Wizard>
            </>
        );
    }

};

export default InjectionConsentForm;
