import moment from "moment";

export const getTimeZoneForCountry = (country) => {
    switch (country) {
        case 'UAE':
        case 'uae':
            return 'Asia/Dubai';
        case 'KUWAIT':
        case 'Kuwait':
        case 'kuwait':
            return 'Asia/Kuwait';
        default:
            throw new Error('Unknown Country!');
    }
}

export const getClinicZoneInfo = (clinicName) => {
    switch (clinicName) {
        case 'DHCC':
        case 'JLT':
        case 'CP':
            return {offset: "+0400", offsetMinutes: 240, offsetHours: 4, zone: 'Asia/Dubai', code: 'GST'};
        case 'Kuwait':
            return {offset: "+0300", offsetMinutes: 180, offsetHours: 3, zone: 'Asia/Kuwait', code: 'AST'};
        default:
            throw new Error('Unknown Clinic!');
    }
};

export const getClinicZoneOffsetWithLocalTime = (clinicName) => {
    return getClinicZoneInfo(clinicName).offsetMinutes + new Date().getTimezoneOffset();
};

export const atZone = (date, zoneOffset) => {
    return moment(date).utcOffset(zoneOffset);
};

export const atClinicZone = (date, clinicName) => {
    return atZone(date, getClinicZoneInfo(clinicName).offset);
};

export default {getTimeZoneForCountry, getClinicZoneInfo, getClinicZoneOffsetWithLocalTime, atZone, atClinicZone};
