import {BaseConstants} from "../constants/BaseConstants";
import SchedulingService from "../scheduling-service/SchedulingService";
import ParameterService from "../parameter/ParameterService";

export * from './alert.actions';

export * from './user.actions';

export * from './emr.actions';

export const getSchedules = () => {
    return dispatch => {
        dispatch({type: BaseConstants.SCHEDULES, value: []});

        SchedulingService.getSchedules((schedules) => {
            dispatch({type: BaseConstants.SCHEDULES, value: schedules});
        });
    };
}

export const getParameters = () => {
    return dispatch => {
        dispatch({type: BaseConstants.PARAMETERS, value: {}});

        ParameterService.getParameters(parameterMap => {
            dispatch({type: BaseConstants.PARAMETERS, value: parameterMap});
        });
    };
}