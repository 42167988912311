import React, {Component} from 'react';
import {DateInput, RadioInput, SelectInput, TextareaInput, TextInput} from '../index';
import {AutoComplete} from 'primereact/autocomplete';
import {FormBase, FormField, FormSection} from '../form';

export default class ExampleValidation extends Component {

    state = {
        selectedDate: null,
        textValue: "",
        textareaValue : "",
        selectValue: null
    }

    render() {
        this.formFields = [];
        return (
            <div>
                <h1>Example Usages Of Validations</h1>
                <FormBase>
                    <FormSection sectionId={5} label="TextInput">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.textValue}>
                            <TextInput value={this.state.textValue} onChange={(newValue) => this.setState({textValue: newValue})} />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={5} label="TextareaInput">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required minLength={5} validateOn={this.state.textareaValue}>
                            <TextareaInput value={this.state.textareaValue} onChange={(newValue) => this.setState({textareaValue: newValue})} />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={5} label="DateInput">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.selectedDate}>
                            <DateInput
                                  value={this.state.selectedDate}
                                  onChange={date => {this.setState({selectedDate: date})}}
                                />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={5} label="SelectInput">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.selectValue}>
                            <SelectInput value={this.state.selectValue} options={["red", "blue", "green"]} onChange={(newValue) => this.setState({selectValue: newValue})} />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={6} label="AutoComplete">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.autoCompleteValue}>
                            <AutoComplete value={this.state.autoCompleteValue} onChange={(e) => this.setState({autoCompleteValue: e.value})} />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={6} label="RadioInput">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.radioValue}>
                            <RadioInput value={this.state.radioValue} options={["red", "blue", "green"]} onChange={(newValue) => this.setState({radioValue: newValue})} />
                        </FormField>
                    </FormSection>
                </FormBase>
            </div>
        )
    }
}
