import StringUtil from "../../../../utils/StringUtil";
import AuthService from "../../../../services/auth-service/AuthService";
import DateSelect from "../../../../components/date-select/DateSelect";
import React from "react";
import TimezoneUtil from "../../../../utils/TimezoneUtil";

export function visitText(count, index, currentVisitIndex) {
    if (currentVisitIndex == index) {
        return "Current Visit";
    } else if (index == 0) {
        return "Initial Visit";
    } else if (currentVisitIndex - 1 == index) {
        return "Previous Visit";
    }
    return (index + 1) + "th Visit";
}

class VisitNavigator extends React.Component {

    constructor(props) {
        super(props);
        const dates = [];

        props.visits.forEach(visit => dates.push(visit.appointment.startTime))

        this.state = {
            dates
        }
    }

    render() {
        const {dates} = this.state;
        const {visits, selectedIndex, onDateChange, currentVisitIndex} = this.props;
        const visit = visits[selectedIndex];
        const timezone = TimezoneUtil.getClinicZoneInfo(visit.clinic.name).zone;
        const showProvider = false !== this.props.showProvider;

        return (
            <div className={"row m-0 mt-2"}>
                {showProvider &&
                <div className={"col-8 no-padding"} style={{alignSelf: "center", textAlign: "right"}}>
                    <h6 style={{margin: "auto"}}>Provider: {StringUtil.isNullOrEmpty(visit.doneBy) ? visit.appointment.staff.name : visit.doneBy}</h6>
                </div>
                }
                <div className={`col-${showProvider ? '1' : '3'}`} style={{alignSelf: "center", textAlign: "right"}}>
                    <h6 style={{margin: "auto"}}>{visitText(dates.length, selectedIndex, currentVisitIndex)}</h6>
                </div>
                <div className={`col-${showProvider ? '3' : '9'} no-padding`}>
                    <DateSelect dates={dates}
                                timezone={timezone}
                                selectedIndex={selectedIndex}
                                onChange={onDateChange}/>
                </div>
            </div>
        )
    }
}

export default VisitNavigator;
