import React, {Component} from "react";
import _uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";

export default class TextareaInput extends Component {

    static defaultProps = {
        autoHeight: false,
        prefix: undefined
    };

    static propTypes = {
        autoHeight: PropTypes.bool,
        prefix: PropTypes.string
    };

    constructor(props){
        super(props);
        this.id = props.id || _uniqueId("textareainput-id-");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.prefix !== this.props.prefix) {
            this.props.onChange(this.props.prefix);
        }
    }

    remainder(){
        if(this.props.showRemaining) {
            return <label id={this.id + "-remainder"}>({this.props.value.length} / {this.props.maxLength})</label>
        }
    }

    handleOnChange(e){
        let value;
        if (!!this.props.prefix) {
            value = this.props.prefix + e.target.value.substr(this.props.prefix.length);
        } else {
            value = e.target.value;
        }
        this.props.onChange(value);
    }

    render() {
        const style = this.props.style;

        if (this.props.autoHeight && this.element) {
            style.height = this.element.scrollHeight;
            style.minHeight = "2rem";
            style.overflow = "hidden";
        }

        let componentClassName = "dim14textareainput"
         if(this.props.className){
            componentClassName += " " + this.props.className;
         }

        return (
                <textarea
                    ref={el => this.element = el}
                    id={this.id}
                    className={componentClassName}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    readOnly={this.props.readOnly}
                    placeholder={this.props.placeholder}
                    title={this.props.title}
                    hidden={this.props.hidden}
                    autoFocus={this.props.autoFocus}
                    onChange={e => this.handleOnChange(e)}
                    onInput={e => this.handleOnChange(e)}
                    style={style}
                    rows={this.props.rows}
                    spellCheck={this.props.spellCheck}
                    maxLength={this.props.maxLength}
                />
        )
    }
}
