import Enum from "../../../../../../enums/Enum";

const EnumRelationshipType = new Enum({
    PARTNER: {name: "Partner", nameInArabic: "شريك الحياة"},
    MOTHER: {name: "Mother", nameInArabic: "الأم"},
    FATHER: {name: "Father", nameInArabic: "الأب"},
    UNCLE: {name: "Uncle", nameInArabic: "عم"},
    RELATIVE: {name: "Relative", nameInArabic: "أحد الأقارب"},
    SPOUSE: {name: "Spouse", nameInArabic: "الزوج/ الزوجة"},
    GUARDIAN: {name: "Guardian", nameInArabic: "الوصي القانوني"},
    OTHER: {name: "Other", nameInArabic: "آخر"}
});

export default EnumRelationshipType;