import React, {Component} from "react";
import '../../VisitHistory.css'
import {AllergyChecker} from "../../../subjective/checkers/AllergyChecker";

export default class AllergyViewHistory extends Component {

    render() {
        if(this.props.allergy == null || !AllergyChecker.isToggleOn(this.props.allergy)) {
            return <div className="pl-3">
                {this.props.allergy && this.props.allergy.none ? "None Known" : "No information available"}
            </div>;
        }

        if(this.props.allergy.none) {
            return <div className="pl-3">None Known</div>;
        }
        return (
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th style={{textAlign: "left"}}>Allergen</th>
                    <th>Medication</th>
                    <th>Food</th>
                    <th>Seasonal</th>
                    <th>Pet</th>
                    <th>Environmental</th>
                </tr>
                <tr>
                    <th style={{textAlign: "left"}}>Allergen Type</th>
                    <td>{this.props.allergy.ingredients == null ? "" : this.props.allergy.ingredients.join(', ')}</td>
                    <td>{this.props.allergy.food == null ? "" : this.props.allergy.food.join(', ')}</td>
                    <td>{this.props.allergy.seasonal == null ? "" : this.props.allergy.seasonal.join(', ')}</td>
                    <td>{this.props.allergy.pet == null ? "" : this.props.allergy.pet.join(', ')}</td>
                    <td>{this.props.allergy.environmental == null ? "" : this.props.allergy.environmental.join(', ')}</td>
                </tr>
                <tr style={{backgroundColor: "white", textAlign: "left"}}>
                    <td colSpan={"6"} style={{paddingLeft: "6px"}}>
                        <strong>Other Allergies / Sensitivities: </strong>
                        {this.props.allergy.other == null ? "" : this.props.allergy.other.join(', ')}
                    </td>
                </tr>
                </tbody>
            </table>


        );
    }
}