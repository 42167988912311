import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import FormField from "../../../../components/form/FormField";
import {RadioInput} from "../../../../components";
import IntakeFormAnyMedicationSupplementEnum from "../enum/IntakeFormAnyMedicationSupplementsEnum";
import StringUtil from "../../../../utils/StringUtil";

class PublicIntakeFormHealthHistoryPage2 extends Component {
    render() {
        const {
            form, selectedLanguage, enumLabel, formFields, yesNoDescribeFieldEqualWidth,
            validatedTextField, onChange
        } = this.props;

        return (
            <>
                <div className="row">
                    <div class="form-group">
                        <label>{IntakeFormTextEnum.Are_You_on_Any_Medication_Supplements[selectedLanguage]}</label>
                            <RadioInput
                                id="anyMedicationSupplementsRadio"
                                value={form.anyMedicationSupplements}
                                options={IntakeFormAnyMedicationSupplementEnum.toArray()}
                                displayProperty={enumLabel} valueProperty="key"
                                onChange={(value) =>
                                    onChange("anyMedicationSupplements", value)
                                }
                            />
                    </div>
                </div>
                {(!StringUtil.isNullOrEmpty(form.anyMedicationSupplements) && "No" !== form.anyMedicationSupplements) && (
                    <div className="row">
                        <div class="container-fluid p-0">
                            <div class="panel bg-grey">
                                <div class="form-group">
                                    <label className="col-form-label">
                                        {IntakeFormTextEnum.If_Yes_Name_of_Medication_Supplement[selectedLanguage]}
                                    </label>
                                    {validatedTextField(
                                        form,
                                        "anyMedicationSupplementsIfYes"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "hadSurgery",
                    IntakeFormTextEnum.Ever_Had_Surgery[selectedLanguage],
                    enumLabel
                )}

                {"Yes" === form.hadSurgery && (
                    <div className="row">
                        <div class="container-fluid p-0">
                            <div class="panel bg-grey">
                                <div class="form-group">
                                    <label className="col-form-label">
                                        {IntakeFormTextEnum.If_Yes_When_And_What_Surgery[selectedLanguage]}
                                    </label>
                                    {validatedTextField(form, "hadSurgeryIfYes")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "receivingHealthCare",
                    IntakeFormTextEnum.Receiving_Health_Care[selectedLanguage],
                    enumLabel
                )}
                {"Yes" === form.receivingHealthCare && (
                    <div className="row">
                        <div class="container-fluid p-0">
                            <div class="panel bg-grey">
                                <div class="form-group">
                                    <label className="col-form-label">
                                        {IntakeFormTextEnum.If_Yes_Please_Specify[selectedLanguage]}
                                    </label>
                                    {validatedTextField(form, "receivingHealthCareIfYes")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default PublicIntakeFormHealthHistoryPage2;