import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {FormLine} from "../../public/FormLine";
import FormField from "../../../../components/form/FormField";
import {FormPhoneInput} from "../../public/FormPhoneInput";
import {Dropdown} from "primereact/dropdown";
import EnumRelationshipType from "../../../patient/patientdetail/personal/emergencycontact/enums/EnumRelationshipType";

class PublicIntakeFormEmergencyContactInformation extends Component {
    render() {
        const {selectedLanguage, enumLabel, form, onChange, validatedTextField, pushFormFieldRef} = this.props;

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Emergency_Contact_Information[selectedLanguage]}/>
                <FormLine label={IntakeFormTextEnum.Emergency_Contact_Name[selectedLanguage]}>
                    {validatedTextField(form, "emergencyContactName")}
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Emergency_Contact_Mobile_Number[selectedLanguage]}>
                    <FormField
                        ref={(ref) => pushFormFieldRef(ref)}
                        required
                        validateOn={form.emergencyContactMobilePhone}
                    >
                        <FormPhoneInput
                            value={form.emergencyContactMobilePhone}
                            onValueChange={(value) =>
                                onChange("emergencyContactMobilePhone", value)
                            }
                        />
                    </FormField>
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Emergency_Contact_Relationship[selectedLanguage]}>
                    <Dropdown
                        className="DropdownBorderColor w-100"
                        value={form.emergencyContactRelationshipType}
                        options={EnumRelationshipType.toArray()
                            .map(r => {
                                return {label: r[enumLabel], value: r.key}
                            })
                        }
                        displayProperty="label"
                        valueProperty="value"
                        searchEnabled={false}
                        onChange={(event) =>
                            onChange(
                                "emergencyContactRelationshipType",
                                event.value
                            )
                        }
                    />
                </FormLine>
            </>
        );
    }
}

export default PublicIntakeFormEmergencyContactInformation;