import Enum from "../../../../../../enums/Enum";

const UnitEnum = new Enum({
    GRAMS: {name: "Grams (g)"},
    MICROGRAMS: {name: "Micrograms (mcg)"},
    MILLIGRAMS: {name: "Milligrams (mg)"},
    LITERS: {name: "Liters (L)"},
    MILLILITERS: {name: "Milliliters (mL)"},
    INTERNATIONALUNITS: {name: "International Units (IU)"},
    UNSURE: {name: "Unsure"}
});

export default UnitEnum;