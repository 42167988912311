import Enum from "../../../../enums/Enum";

const IntakeFormTextEnum = new Enum({
    Intake_Form_Title: {English: "INTAKE FORM", Arabic: "نموذج التاريخ الصحي للمرضى الجدد"},
    Intake_Form_Header: {English: "Intake Form", Arabic: "نموذج التاريخ الصحي للمرضى الجدد"},
    New_Patient_Form_Title: {English: "NEW PATIENT FORM", Arabic: "نموذج التاريخ الصحي لمريض جديد"},
    New_Patient_Form_Header: {English: "New Patient Form", Arabic: "نموذج التاريخ الصحي لمريض جديد"},

    Patient_Information: {English: "PATIENT INFORMATION", Arabic: "معلومات المريض"},
    MRN: {English: "MRN", Arabic: "رقم الملف الطبي"},
    Date: {English: "Date", Arabic: "التاريخ"},
    Name: {English: "Name", Arabic: "الاسم"},

    First_Name: {English: "First Name", Arabic: "الاسم الاول"},
    Middle_Name: {English: "Middle Name", Arabic: "الاسم الأوسط"},
    Last_Name: {English: "Last Name", Arabic: "اسم العائلة"},
    Date_of_Birth : {English: "DOB (D/M/Y) ", Arabic: "تاريخ الميلاد"},
    Age: {English: "Age", Arabic: "العمر"},
    Gender: {English: "Gender", Arabic: "الجنس"},
    ID_No: {English: "ID No", Arabic: "رقم الهوية"},
    Nationality: {English: "Nationality", Arabic: "الجنسية"},
    Occupation: {English: "Occupation", Arabic: "الوظيفة"},
    Job_Physicality: {English: "Job Physicality", Arabic: "النشاط البدني خلال الأداء الوظيفي"},
    Any_Allergies_or_Sensitivities: {English: "Do you have any Allergies or Sensitivities?", Arabic: "هل تعاني من أي نوع حساسية أو فرط التحسس؟"},
    Allergy_Medication: {English: "Medication", Arabic: "دواء"},
    Food: {English: "Food", Arabic: "  طعام"},
    Pet: {English: "Pet", Arabic: "حيوان اليف"},
    Seasonal: {English: "Seasonal", Arabic: "موسمي"},
    Environmental: {English: "Environmental", Arabic: "بيئي"},
    Allergen: {English: "Allergen", Arabic: "مسببات الحساسية"},
    Height: {English: "Height (cm)", Arabic: "الطول (cm)"},
    Weight: {English: "Weight (Kg)", Arabic: "الوزن (Kg)"},

    Referral_Information: {English: "Referral Information", Arabic: "معلومات مرجعية"},
    How_Did_You_Hear_About_The_Clinic: {English: "How did you hear about our office?", Arabic: "كيف سمعت عن العيادة؟"},
    Family_Friend_Name: {English: "Family / Friend Name", Arabic: "اسم فرد العائلة "},
    Doctor_Name: {English: "Doctor Name", Arabic: "اسم الطبيب"},
    Hospital_Name: {English: "Hospital Name", Arabic: "اسم المستشفى"},
    Account_name: {English: "Account Name", Arabic: "اسم الحساب"},

    Contact_Information: {English: "CONTACT INFORMATION", Arabic: "معلومات للتواصل"},
    Phone_Number: {English: "Phone Number", Arabic: "رقم الهاتف"},
    Do_You_Use_This_Number_For_Whatsapp: {English: "Do you use this number for Whatsapp?", Arabic: "هل تستخدم نفس الرقم لتطبيق الواتس آب"},
    Other_Phone: {English: "Other Phone", Arabic: "رقم هاتف آخر"},
    Email: {English: "Email", Arabic: "البريد الإليكتروني"},
    Address: {English: "Address", Arabic: "العنوان"},
    Country: {English: "Country", Arabic: "الدولة"},
    City: {English: "City", Arabic: "المدينة"},
    Postal_Code: {English: "Postal Code", Arabic: "الرمز البريدي"},

    Emergency_Contact_Information: {English: "EMERGENCY CONTACT INFORMATION", Arabic: "الاتصال في حالات الطوارئ"},
    Emergency_Contact_Name: {English: "Name", Arabic: "الاسم"},
    Emergency_Contact_Mobile_Number: {English: "Mobile Phone", Arabic: "رقم الهاتف"},
    Emergency_Contact_Relationship: {English: "Relationship", Arabic: "العلاقة"},

    Complaint: {English: "COMPLAINT", Arabic: "الشكوى المرضية"},
    Please_Mark_Where_It_Hurts: {English: "Please mark where it hurts", Arabic: "يرجى تحديد مكان الألم"},
    What_Is_Your_Main_Complaint: {English: "What is your main complaint today?", Arabic: "ما هي شكوتك المرضية اليوم؟"},
    Duration: {English: "Duration", Arabic: "المدة"},
    When_Did_This_Start: {English: "When did this start?", Arabic: "متى بدأت في مواجهة هذا الألم؟"},
    Please_Rate_Your_Pain: {English: "Rate your pain or discomfort level", Arabic: "الرجاء تحديد درجة الألم أو الشعور بعدم الراحة "},
    Undo: {English: "Undo", Arabic: "الغاء"},

    Current_Patient_Condition: {English: "CURRENT PATIENT CONDITION", Arabic: "حالة المريض الحالية"},
    Complaint_Related_To: {English: "Is this complaint related to", Arabic: "هل ترتبط الحالة التي تشكو منها بأي مما يلي"},
    Happened_Before: {English: "Has this happened before?", Arabic: "هل واجهت نفس الشكوى المرضية من قبل"},
    If_Yes_How_Long_Ago: {English: "If Yes, how long ago?", Arabic: "في حالة الإجابة بنعم، منذ متى؟"},
    What_Have_You_Tried_To_Treat_This_Problem: {English: "What have you tried to treat this problem?", Arabic: "ما هي المحاولات العلاجية التي خضعت لها للسيطرة على هذه المشكلة"},
    How_Did_You_Respond_Last_Treatment: {English: "How did you respond to the last treatment?", Arabic: "الرجاء تحديد مدى استجابتك بعد العلاج السابق حسب القياس التالي"},
    What_Do_You_Hope_From_Treatment: {English: "What do you hope to accomplish from treatment?", Arabic: "ما ترجو تحقيقه من العلاج؟"},

    Describe_Your_Symptoms: {English: "DESCRIBE YOUR SYMPTOMS", Arabic: "قم بوصف الأعراض التي تعاني منها"},
    Describe_Your_Pain_Symptoms: {English: "Describe your pain/symptoms", Arabic: "قم بوصف الألم /الأعراض التي تعاني منها"},
    Constant: {English: "Constant", Arabic: "مستمر"},
    Comes_And_Goes: {English: "Comes and goes", Arabic: "متقطع/ بين حين وآخر"},
    Started_Suddenly: {English: "Started suddenly", Arabic: "بدأ بصورة مفاجئة"},
    Started_Slowly: {English: "Started slowly/gradually", Arabic: "بدأ ببطء/ تدريجياً"},
    Improving: {English: "Improving", Arabic: "في تحسن"},
    Staying_Same: {English: "Staying the same", Arabic: "كما هو دون تغيير"},
    Worsening: {English: "Worsening", Arabic: "يزداد سوءاًً"},
    Throbbing: {English: "Throbbing", Arabic: "نابض"},
    Numbness: {English: "Numbness", Arabic: "تنميل في الجسم"},
    Aching: {English: "Aching", Arabic: "موجع"},
    Burning: {English: "Burning", Arabic: "حارق"},
    Shooting: {English: "Shooting", Arabic: "يلمع أو يضرب"},
    Dull: {English: "Dull", Arabic: "خفيف"},
    Pins_Needles: {English: "Pins & Needles", Arabic: "إحساس بالوخز"},
    Sharp: {English: "Sharp", Arabic: "حاد"},
    Tightness: {English: "Tightness", Arabic: "شد"},
    Stiffness: {English: "Stiffness", Arabic: "تيبس الجسم"},
    Other: {English: "Other", Arabic: "أخرى"},

    Symptoms_Worse_In: {English: "My symptoms are Worse in the", Arabic: "تزداد الاعراض سوءاً خلال"},
    Make_Symptoms_Worse: {English: "What makes your symptom(s) worse?", Arabic: "ما الذي يجعل الأعراض التي تعاني منها أسوأ؟"},
    Movement_Exercise : {English: "Movement/Exercise", Arabic: "الحركة / التمرين"},
    Sleeping : {English: "Sleeping", Arabic: "النوم"},
    Sitting : {English: "Sitting", Arabic: "الجلوس"},
    Standing : {English: "Standing", Arabic: "الوقوف"},
    Driving : {English: "Driving", Arabic: "القيادة"},
    Climbing_Stairs : {English: "Climbing stairs", Arabic: "صعود السلالم"},
    Cold : {English: "Cold", Arabic: "البرد"},

    Make_Symptoms_Better: {English: "What makes your symptom(s) better?", Arabic: "ما الذي يساعد على تحسين الأعراض"},
    Rest : {English: "Rest", Arabic: "الراحة"},
    Medication : {English: "Medication", Arabic: "الأدوية"},
    Sleep : {English: "Sleep", Arabic: "النوم"},
    Heat : {English: "Heat", Arabic: "التعرض للحرارة"},
    Massage : {English: "Massage", Arabic: "التدليك"},

    Symptoms_Interfere_With: {English: "Do the symptoms interfere with", Arabic: "هل تتزامن تلك الأعراض مع"},
    Daily_Routine : {English: "Daily routine", Arabic: "الأنشطة اليومية العادية"},
    Sports_Exercise : {English: "Sports/Exercise", Arabic: "ممارسة الرياضة/ التمرينات"},
    Work : {English: "Work", Arabic: "العمل"},
    Symptoms_Other: {English: "Other", Arabic: "آخر"},

    Had_Any_Xray : {English: "Have you had any X-rays taken or other special imaging such as MRI or CT Scan?", Arabic: "هل سبق ان خضعت لصورة شعاعية أو غيرها من أنواع الأشعة الطبية والتشخيصية مثل الرنين المغناطيسي أو الأشعة المقطعية؟"},
    Have_You_Had_Or_Do_You_Experience: {English: "In the past 3 months have you had or do you experience", Arabic: "خلال الأشهر الثلاثة الماضية، هل عانيت من، او لا زلت تعاني أي مما يلي"},
    Change_In_Your_General_Health: {English: "Change in your general health?", Arabic: "تغيير في الصحة العامة"},
    Fever_Chills_Night_Sweats: {English: "Fever / Chills / Night Sweats", Arabic: "الحمى/ قشعريرة برد/ تعرق ليلي"},
    Unexplained_Weight_Change: {English: "Unexplained weight change (> 5kgs)", Arabic: "تغيير غير مبرر في الوزن سواء فقدان/ زيادة(> 5kgs) "},
    Numbness_or_Tingling: {English: "Numbness or Tingling", Arabic: "تخدر/ تنميل/ وخز"},
    Bowel_Bladder_Incontinence_or_Changes: {English: "Bowel / Bladder Incontinence or Changes", Arabic: "سلس البول أو تغيرات في الأمعاء أو المثانة "},
    Difficulty_Sleeping_Due_to_Pain: {English: "Difficulty sleeping due to pain?", Arabic: "صعوبة في النوم نتيجة للشعور بالألم"},
    Unexplained_Falls_Decreased_Balance: {English: "Unexplained Falls / Decreased balance?", Arabic: "ضعف في التوازن/ سقطات غير مبررة"},

    Health_History: {English: "HEALTH HISTORY", Arabic: " التاريخ الطبي"},
    You_or_Family_Suffer: {English: "Do you or any member of your family suffer from any of the following? (Check all that apply)", Arabic: "هل تعاني أو أي من أفراد عائلتك من أحد الأمراض التالية؟ (ضع علامة على كل ما ينطبق على ذلك)"},
    Self : {English: "Self", Arabic: "بالنسبة لك "},
    Family : {English: "Family", Arabic: "بالنسبة للعائلة"},
    Cancer : {English: "Cancer", Arabic: "السرطان"},
    Diabetes : {English: "Diabetes", Arabic: "السكري"},
    High_Blood_Pressure : {English: "High Blood pressure", Arabic: "ارتفاع ضغط الدم"},
    Heart_Disease : {English: "Heart Disease", Arabic: "امراض القلب"},
    Osteoporosis : {English: "Osteoporosis", Arabic: "هشاشة العظام"},
    Osteoarthritis : {English: "Osteoarthritis", Arabic: "التهاب العظام والمفاصل"},
    Rheumatoid_Arthritis : {English: "Rheumatoid Arthritis", Arabic: "التهاب المفاصل الروماتويدي"},
    Neurologic_Disease : {English: "Neurologic Disease (MS,Parkinsons)", Arabic: "أمراض الاعصاب (التصلب المتعدد، بارنكسون)"},
    Ulcers_GERD_Acid_Reflux : {English: "Ulcers/GERD/Acid Reflux", Arabic: "قرحات/ ارتجاع معدي مريئي/ ارتجاع حمض المعدة"},
    Kidney_Liver_Disease : {English: "Kidney / Liver Disease", Arabic: "أمراض الكلى/ الكبد"},
    If_Family_Who: {English: "If Family, who?", Arabic: "إذا كان أحد أفراد العائلة، الرجاء تحديد من"},

    Are_You_on_Any_Medication_Supplements: {English: "Are you on any medication/supplements?", Arabic: "هل تتناول أي ادوية/ مكملات غذائية؟"},
    If_Yes_Name_of_Medication_Supplement: {English: "If yes, name of medication/supplement", Arabic: "في حالة الإجابة بنعم، الرجاء ذكر اسم الأدوية/ المكملات"},
    Receiving_Health_Care: {English: "Are you currently receiving health care for any other reason?", Arabic: "هل تتلقى حالياً نوع من الرعاية الصحية لأي سببٍ كان؟ "},
    If_Yes_Please_Specify: {English: "If yes, please specify", Arabic: "في حالة الإجابة بنعم، الرجاء التحديد"},
    Ever_Had_Surgery: {English: "Have you ever had a surgery?", Arabic: "هل سبق وأن خضعت لأي عملية جراحية؟"},
    If_Yes_When_And_What_Surgery: {English: "If yes , when and what surgery?", Arabic: "في حالة الإجابة بنعم، الرجاء ذكر متى ونوع العملية الجراحية"},
    Are_You_Currently_Do_You_Have: {English: "Are you currently / Do you have:", Arabic: "هل انت حاليا / هل لديك"},
    Pregnant_Nursing: {English: "Pregnant / Nursing", Arabic: "حامل/ الرضاعة"},
    Have_Children: {English: "Do you have children? If so, how many?", Arabic: "هل لديك أطفال؟ كم؟"},
    History_of_Trauma: {English: "A history of trauma or concussion?", Arabic: "تاريخ سابق من التعرض للصدمات او ارتجاج في المخ؟"},
    Depressed_or_Hopeless: {English: "Often bothered by feeling down, depressed or hopeless? ", Arabic: "غالبًا ما تتضايق من الشعور بالإحباط أو الاكتئاب أو اليأس؟"},
    Little_Interest: {English: "Often bothered by little interest or pleasure in doing things?", Arabic: "غالبًا ما تتضايق من قلة الاهتمام أو المتعة في القيام بالأشياء؟ "},
    Under_Physical_Emotional_Abuse: {English: "Under physical / emotional abuse", Arabic: "تحت الاعتداء الجسدي / العاطفي؟"},
    Dietary_or_Nutritional_Concerns: {English: "Dietary or Nutritional Concerns", Arabic: "مخاوف غذائية أو تغذوية؟ "},
    Experience_Sexual_Dysfunction: {English: "Experience sexual dysfunction", Arabic: "اختبر العجز الجنسي؟ "},
    Fear_of_needles: {English: "A fear of needles", Arabic: "خوف من الحقن و الوخز بالإبر؟ "},

    Lifestyle_Pattern: {English: "Lifestyle Pattern:", Arabic: "نمط الحياة الیومیة"},
    Sleeping_Pattern: {English: "Sleeping Pattern:", Arabic: "نمط النوم"},
    Exercise_Type: {English: "Exercise Type:", Arabic: "نوع ممارسة التمارين"},
    Do_You_Consume_Alcohol: {English: "Do you consume alcohol?", Arabic: "هل تستهلك الكحول؟"},
    Units_per_Week: {English: "Units per week", Arabic: "وحدات في الأسبوع؟"},
    Do_You_Smoke: {English: "Do you smoke?", Arabic: "هل أنت مدخن؟"},
    Packs_per_Day: {English: "Packs per day", Arabic: "كم علبة تستهلك يومياًً"},
    Have_You_Ever_Smoked: {English: "Have you ever smoked?", Arabic: "هل سبق لك أن دخنت؟"},

    Mark_All: {English: "Mark All", Arabic: "وضع علامة على الكل"},
    Detail: {English: "Details", Arabic: "التفاصيل"},
    Medication_Allergy_Name: {English: "Medication Name", Arabic: "اسم الدواء"},
    Select_Type: {English: "Select Type", Arabic: "حدد النوع"},
    Signature: {English: "Signature", Arabic: "التوقيع"},
    Signature_input_Signature: {English: "Signature", Arabic: "إمضاء"},
    Signature_input_Clear: {English: "Clear", Arabic: "حذف"},
    Wizard_Previous: {English: "Previous", Arabic: "السابق"},
    Wizard_Next: {English: "Next", Arabic: "التالي"},
    Wizard_Save: {English: "Save", Arabic: "حفظ"}


});

export default IntakeFormTextEnum;