import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import RelationshipEnum from "../enums/history/RelationshipEnum";
import {FamilyHistoryChecker} from "../checkers/FamilyHistoryChecker";
import {Grid} from "@material-ui/core";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class FamilyHistoryView extends Component {

    render() {
        let divs = this.getDivs();
        return (
            <Grid container alignItems={"center"} className={"content-card content-row m-0 w-100 p-2"}>
                <Grid item xs style={{maxWidth: 200}} className={"content-card-title"}>
                    <h6 className={"no-margin"}>Family History {!this.props.dashboardView && <RedAsterisk className={"font-weight-bold"}
                                                                             appointmentType={this.props.appointmentType}
                                                                             firstVisit newComplaint/>}</h6>
                </Grid>
                <Grid item xs>
                    <div className="p-2 pl-3" style={{borderLeft: "1px gainsboro solid"}}>
                        {FamilyHistoryChecker.isNone(this.props.familyHistory) ?
                            <div>
                                No Family History
                            </div> : (
                                divs != null ? divs :
                                    <div style={{minHeight: "1rem"}}>
                                    </div>
                            )}
                    </div>
                </Grid>
            </Grid>
        );
    }

    getDivs() {
        if (this.props.familyHistory == null
            || this.props.familyHistory.items == null
            || this.props.familyHistory.items.length == 0) {
            return null;
        }
        let divs = [];
        this.props.familyHistory.items.forEach((item, index) => {
            divs.push(
                <div key={"familyHistoryViewDiv" + index}>
                    {item.condition}{item.relationship ? ", " + RelationshipEnum[item.relationship]?.name: ""}{item.detail == null ? "" : (", " + item.detail)}
                </div>);
        });
        return divs;
    }
}