import React, {Component} from 'react'
import Logo from "./Logo";
import moment from "moment";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {RadioInput, TextInput, Wizard} from "../../../components";
import FormField from "../../../components/form/FormField";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import ConsentFormsUtil from "../utils/ConsentFormsUtil";
import MedicalPhotographyConsentFormTextEnum from "../photographyconsent/MedicalPhotographyConsentFormTextEnum";
import FormLanguages from "../enum/FormLanguages";

export default class PublicMedicalPhotographyConsentForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            educationalPurposesAnonymously: null,
            educationalPurposesDisclosingName: null,
            socialMediaPurposesAnonymously: null,
            socialMediaPurposesDisclosingName: null
        }
    }

    componentDidMount() {
        const form = this.props.form;
        if (form.educationalPurposesAnonymously !== null) {
            this.setState({educationalPurposesAnonymously: form.educationalPurposesAnonymously});
        }

        if (form.educationalPurposesDisclosingName !== null) {
            this.setState({educationalPurposesDisclosingName: form.educationalPurposesDisclosingName});
        }

        if (form.socialMediaPurposesAnonymously !== null) {
            this.setState({socialMediaPurposesAnonymously: form.socialMediaPurposesAnonymously});
        }

        if (form.socialMediaPurposesDisclosingName !== null) {
            this.setState({socialMediaPurposesDisclosingName: form.socialMediaPurposesDisclosingName});
        }
    }

    createHeader = (form, selectedLanguage) => {
        let headerClass = "row d-flex justify-content-between align-items-center ";
        headerClass = selectedLanguage === FormLanguages.ARABIC.name ? (headerClass + "arabic-form") : headerClass;
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    {MedicalPhotographyConsentFormTextEnum.Medical_Photography_Consent_Form[selectedLanguage]}
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center" style={{fontSize: "18px"}}>
                    {MedicalPhotographyConsentFormTextEnum.Patient_Information[selectedLanguage]}
                </div>
            </div>
            <div className={headerClass} style={{fontSize: 11}}>
                <div className="col-4 text-left no-padding">
                    <b>{MedicalPhotographyConsentFormTextEnum.Name[selectedLanguage]}: </b> {form.patientFullName}
                </div>
                <div className="col-4 text-center no-padding">
                    <b>{MedicalPhotographyConsentFormTextEnum.MRN[selectedLanguage]}: </b> {form.patientMrn}
                </div>

                <div className="col-4 text-right no-padding">
                    <b>{MedicalPhotographyConsentFormTextEnum.DOB[selectedLanguage]}: </b> {form.patientDOB}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    assignPurposeValues = (form) => {
        this.fieldChanged("educationalPurposesAnonymously", this.state.educationalPurposesAnonymously);
        this.fieldChanged("educationalPurposesDisclosingName", this.state.educationalPurposesDisclosingName);
        this.fieldChanged("socialMediaPurposesAnonymously", this.state.socialMediaPurposesAnonymously);
        this.fieldChanged("socialMediaPurposesDisclosingName", this.state.socialMediaPurposesDisclosingName);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            this.assignPurposeValues(form);
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/medical-photography-consent-form`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        const selectedLanguage = this.props.language;
        const formBodyClass = selectedLanguage == FormLanguages.ARABIC.name ? "arabic-form" : "";
        const enumLabel = selectedLanguage === FormLanguages.ARABIC.name ? "nameInArabic" : "name";
        const yesNoOptions = [{key: true, name: "Yes", nameInArabic: "نعم"}, {key: false, name: "No", nameInArabic: "لا"}];

        return (
            <Wizard
                show={true} fullScreen
                footerStyle={{backgroundColor: '#f5f5f5'}}
                buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                header={this.createHeader(form, selectedLanguage)}
                close={this.close}
                onSubmit={this.submitForm}
                previousBtnText={MedicalPhotographyConsentFormTextEnum.Wizard_Previous[selectedLanguage]}
                nextBtnText={MedicalPhotographyConsentFormTextEnum.Wizard_Next[selectedLanguage]}
                saveBtnText={MedicalPhotographyConsentFormTextEnum.Wizard_Save[selectedLanguage]}>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>
                        <p>
                            {MedicalPhotographyConsentFormTextEnum.Paragraph1[selectedLanguage]}</p>

                        <p>
                            {MedicalPhotographyConsentFormTextEnum.Paragraph2[selectedLanguage]}</p>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>
                        <p><b> {MedicalPhotographyConsentFormTextEnum.Proceed_Filling_Below[selectedLanguage]} </b></p>

                        <p>
                            {MedicalPhotographyConsentFormTextEnum.List_Item_1[selectedLanguage]}
                        </p>

                        <div className="row" style={{marginLeft: "0px"}}>

                            <div className="col-md-3">
                                <p style={{marginBottom: "5px"}}><b> {MedicalPhotographyConsentFormTextEnum.Anonymously[selectedLanguage]} </b></p>

                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={this.state.educationalPurposesAnonymously}>
                                    <RadioInput id="educationalPurposesAnonymously"
                                                value={this.state.educationalPurposesAnonymously}
                                                options={yesNoOptions} displayProperty={enumLabel} valueProperty={"key"}
                                                onChange={(value) =>
                                                    this.setState({
                                                        educationalPurposesAnonymously: value === "true" ? "true" : "false",
                                                        educationalPurposesDisclosingName: value === "true" ? "false" : "true"
                                                    })}/>
                                </FormField>
                            </div>

                            <div className="col-md-3">
                                <p style={{marginBottom: "5px"}}><b> {MedicalPhotographyConsentFormTextEnum.Disclosing_My_Name[selectedLanguage]} </b></p>

                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={this.state.educationalPurposesDisclosingName}>
                                    <RadioInput id="educationalPurposesDisclosingName"
                                                value={this.state.educationalPurposesDisclosingName}
                                                options={yesNoOptions} displayProperty={enumLabel} valueProperty={"key"}
                                                onChange={(value) =>
                                                    this.setState({
                                                        educationalPurposesDisclosingName: value === "true" ? "true" : "false",
                                                        educationalPurposesAnonymously: value === "true" ? "false" : "true"
                                                    })}/>
                                </FormField>
                            </div>
                        </div>

                        <p style={{marginTop: "10px"}}>
                            {MedicalPhotographyConsentFormTextEnum.List_Item_2[selectedLanguage]}
                        </p>

                        <div className="row" style={{marginLeft: "0px"}}>

                            <div className="col-md-3">
                                <p style={{marginBottom: "5px"}}><b> {MedicalPhotographyConsentFormTextEnum.Anonymously[selectedLanguage]} </b></p>
                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={this.state.socialMediaPurposesAnonymously}>
                                    <RadioInput id="socialMediaPurposesAnonymously"
                                                value={this.state.socialMediaPurposesAnonymously}
                                                options={yesNoOptions} displayProperty={enumLabel} valueProperty={"key"}
                                                onChange={(value) =>
                                                    this.setState({
                                                        socialMediaPurposesAnonymously: value === "true" ? "true" : "false",
                                                        socialMediaPurposesDisclosingName: value === "true" ? "false" : "true"
                                                    })}/>
                                </FormField>
                            </div>

                            <div className="col-md-3">
                                <p style={{marginBottom: "5px"}}><b> {MedicalPhotographyConsentFormTextEnum.Disclosing_My_Name[selectedLanguage]} </b></p>
                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={this.state.socialMediaPurposesDisclosingName}>
                                    <RadioInput id="socialMediaPurposesDisclosingName"
                                                value={this.state.socialMediaPurposesDisclosingName}
                                                options={yesNoOptions} displayProperty={enumLabel} valueProperty={"key"}
                                                onChange={(value) =>
                                                    this.setState({
                                                        socialMediaPurposesDisclosingName: value === "true" ? "true" : "false",
                                                        socialMediaPurposesAnonymously: value === "true" ? "false" : "true"
                                                    })}/>
                                </FormField>
                            </div>
                        </div>

                        <p style={{marginTop: "10px"}}>
                            {MedicalPhotographyConsentFormTextEnum.List_Item_3[selectedLanguage]}:
                        </p>

                        <div className="medical-photography-consent-large-text-field">

                            <TextInput value={form.extraRestrictions}
                                       maxLength={255}
                                       onChange={(value) => this.fieldChanged('extraRestrictions', value)}/>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>

                        <p>
                            {MedicalPhotographyConsentFormTextEnum.Please_Sign_This_Form[selectedLanguage]}
                        </p>

                        <div style={{border: "1px solid", borderRadius: "5px", padding: "30px"}}>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {MedicalPhotographyConsentFormTextEnum.Date_Time[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + moment(new Date(form.patientDate)).format('MMMM DD, YYYY')}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {MedicalPhotographyConsentFormTextEnum.Patient_Name[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + form.patientFullName}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="col-md-12 dim14-form-label">
                                            {MedicalPhotographyConsentFormTextEnum.Patient_Signature[selectedLanguage]}
                                        </div>

                                        <div className="col-md-12">
                                            <SignatureInput  selectedLanguage={selectedLanguage}
                                                             textEnum={MedicalPhotographyConsentFormTextEnum}
                                                             signature={form.patientSignature}
                                                            onClear={() => {
                                                                form.patientSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.patientSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>

                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>

                        <p>
                            {MedicalPhotographyConsentFormTextEnum.Witness_Paragraph1[selectedLanguage]}<br/>
                            {MedicalPhotographyConsentFormTextEnum.Witness_Paragraph2[selectedLanguage]}
                        </p>

                        <div style={{border: "1px solid", borderRadius: "5px", padding: "30px"}}>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {MedicalPhotographyConsentFormTextEnum.Print_Name[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                <TextInput value={form.guardianName} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('guardianName', value)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {MedicalPhotographyConsentFormTextEnum.Relationship_To_Patient[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                <TextInput value={form.guardianRelationship} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {MedicalPhotographyConsentFormTextEnum.Date_Time[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + moment(new Date(form.guardianDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {MedicalPhotographyConsentFormTextEnum.Name[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + form.patientFullName}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="col-md-12 dim14-form-label">
                                            {MedicalPhotographyConsentFormTextEnum.Signature_Of_Witness[selectedLanguage]}
                                        </div>
                                        <div className="col-md-12">
                                            <FormField ref={(ref) => this.formFields.push(ref)}
                                                       disabled={form.patientSignature != null}
                                                       required requiredMessage="Signature required"
                                                       validateOn={form.guardianSignature}>
                                                <SignatureInput  selectedLanguage={selectedLanguage}
                                                                 textEnum={MedicalPhotographyConsentFormTextEnum}
                                                                 signature={form.guardianSignature}
                                                                onClear={() => {
                                                                    form.guardianSignature = null;
                                                                    this.forceUpdate();
                                                                }}
                                                                onSave={(signature) => {
                                                                    form.guardianSignature = signature;
                                                                    this.forceUpdate();
                                                                }}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Wizard.Page>
            </Wizard>
        )
    }
}
