import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import StringUtil from "../../utils/StringUtil";
import DateUtil from "../../utils/DateUtil";
import PageTitle from "../../layout/PageTitle";
import RemotingService from '../../services/remoting-service/RemotingService';
import {Column} from 'primereact/column';
import {DateInput, NumberInput, SelectInput, TextInput} from "../../components";
import {Button, Image} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EnumGender from "../../enums/EnumGender";
import './Patient.css';
import ResultTable from "../../components/query/ResultTable";
import QueryPanel from "../../components/query/QueryPanel";
import CriteriaPanel from "../../components/query/CriteriaPanel";
import PatientUtil from "../../utils/PatientUtil";
import NotificationService from "../../services/notification-service/NotificationService";
import Tooltip from "../../components/tooltip/Tooltip";
import AuthService from "../../services/auth-service/AuthService";
import dialog from "../../components/dialog/Dialog";
import PersonalInformationUpdateModal from "./patientdetail/personal/PersonalInformationUpdateModal";
import PhoneNumberUtil from "../../utils/PhoneNumberUtil";

class Patient extends Component {

    state = {
        nationalities: [],
        patientName:'',
        mobilePhone:'',
        eid: '',
        queryOptions: {
            mrn: '',
            eid: '',
            name: '',
            mobilePhone: '',
            gender: '',
            age: '',
            birthday: null,
            nationality: '',
        }
    };

    componentDidMount() {
        this.retrieveNationalities();
    }

    retrieveNationalities = () => {
        RemotingService.getRemoteCall('api/reference/nationality', null, (nationalities) => {
            this.setState({nationalities})
        });
    };

    patientNameTemplate(rowData, column) {
        let photoSrc = process.env.PUBLIC_URL + '/assets/patient_profile.png';
        if(rowData.thumbnail){
            photoSrc = 'data:image/jpeg;base64,' + rowData.thumbnail;
        }
        return (<>
            <Image src={photoSrc} rounded width={"40px"} />
            <Link to={'/patientdetail/'+rowData.id}>{PatientUtil.getPatientName(rowData.firstName, rowData.middleName, rowData.lastName)}</Link>
        </>);
    }

    minorAlertTemplate(rowData, column) {
        if (rowData.birthday) {
            const age = DateUtil.getAge(rowData.birthday);
            const selectedCountries = AuthService.getCountries();
            const country =  rowData.mrn?.country || (selectedCountries ? selectedCountries[0] : null);

            if ((country === "UAE" && age < 18)
                || (country === "KUWAIT" && age < 20)) {

                return (
                    <div className="text-center">
                        <FontAwesomeIcon icon={["fas", "exclamation"]}/>
                    </div>
                );
            }
        }

        return null;
    }

    ageTemplate(rowData, column) {
        return rowData.birthday ? DateUtil.getAge(rowData.birthday) : '-';
    }

    birthdayTemplate(rowData, column) {
        if(rowData.birthday){
            return DateUtil.formatDateMMM(rowData.birthday);
        }
        return "";
    }

    registerDateTemplate(rowData, column) {
        if(rowData.creationDate){
            return DateUtil.formatDateMMM(rowData.creationDate);
        }
        return "";
    }

    nationalityTemplate(rowData, column) {
        if(rowData.nationality){
            return rowData.nationality.nameInEnglish;
        }
        return "";
    }

    mobilePhoneTemplate(rowData) {
        return PhoneNumberUtil.formatInternational(rowData.mobilePhone);
    }

    intakeTemplate(rowData, column) {

        let color = 'red';
        let imageName = 'times';
        if(rowData.informedConsentForm){
            color = 'green';
            imageName = 'check';
        }

        return (
            //<Tooltip title={rowData.informedConsentForm ? "Signed" : "Not Signed"}>
            //    <div style={{margin:'auto', marginTop:'auto', color:color, fontWeight:"bold", textAlign: "center"}}>
            //        <FontAwesomeIcon icon={["fas", imageName]} size={"lg"} style={{color: color,
            //            marginTop:"2px", marginRight:"3px"}} />
            //    </div>
            //</Tooltip>


            <div style={{margin:'auto', marginTop:'auto', color:color, fontWeight:"bold"}}>
                <FontAwesomeIcon icon={["fas", imageName]} size={"lg"} style={{color: color, float:"left",
                    marginTop:"2px", marginRight:"3px"}} />
                <div style={{float:"left", marginTop:"1px"}}>{rowData.informedConsentForm ? "Signed" : "Not Signed"}</div>
            </div>
        );
    }

    emrTemplate(rowData, column, props) {
        return (
            <Tooltip title={"View Medical Record"}>
                <img src={process.env.PUBLIC_URL + '/assets/emr/icon-emr.svg'} alt="" onClick={() => {
                    RemotingService.getRemoteCall('api/appointment/last-appointment/' + rowData.id, {},
                        (lastAppointmentId) => {
                            if(lastAppointmentId != null){
                                props.history.push(`/emr/view/${rowData.id}/${lastAppointmentId}`);
                            }
                            else {
                                props.history.push(`/emr/visithistory/${rowData.id}`);
                            }
                        });
                }}/>
            </Tooltip>
        );
    }

    genderTemplate(rowData, column) {
        return <>{EnumGender[rowData.gender]}</>;
    }

    handleQueryOptionChange(key, val) {
        this.setState((state) => {
            let newState = {...state};
            newState.queryOptions[key] = val;
            return newState;
        });
    }

    clearQueryOptions = () => {
        this.setState({queryOptions: {
                mrn: '',
                eid: '',
                name: '',
                mobilePhone: '',
                gender: '',
                age: '',
                birthday: null,
                nationality: '',
            }});
    }

    showNewPatientModal() {
        dialog({
            title: "New Patient",
            component: <PersonalInformationUpdateModal personalInformation={{}}
                                                       onUpdated={() => this.handleNewPatientSaved()}/>
        });
    }

    handleNewPatientSaved() {
        this.patientQueryPanel.query();
    }

    render() {
        return (
            <>
                <div className="d-flex p-justify-between mb-2">
                    <PageTitle title="Patient List" className="bg-white" labelClassName="font-weight-bold"/>
                    {AuthService.userHasAuthority("ADD_APPOINTMENT") &&
                    <Button style={{height: "fit-content"}} onClick={() => this.showNewPatientModal()}>
                        <FontAwesomeIcon icon="plus"/> New Patient
                    </Button>
                    }
                </div>
                <QueryPanel ref={el => this.patientQueryPanel = el}
                            url="api/patient/list"
                            queryOptions={this.state.queryOptions}
                            onClear={this.clearQueryOptions}>
                    <CriteriaPanel>
                        <div className={"container dim14-form-row-align w-100 ml-0"}>
                            <div className={"row align-items-center"}>
                                <div className="col-md-1 dim14-form-label">
                                    MRN&nbsp;&nbsp;
                                </div>
                                <div className="col-md-2 dim14-base-input">
                                    <TextInput latinNumericOnly="true" style={{maxWidth:250, minHeight: 36}} value={this.state.queryOptions.mrn} onChange={(val) => this.handleQueryOptionChange("mrn", val)} />
                                </div>
                                <div className="col-md-1 dim14-form-label">
                                    ID NO
                                </div>
                                <div className="col-md-2 dim14-base-input">
                                    <TextInput style={{maxWidth:150, minHeight: 36}} value={this.state.queryOptions.eid} onChange={(val) => this.handleQueryOptionChange("eid", val)} />
                                </div>

                                <div className="col-md-1 dim14-form-label">
                                    Patient Name
                                </div>
                                <div className="col-md-2 dim14-base-input">
                                    <TextInput style={{maxWidth:150, minHeight: 36}} value={this.state.queryOptions.name} alphabeticOnly onChange={(val) => this.handleQueryOptionChange("name", val)} />
                                </div>
                                <div className="col-md-1 dim14-form-label">
                                    Mobile Phone
                                </div>
                                <div className="col-md-2 dim14-base-input">
                                    <TextInput style={{maxWidth:250, minHeight: 36}}
                                               value={this.state.queryOptions.mobilePhone}
                                               onChange={(val) => {
                                                   const value = !!val ? val.replaceAll(/\s+/g, "") : val;
                                                   this.handleQueryOptionChange("mobilePhone", value);
                                               }} />
                                </div>
                            </div>

                            <div className={"row align-items-center"}>
                                <div className="col-md-1 dim14-form-label">
                                    Gender
                                </div>
                                <div className="col-md-2 dim14-base-input">
                                    <SelectInput value={this.state.queryOptions.gender}
                                                 options={EnumGender.toArray()}
                                                 showClear={false}
                                                 displayProperty="name"
                                                 valueProperty="key"
                                                 onChange={(val) => this.handleQueryOptionChange('gender', val)}
                                    />
                                </div>

                                <div className="col-md-1 dim14-form-label">
                                    Age
                                </div>
                                <div className="col-md-2 dim14-base-input">
                                    <NumberInput style={{maxWidth: 150, minHeight: 36}}
                                                 value={this.state.queryOptions.age}
                                                 min={0}
                                                 onChange={(value) => this.handleQueryOptionChange('age', value)}/>
                                </div>

                                <div className="col-md-1 dim14-form-label">
                                    Date of Birth
                                </div>
                                <div className="col-md-2 dim14-base-input">
                                    <DateInput value={this.state.queryOptions.birthday}
                                               disableFuture="true"
                                               style={{maxWidth:150, backgroundColor: 'white'}}
                                               onChange={(value) => this.handleQueryOptionChange('birthday', value.format("YYYY-MM-DD"))}
                                    />
                                </div>

                                <div className="col-md-1 dim14-form-label">
                                    Nationality
                                </div>
                                <div className="col-md-2 dim14-base-input">
                                    <SelectInput value={this.state.queryOptions.nationality}
                                                 style={{maxWidth:250}}
                                                 options={this.state.nationalities ? this.state.nationalities : []}
                                                 displayProperty={"nameInEnglish"} valueProperty={"code"}
                                                 onChange={(val) => this.handleQueryOptionChange('nationality', val)}
                                    />
                                </div>
                            </div>
                        </div>
                    </CriteriaPanel>
                    <ResultTable>
                        <Column field="mrn.id" header="MRN" style={{width: "100px", textAlign: 'center'}} body={(r) => StringUtil.mrn(r.mrn)} sortable={true}/>
                        <Column field="eid" header="ID NO" style={{width: "150px", textAlign: 'center', "word-wrap": "break-word"}} body={(r) => PatientUtil.getPatientId(r)} sortable={true}/>
                        <Column field="firstName" header="PATIENT NAME" body={this.patientNameTemplate} sortable={true}/>
                        <Column field="age" header="AGE" style={{width: 70, textAlign: 'center'}} body={this.ageTemplate}/>
                        <Column field="gender" header="GENDER" body={this.genderTemplate} style={{width: "90px", textAlign: 'center'}} sortable={true}/>
                        <Column field="birthday" header="DATE OF BIRTH" body={this.birthdayTemplate} style={{width: "140px", textAlign: 'center'}} sortable={true}/>
                        <Column field="creationDate" header="DATE OF REGISTRATION" body={this.registerDateTemplate} style={{width: "150px", textAlign: 'center'}}/>
                        <Column field="nationality.nameInEnglish" header="NATIONALITY" body={this.nationalityTemplate} sortable={true}/>
                        <Column field="mobilePhone" header="MOBILE PHONE" body={this.mobilePhoneTemplate}/>
                        <Column field="email" header="E-MAIL" className={"text-break"}/>
                        <Column field="minorAlert" header="MINOR ALERT" body={this.minorAlertTemplate} style={{width: "64px"}} />
                        <Column field="intakeConsentForm" header="INFORMED CONSENT" body={this.intakeTemplate} style={{width: "96px"}} />
                        <Column field="emr" header="" body={(rowData, column) => this.emrTemplate(rowData, column, this.props)} className={"emr-icon"}/>
                    </ResultTable>
                </QueryPanel>
            </>
        )
    }
}
export default withRouter(Patient);