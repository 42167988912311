import Enum from "../../../../../../enums/Enum";

const MedicationRouteEnum = new Enum({

    NA: {name: "N/A"},
    DENTAL: {name: "DENTAL"},
    DENTAL_INFILTRATION_INJ_DENTAL_BLOCK: {name: "DENTAL INFILTRATION INJ. DENTAL BLOCK"},
    DENTAL_INJECTION: {name: "DENTAL INJECTION"},
    EPIDURAL: {name: "EPIDURAL"},
    EPIDURAL_PERIPHERAL_NERVE_BLOCKS_CAUDAL: {name: "EPIDURAL PERIPHERAL NERVE BLOCKS CAUDAL"},
    EXTERNAL: {name: "EXTERNAL"},
    GENITAL: {name: "GENITAL"},
    IM: {name: "IM"},
    IMPLANT: {name: "IMPLANT"},
    INFILTRATION_DENTAL_BLOCK: {name: "INFILTRATION DENTAL BLOCK"},
    INHALATION: {name: "INHALATION"},
    INTRAAMNIOTIC: {name: "INTRAAMNIOTIC"},
    INTRAARTICULAR: {name: "INTRAARTICULAR"},
    INTRACAVERNOSAL: {name: "INTRACAVERNOSAL"},
    INTRACERVICAL: {name: "INTRACERVICAL"},
    INTRAGLUTEALLY: {name: "INTRAGLUTEALLY"},
    INTRAMASTITIS: {name: "INTRAMASTITIS"},
    INTRAMMAMARY: {name: "INTRAMMAMARY"},
    INTRAPERIODONTAL_POCKET: {name: "INTRAPERIODONTAL POCKET"},
    INTRAPERITONEAL: {name: "INTRAPERITONEAL"},
    INTRATRACHEAL: {name: "INTRATRACHEAL"},
    INTRAUTERIN: {name: "INTRAUTERIN"},
    INTRAVAGINAL: {name: "INTRAVAGINAL"},
    INTRAVESICALLY: {name: "INTRAVESICALLY"},
    IV: {name: "IV"},
    IV_CATHETER: {name: "IV CATHETER"},
    IV_INFUSION: {name: "IV INFUSION"},
    LOCAL_ORAL: {name: "LOCAL ORAL"},
    LOCAL_ORAL_DENTAL: {name: "LOCAL ORAL DENTAL"},
    NASAL: {name: "NASAL"},
    NEBULISATION: {name: "NEBULISATION"},
    OCULAR: {name: "OCULAR"},
    ORAL: {name: "ORAL"},
    ORAL_INHALATION: {name: "ORAL INHALATION"},
    ORALLY_TUBE_FEEDING: {name: "ORALLY TUBE FEEDING"},
    OTIC: {name: "OTIC"},
    PARENTERAL: {name: "PARENTERAL"},
    PERITONEAL_DIALYSIS: {name: "PERITONEAL DIALYSIS"},
    RECTAL: {name: "RECTAL"},
    SC: {name: "SC"},
    SPINAL: {name: "SPINAL"},
    SUBLINGUAL: {name: "SUBLINGUAL"},
    TOPICAL: {name: "TOPICAL"},
    TOPICAL_ANNAL: {name: "TOPICAL ANNAL"},
    TOPICAL_SCALP: {name: "TOPICAL SCALP"},
    TRANSDERMAL: {name: "TRANSDERMAL"},
    TRANSMUCOSAL: {name: "TRANSMUCOSAL"},
    VAGINAL: {name: "VAGINAL"},
    URETHRAL: {name: "URETHRAL"},
    MISCELLANEOUS: {name: "MISCELLANEOUS"},
    SUBARACHNOID: {name: "SUBARACHNOID"},
    INTRATHECAL: {name: "INTRATHECAL"},
    INTRAOCULAR: {name: "INTRAOCULAR"},
    INTRACARDIAC: {name: "INTRACARDIAC"},
    INTRADERMAL: {name: "INTRADERMAL"},
    INTRAVENTRICULAR: {name: "INTRAVENTRICULAR"},
    SUBMUCOSAL: {name: "SUBMUCOSAL"},
    INTRAPLEURAL: {name: "INTRAPLEURAL"},
    INFILTRATION: {name: "INFILTRATION"},
    IRRIGATION: {name: "IRRIGATION"},
    SUBDERMAL: {name: "SUBDERMAL"},
    BUCCAL: {name: "BUCCAL"},
    ARTERIOVENOUS: {name: "ARTERIOVENOUS"},
    CENTRAL_NERVE_BLOCK: {name: "CENTRAL NERVE BLOCK"},
    DEEP_SC: {name: "DEEP SC"},
    DERMAL: {name: "DERMAL"},
    ENDOTRACHEAL: {name: "ENDOTRACHEAL"},
    ILIOHYPOGASTRIC_NERVE_BLOCK: {name: "ILIOHYPOGASTRIC (NERVE BLOCK)"},
    ILIONGUINAL: {name: "ILIONGUINAL"},
    INTRAARTERIAL: {name: "INTRAARTERIAL"},
    INTRABURSAL: {name: "INTRABURSAL"},
    INTRACAVITIRY: {name: "INTRACAVITIRY"},
    INTRALESIONAL: {name: "INTRALESIONAL"},
    INTRAMEDULLARILY: {name: "INTRAMEDULLARILY"},
    INTRAOSSEOUS: {name: "INTRAOSSEOUS"},
    INTRAPULMONARY: {name: "INTRAPULMONARY"},
    INTRASPINAL: {name: "INTRASPINAL"},
    INTRASUBLESIONAL: {name: "INTRASUBLESIONAL"},
    INTRASYNOVIAL: {name: "INTRASYNOVIAL"},
    IV_BOLUS: {name: "IV BOLUS"},
    OROFACIAL: {name: "OROFACIAL"},
    OROPHARYNGEAL: {name: "OROPHARYNGEAL"},
    PERIANAL: {name: "PERIANAL"},
    PERIARTICULAR: {name: "PERIARTICULAR"},
    PERIBULBAR: {name: "PERIBULBAR"},
    PERIPHERAL_NERVE_BLOCK: {name: "PERIPHERAL NERVE BLOCK"},
    SUBCONJUNCTIVAL: {name: "SUBCONJUNCTIVAL"},
    SYMPATHETIC_NERVE_BLOCK: {name: "SYMPATHETIC NERVE BLOCK"},
    TRANSCERVICAL: {name: "TRANSCERVICAL"},
    CARDIAC_PERFUSION: {name: "CARDIAC PERFUSION"}

});

export default MedicationRouteEnum;