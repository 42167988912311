import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import CurrentPreviousEnum from "../enums/complaint/CurrentPreviousEnum";
import DurationEnum from "../enums/complaint/DurationEnum";
import DateUtil from "../../../../../utils/DateUtil";
import TimingEnum from "../enums/complaint/TimingEnum";
import TimingSensitivityEnum from "../enums/complaint/TimingSensitivityEnum";
import CharacterEnum from "../enums/complaint/CharacterEnum";
import OnsetEnum from "../enums/complaint/OnsetEnum";
import AggravatingFactorEnum from "../enums/complaint/AggravatingFactorEnum";
import DescriptionEnum from "../enums/complaint/DescriptionEnum";
import RelievingFactorsEnum from "../enums/complaint/RelievingFactorsEnum";
import FrequencyEnum from "../enums/complaint/FrequencyEnum";
import RedFlagEnum from "../enums/complaint/RedFlagEnum";
import EtiologyEnum from "../enums/complaint/EtiologyEnum";
import FeelEnum from "../enums/complaint/FeelEnum";
import NatureEnum from "../enums/complaint/NatureEnum";
import {Arrow, Circle, Cross} from "../../../../consentform/BodySilhouette";
import EnumBodySilhouetteSignTypes from "../../../../consentform/BodySilhouette/EnumBodySilhouetteSignTypes";
import BodySilhouetteStaffViewCanvas from "../../../../consentform/BodySilhouette/BodySilhouetteStaffViewCanvas";
import StringUtil from "../../../../../utils/StringUtil";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import DurationTimeEnum from "../enums/complaint/DurationTimeEnum";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export default class ComplaintView extends Component {

    findMarkType = (mark) => {
        const props = {
            top: mark.topDistance ? mark.topDistance : mark.top,
            left: mark.leftDistance ? mark.leftDistance : mark.left,
            angle: mark.angle,
            scaleX: mark.scaleX,
            scaleY: mark.scaleY,
            key: mark.type + Math.floor(Math.random() * 9999)
        }
        if (mark.type === EnumBodySilhouetteSignTypes.CROSS)
            return <Cross {...props}/>
        else if (mark.type === EnumBodySilhouetteSignTypes.ARROW)
            return <Arrow {...props}/>
        else if (mark.type === EnumBodySilhouetteSignTypes.CIRCLE)
            return <Circle {...props}/>
    }

    render() {
        let complaints = this.getComplaints();
        if (this.props.complaint.bodySilhouetteMarkList != null) {
            this.bodySilhouetteChilds = this.props.complaint.bodySilhouetteMarkList.map(mark => {
                return this.findMarkType(mark)
            });
        }

        return (
            <div className="view-wrapper">
                <div className="content-label" className="content-label">Complaint <RedAsterisk renderForAllTypes/></div>
                <div className={"content-card"}>
                    {complaints.length == 0 && "No info available yet"}
                    {complaints.length != 0 &&
                    <div className={"complaints-wrapper"}>
                        {complaints}
                    </div>
                    }
                </div>

                <br/>

                {this.props.complaint.secondaryComplaint != null &&
                <div>
                    <div className={"content-card padding25"}>
                        <div className={"display-flex"}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}>Secondary Complaint:</div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>
                                {this.props.complaint.secondaryComplaint}
                            </div>
                        </div>
                    </div>
                    <br/>
                </div>
                }

                <div className={"content-card"} style={{overflowX: "auto"}}>
                    <BodySilhouetteStaffViewCanvas>
                        {this.bodySilhouetteChilds}
                    </BodySilhouetteStaffViewCanvas>
                </div>

                <br/>

                <div className="content-label" className="content-label">Symptoms</div>
                <div className={"content-card"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th></th>
                            <th></th>
                            <th style={{width: "380px"}}>Details</th>
                        </tr>
                        <tr>
                            <th className={"emr-table-head"}>Pain (VAS)</th>
                            <td>{this.props.complaint.pain ?? 'N/A'}</td>
                            <td>{this.props.complaint.painDetail}</td>
                        </tr>
                        <tr>
                            <th className={"emr-table-head"}>Swelling</th>
                            <td>{this.props.complaint.swelling ?? 'N/A'}</td>
                            <td>{this.props.complaint.swellingDetail}</td>
                        </tr>
                        <tr>
                            <th className={"emr-table-head"}>Nature</th>
                            <td>{this.props.complaint.nature == null ? 'N/A' : NatureEnum[this.props.complaint.nature].name}</td>
                            <td>{this.props.complaint.natureDetail}</td>
                        </tr>
                        <tr>
                            <th className={"emr-table-head"}>Referral</th>
                            <td></td>
                            <td>{this.props.complaint.referral}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    getComplaints() {
        let complaints = [];
        let complaint = this.props.complaint;
        complaints.push(this.getComplaintViewDiv("Complaint", complaint.complaint));
        if (complaint.currentPrevious != null) {
            complaints.push(this.getComplaintViewDiv("Current/Previous",
                CurrentPreviousEnum[complaint.currentPrevious].name));
        }

        if(complaint.durationTime != null || (complaint.durationNumber != null && complaint.durationTimeEnum != null)){
            complaint.durationTime = this.calculateDurationTime(complaint.durationNumber, complaint.durationTimeEnum);
        }
        if (complaint.durationType != null
            || (complaint.durationDetails != null && complaint.durationDetails != "")
            || (complaint.durationTime != null)
        ) {
            let text = [
                complaint.durationType ? DurationEnum[complaint.durationType].name : null,
                !ArrayUtils.isEmpty(complaint.durationDetails) ? complaint.durationDetails : null,
                complaint.durationNumber != null && complaint.durationTimeEnum != null ?
                    [complaint.durationNumber, DurationTimeEnum[complaint.durationTimeEnum]?.name].join(" ") : null,
                complaint.durationTime ? DateUtil.formatDateMMM(complaint.durationTime) : null
            ].filter(Boolean).join(", ");

            complaints.push(this.getComplaintViewDiv(
                "Duration",
                text));
        }
        if (complaint.timing.length > 0
            || complaint.timingDetail != null) {
            let timingStr = this.enumString(
                TimingEnum,
                complaint.timing,
                complaint.timingDetail);
            if (complaint.timingSensitivity.length > 0) {
                timingStr += '(' + this.enumString(
                    TimingSensitivityEnum,
                    complaint.timingSensitivity) + ')';
            }
            complaints.push(this.getComplaintViewDiv(
                "Timing",
                timingStr
            ));
        }
        if (complaint.character.length > 0
            || complaint.characterDetail != null
                && complaint.characterDetail != "") {
            complaints.push(this.getComplaintViewDiv(
                "Character",
                this.enumString(
                    CharacterEnum,
                    complaint.character,
                    complaint.characterDetail)
                )
            );
        }
        if (complaint.onset != null
            || (complaint.onsetDetail != null
                && complaint.onsetDetail != "")) {
            let detail = complaint.onsetDetail;
            let onset = complaint.onset == null ? "" :
                OnsetEnum[complaint.onset].name;
            let text = onset == "" ? detail : ((detail == null || detail == "") ? onset : (onset + ", " + detail));
            complaints.push(this.getComplaintViewDiv("Onset", text));
        }
        if (complaint.aggravatingFactor.length > 0
            || (complaint.aggravatingFactorDetail != null
                && complaint.aggravatingFactorDetail != "")) {
            complaints.push(this.getComplaintViewDiv(
                "Aggravating Factors",
                this.enumString(
                    AggravatingFactorEnum,
                    complaint.aggravatingFactor,
                    complaint.aggravatingFactorDetail)
                )
            );
        }
        if(complaint.aggravatingFactorNormal){
            complaints.push(this.getComplaintViewDiv(
                "Aggravating Factors",
                "No Aggravating Factors"
            ))
        }
        if (complaint.description != null
            || (complaint.descriptionDetail != null
                && complaint.descriptionDetail != "")) {
            let detail = complaint.descriptionDetail;
            let description = complaint.description == null ? "" :
                DescriptionEnum[complaint.description].name;
            let text = description == "" ? detail : ((detail == null || detail == "") ? description : (description + ", " + detail));
            complaints.push(this.getComplaintViewDiv("Description", text));
        }
        if (complaint.relievingFactors.length > 0
            || (complaint.relievingFactorsDetail != null
                && complaint.relievingFactorsDetail != "")) {
            complaints.push(this.getComplaintViewDiv(
                "Relieving Factors",
                this.enumString(
                    RelievingFactorsEnum,
                    complaint.relievingFactors,
                    complaint.relievingFactorsDetail)
                )
            );
        }
        if(complaint.relievingFactorsNormal){
            complaints.push(this.getComplaintViewDiv(
                "Relieving Factors",
                "No Relieving Factors"
            ))
        }
        if (complaint.frequency.length > 0
            || complaint.frequencyDetail != null) {
            complaints.push(this.getComplaintViewDiv(
                "Frequency",
                this.enumString(
                    FrequencyEnum,
                    complaint.frequency,
                    complaint.frequencyDetail)
                )
            );
        }
        if (complaint.redFlag.length > 0
            || (complaint.redFlagDetail != null
                && complaint.redFlagDetail != "")) {
            complaints.push(this.getComplaintViewDiv(
                "Red Flags",
                this.enumString(
                    RedFlagEnum,
                    complaint.redFlag,
                    complaint.redFlagDetail)
                )
            );
        }
        if(complaint.redFlagNormal){
            complaints.push(this.getComplaintViewDiv(
                "Red Flags",
                "No Red Flags"
            ))
        }
        if (complaint.etiology.length > 0
            || complaint.etiologyDetail != null
            || complaint.historyOfTrauma != null) {
            let text = "";
            if (complaint.historyOfTrauma != null) {
                let b = complaint.historyOfTrauma ? "Yes" : "No";
                text += "History of Trauma (" + b + ")";
            }
            let enumDetail = this.enumString(EtiologyEnum,
                complaint.etiology,
                complaint.etiologyDetail);
            if (enumDetail != 'NA') {
                if (StringUtil.isNullOrEmpty(text)) {
                    text = enumDetail;
                } else {
                    text += ", " + enumDetail;
                }
            }

            complaints.push(this.getComplaintViewDiv(
                "Etiology",
                text
            ));
        }
        if (complaint.feel.length > 0) {
            complaints.push(this.getComplaintViewDiv(
                "How does this make you feel?",
                this.enumString(
                    FeelEnum,
                    complaint.feel)
                )
            );
        }
        return complaints;
    }

    getComplaintViewDiv(label, value) {
        return (
            <div key={label} className={"display-flex"}>
                <div className={"col-sm-5 no-padding dim14-form-label"}>{label}:</div>
                <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>{value}</div>
            </div>);
    }

    enumString(_class, enumObject, detail) {
        if (enumObject == null || enumObject.length == 0) {
            return (detail && detail != null) ? detail : 'NA';
        }
        let res = enumObject.map(e => _class[e]?.name);
        if (detail && detail != null && detail != '') {
            res.push(detail);
        }
        return res.filter(Boolean).join(', ');
    }

    calculateDurationTime(number, timeEnum) {
        if (!number || !timeEnum) {
            return null;
        }
        const today = new Date();
        if (timeEnum == "DAY") {
            return DateUtil.addDays(today, -1 * number);
        } else if (timeEnum == "WEEK") {
            return DateUtil.addWeeks(today, -1 * number);
        } else if (timeEnum == "MONTH") {
            return DateUtil.addMonths(today, -1 * number);
        } else if (timeEnum == "YEAR") {
            return DateUtil.addYears(today, -1 * number);
        }
        return null;
    }
}
