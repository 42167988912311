import "../MedicalReports.css"
import React, {Component} from "react";
import ReportTemplate from "../ReportTemplate";
import ReportSection from "../ReportSection";
import ReviewOfSystemViewHistory from "../../visithistory/views/subjective/ReviewOfSystemViewHistory";
import TreatmentResultHistory from "../../visithistory/views/treatment/TreatmentResultHistory";
import MedicationUseViewHistory from "../../visithistory/views/subjective/MedicationUseViewHistory";
import TreatmentReceivedReportView from "../view/TreatmentReceivedReportView";
import AssessmentReportView from "../view/AssessmentReportView";
import ReferralInterventionsReportView from "../view/ReferralInterventionsReportView";
import ResponseToTreatmentReportView from "../view/ResponseToTreatmentReportView";
import LabImagingReportView from "../view/LabImagingReportView";
import ComplaintReportView from "../view/ComplaintReportView";
import AllergiesReportView from "../view/AllergiesReportView";
import DischargePlanReportView from "../view/DischargePlanReportView";
import DischargePlanReadinessReportView from "../view/DischargePlanReadinessReportView";
import DischargePlanInstructionsReportView from "../view/DischargePlanInstructionsReportView";
import PreviousTreatmentReceivedReportView from "../view/PreviousTreatmentReceivedReportView";
import ActivityLimitationReportView from "../view/ActivityLimitationReportView";
import MedicalHistoryReportView from "../view/MedicalHistoryReportView";
import FamilyHistoryReportView from "../view/FamilyHistoryReportView";
import SurgicalHistoryReportView from "../view/SurgicalHistoryReportView";
import MSKDiagnosisReportView from "../view/MSKDiagnosisReportView";
import ReportViewUtils from "../view/ReportViewUtils";
import SymptomPainReferralReportView from "../view/SymptomPainReferralReportView";
import DiagnosisReportView from "../view/DiagnosisReportView";

export default class DischargeSummaryReportTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {visit: this.props.emr.visits[this.props.emr.visits.length - 1]}; // TODO remove
    }

    render() {
        const visit = this.state.visit;
        const subjective = visit.subjective;
        const assessment = visit.assessment;
        const treatment = visit.treatment;
        const prescriptions = visit.prescriptions;

        return (
            <ReportTemplate {...this.props} generateUrl={'api/medical-report/generate/discharge-summary'}
                            onVisitSelected={(visit) => {
                                this.setState({visit})
                            }}>

                <ReportSection header1={"Complaint"}>
                    {!ReportViewUtils.isComplaintNull(subjective.complaint) && <ComplaintReportView complaint={subjective.complaint}/>}
                </ReportSection>

                <ReportSection header1={"Symptoms"} header2={"Previous Treatment Received"}>
                    {!ReportViewUtils.isSymptomsPainReferralNull(subjective.complaint) && <SymptomPainReferralReportView complaint={subjective.complaint}/>}
                    {!ReportViewUtils.isPreviousTreatmentReceivedNull(subjective.treatmentReceived) && <PreviousTreatmentReceivedReportView treatmentReceived={subjective.treatmentReceived}/>}
                </ReportSection>

                <ReportSection header1={"Activity Limitations"} header2={"Labs/Imaging"}>
                    {!ReportViewUtils.isActivityLimitationNull(subjective.activityLimitation) && <ActivityLimitationReportView activityLimitation={subjective.activityLimitation}/>}
                    {!ReportViewUtils.isLabImagingNull(subjective.labsImaging) && <LabImagingReportView labsImaging={subjective.labsImaging}/>}
                </ReportSection>

                <ReportSection header1={"Allergies"}>
                    {!ReportViewUtils.isAllergyNull(subjective.allergy) && <AllergiesReportView allergy={subjective.allergy}/>}
                </ReportSection>

                <ReportSection header1={"Medications"}>
                    {(!ReportViewUtils.isMedicationUseNull(subjective.medicationUse) || !ReportViewUtils.isPrescriptionsNull(prescriptions))
                    && <MedicationUseViewHistory medicationUse={subjective.medicationUse} prescriptions={prescriptions}/>}
                </ReportSection>

                <ReportSection header1={"Medical History"} header2={"Family History"}>
                    {(!ReportViewUtils.isMedicalHistoryNull(subjective.medicalHistory) || !ReportViewUtils.isVaccinationHistoryNull(subjective.medicalHistory)) && <MedicalHistoryReportView medicalHistory={subjective.medicalHistory} isPrintVaccination/>}
                    {!ReportViewUtils.isFamilyHistoryNull(subjective.familyHistory) && <FamilyHistoryReportView familyHistory={subjective.familyHistory}/>}
                </ReportSection>

                <ReportSection header1={"Surgical History"} header2={"Review Of Systems"}>
                    {!ReportViewUtils.isSurgicalHistoryNull(subjective.surgicalHistory) && <SurgicalHistoryReportView surgicalHistory={subjective.surgicalHistory}/>}
                    {!ReportViewUtils.isReviewOfSystemNull(subjective.reviewOfSystem) && <ReviewOfSystemViewHistory reviewOfSystem={subjective.reviewOfSystem} reportView/>}
                </ReportSection>

                <ReportSection header1={"MSK Diagnostic Ultrasound"}>
                    {!ReportViewUtils.isMskDiagnosisNull(assessment.mskDiagnosis) && <MSKDiagnosisReportView mskDiagnosis={assessment.mskDiagnosis}/>}
                </ReportSection>

                <ReportSection header1={"Assessment"}>
                    {!ReportViewUtils.isAssessmentNull(assessment) && <AssessmentReportView assesment={assessment}/>}
                </ReportSection>

                <ReportSection header1={"Diagnosis"}>
                    {!ReportViewUtils.isDiagnosisNull(visit.diagnosis) && !ReportViewUtils.isDiagnosisItemsNull(visit.diagnosis.diagnosisGroups[0].diagnosisItems) && <DiagnosisReportView diagnosis={visit.diagnosis}/>}
                </ReportSection>

                <ReportSection header1={"Treatment"}>
                    {!ReportViewUtils.isTreatmentInfoNull(treatment.treatmentReceived) && <div>
                        <div style={{marginBottom: "15px"}}><b style={{marginBottom: "15px"}}>Treatment Received</b>
                        </div>
                        <div>
                            The patient received the following treatment
                        </div>
                        <TreatmentReceivedReportView treatmentInfo={treatment.treatmentReceived}/>
                    </div>}
                </ReportSection>

                <ReportSection header1={"Response To Treatment"}>
                    {(!ReportViewUtils.isResponseToTreatmentNull(treatment.response) || ReportViewUtils.isReferralInterventionsNull(treatment.referral)) && <div>
                        {!ReportViewUtils.isResponseToTreatmentNull(treatment.response) && <div style={{marginBottom: "15px"}}>
                            <ResponseToTreatmentReportView response={treatment.response}/>
                        </div>}
                        {!ReportViewUtils.isReferralInterventionsNull(treatment.referral) && <ReferralInterventionsReportView referral={treatment.referral}/>}
                    </div>}
                </ReportSection>

                <ReportSection header1={"Results"}>
                    {!ReportViewUtils.isTreatmentResultNull(treatment.results) && <div>
                        The Following Interventions Were Ordered With The Findings Documented Noted As Below:
                        <TreatmentResultHistory results={treatment.results}/>
                    </div>}
                </ReportSection>

                <ReportSection header1={"Discharge Readiness"} header2={"Discharge Plan"}>
                    {!ReportViewUtils.isDischargePlanReadinessNull(treatment.dischargePlan) && <DischargePlanReadinessReportView dischargePlan={treatment.dischargePlan}/>}
                    {!ReportViewUtils.isDischargePlanNull(treatment.dischargePlan) && <DischargePlanReportView dischargePlan={treatment.dischargePlan}/>}
                </ReportSection>

                {!ReportViewUtils.isDischargePlanInstructionsNull(treatment.dischargePlan) && <DischargePlanInstructionsReportView dischargePlan={treatment.dischargePlan} />}

            </ReportTemplate>
        );
    }

}
