import "../MedicalReports.css"
import React, {Component} from "react";
import ReportTemplate from "../ReportTemplate";
import ReportSection from "../ReportSection";
import DateUtil from "../../../../../utils/DateUtil";
import ReviewOfSystemViewHistory from "../../visithistory/views/subjective/ReviewOfSystemViewHistory";
import ComplaintReportView from "../view/ComplaintReportView";
import LabImagingReportView from "../view/LabImagingReportView";
import AllergiesReportView from "../view/AllergiesReportView";
import PreviousTreatmentReceivedReportView from "../view/PreviousTreatmentReceivedReportView";
import ActivityLimitationReportView from "../view/ActivityLimitationReportView";
import MedicalHistoryReportView from "../view/MedicalHistoryReportView";
import FamilyHistoryReportView from "../view/FamilyHistoryReportView";
import SurgicalHistoryReportView from "../view/SurgicalHistoryReportView";
import MSKDiagnosisReportView from "../view/MSKDiagnosisReportView";
import DiagnosisReportView from "../view/DiagnosisReportView";
import TreatmentResultReportView from "../view/TreatmentResultReportView";
import ReferralsReportView from "../view/ReferralsReportView";
import ReportViewUtils from "../view/ReportViewUtils";
import AssessmentObservationPalpationReportView from "../view/AssessmentObservationPalpationReportView";
import SymptomPainReferralReportView from "../view/SymptomPainReferralReportView";
import TreatmentReceivedReportView from "../view/TreatmentReceivedReportView";

export default class MedicalReportTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {visit: this.props.emr.visits[this.props.emr.visits.length - 1]}; // TODO remove
    }

    render() {
        const emr = this.props.emr;
        const firstVisit = emr.visits[0];
        const visit = this.state.visit;
        const subjective = visit.subjective;
        const assessment = visit.assessment;
        const treatment = visit.treatment;

        return (
            <ReportTemplate {...this.props} generateUrl={'api/medical-report/generate/medical'}
                            onVisitSelected={(visit) => {
                                this.setState({visit})
                            }}>

                <ReportSection header1={"Complaint"}>
                    {!ReportViewUtils.isComplaintNull(subjective.complaint) && <ComplaintReportView complaint={subjective.complaint}/>}
                </ReportSection>

                <ReportSection header1={"Symptoms"} header2={"Date Of First Visit"}>
                    {!ReportViewUtils.isSymptomsPainReferralNull(subjective.complaint) && <SymptomPainReferralReportView complaint={subjective.complaint}/>}
                    {firstVisit.appointment.startTime != null && <div>{DateUtil.formatDateDoMMYY(firstVisit.appointment.startTime)}</div>}
                </ReportSection>

                <ReportSection header1={"Previous Treatment Received"} header2={"Activity Limitations"}>
                    {!ReportViewUtils.isPreviousTreatmentReceivedNull(subjective.treatmentReceived) && <PreviousTreatmentReceivedReportView treatmentReceived={subjective.treatmentReceived}/>}
                    {!ReportViewUtils.isActivityLimitationNull(subjective.activityLimitation) && <ActivityLimitationReportView activityLimitation={subjective.activityLimitation}/>}
                </ReportSection>

                <ReportSection header1={"Labs/Imaging"} header2={"Allergies"}>
                    {!ReportViewUtils.isLabImagingNull(subjective.labsImaging) && <LabImagingReportView labsImaging={subjective.labsImaging}/>}
                    {!ReportViewUtils.isAllergyNull(subjective.allergy) && <AllergiesReportView allergy={subjective.allergy}/>}
                </ReportSection>

                <ReportSection header1={"Medical History"} header2={"Family History"}>
                    {!ReportViewUtils.isMedicalHistoryNull(subjective.medicalHistory) && <MedicalHistoryReportView medicalHistory={subjective.medicalHistory}/>}
                    {!ReportViewUtils.isFamilyHistoryNull(subjective.familyHistory) && <FamilyHistoryReportView familyHistory={subjective.familyHistory}/>}
                </ReportSection>

                <ReportSection header1={"Surgical History"} header2={"Review Of Systems"}>
                    {!ReportViewUtils.isSurgicalHistoryNull(subjective.surgicalHistory) && <SurgicalHistoryReportView surgicalHistory={subjective.surgicalHistory}/>}
                    {!ReportViewUtils.isReviewOfSystemNull(subjective.reviewOfSystem) && <ReviewOfSystemViewHistory reviewOfSystem={subjective.reviewOfSystem} reportView/>}
                </ReportSection>

                <ReportSection header1={"MSK Diagnostic Ultrasound"}>
                    {!ReportViewUtils.isMskDiagnosisNull(assessment.mskDiagnosis) && <MSKDiagnosisReportView mskDiagnosis={assessment.mskDiagnosis}/>}
                </ReportSection>

                <ReportSection header1={"Assessment"}>
                    {!ReportViewUtils.isAssessmentObservationPalpationNull(assessment) && <AssessmentObservationPalpationReportView assesment={assessment}/>}
                </ReportSection>

                <ReportSection header1={"Diagnosis"} header2={"Treatment Plan"}>
                    {!ReportViewUtils.isDiagnosisNull(visit.diagnosis) && !ReportViewUtils.isDiagnosisItemsNull(visit.diagnosis.diagnosisGroups[0].diagnosisItems) && <DiagnosisReportView diagnosis={visit.diagnosis}/>}
                    {!ReportViewUtils.isTreatmentInfoNull(treatment.treatmentPlan) && <div>
                        The patient is recommended for the following treatment plan.
                        <TreatmentReceivedReportView  treatmentInfo={treatment.treatmentPlan}/>
                    </div>}
                </ReportSection>

                <ReportSection header1={"Referrals"}>
                    {!ReportViewUtils.isReferralsNull(treatment.referral) && <ReferralsReportView referral={treatment.referral}/>}
                </ReportSection>

                <ReportSection header1={"Results"}>
                    {!ReportViewUtils.isTreatmentResultNull(treatment.results) && (<div>
                        The Following Interventions Were Ordered With The Findings Documented Noted As Below:
                        <TreatmentResultReportView results={treatment.results}/>
                    </div>)}
                </ReportSection>

            </ReportTemplate>
        );
    }

}
