import React, {Component} from "react";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {Column} from "primereact/column";
import DateUtil from "../../../utils/DateUtil";
import {DataTable} from "primereact/datatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {base64ToBlob, downloadBlob} from "../../../utils/FileDownloader";
import ActionMenu from "../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../../components/action-menu/icons/ViewIcon";
import DeleteIcon from "../../../components/action-menu/icons/DeleteIcon";
import "./AppointmentDocuments.css";
import AttachmentUtils from "../../emr/recordpage/treatment/utils/AttachmentUtils";
import AuthService from "../../../services/auth-service/AuthService";

class AppointmentDocuments extends Component {

    constructor(props) {
        super(props);
        this.state = {documents: []};
    }

    componentDidMount() {
        RemotingService.getRemoteCall("api/appointment/" + this.props.appointmentId + "/document-meta", null, result => {
            this.setState({documents: result});
        });
    }

    render() {
        return (
            <>
                <DataTable className="AppointmentDocumentsTable"
                           value={this.state.documents}
                           dataKey="id"
                           emptyMessage="No records found.">
                    <Column header="Name"
                            body={document => document.name}/>
                    <Column header="Description"
                            body={document => document.description}/>
                    <Column header="Date"
                            body={document => DateUtil.formatDateTime12H(document.creationDate)}
                            style={{width: "150px"}}/>
                    <Column header="Actions"
                            body={this.actionsTemplate}
                            style={{width: "80px", textAlign: "center"}}/>
                </DataTable>
            </>
        );
    }

    actionsTemplate = (document) => {
        return (
            <ActionMenu>
                <ActionMenuItem text="View" icon={<ViewIcon/>} onClick={() => this.view(document)} disabled={!AttachmentUtils.viewEnable(document.name)}/>
                <ActionMenuItem text="Download" icon={<FontAwesomeIcon icon={["fas", "file-download"]}/>} onClick={() => this.download(document)}/>
                {AuthService.userHasAuthority("UPDATE_APPOINTMENT") &&
                <ActionMenuItem text="Delete" icon={<DeleteIcon/>} onClick={() => this.delete(document)}/>
                }
            </ActionMenu>
        );
    };

    view = (document) => {
        RemotingService.getRemoteCall("api/appointment/document/" + document.id, null, result => {
            let blob = base64ToBlob(result.content, result.mimeType);
            AttachmentUtils.viewFile({fileName: document.name, blob: blob, id: 'appointmentViewer'});
        });
    }

    download = (document) => {
        RemotingService.getRemoteCall("api/appointment/document/" + document.id, null, result => {
            downloadBlob(base64ToBlob(result.content, result.mimeType), result.name);
        });
    }

    delete = (document) => {
        RemotingService.deleteRemoteCall("api/appointment/" + this.props.appointmentId + "/document/" + document.id, null, result => {
            let documents = this.state.documents.filter(item => item.id != document.id);
            this.setState(
                {documents: documents},
                () => {
                    this.props.onDocumentDelete(this.props.appointmentId);
                }
            );
        });
    }

}

export default AppointmentDocuments;