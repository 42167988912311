import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import FormField from "../../../components/form/FormField";
import {PhoneInput, RadioInput, TextInput} from "../../../components";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";
import {Dropdown} from "primereact/dropdown";

class IntakeFormContactInformation extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, nationalityLabel, onChange, onFieldGroupChanged, formFields,
            contactMobilePhone, doYouUseThisNumberForWhatsapp, contactOtherPhone, contactEmail, address,
            country, nationalities, city, postalCode
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <h3 className="text-center mb-3">
                    <b>{IntakeFormTextEnum.Contact_Information[selectedLanguage]}</b>
                </h3>
                <div className="row">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Phone_Number[selectedLanguage]}:</div>
                    <div className="col-8">
                        <FormField
                            ref={(formField) => formFields.push(formField)}
                            required
                            validateOn={readOnly ? true : contactMobilePhone}
                        >
                            <PhoneInput
                                containerClass={
                                    readOnly ? "PhoneInputDisabledBackground" : ""
                                }
                                inputClass="w-100"
                                value={contactMobilePhone}
                                disabled={readOnly}
                                onChange={(value) => {
                                    onChange("contactMobilePhone", value);
                                }}
                            />
                        </FormField>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Do_You_Use_This_Number_For_Whatsapp[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <RadioInput
                            id="allergiesRadioInput"
                            value={doYouUseThisNumberForWhatsapp}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange(
                                    "doYouUseThisNumberForWhatsapp",
                                    value
                                )
                            }
                        />
                    </div>
                </div>
                {doYouUseThisNumberForWhatsapp === "No" ? (
                    <div className="row">
                        <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Other_Phone[selectedLanguage]}:
                        </div>
                        <div className="col-8">
                            <PhoneInput
                                containerClass={
                                    readOnly ? "PhoneInputDisabledBackground" : ""
                                }
                                inputClass="w-100"
                                value={contactOtherPhone}
                                disabled={readOnly}
                                onChange={(value) => {
                                    onChange("contactOtherPhone", value);
                                }}
                            />
                        </div>
                    </div>
                ) : null}

                <div className="row">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Email[selectedLanguage]}:</div>
                    <div className="col-8">
                        <TextInput
                            className="w-100"
                            value={contactEmail}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("contactEmail", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">{IntakeFormTextEnum.Address[selectedLanguage]}:</div>
                    <div className="col-8">
                        <TextInput
                            className="w-100"
                            value={address}
                            disabled={readOnly}
                            onChange={(value) => onChange("address", value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto dim14-form-label">{IntakeFormTextEnum.Country[selectedLanguage]}:</div>
                    <div className="col-4">
                        <Dropdown
                            className={`DropdownBorderColor w-100 ${
                                readOnly ? "DropdownDisabledBackground" : ""
                            }`}
                            value={country}
                            disabled={readOnly}
                            dataKey={"code"}
                            options={nationalities}
                            optionLabel={nationalityLabel}
                            filter
                            filterLocale={"en-US"}
                            filterBy={nationalityLabel}
                            onChange={(event) =>
                                onChange("country", event.value)
                            }
                        />
                    </div>
                    <div className="col-auto dim14-form-label">{IntakeFormTextEnum.City[selectedLanguage]}:</div>
                    <div className="col">
                        <TextInput
                            className="w-100"
                            value={city}
                            disabled={readOnly}
                            onChange={(value) => onChange("city", value)}
                        />
                    </div>
                    <div className="col-auto dim14-form-label">{IntakeFormTextEnum.Postal_Code[selectedLanguage]}:</div>
                    <div className="col">
                        <TextInput
                            className="w-100"
                            value={postalCode}
                            disabled={readOnly}
                            onChange={(value) => !isNaN(value) ? onChange("postalCode", value) : null}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default IntakeFormContactInformation;