import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import RemotingService from '../../../../../services/remoting-service/RemotingService';
import EmergencyContactItem from './EmergencyContactItem';
import Tooltip from "../../../../../components/tooltip/Tooltip";
import {Modal, PhoneInput, SelectInput, TextInput} from '../../../../../components';
import EnumRelationshipType from './enums/EnumRelationshipType';
import {FormField} from "../../../../../components/form";
import ValidationUtil from "../../../../../components/form/validator/ValidationUtil";
import NotificationService from "../../../../../services/notification-service/NotificationService";
import moment from "moment";
import confirmDialog from "../../../../../components/dialog/ConfirmDialog";
import PhoneNumberUtil from "../../../../../utils/PhoneNumberUtil";

class EmergencyContact extends React.Component {

    constructor(props) {
        super(props);
        let emergencyContactList = this.props.emergencyContact ? this.props.emergencyContact : [];
        let activeEmergencyContact = this.findActiveEmergencyContact(this.props.emergencyContact);
        this.state = {
            updateEmergencyModalVisible: false,
            addEmergencyModalVisible: false,
            contactListModalVisible: false,
            emergencyContactList: emergencyContactList,
            activeEmergencyContact: activeEmergencyContact,
            editedEmergencyContact: {},

            newName: "",
            newMobilePhone: "",
            newRelationshipType: "",
        }
    }

    findActiveEmergencyContact = (emergencyContact) => {
        return (
            emergencyContact && emergencyContact.length
                ? emergencyContact.reduce((prev, current) => (moment(prev.updateDate).isAfter(moment(current.updateDate))) ? prev : current)
                : {
                    relationshipType: "",
                    name: "",
                    mobilePhone: ""
                }
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.emergencyContact) !== JSON.stringify(this.props.emergencyContact)){
            let activeEmergencyContact = this.findActiveEmergencyContact(this.props.emergencyContact);
            this.setState({ emergencyContactList: this.props.emergencyContact,
                activeEmergencyContact: activeEmergencyContact})
        }
    }

    render() {
        return (
            <>
                <div className="card-body special-status-wrapper small-font">
                    <div className="row" style={{width: '100%'}}>
                        <div className="col-3" style={{whiteSpace: "normal"}}>
                            <label style={{fontWeight: 500}}>Emergency Contact:</label>
                        </div>
                        <div className="col-9">
                            <div className="form-group row">
                                <label className="control-label col-sm-5">Name:</label>
                                <div className="col-sm-7">
                                    {this.state.activeEmergencyContact.relationshipType ?
                                        <Tooltip title={this.state.activeEmergencyContact.relationshipType && EnumRelationshipType[this.state.activeEmergencyContact.relationshipType].name}>
                                            <span>{this.state.activeEmergencyContact.name}</span>
                                        </Tooltip> :
                                        <span>{this.state.activeEmergencyContact.name}</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-5">Phone:</label>
                                <div className="col-sm-7">
                                    {this.getOrDefault(PhoneNumberUtil.formatInternational(this.state.activeEmergencyContact.mobilePhone))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="display-flex" style={{flexDirection: "column", zIndex: "1"}}>
                        <Tooltip title={"See Details"}>
                            <span style={{marginLeft: "7px"}}>
                                {this.props.editable &&
                                <FontAwesomeIcon icon={["fa", "edit"]}
                                                 onClick={() => this.setState({contactListModalVisible: true})}/>
                                }
                            </span>
                        </Tooltip>
                    </div>
                </div>
                {this.renderContactListModal()}
                {this.renderAddEmergencyModal()}
                {this.renderUpdateEmergencyModal()}
            </>
        );
    }

    getOrDefault = (param) => {
        return param ? param : '-';
    }

    renderContactListModal = () => {
        return this.state.contactListModalVisible ?
            (<Modal
                    visible={this.state.contactListModalVisible}
                    header="Emergency Contact"
                    fullWidth
                    maxWidth={"md"}
                    submitContent="Add New Emergency Contact"
                    submitAction={() => {
                        this.setState({addEmergencyModalVisible: true})
                    }}
                    closeAction={() => this.setState({
                        contactListModalVisible: false,
                    })}>
                {this.contactListModalBody()}
            </Modal>)
            : null;
    }

    renderAddEmergencyModal = () => {
        return this.state.addEmergencyModalVisible ?
            (<Modal visible={this.state.addEmergencyModalVisible}
                    header="Add New Emergency Contact"
                    id="add-emergency-contact"
                    submitContent="Add New Emergency Contact"
                    fullWidth
                    maxWidth={"sm"}
                    submitAction={this.handleAddEmergencyContact}
                    closeAction={() => this.setState({
                        addEmergencyModalVisible: false,
                        newName: "",
                        newMobilePhone: "",
                        newRelationshipType: ""
                    })}>
                {this.addModalBody()}
            </Modal>)
            : null;
    }

    renderUpdateEmergencyModal = () => {
        return this.state.updateEmergencyModalVisible ?
            (<Modal visible={this.state.updateEmergencyModalVisible}
                    header="Update Emergency Contact"
                    id="update-emergency-contact"
                    fullWidth
                    maxWidth={"sm"}
                    submitContent="Update Emergency Contact"
                    submitAction={() => this.handleUpdate()}
                    closeAction={() => this.setState({
                        updateEmergencyModalVisible: false,
                        editedEmergencyContact: {}
                    })}>
                {this.updateModalBody()}
            </Modal>)
            : null;
    }

    handleActivate = (emergencyId) => {
        RemotingService.postRemoteCall('api/patient/' + this.props.patientId + '/' + emergencyId + '/activate', null, (result) => {
            const activeEmergencyContact = this.findActiveEmergencyContact(result);
            this.setState({
                ...this.state,
                activeEmergencyContact: activeEmergencyContact,
                emergencyContactList: result
            })
        });
    }

    handleUpdate = () => {
        if (!ValidationUtil.isValid(this.updateModalFormFields)) {
            NotificationService.showNotification({ severity: 'error', summary: 'Validation Error', detail: 'There are missing fields.' });
            return;
        }

        let dto = {
            relationshipType: this.state.editedEmergencyContact.relationshipType,
            name: this.state.editedEmergencyContact.name,
            mobilePhone: this.state.editedEmergencyContact.mobilePhone,
        };

        RemotingService.postRemoteCall('api/patient/' + this.props.patientId + '/' + this.state.editedEmergencyContact.id + '/update', dto, (result) => {
            this.setState({
                ...this.state,
                emergencyContactList: result,
                updateEmergencyModalVisible: false,
                activeEmergencyContact: this.findActiveEmergencyContact(result)
            })
        });
    }

    handleAddEmergencyContact = () => {
        if (!ValidationUtil.isValid(this.addModalFormFields)) {
            NotificationService.showNotification({ severity: 'error', summary: 'Validation Error', detail: 'There are missing fields.' });
            return;
        }
        let dto = {
            relationshipType: this.state.newRelationshipType,
            name: this.state.newName,
            mobilePhone: this.state.newMobilePhone
        };

        RemotingService.postRemoteCall('api/patient/' + this.props.patientId + '/emergency-contact', dto, (result) => {
            const activeEmergencyContact = this.findActiveEmergencyContact(result)
            this.setState({
                ...this.state,
                newName: "",
                newMobilePhone: "",
                newRelationshipType: "",
                activeEmergencyContact: activeEmergencyContact,
                emergencyContactList: result,
                addEmergencyModalVisible: false
            })
        });
    }

    contactListModalBody = () => {
        const emergencyContactList = [...this.state.emergencyContactList];
        let emergencyContactItems = emergencyContactList
            .sort((prev, current) => (moment(prev.updateDate).isAfter(moment(current.updateDate))) ? -1 : 1)
            .map(emergencyContact =>
                <EmergencyContactItem
                    emergencyContact={emergencyContact} handleActivate={() => this.handleActivate(emergencyContact.id)}
                    openUpdateModalBody={() => this.openUpdateModalBody(emergencyContact)} onDelete={this.deleteEmergencyContact}/>)
        return (
            <div className="card">
                <div className="card-body line-wrapper small-font" style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col-4">Name</div>
                        <div className="col-4">Phone</div>
                        <div className="col-3">Relationship</div>
                        <div className="col-1">Actions</div>
                    </div>
                    <hr/>
                    {emergencyContactItems}
                </div>
            </div>
        );
    }

    addModalBody = () => {
        this.addModalFormFields = [];
        return (
            <div className={"container dim14-form-row-align"} style={{maxWidth: '100%'}}>
                <div className= "row">
                    <div className="col-md-5 dim14-form-label">
                        Name
                    </div>
                    <div className="col-md-7 dim14-base-input">
                        <FormField ref={formField => this.addModalFormFields.push(formField)} required validateOn={this.state.newName}>
                            <TextInput value={this.state.newName} alphabeticOnly onChange={(value) => this.setState({newName: value})}/>
                        </FormField>
                    </div>
                </div>

                <div className= "row">
                    <div className="col-md-5 dim14-form-label">
                        Mobile Phone
                    </div>
                    <div className="col-md-7 dim14-base-input">
                        <FormField ref={formField => this.addModalFormFields.push(formField)} required validateOn={this.state.newMobilePhone}>
                            <PhoneInput value={this.state.newMobilePhone}
                                        onChange={(value) => this.setState({newMobilePhone: value})}/>
                        </FormField>
                    </div>
                </div>

                <div className= "row">
                    <div className="col-md-5 dim14-form-label">
                        Relationship
                    </div>
                    <div className="col-md-7 dim14-base-input">
                        <FormField ref={formField => this.addModalFormFields.push(formField)} required validateOn={this.state.newRelationshipType}>
                            <SelectInput className="light-color-bordered" value={this.state.newRelationshipType}
                                         options={EnumRelationshipType.toArray().map(v=> {
                                                 return {value: v.key, label:v.name}
                                             }
                                         )}
                                         displayProperty="label" valueProperty="value" searchEnabled={false}
                                         onChange={(val) => {
                                             this.setState({newRelationshipType: val})
                                         }}
                            />
                        </FormField>
                    </div>
                </div>
            </div>
        )
    }

    openUpdateModalBody = (emergencyContact) => {
        this.setState({updateEmergencyModalVisible: true, editedEmergencyContact: {...emergencyContact}})
    }

    updateModalBody = () => {
        this.updateModalFormFields = []
        return (
            <div className={"container dim14-form-row-align"} style={{maxWidth: '100%'}}>
                <div className= "row">
                    <div className="col-md-5 dim14-form-label">
                        Name
                    </div>
                    <div className="col-md-7 dim14-base-input">
                        <FormField ref={formField => this.updateModalFormFields.push(formField)} required validateOn={this.state.editedEmergencyContact.name}>
                            <TextInput value={this.state.editedEmergencyContact.name} alphabeticOnly
                                       onChange={(value) => this.setState({
                                           ...this.state,
                                           editedEmergencyContact: {...this.state.editedEmergencyContact, name: value}
                                       })}/>
                        </FormField>
                    </div>
                </div>
                <div className= "row">
                    <div className="col-md-5 dim14-form-label">
                        Mobile Phone
                    </div>
                    <div className="col-md-7 dim14-base-input">
                        <FormField ref={formField => this.updateModalFormFields.push(formField)} required validateOn={this.state.editedEmergencyContact.mobilePhone}>
                            <PhoneInput value={this.state.editedEmergencyContact.mobilePhone}
                                        onChange={(value) => this.setState({
                                            ...this.state,
                                            editedEmergencyContact: {...this.state.editedEmergencyContact, mobilePhone: value}
                                        })}/>
                        </FormField>
                    </div>
                </div>
                <div className= "row">
                    <div className="col-md-5 dim14-form-label">
                        Relationship
                    </div>
                    <div className="col-md-7 dim14-base-input">
                        <FormField ref={formField => this.updateModalFormFields.push(formField)} required validateOn={this.state.editedEmergencyContact.relationshipType}>
                            <SelectInput className="light-color-bordered" value={this.state.editedEmergencyContact.relationshipType}
                                         options={EnumRelationshipType.toArray().map(v=> {
                                                 return {value: v.key, label:v.name}
                                             }
                                         )}
                                         displayProperty="label" valueProperty="value"
                                         searchEnabled={false}
                                         onChange={(value) => this.setState({
                                             ...this.state,
                                             editedEmergencyContact: {
                                                 ...this.state.editedEmergencyContact,
                                                 relationshipType: value
                                             }
                                         })}
                            />
                        </FormField>
                    </div>
                </div>
            </div>
        )
    }

    deleteEmergencyContact = (emergencyContactId) => {
        confirmDialog("Delete Emergency Contact",
            "You are about to delete an emergency contact. Are you sure?",
            () => {
                RemotingService.deleteRemoteCall(
                    'api/patient/' + this.props.patientId + '/' + emergencyContactId + '/delete/',
                    null, () => {
                        this.setState(state => {
                            state.emergencyContactList = state.emergencyContactList.filter(item => item.id != emergencyContactId);
                            state.activeEmergencyContact = this.findActiveEmergencyContact(state.emergencyContactList);
                            return state;
                        })
                    });
            });
    }

};

export default EmergencyContact;