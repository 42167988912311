import React, {Component} from "react";
import "../../VisitHistory.css"
import MedicationTypeEnum from "../../../subjective/enums/medicationuse/MedicationTypeEnum";
import UnitEnum from "../../../subjective/enums/medicationuse/UnitEnum";
import FrequencyEnum from "../../../subjective/enums/medicationuse/FrequencyEnum";
import DateUtil from "../../../../../../utils/DateUtil";
import * as ArrayUtil from "../../../../../../utils/ArrayUtil";
import UOMEnum from "../../../prescription/enums/UOMEnum";
import PrescriptionTimeUnitEnum from "../../../prescription/enums/PrescriptionTimeUnitEnum";
import {connect} from "react-redux";

class MedicationUseViewHistory extends Component {

    render() {
        if(ArrayUtil.isEmpty(this.props.prescriptions) &&
            (this.props.medicationUse == null || ArrayUtil.isEmpty(this.props.medicationUse.items))) {
            return <div className="pl-3">
                {this.props.medicationUse?.none ? "No Medication" : "No information available"}
            </div>;
        }

        let prescriptionItems = [];
        if (!ArrayUtil.isEmpty(this.props.prescriptions)) {
            this.props.prescriptions.forEach(p => {
                p.prescriptionItems.forEach(pi => {
                    pi.staff = p.staff;
                    prescriptionItems.push(pi);
                });
            });
        }

        return(
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>Prescribing Doctor</th>
                    <th>Condition</th>
                    <th>Dose</th>
                    <th>Unit</th>
                    <th>Frequency</th>
                    <th>Comment</th>
                </tr>
                {this.props.medicationUse.items.map((m, index) => {
                    return (<tr key={"medicationUseView" + index} style={{backgroundColor: "white"}}>
                        <td>{m.type == null ? "" : MedicationTypeEnum[m.type].name}</td>
                        <td>{m.name}</td>
                        <td>{m.startDate == null ? "" : DateUtil.formatDateMonthAndYear(m.startDate)}</td>
                        <td>{m.prescribingDoctor}</td>
                        <td>{m.condition}</td>
                        <td>{m.dose}</td>
                        <td>{m.unit == null ? "" : UnitEnum[m.unit]?.name}</td>
                        <td>{m.frequency == null ? "" : FrequencyEnum[m.frequency].name}</td>
                        <td>{m.comment}</td>
                    </tr>)
                })}
                {prescriptionItems.map((pi, index) => {
                    return (<tr key={"prescriptionItem" + index} style={{backgroundColor: "#E0E0E0"}}>
                        <td>-</td>
                        <td>{pi.brand}</td>
                        <td>{DateUtil.formatDateMonthAndYear(pi.startDate)}</td>
                        <td>{pi.staff.name}</td>
                        <td>-</td>
                        <td>{pi.strength} {pi.form}</td>
                        <td>{pi.uom ? UOMEnum[pi.uom].name : null}</td>
                        <td>{pi.frequency && pi.frequency.value && pi.frequency.unit ?
                            pi.frequency.value + " per " + PrescriptionTimeUnitEnum[pi.frequency.unit].name : null}
                        </td>
                        <td>{pi.instruction}</td>
                    </tr>)
                })}

                </tbody>
            </table>


        );
    }
}

function mapStateToProps(state) {
    return {
        prescriptions: state.base.prescriptions
    };
}

export default connect(mapStateToProps)(MedicationUseViewHistory);