import React, {Component} from 'react';
import LogoDubai from "../../../layout/LogoDubai";
import ConsentFormPartsDxb from "../utils/ConsentFormPartsDxb";
import PatientUtil from "../../../utils/PatientUtil";
import InformedConsentFormDxbTextEnum from "./enum/InformedConsentFormDxbTextEnum";

class InformedConsentHeader extends Component {
    render() {
        const {mrnNo, patient, selectedLanguage, Logo} = this.props;

        return (
            <>
                <div className="d-flex justify-content-between align-items-center mt-3 w-100">
                    <Logo/>
                    <ConsentFormPartsDxb.Title selectedLanguage={selectedLanguage}/>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-3">
                    <p className="mb-0"><b>{InformedConsentFormDxbTextEnum.Date_Of_Admission[selectedLanguage]}: </b> -</p>  {/*TODO: Date of Admission will be implemented after signature component*/}
                    <p className="mb-0"><b>{InformedConsentFormDxbTextEnum.MRN[selectedLanguage]}:</b> {mrnNo}</p>
                    <p className="mb-0"><b>{InformedConsentFormDxbTextEnum.ID_No[selectedLanguage]}:</b> {PatientUtil.getPatientId(patient)}</p>
                    <p className="mb-0"><b>{InformedConsentFormDxbTextEnum.Date_Of_Birth[selectedLanguage]}:</b> {patient.birthday}</p>
                </div>

                <hr className="header-line mt-0"/>
            </>
        );
    }
}

export default InformedConsentHeader;