import React, {Component} from "react";
import '../../EMR.css';
import MobilizationEnum from "../enums/treatmentrecieved/MobilizationEnum";
import ManipulationEnum from "../enums/treatmentrecieved/ManipulationEnum";
import ThermalTherapyEnum from "../enums/treatmentrecieved/ThermalTherapyEnum";
import ProceduresEnum from "../enums/treatmentrecieved/ProceduresEnum";
import TherapeuticExerciseEnum from "../enums/treatmentrecieved/TherapeuticExerciseEnum";
import TreatmentInfoEnum from "../modals/TreatmentInfoEnum";
import TreatmentInfoUtils from "../utils/TreatmentInfoUtils";
import DiagnosisGroup from "./DiagnosisGroup";

export default class TreatmentInfoView extends Component {

    state = {}

    render() {

        return (
            <div>
                {this.treatmentInfoTypeAView("Manipulation", "manipulation", ManipulationEnum)}
                {this.treatmentInfoTypeAView("Mobilization", "mobilization", MobilizationEnum)}
                {this.treatmentInfoTypeBView("Stretching", "stretching")}
                {this.treatmentInfoTypeBView("Dry Needling", "dryNeedling")}
                {this.treatmentInfoTypeBView("Acupuncture", "acupuncture")}
                {this.treatmentInfoTypeBView("Electrotherapy", "electrotherapy")}
                {this.treatmentInfoTypeBView("Shockwave", "shockwave", this.props.type === TreatmentInfoEnum.TREATMENT_RECEIVED)}
                {this.treatmentInfoTypeBView("Cupping", "cupping")}
                {this.treatmentInfoTypeBView("Taping", "taping")}
                {this.treatmentInfoTypeBView("Therapeutic Massage", "therapeuticMassage")}
                {this.treatmentInfoTypeBView("Lymphatic Drainage", "lymphaticDrainage")}
                {this.treatmentInfoTypeAView("Thermal Therapy", "thermalTherapy", ThermalTherapyEnum)}
                {this.treatmentInfoTypeCView("Therapeutic Exercise", "therapeuticExercise", TherapeuticExerciseEnum)}
                {this.treatmentInfoTypeBView("Gait Training", "gaitTraining")}
                {this.treatmentInfoTypeBView("Breathing Patterns", "breathingPatterns")}
                {this.props.type == TreatmentInfoEnum.TREATMENT_RECEIVED && this.treatmentReceivedProcedureView("Procedures", "procedures", ProceduresEnum)}
                {this.props.type == TreatmentInfoEnum.TREATMENT_PLAN && this.treatmentPlanProcedureView("Procedures", "procedures", ProceduresEnum)}
                {this.treatmentInfoTypeBView("Other", "other")}
            </div>
        );
    }

    treatmentInfoTypeAView = (title, field, dropdownEnum) => {
        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length == 0) {
            return;
        }

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-4 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-8"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length != 1 &&
                            index != this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Type</div>
                                        <div className={"col-1"}>:</div>
                                        {this.props.type == TreatmentInfoEnum.TREATMENT_PLAN && field == "procedures" ?
                                            <div className={"col-8"}>{item.type}</div> :
                                            <div className={"col-8"}>{dropdownEnum[item.type].name}</div>}
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    {(item.details != null && item.details.length != 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    treatmentInfoTypeBView = (title, field, patientConsent = false) => {
        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length == 0)
            return;

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-4 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-8"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length != 1 &&
                            index != this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    {(item.details != null && item.details.length != 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                    {patientConsent &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Patient Consent</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.patientConsent ? "Yes" : "No"}</div>
                                    </div>}

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    treatmentInfoTypeCView = (title, field, dropdownEnum) => {
        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length == 0)
            return;

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-4 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-8"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length != 1 &&
                            index != this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div style={{...paddingBetweenItems}}>
                                    {item.focus != null &&
                                        <div className={"row no-margin"}>
                                            <div className={"col-3 font-weight-bold"}>Focus</div>
                                            <div className={"col-1"}>:</div>
                                            <div className={"col-8"}>{dropdownEnum[item.focus].name}</div>
                                        </div>
                                    }
                                    {item.exercise != null &&<div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Exercise</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.exercise}</div>
                                    </div>}
                                    {item.weight != null &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Weight</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.weight}</div>
                                    </div>}
                                    {item.sets != null &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Sets</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.sets}</div>
                                    </div>}
                                    {item.reps != null &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Reps</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.reps}</div>
                                    </div>}
                                    {(item.details != null && item.details.length != 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    treatmentReceivedProcedureView = (title, field, dropdownEnum) => {
        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length == 0) {
            return;
        }

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-4 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-8"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length != 1 &&
                            index != this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Type</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{dropdownEnum[item.type].name}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        {TreatmentInfoUtils.getTreatmentReceivedProcedureList(item)}
                                    </div>
                                    {(item.details != null && item.details.length != 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    treatmentPlanProcedureView = (title, field, dropdownEnum) => {
        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length == 0) {
            return;
        }

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-4 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-8"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length != 1 &&
                            index != this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Diagnosis</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}><DiagnosisGroup diagnosisGroup={item.diagnosisGroup} showSecondaryItems/></div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Type</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{dropdownEnum[item.type] == null ? item.type : dropdownEnum[item.type].name}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    {(item.details != null && item.details.length != 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                    {item.noOfSessions != null && <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Number of Sessions</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.noOfSessions}</div>
                                    </div>}
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Approval Required</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.approvalRequired ? "Yes" : "No"}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}