import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {RadioInput, TextInput} from "../../../components";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";
import FamilySelect from "../FamilySelect";

class IntakeFormHealthHistory extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange, onFieldGroupChanged, formFields,
            markAllSelf, markAllFamily,
            cancerSelf, cancerFamily, cancerFamilyWho,
            diabetesSelf, diabetesFamily, diabetesFamilyWho,
            highBloodPressureSelf, highBloodPressureFamily, highBloodPressureFamilyWho,
            heartDiseaseSelf, heartDiseaseFamily, heartDiseaseFamilyWho,
            osteoporosisSelf, osteoporosisFamily, osteoporosisFamilyWho,
            osteoarthritisSelf, osteoarthritisFamily, osteoarthritisFamilyWho,
            rheumatoidArthritisSelf, rheumatoidArthritisFamily, rheumatoidArthritisFamilyWho,
            neurologicDiseaseSelf, neurologicDiseaseFamily, neurologicDiseaseFamilyWho,
            ulcersGerdRefluxSelf, ulcersGerdRefluxFamily, ulcersGerdRefluxFamilyWho,
            kidneyLiverDiseaseSelf, kidneyLiverDiseaseFamily, kidneyLiverDiseaseFamilyWho,
            sufferFromOtherSelf, sufferFromOtherFamily
        } = this.props

        return (
            <>
                <h3 className="text-center mb-3">
                    <b>{IntakeFormTextEnum.Health_History[selectedLanguage]}</b>
                </h3>
                <div className="container dim14-form-row-align">
                    <div className="row">
                        <div className="col-12 dim14-form-label">
                            <p>
                                <b>
                                    <u>
                                        {IntakeFormTextEnum.You_or_Family_Suffer[selectedLanguage]}
                                    </u>
                                </b>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5"></div>
                        <div className="col-1 text-right">
                            <p>
                                <b>
                                    <u> {IntakeFormTextEnum.Self[selectedLanguage]} </u>
                                </b>
                            </p>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-1 text-right">
                            <p>
                                <b>
                                    <u> {IntakeFormTextEnum.Family[selectedLanguage]} </u>
                                </b>
                            </p>
                        </div>
                    </div>

                    {!readOnly ? (
                        <div className="row">
                            <div className="col-4"></div>
                            <div className="col-1 dim14-form-label text-right">
                                {IntakeFormTextEnum.Mark_All[selectedLanguage]}
                            </div>
                            <div className="col-2">
                                <RadioInput
                                    id="markAllSelfRadioInput"
                                    value={markAllSelf}
                                    disabled={readOnly}
                                    options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                    valueProperty={"key"}
                                    onChange={(value) => {
                                        onFieldGroupChanged({
                                            cancerSelf: value,
                                            diabetesSelf: value,
                                            highBloodPressureSelf: value,
                                            heartDiseaseSelf: value,
                                            osteoporosisSelf: value,
                                            osteoarthritisSelf: value,
                                            rheumatoidArthritisSelf: value,
                                            neurologicDiseaseSelf: value,
                                            ulcersGerdRefluxSelf: value,
                                            kidneyLiverDiseaseSelf: value,
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-2">
                                <RadioInput
                                    id="markAllFamilyRadioInput"
                                    value={markAllFamily}
                                    disabled={readOnly}
                                    options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                    valueProperty={"key"}
                                    onChange={(value) => {
                                        onFieldGroupChanged({
                                            cancerFamily: value,
                                            diabetesFamily: value,
                                            highBloodPressureFamily: value,
                                            heartDiseaseFamily: value,
                                            osteoporosisFamily: value,
                                            osteoarthritisFamily: value,
                                            rheumatoidArthritisFamily: value,
                                            neurologicDiseaseFamily: value,
                                            ulcersGerdRefluxFamily: value,
                                            kidneyLiverDiseaseFamily: value,
                                        })

                                        if (value === "No") {
                                            onFieldGroupChanged({
                                                cancerFamilyWho: null,
                                                diabetesFamilyWho: null,
                                                highBloodPressureFamilyWho: null,
                                                heartDiseaseFamilyWho: null,
                                                osteoporosisFamilyWho: null,
                                                osteoarthritisFamilyWho: null,
                                                rheumatoidArthritisFamilyWho: null,
                                                neurologicDiseaseFamilyWho: null,
                                                ulcersGerdRefluxFamilyWho: null,
                                                kidneyLiverDiseaseFamilyWho: null,
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}

                    <div className="row">
                        <div
                            className="col-5 dim14-form-label">{IntakeFormTextEnum.Cancer[selectedLanguage]}</div>
                        <div className="col-2">
                            <RadioInput
                                id="cancerSelfRadioInput"
                                value={cancerSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => onChange("cancerSelf", value)}
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="cancerFamilyRadioInput"
                                value={cancerFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("cancerFamily", value)
                                    if (value === "No") {
                                        onChange("cancerFamilyWho", null);
                                    }
                                }}
                            />
                            {cancerFamily === "Yes" &&
                            <FamilySelect value={cancerFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("cancerFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div
                            className="col-5 dim14-form-label">{IntakeFormTextEnum.Diabetes[selectedLanguage]}</div>
                        <div className="col-2">
                            <RadioInput
                                id="diabetesSelfRadioInput"
                                value={diabetesSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("diabetesSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="diabetesFamilyRadioInput"
                                value={diabetesFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("diabetesFamily", value)
                                    if (value === "No") {
                                        onChange("diabetesFamilyWho", null);
                                    }
                                }}
                            />
                            {diabetesFamily === "Yes" &&
                            <FamilySelect value={diabetesFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("diabetesFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 dim14-form-label">
                            {IntakeFormTextEnum.High_Blood_Pressure[selectedLanguage]}
                        </div>
                        <div className="col-2">
                            <RadioInput
                                id="highBloodPressureSelfRadioInput"
                                value={highBloodPressureSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("highBloodPressureSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="highBloodPressureFamilyRadioInput"
                                value={highBloodPressureFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("highBloodPressureFamily", value)
                                    if (value === "No") {
                                        onChange("highBloodPressureFamilyWho", null);
                                    }
                                }}
                            />
                            {highBloodPressureFamily === "Yes" &&
                            <FamilySelect value={highBloodPressureFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("highBloodPressureFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div
                            className="col-5 dim14-form-label">{IntakeFormTextEnum.Heart_Disease[selectedLanguage]}</div>
                        <div className="col-2">
                            <RadioInput
                                id="heartDiseaseSelfRadioInput"
                                value={heartDiseaseSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("heartDiseaseSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="heartDiseaseFamilyRadioInput"
                                value={heartDiseaseFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("heartDiseaseFamily", value)
                                    if (value === "No") {
                                        onChange("heartDiseaseFamilyWho", null);
                                    }
                                }}
                            />
                            {heartDiseaseFamily === "Yes" &&
                            <FamilySelect value={heartDiseaseFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("heartDiseaseFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div
                            className="col-5 dim14-form-label">{IntakeFormTextEnum.Osteoporosis[selectedLanguage]}</div>
                        <div className="col-2">
                            <RadioInput
                                id="osteoporosisSelfRadioInput"
                                value={osteoporosisSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("osteoporosisSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="osteoporosisFamilyRadioInput"
                                value={osteoporosisFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("osteoporosisFamily", value)
                                    if (value === "No") {
                                        onChange("osteoporosisFamilyWho", null);
                                    }
                                }}
                            />
                            {osteoporosisFamily === "Yes" &&
                            <FamilySelect value={osteoporosisFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("osteoporosisFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div
                            className="col-5 dim14-form-label">{IntakeFormTextEnum.Osteoarthritis[selectedLanguage]}</div>
                        <div className="col-2">
                            <RadioInput
                                id="osteoarthritisSelfRadioInput"
                                value={osteoarthritisSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("osteoarthritisSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="osteoarthritisFamilyRadioInput"
                                value={osteoarthritisFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("osteoarthritisFamily", value)
                                    if (value === "No") {
                                        onChange("osteoarthritisFamilyWho", null);
                                    }
                                }}
                            />
                            {osteoarthritisFamily === "Yes" &&
                            <FamilySelect value={osteoarthritisFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("osteoarthritisFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 dim14-form-label">
                            {IntakeFormTextEnum.Rheumatoid_Arthritis[selectedLanguage]}
                        </div>
                        <div className="col-2">
                            <RadioInput
                                id="rheumatoidArthritisSelfRadioInput"
                                value={rheumatoidArthritisSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("rheumatoidArthritisSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="rheumatoidArthritisFamilyRadioInput"
                                value={rheumatoidArthritisFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("rheumatoidArthritisFamily", value)
                                    if (value === "No") {
                                        onChange("rheumatoidArthritisFamilyWho", null);
                                    }
                                }}
                            />
                            {rheumatoidArthritisFamily === "Yes" &&
                            <FamilySelect value={rheumatoidArthritisFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("rheumatoidArthritisFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 dim14-form-label">
                            {IntakeFormTextEnum.Neurologic_Disease[selectedLanguage]}
                        </div>
                        <div className="col-2">
                            <RadioInput
                                id="neurologicDiseaseSelfRadioInput"
                                value={neurologicDiseaseSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("neurologicDiseaseSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="neurologicDiseaseFamilyRadioInput"
                                value={neurologicDiseaseFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("neurologicDiseaseFamily", value)
                                    if (value === "No") {
                                        onChange("neurologicDiseaseFamilyWho", null);
                                    }
                                }}
                            />
                            {neurologicDiseaseFamily === "Yes" &&
                            <FamilySelect value={neurologicDiseaseFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("neurologicDiseaseFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 dim14-form-label">
                            {IntakeFormTextEnum.Ulcers_GERD_Acid_Reflux[selectedLanguage]}
                        </div>
                        <div className="col-2">
                            <RadioInput
                                id="ulcersGerdRefluxSelfRadioInput"
                                value={ulcersGerdRefluxSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("ulcersGerdRefluxSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="ulcersGerdRefluxFamilyRadioInput"
                                value={ulcersGerdRefluxFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("ulcersGerdRefluxFamily", value)
                                    if (value === "No") {
                                        onChange("ulcersGerdRefluxFamilyWho", null);
                                    }
                                }}
                            />
                            {ulcersGerdRefluxFamily === "Yes" &&
                            <FamilySelect value={ulcersGerdRefluxFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("ulcersGerdRefluxFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5 dim14-form-label">
                            {IntakeFormTextEnum.Kidney_Liver_Disease[selectedLanguage]}
                        </div>
                        <div className="col-2">
                            <RadioInput
                                id="kidneyLiverDiseaseSelfRadioInput"
                                value={kidneyLiverDiseaseSelf}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("kidneyLiverDiseaseSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <RadioInput
                                id="kidneyLiverDiseaseFamilyRadioInput"
                                value={kidneyLiverDiseaseFamily}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onChange("kidneyLiverDiseaseFamily", value)
                                    if (value === "No") {
                                        onChange("kidneyLiverDiseaseFamilyWho", null);
                                    }
                                }}
                            />
                            {kidneyLiverDiseaseFamily === "Yes" &&
                            <FamilySelect value={kidneyLiverDiseaseFamilyWho}
                                          style={{width: 150, height: 32}}
                                          disabled={readOnly}
                                          enumLabel={enumLabel}
                                          onChange={(value) =>
                                              onChange("kidneyLiverDiseaseFamilyWho", value)}/>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div
                            className="col-5 dim14-form-label">{IntakeFormTextEnum.Other[selectedLanguage]}</div>
                        <div className="col-2">
                            <TextInput
                                value={sufferFromOtherSelf}
                                disabled={readOnly}
                                style={{maxWidth: "120px"}}
                                onChange={(value) =>
                                    onChange("sufferFromOtherSelf", value)
                                }
                            />
                        </div>
                        <div className="col-4 d-flex">
                            <p style={{marginTop: "5px", marginRight: "10px"}}>
                                {IntakeFormTextEnum.If_Family_Who[selectedLanguage]}
                            </p>
                            <div style={{width: "150px", height: '40px'}}>
                                <FamilySelect value={sufferFromOtherFamily}
                                              disabled={readOnly}
                                              enumLabel={enumLabel}
                                              onChange={(value) =>
                                                  onChange("sufferFromOtherFamily", value)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default IntakeFormHealthHistory;