import {MultipleHeaderAccordion} from "../../../../../../components/accordion/MultipleHeaderAccordion";
import React from "react";
import TreatmentResultHistory from "./TreatmentResultHistory";
import FallRiskManagementView from "../../../treatment/views/FallRiskManagementView";
import ReferralHistory from "./ReferralHistory";
import RiskOfHarmHistory from "./RiskOfHarmHistory";
import TreatmentInfoHistoryView from "./TreatmentInfoHistoryView";
import PatientEducationHistoryView from "./PatientEducationHistoryView";
import DischargePlanHistoryView from "./DischargePlanHistoryView";
import TreatmentScheduleHistoryView from "./TreatmentScheduleHistoryView";
import TreatmentPlanComplianceHistoryView from "./TreatmentPlanComplianceHistoryView";
import TreatmentResponseHistoryView from "./TreatmentResponseHistoryView";
import TreatmentGoalsHistoryView from "./TreatmentGoalsHistoryView";
import GrayPageTitle from "../../GrayPageTitle";
import TreatmentInfoEnum from "../../../treatment/modals/TreatmentInfoEnum";

export class TreatmentHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render = () => {
        const visit = this.props.visit;
        const treatment = visit?.treatment || {};
        // const isLegacy = false;

        return <>
            <MultipleHeaderAccordion
                headerText={{firstHeaderText: "Treatment"}}
                headerExist={{secondHeader: false, thirdHeader: false}}
                defaultExpanded={false}>
                {visit ?
                    <>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Treatment Received"/>
                                    <TreatmentInfoHistoryView type={TreatmentInfoEnum.TREATMENT_RECEIVED} treatmentInfo={treatment.treatmentReceived}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Response To Treatment"/>
                                    <TreatmentResponseHistoryView response={treatment.response}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Patient Education"/>
                                    <PatientEducationHistoryView patientEducation={treatment.patientEducation}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Fall Risk Management"/>
                                    <FallRiskManagementView fallRiskManagement={treatment.fallRiskManagement}/>
                                </div>
                            </div>
                        </div>
                    </> : <div className="font-italic">No visit selected!</div>
                }

            </MultipleHeaderAccordion>
            <MultipleHeaderAccordion
                headerText={{firstHeaderText: "Treatment Plan"}}
                headerExist={{secondHeader: false, thirdHeader: false}}
                defaultExpanded={false}>

                {visit ?
                    <>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Treatment Goals"/>
                                    <TreatmentGoalsHistoryView goal={treatment.goal}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Treatment Plan"/>
                                    <TreatmentInfoHistoryView type={TreatmentInfoEnum.TREATMENT_RECEIVED} treatmentInfo={treatment.treatmentPlan}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <GrayPageTitle title="Treatment Schedule"/>
                                    <TreatmentScheduleHistoryView treatmentSchedule={treatment.treatmentSchedule}/>
                                </div>

                                <div className="col-6">
                                    <GrayPageTitle title="Compliance with Treatment Plan"/>
                                    <TreatmentPlanComplianceHistoryView
                                        treatmentPlanCompliance={treatment.treatmentPlanCompliance}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Risk of Harm"/>
                                    <RiskOfHarmHistory risk={treatment.riskOfHarmFromTreatment}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Referrals"/>
                                    <ReferralHistory referral={treatment.referral}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Results"/>
                                    <TreatmentResultHistory results={treatment.results}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Discharge Plan"/>
                                    <DischargePlanHistoryView dischargePlan={treatment.dischargePlan}/>
                                </div>
                            </div>
                        </div>
                    </> : <div className="font-italic">No visit selected!</div>
                }
            </MultipleHeaderAccordion>
        </>
    }
}
