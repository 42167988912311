import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import FormField from "../../../components/form/FormField";
import {PhoneInput, TextInput} from "../../../components";
import {Dropdown} from "primereact/dropdown";
import EnumRelationshipType from "../../patient/patientdetail/personal/emergencycontact/enums/EnumRelationshipType";

class IntakeFormEmergencyContactInformation extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange,
            emergencyContactName, emergencyContactMobilePhone, emergencyContactRelationshipType, pushFormFieldRef
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <h3 className="text-center my-3">
                    <b>{IntakeFormTextEnum.Emergency_Contact_Information[selectedLanguage]}</b>
                </h3>
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Emergency_Contact_Name[selectedLanguage]}:
                    </div>
                    <div className="col-8">
                        <FormField
                            ref={(ref) => pushFormFieldRef(ref)}
                            required
                            validateOn={readOnly ? true : emergencyContactName}
                        >
                            <TextInput
                                value={emergencyContactName}
                                alphabeticOnly
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("emergencyContactName", value)
                                }
                            />
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Emergency_Contact_Mobile_Number[selectedLanguage]}:
                    </div>
                    <div className="col-8">
                        <FormField
                            ref={(ref) => pushFormFieldRef(ref)}
                            required
                            validateOn={readOnly ? true : emergencyContactMobilePhone}
                        >
                            <PhoneInput
                                inputClass="w-100"
                                containerClass={
                                    readOnly ? "PhoneInputDisabledBackground" : ""
                                }
                                value={emergencyContactMobilePhone}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("emergencyContactMobilePhone", value)
                                }
                            />
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Emergency_Contact_Relationship[selectedLanguage]}:
                    </div>
                    <div className="col-8">
                        <Dropdown
                            className={`DropdownBorderColor w-100 ${
                                readOnly
                                    ? "DropdownDisabledBackground"
                                    : "DropdownBorderColor"
                            }`}
                            value={emergencyContactRelationshipType}
                            disabled={readOnly}
                            options={EnumRelationshipType.toArray()
                                .map(r => {
                                    return {label: r[enumLabel], value: r.key}
                                })
                            }
                            displayProperty="label"
                            valueProperty="value"
                            searchEnabled={false}
                            onChange={(event) =>
                                onChange(
                                    "emergencyContactRelationshipType",
                                    event.value
                                )
                            }
                        />
                    </div>
                </div>
            </div>

        );
    }
}

export default IntakeFormEmergencyContactInformation;