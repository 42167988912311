import React from 'react';
import {connect} from "react-redux";
import {getParameters, getSchedules} from "../services/actions";
import AuthService from "../services/auth-service/AuthService";

class LoginData extends React.Component {

    componentDidMount() {
        this.appInit();
    }

    appInit = () => {
        // can retrieve and cache application wide data that requires login here
        this.props.dispatch(getSchedules());
        this.props.dispatch(getParameters());
        this.props.loginTrigger(AuthService.getUser().email);
    };

    render() {
        return (
            <>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(LoginData);
