import React from 'react';
import {Chart} from 'primereact/chart';
import EnumAppointmentType from "../../../appointment/enums/EnumAppointmentType";

export class StatusGraph extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const options = {
            legend : {
                display: false
            },
            animation: {
                duration: 0
            },
            aspectRatio: 1.75
        }

        let labels = [];
        let dataset = [];
        let colors = [];
        let totalPatient = 0;
        for (let pair of this.props.patientTypeMap) {
            let [key, value] = pair;
            labels.push(EnumAppointmentType[key].name);
            dataset.push(value);
            totalPatient += value;
            colors.push(EnumAppointmentType[key].bgColor);
        }

        let data = {
                labels : labels,
                datasets: [{
                    data: dataset,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors
                }]
        };

        return (
            <div className={"outer"}>
                <div style={{height: "100px"}}>
                    <div className="inner">
                        <Chart type="doughnut" data={data} options={options}/>
                    </div>
                    <div className={"inner"} style={{fontWeight: "bold"}}>{totalPatient}</div>
                </div>

                <div style={{height: "30px"}}>
                    <div>
                        <div className="text-center">
                            <p className="font-weight-bold m-0">Number of appointments</p>
                            <p className="font-weight-lighter m-0">TOTAL</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}