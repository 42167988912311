import React, {Component} from 'react'
import {Wizard} from "../../../components";
import "./PublicForm.css"
import Logo from "./Logo";
import DateUtil from "../../../utils/DateUtil";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import ConsentFormPartsDxb from "../utils/ConsentFormPartsDxb";
import FormLanguages from "../enum/FormLanguages";
import InformedConsentSignatureInputs from "../informedconsent/InformedConsentSignatureInputs";
import InformedConsentFormDxbTextEnum from "../informedconsent/enum/InformedConsentFormDxbTextEnum";
import IntakeFormTextEnum from "../intakeform/enum/IntakeFormTextEnum";

export default class PublicInformedConsentFormDxb extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    createHeader = (form, formBodyClass) => {
        const {language} = this.props;

        return <div className={formBodyClass} style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    {InformedConsentFormDxbTextEnum.Informed_Consent_Title[language]}
                </div>
            </div>
            <div className="row justify-content-between" style={{fontSize: 11}}>
                <div>
                    <b>{InformedConsentFormDxbTextEnum.Date_Of_Admission[language]}: </b> {DateUtil.formatDateMMM(form.consentDate)}
                </div>
                <div>
                    <b>{InformedConsentFormDxbTextEnum.MRN[language]}: </b> {form.patientMrn}
                </div>
                <div>
                    <b>{InformedConsentFormDxbTextEnum.ID_No[language]}: </b> {form.patientEId}
                </div>
                <div>
                    <b>{InformedConsentFormDxbTextEnum.Date_Of_Birth[language]}: </b> {DateUtil.formatDateMMM(form.patientDOB)}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    onSavePatientSignature = (signature) => {
        this.props.form.patientSignature = signature;
        this.forceUpdate();
    }

    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/informed-consent-form-dxb`, form);
            this.props.onFormSubmit(form);
        }
    };

    pushFormFieldRef = (ref) => this.formFields.push(ref);

    render() {
        this.formFields = [];
        const {form, language} = this.props;
        const formBodyClass = language == FormLanguages.ARABIC.name ? "arabic-form" : "";
        const enumLabel = language == FormLanguages.ARABIC.name ? "nameInArabic" : "name";
        const commonProps = {
            selectedLanguage: language,
            pushFormFieldRef: this.pushFormFieldRef
        }

        return (
            <>
                <Wizard
                    show={true} fullScreen
                    footerStyle={{backgroundColor: '#f5f5f5'}}
                    buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}

                    previousButtonStyle={{
                        backgroundColor: "#fff",
                        color: "rgb(132, 190, 86)",
                        borderColor: "rgb(132, 190, 86)"
                    }}
                    header={this.createHeader(form, formBodyClass)}
                    close={this.close}
                    onSubmit={this.submitForm}
                    previousBtnText={InformedConsentFormDxbTextEnum.Wizard_Previous[language]}
                    nextBtnText={InformedConsentFormDxbTextEnum.Wizard_Next[language]}
                    saveBtnText={InformedConsentFormDxbTextEnum.Wizard_Save[language]}
                >

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                        <div className={formBodyClass + ' m-4'}>
                            <ConsentFormPartsDxb.Introduction {...commonProps}/>
                            <ConsentFormPartsDxb.Benefits {...commonProps}/>

                            <ConsentFormPartsDxb.RisksIntro {...commonProps}/>
                            <ul className="list-unstyled pl-0">
                                <ConsentFormPartsDxb.RisksItem1 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem2 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem3 {...commonProps}/>
                            </ul>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                        onPrevious={() => (this.formFields = [])}>
                        <div className={formBodyClass + ' m-4'}>
                            <ul className="list-unstyled pl-0">
                                <ConsentFormPartsDxb.RisksItem4 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem5 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem6 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem7 {...commonProps}/>
                            </ul>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                        onPrevious={() => (this.formFields = [])}>
                        <div className={formBodyClass + ' m-4'}>
                            <ConsentFormPartsDxb.Alternatives {...commonProps}/>
                            <ConsentFormPartsDxb.InjectionAndMedication {...commonProps}/>
                            <ConsentFormPartsDxb.DataCollectionAndPrivacy {...commonProps}/>
                            <ConsentFormPartsDxb.QuestionsOfConcerns {...commonProps}/>
                            <ConsentFormPartsDxb.Complaints {...commonProps}/>
                            <ConsentFormPartsDxb.ConsentTable {...commonProps}
                                                              formFields={this.formFields}
                                                              readOnly={false}
                                                              traineeConsent={form.traineeConsent}
                                                              onChange={this.fieldChanged}
                                                              enumLabel={enumLabel}/>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page onPrevious={() => this.formFields = []}>
                        <div className={formBodyClass + ' m-4'}>
                            <div>
                                <ConsentFormPartsDxb.Conclusion {...commonProps}/>

                                <InformedConsentSignatureInputs {...commonProps}
                                                                readOnly={false}
                                                                formFields={this.formFields}
                                                                textEnum={InformedConsentFormDxbTextEnum}
                                                                consentDate={DateUtil.formatDateDo(form.consentDate)}
                                                                patientName={form.patientFullName}
                                                                providerName={form.providerName}
                                                                guardianName={form.guardianName}
                                                                guardianRelationship={form.guardianRelationship}
                                                                patientSignature={form.patientSignature}
                                                                providerSignature={form.providerSignature}
                                                                onSavePatientSignature={this.onSavePatientSignature}
                                                                onChange={this.fieldChanged}/>
                            </div>
                        </div>
                    </Wizard.Page>
                </Wizard>
            </>
        );
    }
}
