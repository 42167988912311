import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {RadioInput} from "../../../components";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";

class IntakeFormHaveYouExperienced extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange, onFieldGroupChanged, formFields,
            experienceMarkAll, changeInGeneralHealth, haveFeverChillsNightSweats, unexplainedWeightChange,
            numbnessOrTingling, bowelIncontinence, difficultySleeping, unexplainedFalls
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <div className="row">
                    <div className="col-12 my-2">
                        <b>
                            <u>
                                {IntakeFormTextEnum.Have_You_Had_Or_Do_You_Experience[selectedLanguage]}
                            </u>
                        </b>
                    </div>
                </div>

                {!readOnly ? (
                    <div className="row">
                        <div className="col-5"></div>
                        <div
                            className="col-1 dim14-form-label">{IntakeFormTextEnum.Mark_All[selectedLanguage]}</div>
                        <div className="col-3">
                            <RadioInput
                                id="experienceMarkAllRadioInput"
                                value={experienceMarkAll}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()}
                                displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) => {
                                    onFieldGroupChanged({
                                        changeInGeneralHealth: value,
                                        haveFeverChillsNightSweats: value,
                                        unexplainedWeightChange: value,
                                        numbnessOrTingling: value,
                                        bowelIncontinence: value,
                                        difficultySleeping: value,
                                        unexplainedFalls: value,
                                    })
                                }}
                            />
                        </div>
                        <div className="col-3"></div>
                    </div>
                ) : null}

                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Change_In_Your_General_Health[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="changeInGeneralHealthRadioInput"
                            value={changeInGeneralHealth}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("changeInGeneralHealth", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Fever_Chills_Night_Sweats[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="haveFeverChillsNightSweatsRadioInput"
                            value={haveFeverChillsNightSweats}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("haveFeverChillsNightSweats", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Unexplained_Weight_Change[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="unexplainedWeightChangeRadioInput"
                            value={unexplainedWeightChange}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("unexplainedWeightChange", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Numbness_or_Tingling[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="numbnessOrTinglingRadioInput"
                            value={numbnessOrTingling}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("numbnessOrTingling", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Bowel_Bladder_Incontinence_or_Changes[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="bowelIncontinenceRadioInput"
                            value={bowelIncontinence}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("bowelIncontinence", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Difficulty_Sleeping_Due_to_Pain[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="difficultySleepingRadioInput"
                            value={difficultySleeping}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("difficultySleeping", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Unexplained_Falls_Decreased_Balance[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="unexplainedFallsRadioInput"
                            value={unexplainedFalls}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("unexplainedFalls", value)
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default IntakeFormHaveYouExperienced;