import {AllergyChecker} from "../../../../recordpage/subjective/checkers/AllergyChecker";
import {MedicationUseChecker} from "../../../../recordpage/subjective/checkers/MedicationUseChecker";
import {FamilyHistoryChecker} from "../../../../recordpage/subjective/checkers/FamilyHistoryChecker";
import {SurgicalHistoryChecker} from "../../../../recordpage/subjective/checkers/SurgicalHistoryChecker";
import {MedicalHistoryChecker} from "../../../../recordpage/subjective/checkers/MedicalHistoryChecker";
import {PreviousTreatmentReceivedChecker} from "../../../../recordpage/subjective/checkers/PreviousTreatmentReceivedChecker";
import {LabsImagingChecker} from "../../../../recordpage/subjective/checkers/LabsImagingChecker";
import {ReviewOfSystemChecker} from "../../../../recordpage/subjective/checkers/ReviewOfSystemChecker";
import {ItemsChecker} from "../../../../recordpage/assessment/checkers/ItemsChecker";
import {ClinicalImpressionChecker} from "../../../../recordpage/diagnosis/checkers/ClinicalImpressionChecker";
import {RiskOfHarmChecker} from "../../../../recordpage/treatment/checkers/RiskOfHarmChecker";
import {OtherInjuriesChecker} from "../../../../recordpage/subjective/checkers/OtherInjuriesChecker";
import {TreatmentInfoChecker} from "../../../../recordpage/treatment/checkers/TreatmentInfoChecker";
import {RedFlagChecker} from "../../../../recordpage/subjective/checkers/RedFlagChecker";
import {AggravatingFactorChecker} from "../../../../recordpage/subjective/checkers/AggravatingFactorChecker";
import {RelievingFactorsChecker} from "../../../../recordpage/subjective/checkers/RelievingFactorsChecker";
import {TreatmentScheduleChecker} from "../../../../recordpage/treatment/checkers/TreatmentScheduleChecker";
import {RiskChecker} from "../../../../recordpage/diagnosis/checkers/RiskChecker";
import {DiagnosisChecker} from "../../../../recordpage/diagnosis/checkers/DiagnosisChecker";
import {DiagnosisProcedureChecker} from "../../../../recordpage/diagnosis/checkers/DiagnosisProcedureChecker";
import {NotEmptyChecker} from "../../../../recordpage/common/checkers/NotEmptyChecker";
import {OrChecker} from "../../../../recordpage/common/checkers/OrChecker";

const MissingDataFirstVisit = [
    {path: "subjective.allergy", checker: AllergyChecker},
    {path: "subjective.medicalHistory", checker: MedicalHistoryChecker},
    {path: "subjective.familyHistory", checker: FamilyHistoryChecker},
    {path: "subjective.surgicalHistory", checker: SurgicalHistoryChecker},
    {path: "subjective.medicationUse", checker: MedicationUseChecker, labels: ["Subjective", "Medications"]},
    {path: "subjective.lifestyle.type", checker: NotEmptyChecker},
    {path: "subjective.lifestyle.occupation", checker: NotEmptyChecker},
    {path: "subjective.lifestyle.jobPhysicality", checker: NotEmptyChecker},
    {path: "subjective.lifestyle.physicalActivity", checker: NotEmptyChecker, labels: ["Subjective", "Lifestyle", "Physical Activity / Exercise"]},
    {path: "subjective.lifestyle.nutrition", checker: NotEmptyChecker},
    {path: "subjective.lifestyle.psychoSocialHistory", checker: NotEmptyChecker, labels: ["Subjective", "Lifestyle", "Psycho - Social History"]},
    {path: "subjective.complaint.complaint", checker: NotEmptyChecker},
    {path: "subjective.complaint.description", checker: NotEmptyChecker},
    {path: "subjective.complaint.frequency", checker: NotEmptyChecker},
    {path: "subjective.complaint.etiology", checker: NotEmptyChecker},
    {path: "subjective.complaint", checker: RedFlagChecker, labels: ["Subjective", "Complaint", "Red Flag"]},
    {path: "subjective.complaint", checker: AggravatingFactorChecker, labels: ["Subjective", "Complaint", "Aggravating Factors"]},
    {path: "subjective.complaint", checker: RelievingFactorsChecker, labels: ["Subjective", "Complaint", "Relieving Factors"]},
    {path: "subjective.complaint.pain", checker: NotEmptyChecker},
    {path: "subjective.complaint.nature", checker: NotEmptyChecker},
    {path: "subjective.complaint.onset", checker: NotEmptyChecker},
    {path: "subjective.complaint.durationType", checker: NotEmptyChecker, labels: ["Subjective", "Complaint", "Duration"]},
    {path: "subjective.treatmentReceived", checker: PreviousTreatmentReceivedChecker},
    {path: "subjective.labsImaging", checker: LabsImagingChecker, labels: ["Subjective", "Labs/Imaging"]},
    {path: "subjective.reviewOfSystem", checker: ReviewOfSystemChecker},
    {path: "subjective.otherInjuries", checker: OtherInjuriesChecker},

    {path: "assessment.vitalSigns.temperature", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.pulse", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.bloodPressureAbove", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.bloodPressureBelow", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.height", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.weight", checker: NotEmptyChecker},
    {path: "assessment.observation", checker: ItemsChecker},
    {path: "assessment.posture", checker: ItemsChecker},
    {
        path: "assessment",
        checker: OrChecker([
            {path: "muscleGroup", checker: ItemsChecker},
            {path: "individualMuscle", checker: ItemsChecker}
        ]),
        labels: ["Assessment", "Muscle Strength "]
    },
    {path: "assessment.rangeOfMotion", checker: ItemsChecker},
    {path: "assessment.palpation", checker: ItemsChecker},
    {path: "assessment.dermatomes", checker: ItemsChecker},
    {path: "assessment.myotomes", checker: ItemsChecker},
    {path: "assessment.reflexes", checker: ItemsChecker},
    {path: "assessment.fallRisk.fallingHistory", checker: NotEmptyChecker, labels: ["Assessment", "Fall Risk Status", "History of Falling"]},
    {path: "assessment.fallRisk.secondaryDiagnosis", checker: NotEmptyChecker, labels: ["Assessment", "Fall Risk Status", "Secondary Diagnosis"]},
    {path: "assessment.fallRisk.ambulatoryAid", checker: NotEmptyChecker, labels: ["Assessment", "Fall Risk Status", "Ambulatory Aid"]},
    {path: "assessment.fallRisk.heparinLockIVTherapy", checker: NotEmptyChecker, labels: ["Assessment", "Fall Risk Status", "IV Therapy / Heparin Lock"]},
    {path: "assessment.fallRisk.gait", checker: NotEmptyChecker, labels: ["Assessment", "Fall Risk Status", "Gait"]},
    {path: "assessment.fallRisk.mentalStatus", checker: NotEmptyChecker, labels: ["Assessment", "Fall Risk Status", "Mental Status"]},
    {path: "assessment.mood", checker: NotEmptyChecker, labels: ["Assessment", "Mood and Well-being of the Patient"]},

    {path: "diagnosis.diagnosisGroups", checker: DiagnosisChecker},
    {path: "diagnosis.clinicalImpression", checker: ClinicalImpressionChecker},
    {path: "diagnosis.risk", checker: RiskChecker},
    {
        path: "diagnosis",
        checker: OrChecker([
            {path: "directBillingProcedures", checker: DiagnosisProcedureChecker},
            {path: "selfPayProcedures", checker: DiagnosisProcedureChecker}
        ]),
        labels: ["Diagnosis", "Procedures"]
    },

    {path: "treatment.treatmentReceived", checker: TreatmentInfoChecker},
    {path: "treatment.response.overallImproveValue", checker: NotEmptyChecker, labels: ["Treatment", "Response To Treatment", "Overall Improve By"]},
    {path: "treatment.response.romImproveValue", checker: NotEmptyChecker, labels: ["Treatment", "Response To Treatment", "Rom Improve By"]},
    {path: "treatment.response.painScoreValue", checker: NotEmptyChecker, labels: ["Treatment", "Response To Treatment", "Pain Score"]},
    {path: "treatment.patientEducation", checker: ItemsChecker},
    {path: "treatment.goal.details", checker: NotEmptyChecker},
    {path: "treatment.treatmentPlan", checker: TreatmentInfoChecker},
    {path: "treatment.treatmentSchedule.numberOfRecommendedSessions", checker: NotEmptyChecker, labels: ["Treatment", "Treatment Schedule", "The patient is recommended for"]},
    {path: "treatment.treatmentSchedule.numberOfScheduledSessions", checker: NotEmptyChecker, labels: ["Treatment", "Treatment Schedule", "The patient is scheduled for"]},
    {path: "treatment.treatmentSchedule.nextAppointmentIsIn", checker: NotEmptyChecker, labels: ["Treatment", "Treatment Schedule", "Next appointment is in"]},
    {path: "treatment.treatmentSchedule", checker: TreatmentScheduleChecker, labels: ["Treatment", "Treatment Schedule", "The patient will be reassessed in"]},
    {path: "treatment.riskOfHarmFromTreatment", checker: RiskOfHarmChecker, labels: ["Treatment", "Is There Risk of Harm From Planned Care of Treatment?"]}
];

export default MissingDataFirstVisit;