import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {Image} from "react-bootstrap";
import {FormLine} from "../../public/FormLine";
import FormField from "../../../../components/form/FormField";
import {Dropdown} from "primereact/dropdown";
import IntakeFormGenderEnum from "../enum/IntakeFormGenderEnum";
import {DateInput, TextInput} from "../../../../components";
import DateUtil from "../../../../utils/DateUtil";
import OccupationEnum from "../../../emr/recordpage/subjective/enums/lifestyle/OccupationEnum";
import JobPhysicalityEnum from "../../../emr/recordpage/subjective/enums/lifestyle/JobPhysicalityEnum";
import {YesNoRadioInput} from "../../public/YesNoRadioInput";
import FormAllergiesSection from "../../FormAllergiesSection";

class PublicIntakeFormPatientInformation extends Component {
    render() {
        const {
            selectedLanguage, enumLabel, nationalityLabel, form,
            textField, numberInput, validatedTextField, formFields, onChange, forceFormUpdate,
            photoSrc, nationalities
        } = this.props;

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Patient_Information[selectedLanguage]}/>
                <div className="row text-center">
                    <div className="col-10 col-md-12">
                        <Image
                            className={"rounded-circle"}
                            width={"75px"}
                            src={photoSrc}
                        />
                    </div>
                </div>

                <FormLine label={IntakeFormTextEnum.First_Name[selectedLanguage]}>
                    {validatedTextField(form, "firstName")}
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Middle_Name[selectedLanguage]}>
                    {textField(form, "middleName")}
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Last_Name[selectedLanguage]}>
                    {validatedTextField(form, "lastName")}
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Gender[selectedLanguage]}>
                    <FormField
                        ref={(ref) => formFields.push(ref)}
                        required
                        validateOn={form.gender}
                    >
                        <Dropdown
                            className="form-control"
                            value={form.gender}
                            options={IntakeFormGenderEnum.toArray().map(v => {
                                return {value: v.key, label: v[enumLabel]}
                            })}
                            displayProperty="label"
                            valueProperty="value"
                            onChange={(event) =>
                                onChange("gender", event.value)
                            }
                        />
                    </FormField>
                </FormLine>
                <div className="form-group row">
                    <label class="col-3 col-form-label">
                        {IntakeFormTextEnum.Date_of_Birth[selectedLanguage]}
                    </label>
                    <div className="col">
                        <FormField
                            ref={(ref) => formFields.push(ref)}
                            required
                            validateOn={form.dob}
                        >
                            <DateInput
                                className="date-input"
                                style={{backgroundColor: "white"}}
                                disableFuture="true"
                                value={form.dob ? form.dob : new Date()}
                                onChange={(val) => {
                                    form.dob = val;
                                    form.age = DateUtil.getAgeWithMonths(val);
                                    forceFormUpdate();
                                }}
                            />
                        </FormField>
                    </div>
                    <div className="col-2 text-center">
                        <span class="small">{form.age}</span>
                    </div>
                </div>
                <FormLine label={IntakeFormTextEnum.ID_No[selectedLanguage]}>
                    <TextInput
                        className="form-control"
                        value={form.eid}
                        onChange={(value) => onChange("eid", value)}
                    />
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Nationality[selectedLanguage]}>
                    <FormField
                        ref={(ref) => formFields.push(ref)}
                        required
                        validateOn={form.nationality}
                    >
                        <Dropdown
                            className="DropdownBorderColor"
                            value={form.nationality}
                            dataKey={"code"}
                            options={nationalities}
                            style={{width: "100%"}}
                            optionLabel={nationalityLabel}
                            filter
                            filterLocale={"en-US"}
                            filterBy={nationalityLabel}
                            onChange={(event) =>
                                onChange("nationality", event.value)
                            }
                        />
                    </FormField>
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Occupation[selectedLanguage]}>
                    <Dropdown
                        className="DropdownBorderColor w-100"
                        value={form.occupation}
                        options={OccupationEnum.toArray().map(v => {
                            return {value: v.key, label: v[enumLabel]}
                        })}
                        optionLabel={"label"}
                        onChange={(event) => onChange("occupation", event.value)}
                    />
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Job_Physicality[selectedLanguage]}>
                    <Dropdown
                        className="DropdownBorderColor w-100"
                        value={form.jobPhysicality}
                        options={JobPhysicalityEnum.toArray().map(v => {
                            return {value: v.key, label: v[enumLabel]}
                        })}
                        optionLabel={"label"}
                        onChange={(event) =>
                            onChange("jobPhysicality", event.value)
                        }
                    />
                </FormLine>
                <div className="form-group row">
                    <label class="col-6 col-form-label">
                        {IntakeFormTextEnum.Any_Allergies_or_Sensitivities[selectedLanguage]}
                    </label>
                    <div className="allergy-radio-input text-right col">
                        <FormField
                            ref={(ref) => formFields.push(ref)}
                            required
                            validateOn={form.haveAllergies}
                        >
                            <YesNoRadioInput
                                value={form.haveAllergies}
                                enumLabel={enumLabel}
                                onValueChange={(value) => {
                                    if ("No" === value) {
                                        form.allergy = null;
                                    }
                                    if ("Yes") {
                                        form.allergy = {};
                                    }
                                    onChange("haveAllergies", value);
                                }}
                            />
                        </FormField>
                    </div>
                </div>
                {"Yes" === form.haveAllergies && (
                    <div className="container mb-3">
                        <FormAllergiesSection allergy={form.allergy} readOnly={false}
                                              selectedLanguage={selectedLanguage}/>
                    </div>
                )}

                <FormLine label={IntakeFormTextEnum.Height[selectedLanguage]}>
                    {numberInput(form, "height", {allowDecimal: true, step: '.1', maxFractionDigits: 2})}
                </FormLine>

                <FormLine label={IntakeFormTextEnum.Weight[selectedLanguage]}>
                    {numberInput(form, "weight", {allowDecimal: true, step: '.1', maxFractionDigits: 2})}
                </FormLine>
            </>
        );
    }
}

export default PublicIntakeFormPatientInformation;