import React, {Component} from "react";
import RelationshipEnum from "../emr/recordpage/subjective/enums/history/RelationshipEnum";
import {Dropdown} from "primereact/dropdown";
import "./FamilySelect.css";

export default class FamilySelect extends Component {

    constructor(props) {
        super(props);

        this.state = {value: this.props.value};
    }

    render() {
        const {value} = this.state;
        const {disabled, enumLabel} = this.props;

        return (
            <Dropdown
                className="family-select"
                optionLabel={enumLabel} optionValue="key"
                options={RelationshipEnum.toArray()}
                value={value}
                onChange={(event) => {
                    const value = event.value;
                    this.setState({value});
                    this.props.onChange(value);
                }}
                disabled={disabled}
                style={{width: '100%', ...this.props.style}}
            />
        );
    }

}
