import React, { Component } from 'react';
import {CheckboxInput, DateInput, TextInput, TextareaInput,
 RadioInput, SelectInput, Badge, Button, FileUploader} from '../index';
import {FormBase, FormSection} from '../form';
import ExampleValidation from './ExampleValidation';
import ExampleValidationTypes from './ExampleValidationTypes';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from '../../layout/PageTitle';

const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      Select a date
    </button>
);

export default class Example extends Component {

    state = {
        selectedDate: null,
        textValue : "my text value",
        textareaValue : "my textarea value",
        selectValue: "blue",
        tableData: [
            {
                id: 1,
                color: "red",
                turkishColor: "kirmizi"
            },
            {
                id: 2,
                color: "blue",
                turkishColor: "mavi"
            },
            {
                id: 3,
                color: "green",
                turkishColor: "yesil"
            },
        ]
    }

    handleButtonClick = (e) => {
        console.log("button is clicked");
    };

    render() {
        return (
            <div>
                <PageTitle title="Example Usages Of Components"/>
                <FormBase>
                    <FormSection sectionId={1} label="FormSection1" >
                        FormSection1
                    </FormSection>
                    <FormSection sectionId={2} label="FormSection2" >
                        FormSection2
                    </FormSection>
                    <FormSection sectionId={3} label="FormSection3" >
                        FormSection3
                    </FormSection>
                    <FormSection sectionId={4} label="FormSection4" >
                        FormSection4
                    </FormSection>
                    <FormSection sectionId={5} label="FormSection5" >
                        FormSection5
                    </FormSection>
                    <FormSection sectionId={6} label="FormSection6" >
                        FormSection6
                    </FormSection>
                    <FormSection sectionId={7} label="FormSection7" >
                        FormSection7
                    </FormSection>
                    <FormSection sectionId={8} label="FormSection8" >
                        FormSection8
                    </FormSection>
                    <FormSection sectionId={9} label="FormSection9" >
                        FormSection9
                    </FormSection>
                    <FormSection sectionId={5} label="TextInput" >
                        <TextInput id="exampletextinputid" value={this.state.textValue} onChange={(newValue) => this.setState({textValue: newValue})} />
                    </FormSection>
                    <FormSection sectionId={5} label="TextareaInput" >
                        <TextareaInput id="exampletextareainputid" value={this.state.textareaValue} onChange={(newValue) => this.setState({textareaValue: newValue})} />
                    </FormSection>
                    <FormSection sectionId={5} label="DateInput" >
                        <DateInput id="exampledateinputid"
                              value={this.state.selectedDate}
                              onChange={date => {(this.setState({selectedDate: date}))}}
                            />
                    </FormSection>

                    <FormSection sectionId={5} label="SelectInput" >
                        <SelectInput id="exampleselectinputid" value={this.state.selectValue} options={["red", "blue", "green"]} onChange={(newValue) => this.setState({selectValue: newValue})} />
                    </FormSection>
                    <FormSection sectionId={5} label="RadioInput" >
                        <RadioInput id="exampleradioinputid" value={this.state.radioValue} options={["red", "blue", "green"]} onChange={(newValue) => this.setState({radioValue: newValue})} />
                    </FormSection>
                    <FormSection sectionId={5} label="RadioInput" >
                        <RadioInput orientation="vertical" value={this.state.radioValue} options={["red", "blue", "green"]} onChange={(newValue) => this.setState({radioValue: newValue})} />
                    </FormSection>
                    <FormSection sectionId={5} label="CheckboxInput" >
                        <CheckboxInput id="examplecheckboxinputid" value={this.state.checkboxValue} onChange={(newValue) => this.setState({checkboxValue: newValue})} />
                    </FormSection>
                    <FormSection sectionId={6} label="FileUploader" >
                        <FileUploader id="examplefileuploaderinputid" value={this.state.fileValue} onChange={(newValue) => this.setState({fileValue: newValue})} />
                    </FormSection>
                    <FormSection sectionId={6} label="Badge" >
                        <Badge pill={true} variant="primary">our badge</Badge>
                    </FormSection>
                    <FormSection sectionId={6} label="Button" >
                        <Button id="examplebuttonid" onClick={(e) => this.handleButtonClick(e)} >our button</Button>
                    </FormSection>
                    <FormSection sectionId={6} label="FontAwesomeIcon" >
                            <FontAwesomeIcon icon={["fas", "calendar-alt"]} />
                    </FormSection>
                    <FormSection sectionId={7} label="DataTable" >
                        <DataTable id="exampletableid" value={this.state.tableData} >
                            <Column field="id" header="ID" />
                            <Column field="color" header="Color" />
                            <Column field="turkishColor" header="Renk" />
                        </DataTable>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={4} inputPercent={8} label="labelPercent=4" >
                        inputPercent=8
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="labelPercent=5" >
                        inputPercent=7
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={6} inputPercent={6} label="labelPercent=6" >
                        inputPercent=6
                    </FormSection>

                </FormBase>
                <FormBase>
                    <FormSection sectionId={1} labelHidden={true} >
                        <ExampleValidation />
                        <ExampleValidationTypes />
                    </FormSection>
                </FormBase>
            </div>
        )
    }
}
