import React, {Component} from "react";
import ProceduresEnum from "../../treatment/enums/treatmentrecieved/ProceduresEnum";
import ReportViewUtils from "../view/ReportViewUtils";
import TreatmentInfoUtils from "../../treatment/utils/TreatmentInfoUtils";

export default class ProcedureReportTreatmentView extends Component {

    render() {
        const procedures = this.props.procedures;

        return (
            <div className={"container ml-0"}>
                <div className="row" style={{marginBottom: "15px"}}>
                    <div className="col-12">
                        <b>Treatment Received</b>
                    </div>
                </div>
                <div className="row" style={{marginBottom: "15px"}}>
                    <div className="col-12">
                        The patient received the following treatment
                    </div>
                </div>
                <div className="row" style={{marginBottom: "15px"}}>
                    <div className="col-12">
                        <b>Procedure</b>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        Procedural time out was conducted to verify the correct patient identification, correct
                        procedure, correct side and location, patient position and availability of all specialized
                        equipment that may be required during procedure.
                    </div>
                </div>
                {procedures && procedures.map(procedure => this.getProcedureItem(procedure))}
            </div>
        )
    }

    getProcedureItem(procedure) {
        return (
            <div className={"container px-0 mt-3"}>
                <div className="row">
                    <div className={"col-2"}>
                        <b>Patient Consent</b>
                    </div>
                    <div className={"col-10"}>
                        : Yes. Written consent was obtained after discussion of risks, benefits, alternatives.
                    </div>
                </div>
                {!ReportViewUtils.isProcedureNull(procedure) && <>
                    <div className="row">
                        <div className={"col-2"}>
                            <b>Type</b>
                        </div>
                        <div className={"col-10"}>
                            : {procedure.type ? (ProceduresEnum[procedure.type] ? ProceduresEnum[procedure.type].name : procedure.type) : ""}
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-2"}>
                            <b>Area Of Focus</b>
                        </div>
                        <div className={"col-10"}>
                            : {procedure.areaOfFocus}
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-2"}>
                            <b>Details</b>
                        </div>
                        <div className={"col-10"}>
                            : {procedure.details}
                        </div>
                    </div>
                    <div className="row">
                        {TreatmentInfoUtils.getTreatmentReceivedProcedureList(procedure)}
                    </div>
                </>}
            </div>
        );
    }
}