import React, {Component} from "react";
import '../../EMR.css';
import BorderedRadioInput from "../../../../../components/radio-input/BorderedRadioInput";
import TextInput from "../../../../../components/text-input/TextInput";
import TypeEnum from "../enums/lifestyle/TypeEnum";
import NutritionEnum from "../enums/lifestyle/NutritionEnum";
import AlcoholUseEnum from "../enums/lifestyle/AlcoholUseEnum";
import CurrentPastEnum from "../enums/lifestyle/CurrentPastEnum";
import AlcoholFrequencyEnum from "../enums/lifestyle/AlcoholFrequencyEnum";
import Select from "react-select";
import PhysicalActivityEnum from "../enums/lifestyle/PhysicalActivityEnum";
import OccupationEnum from "../enums/lifestyle/OccupationEnum";
import JobPhysicalityEnum from "../enums/lifestyle/JobPhysicalityEnum";
import BorderedCheckboxInput from "../../../../../components/checkbox-input/BorderedCheckboxInput";
import SleepingPatternEnum from "../enums/lifestyle/SleepingPatternEnum";
import SmokingUseEnum from "../enums/lifestyle/SmokingUseEnum";
import SmokingFrequencyEnum from "../enums/lifestyle/SmokingFrequencyEnum";
import PsychoSocialHistoryEnum from "../enums/lifestyle/PsychoSocialHistoryEnum";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class LifestyleModal extends Component {

    state= {}

    constructor(props) {
        super(props);
        this.state.lifestyle = props.lifestyle;
    }

    render() {
        let valueLabelUntilTwenty = this.generateValueLabelArray(20);
        let valueLabelUntilTen = this.generateValueLabelArray(10);
        return (<div className={"emr-background"}>
                <div className="content-label" style={{marginLeft: "40px"}}>Lifestyle <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                <div className={"emr-modal content-row"} style={{width: "100%"}}>
                    <div className={"content-col-6"}
                         style={{maxWidth: "42%",marginLeft:"0px", marginRight:"40px", marginTop:"0px"}}>
                        <div>
                            <div style={{fontWeight: "bold", fontSize:"14px", marginBottom: "0.2rem"}}>Type <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <BorderedRadioInput
                                values={TypeEnum.toArray()}
                                selected={this.state.lifestyle.type}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.type = value;
                                        return state;
                                    })
                                }}
                            />
                            <TextInput value={this.state.lifestyle.typeDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.typeDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize:"14px", marginBottom: "0.2rem"}}>Nutrition <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <BorderedRadioInput
                                values={NutritionEnum.toArray()}
                                selected={this.state.lifestyle.nutrition}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.nutrition = value;
                                        return state;
                                    })
                                }}
                            />
                            <TextInput value={this.state.lifestyle.nutritionDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.nutritionDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize:"14px", marginBottom: "0.2rem"}}>Alcohol</div>
                            <BorderedRadioInput
                                values={AlcoholUseEnum.toArray()}
                                selected={this.state.lifestyle.alcoholUse}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.alcoholUse = value;
                                        return state;
                                    })
                                }}
                            />
                            <BorderedRadioInput
                                values={CurrentPastEnum.toArray()}
                                selected={this.state.lifestyle.alcoholHabit}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.alcoholHabit = value;
                                        return state;
                                    })
                                }}
                            />
                            <BorderedRadioInput
                                values={AlcoholFrequencyEnum.toArray()}
                                selected={this.state.lifestyle.alcoholUseFrequency}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.alcoholUseFrequency = value;
                                        return state;
                                    })
                                }}
                            />
                            <div className={"row no-margin p-1"}>
                                <div className={"col-3 no-padding"}
                                     style={{marginTop: "auto", marginBottom: "auto", fontWeight:"bold", fontSize: "11px"}}>
                                    Units Per Week
                                </div>
                                <div className={"col-4 no-padding"}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        value={this.state.lifestyle.alcoholUnitsPerWeek != null ?
                                            {
                                                value: this.state.lifestyle.alcoholUnitsPerWeek,
                                                label: this.state.lifestyle.alcoholUnitsPerWeek
                                            }: null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState((state) => {
                                                state.lifestyle.alcoholUnitsPerWeek = r;
                                                return state;
                                            });
                                        }}
                                        options={valueLabelUntilTwenty}
                                    />
                                </div>
                            </div>

                            <TextInput value={this.state.lifestyle.alcoholDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.alcoholDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize:"14px", marginBottom: "0.2rem"}}>Physical Activity / Exercise <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <BorderedRadioInput
                                values={PhysicalActivityEnum.toArray()}
                                selected={this.state.lifestyle.physicalActivity}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.physicalActivity = value;
                                        return state;
                                    })
                                }}
                            />
                            <TextInput value={this.state.lifestyle.physicalActivityDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.physicalActivityDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>


                    </div>
                    <div className={"content-col-6"}
                         style={{maxWidth: "42%",marginLeft:"20px",marginRight:"0px", marginTop:"0px"}}>
                        <div>
                            <div className={"row no-margin"}>
                                <div className={"col-6"} style={{paddingLeft: "0px", paddingTop: "0px"}}>
                                    <div style={{fontWeight: "bold", fontSize:"14px", marginBottom: "0.2rem"}}>Occupation <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Occupation"}
                                        value={this.state.lifestyle.occupation != null ?
                                            {value: OccupationEnum[this.state.lifestyle.occupation].key,
                                                label: OccupationEnum[this.state.lifestyle.occupation].name}: null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState((state) => {
                                                state.lifestyle.occupation = r;
                                                return state;
                                            });
                                        }}
                                        options={OccupationEnum.toArray().map(v=> {
                                                return {value: v.key, label:v.name}
                                            }
                                        )}
                                    />
                                </div>
                                <div className={"col-6"} style={{paddingLeft: "0px", paddingTop: "0px"}}>
                                    <div style={{fontWeight: "bold", fontSize:"14px", marginBottom: "0.2rem"}}>Job Physicality <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Job Physicality"}
                                        value={this.state.lifestyle.jobPhysicality != null ?
                                            {value: JobPhysicalityEnum[this.state.lifestyle.jobPhysicality].key,
                                                label: JobPhysicalityEnum[this.state.lifestyle.jobPhysicality].name}: null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState((state) => {
                                                state.lifestyle.jobPhysicality = r;
                                                return state;
                                            });
                                        }}
                                        options={JobPhysicalityEnum.toArray().map(v=> {
                                                return {value: v.key, label:v.name}
                                            }
                                        )}
                                    />
                                </div>
                            </div>

                            <TextInput value={this.state.lifestyle.occupationDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.occupationDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize:"14px", marginBottom: "0.3rem"}}>Sleeping Pattern</div>
                            <BorderedCheckboxInput
                                values={SleepingPatternEnum.toArray()}
                                selected={this.state.lifestyle.sleepingPattern}
                            />
                            <TextInput value={this.state.lifestyle.sleepingPatternDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.sleepingPatternDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize:"14px", marginBottom: "0.2rem"}}>Smoking</div>
                            <BorderedRadioInput
                                values={SmokingUseEnum.toArray()}
                                selected={this.state.lifestyle.smokingUse}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.smokingUse = value;
                                        return state;
                                    })
                                }}
                            />
                            <BorderedRadioInput
                                values={CurrentPastEnum.toArray()}
                                selected={this.state.lifestyle.smokingHabit}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.smokingHabit = value;
                                        return state;
                                    })
                                }}
                            />
                            <BorderedRadioInput
                                values={SmokingFrequencyEnum.toArray()}
                                selected={this.state.lifestyle.smokingUseFrequency}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.smokingUseFrequency = value;
                                        return state;
                                    })
                                }}
                            />
                            <div className={"row no-margin p-1"}>
                                <div className={"col-3 no-padding"}
                                     style={{marginTop: "auto", marginBottom: "auto", fontWeight:"bold", fontSize: "11px"}}>
                                    Packs Per Day
                                </div>
                                <div className={"col-4 no-padding"}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        value={this.state.lifestyle.smokingPacksPerDay != null ?
                                            {
                                                value: this.state.lifestyle.smokingPacksPerDay,
                                                label: this.state.lifestyle.smokingPacksPerDay
                                            }: null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState((state) => {
                                                state.lifestyle.smokingPacksPerDay = r;
                                                return state;
                                            });
                                        }}
                                        options={valueLabelUntilTen}
                                    />
                                </div>
                            </div>

                            <TextInput value={this.state.lifestyle.smokingDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.smokingDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize:"18px", marginBottom: "0.3rem"}}>Psycho - Social History <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <BorderedCheckboxInput
                                values={PsychoSocialHistoryEnum.toArray()}
                                selected={this.state.lifestyle.psychoSocialHistory}
                            />
                            <TextInput value={this.state.lifestyle.psychoSocialHistoryDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.psychoSocialHistoryDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>


                    </div>
                </div>

            </div>
        );
    }

    generateValueLabelArray(arraySize) {
        const foo = new Array(arraySize);
        for (let i = 1; i <= arraySize; i++) {
            foo.push({value: i, label: i});
        }
        return foo;
    }
}