import React, {Component} from "react";
import "../../VisitHistory.css";
import TypeEnum from "../../../subjective/enums/lifestyle/TypeEnum";
import OccupationEnum from "../../../subjective/enums/lifestyle/OccupationEnum";
import JobPhysicalityEnum from "../../../subjective/enums/lifestyle/JobPhysicalityEnum";
import PhysicalActivityEnum from "../../../subjective/enums/lifestyle/PhysicalActivityEnum";
import SleepingPatternEnum from "../../../subjective/enums/lifestyle/SleepingPatternEnum";
import CurrentPastEnum from "../../../subjective/enums/lifestyle/CurrentPastEnum";
import SmokingFrequencyEnum from "../../../subjective/enums/lifestyle/SmokingFrequencyEnum";
import AlcoholFrequencyEnum from "../../../subjective/enums/lifestyle/AlcoholFrequencyEnum";
import NutritionEnum from "../../../subjective/enums/lifestyle/NutritionEnum";

export default class LifeStyleViewHistory extends Component {
    render() {
        if (this.props.lifestyle == null || this.checkLifeStyleDataAvailability(this.props.lifestyle)) {
            return <div className="pl-3">No information available</div>;
        }

        let lifeStyleCol = this.getLifeStyle();
        return (
            <div className={"d-block pl-3"}>
                {lifeStyleCol}
            </div>
        );
    }

    getLifeStyleViewHistoryDiv(label, value) {
        return (
            <>
                <span> <b>{label}: </b> {value} </span>
            </>
        );
    }

    getLifeStyle() {
        let col = [];
        let lifeStyle = this.props.lifestyle;

        ((lifeStyle.type == null || lifeStyle.type === " ") && (lifeStyle.typeDetail == null || lifeStyle.typeDetail === " "))
            ? col.push(this.getLifeStyleViewHistoryDiv("Type", "NA"))
            : col.push(this.getLifeStyleViewHistoryDiv("Type", this.mergeWithDetail(lifeStyle.type, lifeStyle.typeDetail, TypeEnum)));

        ((lifeStyle.occupation == null || lifeStyle.occupation === " ") && (lifeStyle.occupationDetail == null || lifeStyle.occupationDetail === " "))
            ? col.push(this.getLifeStyleViewHistoryDiv("Occupation", "NA"))
            : col.push(this.getLifeStyleViewHistoryDiv("Occupation", this.mergeWithDetail(lifeStyle.occupation, lifeStyle.occupationDetail, OccupationEnum)));

        (lifeStyle.jobPhysicality == null || lifeStyle.jobPhysicality === " ") ? col.push(this.getLifeStyleViewHistoryDiv("Job Physicality", "NA"))
            : col.push(this.getLifeStyleViewHistoryDiv("Job Physicality", JobPhysicalityEnum[lifeStyle.jobPhysicality].name));

        ((lifeStyle.nutrition == null || lifeStyle.nutrition === " ") && (lifeStyle.nutritionDetail == null || lifeStyle.nutritionDetail === " "))
            ? col.push(this.getLifeStyleViewHistoryDiv("Nutrition", "NA"))
            : col.push(this.getLifeStyleViewHistoryDiv("Nutrition", this.mergeWithDetail(lifeStyle.nutrition, lifeStyle.nutritionDetail, NutritionEnum)));

        ((lifeStyle.physicalActivity == null || lifeStyle.physicalActivity === " ") && (lifeStyle.physicalActivityDetail == null || lifeStyle.physicalActivityDetail === " "))
            ? col.push(this.getLifeStyleViewHistoryDiv("Activity", "NA"))
            : col.push(this.getLifeStyleViewHistoryDiv("Activity", this.mergeWithDetail(lifeStyle.physicalActivity, lifeStyle.physicalActivityDetail, PhysicalActivityEnum)));

        if ((lifeStyle.sleepingPattern == null || lifeStyle.sleepingPattern.length === 0)
            && (lifeStyle.sleepingPatternDetail == null || lifeStyle.sleepingPatternDetail === " ")) {
            col.push(this.getLifeStyleViewHistoryDiv("Sleeping Pattern", "NA"));
        } else {
            let sleepingPatternArr = [];
            lifeStyle.sleepingPattern.forEach((item) => {
                sleepingPatternArr.push(SleepingPatternEnum[item].name);
            });
            sleepingPatternArr.join("/");
            col.push(this.getLifeStyleViewHistoryDiv("Sleeping Pattern", this.mergeWithDetail(sleepingPatternArr, lifeStyle.sleepingPatternDetail)));
        }

        if (lifeStyle.alcoholHabit != null
            || lifeStyle.alcoholUseFrequency != null
            || (lifeStyle.alcoholDetail != null && lifeStyle.alcoholDetail != "")) {

            let text = [
                CurrentPastEnum[lifeStyle.alcoholHabit]?.name,
                AlcoholFrequencyEnum[lifeStyle.alcoholUseFrequency]?.name,
                lifeStyle.alcoholDetail
            ].filter(Boolean).join(", ");

            col.push(this.getLifeStyleViewHistoryDiv("Alcohol", text));
        } else {
            col.push(this.getLifeStyleViewHistoryDiv("Alcohol", "NA"));
        }

        (lifeStyle.alcoholUnitsPerWeek == null || lifeStyle.alcoholUnitsPerWeek === " ") ? col.push(this.getLifeStyleViewHistoryDiv("Alcohol per week", "NA"))
            : col.push(this.getLifeStyleViewHistoryDiv("Alcohol per week", lifeStyle.alcoholUnitsPerWeek));

        let alcohol = null;
        if (lifeStyle.alcoholUse != null && lifeStyle.alcoholUse == "NA") {
            alcohol = null;
        } else if (lifeStyle.alcoholUse != null && lifeStyle.alcoholUse == "NONDRINKER") {
            alcohol = false;
        } else if (lifeStyle.alcoholUseFrequency != null) {
            alcohol = true;
        }

        col.push(this.getAlcoholViewDiv(alcohol))

        if (lifeStyle.smokingHabit != null
            || lifeStyle.smokingUseFrequency != null
            || (lifeStyle.smokingDetail !== null && lifeStyle.smokingDetail !== "")) {

            let text = [
                CurrentPastEnum[lifeStyle.smokingHabit]?.name,
                SmokingFrequencyEnum[lifeStyle.smokingUseFrequency]?.name,
                lifeStyle.smokingDetail
            ].filter(Boolean).join(", ");

            col.push(this.getLifeStyleViewHistoryDiv("Smoking", text));

        } else {
            col.push(this.getLifeStyleViewHistoryDiv("Smoking", "NA"));
        }

        (lifeStyle.smokingPacksPerDay == null || lifeStyle.smokingPacksPerDay === " ") ? col.push(this.getLifeStyleViewHistoryDiv("Packs per day", "NA"))
            : col.push(this.getLifeStyleViewHistoryDiv("Packs per day", lifeStyle.smokingPacksPerDay));

        let smoking = null;
        if (lifeStyle.smokingUse != null && lifeStyle.smokingUse == "NA") {
            smoking = null;
        } else if (lifeStyle.smokingUse != null && lifeStyle.smokingUse == "NONDRINKER") {
            smoking = false;
        } else if (lifeStyle.smokingUseFrequency != null) {
            smoking = true;
        }

        col.push(this.getSmokingViewDiv(smoking))

        return col;
    }

    mergeWithDetail(prop, propDetail, enumClass = null) {
        let text = "";

        if (prop != null) {
            text += enumClass == null ? prop : enumClass[prop].name;
        }

        if (propDetail != null && propDetail != "") {
            if (text !== "") {
                text += ", ";
            }
            text += propDetail;
        }

        if (text != "") {
            return text;
        }

        return null;
    }

    getSmokingViewDiv(smoking) {
        return (
            <>
                <span className={"dim14-form-label"}
                     style={{color: smoking ? "red" : "#7BAC16", marginTop: "auto"}}>
                    {smoking == null ? "" : (smoking ? "+Smoker" : "-Non-Smoker")}
                </span>
            </>);
    }

    getAlcoholViewDiv(alcohol) {
        return (
            <>
                <span className={"dim14-form-label"}
                      style={{color: alcohol ? "red" : "#7BAC16", marginTop: "auto"}}>
                    {alcohol == null ? "" : (alcohol ? "+Drinker" : "-Non-Drinker")}
                </span>
            </>);
    }

    checkLifeStyleDataAvailability(lifestyle) {
        return lifestyle.jobPhysicality == null
            && lifestyle.occupation == null && lifestyle.occupationDetail == null
            && lifestyle.nutrition == null && lifestyle.nutritionDetail == null
            && lifestyle.physicalActivity == null && lifestyle.physicalActivityDetail == null
            && lifestyle.sleepingPattern.length === 0 && lifestyle.sleepingPatternDetail == null
            && lifestyle.smokingHabit == null && lifestyle.smokingUse == null
            && lifestyle.smokingUseFrequency == null && lifestyle.smokingDetail == null
            && lifestyle.smokingPacksPerDay == null && lifestyle.alcoholHabit == null && lifestyle.alcoholUse == null
            && lifestyle.alcoholUseFrequency == null && lifestyle.alcoholDetail == null && lifestyle.alcoholUnitsPerWeek == null;
    }
}