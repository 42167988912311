import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import {AllergyChecker} from "../checkers/AllergyChecker";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import StringUtil from "../../../../../utils/StringUtil";

export default class AllergyModal extends Component {
    state = {
        switch: true,
        activeIngredient: null,
        activeMedication: null,
        ingredientDetail: null,
        allergen: null,
        allergenDetail: null
    };

    constructor(props) {
        super(props);
        this.MINIMUM_SEARCH_LENGTH = 2;
    }

    render() {
        let allergenDivs = this.getAllergenDivs();
        let medicationDivs = this.getMedicationDivs();

        let toggleOn = AllergyChecker.isToggleOn(this.props.subjective.allergy);
        let isNone = AllergyChecker.isNone(this.props.subjective.allergy);

        return (
            <div className={" content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div style={{display: "flex"}}>
                        <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Allergies <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                        <GreenSwitchWithMessage
                            message={isNone ? "None Known" : ""}
                            checked={toggleOn}
                            onChange={() => {
                                if (toggleOn) {
                                    let id = this.props.subjective.allergy.id;
                                    this.props.subjective.allergy = {id: id, none: false};
                                    this.forceUpdate();
                                }
                            }}/>
                    </div>

                    <div style={{display: "flex", marginLeft: "5px"}}>
                        <div>
                            <CheckboxInput value={isNone}
                                           onChange={(value) => {
                                               if (this.props.subjective.allergy == null) {
                                                   this.props.subjective.allergy = {};
                                               }
                                               if (value) {
                                                   let id = this.props.subjective.allergy.id;
                                                   this.props.subjective.allergy = {id: id};
                                               }
                                               this.props.subjective.allergy.none = value;
                                               this.forceUpdate();
                                           }}/>
                        </div>
                        <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>None Known</div>
                    </div>

                    <br/><br/>

                    <div className={isNone ? "not-allowed" : ""}>
                        <div className={isNone ? "disable-pointer" : ""}>
                            <div style={{fontSize: "12px", fontWeight: "bold"}}>Medication</div>
                            <br/>
                            <div className={"row no-margin"}>
                                <div className={"col-6"}>
                                    <div style={{fontSize: "12px", fontWeight: "bold"}}>Active Ingredient</div>
                                </div>
                            </div>
                            <div className={"row no-margin"}>
                                <div className={"col-5"}>
                                    <TextInput value={this.state.activeIngredient || ''}
                                               placeholder={"Ingredient"}
                                               onChange={value => this.setState({activeIngredient: value})}
                                               style={{fontSize: "14px", width: "100%"}}
                                    />
                                </div>
                                <div className={"col-6"}>
                                    <TextInput value={this.state.ingredientDetail || ''}
                                               placeholder={"Details"}
                                               onChange={value => this.setState({ingredientDetail: value})}
                                               style={{fontSize: "14px", width: "100%"}}
                                    />
                                </div>
                                <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                    <Button className={"emr-plus-button"}
                                            variant="success"
                                            disabled={!(this.props.subjective.allergy != null &&
                                                (this.state.activeIngredient != null && this.state.activeIngredient != "")
                                            )}
                                            style={{fontSize: "12px"}}
                                            onClick={this.addMedication.bind(this)}>
                                        <FontAwesomeIcon icon={["fas", "plus"]}/>
                                    </Button>
                                </div>
                            </div>

                            <div className={"row no-margin"}>
                                <div className={"div-list-wrapper"}>
                                    {medicationDivs}
                                </div>
                            </div>

                            <br/>
                            <div style={{fontSize: "12px", fontWeight: "bold"}}>Allergen</div>
                            <br/>

                            <div className={"row no-margin"}>
                                <div className={"col-5"}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Allergen"}
                                        value={this.props.allergen}
                                        menuPlacement="top"
                                        onChange={(event) => {
                                            this.setState({
                                                allergen: event
                                            })
                                        }}
                                        options={[
                                            {label: 'Food', value: 'Food'},
                                            {label: 'Pet', value: 'Pet'},
                                            {label: 'Seasonal', value: 'Seasonal'},
                                            {label: 'Environmental', value: 'Environmental'},
                                            {label: 'Other', value: 'Other'}
                                        ]}
                                    />
                                </div>
                                <div className={"col-6"}>
                                    <TextInput value={this.state.allergenDetail || ''}
                                               placeholder={"Details"}
                                               onChange={value => this.setState({allergenDetail: value})}
                                               style={{fontSize: "14px", width: "100%"}}
                                    />
                                </div>
                                <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                    <Button className={"emr-plus-button"}
                                            variant="success"
                                            disabled={!(this.props.subjective.allergy != null
                                                && this.state.allergen != null
                                                && !StringUtil.isNullOrEmpty(this.state.allergenDetail))}
                                            style={{fontSize: "12px"}}
                                            onClick={this.addAllergen.bind(this)}>
                                        <FontAwesomeIcon icon={["fas", "plus"]}/>
                                    </Button>
                                </div>
                            </div>

                            <div className={"row no-margin"}>
                                <div className={"div-list-wrapper"}>
                                    {allergenDivs}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addMedication() {
        if (this.props.subjective.allergy.ingredients == null) {
            this.props.subjective.allergy.ingredients = [];
        }
        let text = this.state.activeIngredient;
        if (this.state.ingredientDetail != null) {
            text += ' (' + this.state.ingredientDetail + ')';
        }
        if (this.props.subjective.allergy.ingredients.indexOf(text) == -1) {
            this.props.subjective.allergy.ingredients.push(text);
        }

        this.setState({activeIngredient: null, activeMedication: null, ingredientDetail: null});
    }

    getMedicationDivs() {
        if (this.props.subjective.allergy == null || this.props.subjective.allergy.ingredients == null) {
            return;
        }
        let divs = [];
        this.props.subjective.allergy.ingredients.forEach((v, index) => divs.push(
            <div className={"div-list-item"} key={v + index}>
                <div>
                    {v}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            let i = this.props.subjective.allergy.ingredients.indexOf(v);
                            this.props.subjective.allergy.ingredients.splice(i, 1);
                            if (this.props.subjective.allergy.ingredients.length == 0) {
                                this.props.subjective.allergy.ingredients = null;
                            }
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    getAllergenDivs() {
        let divs = [];
        this.addAllergenDivs(divs, "Food", "food");
        this.addAllergenDivs(divs, "Pet", "pet");
        this.addAllergenDivs(divs, "Seasonal", "seasonal");
        this.addAllergenDivs(divs, "Environmental", "environmental");
        this.addAllergenDivs(divs, "Other", "other");
        return divs;
    }

    addAllergenDivs(divs, label, field) {
        if (this.props.subjective.allergy == null || this.props.subjective.allergy[field] == null) {
            return;
        }
        this.props.subjective.allergy[field].forEach((v, index) => divs.push(
            <div className={"div-list-item"} key={label + v + index}>
                <div>
                    {label}: {v}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            const field = label.toLowerCase();
                            let i = this.props.subjective.allergy[field].indexOf(v);
                            this.props.subjective.allergy[field].splice(i, 1);
                            if (this.props.subjective.allergy[field].length == 0) {
                                this.props.subjective.allergy[field] = null;
                            }
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
    }

    addAllergen() {
        this.doAddAllergen(this.state.allergen.value);
        this.setState({allergenDetail: null});
    }

    doAddAllergen(fieldName) {
        let field = fieldName.toLowerCase();
        if (this.props.subjective.allergy[field] == null) {
            this.props.subjective.allergy[field] = [];
        }
        this.props.subjective.allergy[field].push(this.state.allergenDetail);
    }

    noOptionsMessage(input) {
        if (input.inputValue == null || input.inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
        } else {
            return <div>No options</div>;
        }
    }
}
