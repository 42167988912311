import React, {Component} from 'react'
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ColumnGroup} from 'primereact/columngroup';
import dialog from "../../../../components/dialog/Dialog";
import ObservationsModal from "../observation/ObservationsModal";
import ClaimObservationsModal from "./observation/ClaimObservationsModal";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import ActionMenu from "../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../components/action-menu/ActionMenuItem";
import {FileUploader, Modal, TextareaInput} from "../../../../components";
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import VersionModal from "../component/VersionModal";
import ResubmissionsModal from "../resubmission/ResubmissionsModal";



export default class ClaimActivities extends Component {

    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.state = {
            items: [],
            selectedItems: [],
            internalComplaintModalVisible: false,
            correctionModalVisible: false,
            resubmission: {},
            resubmissionClaimItem: {},
            resubmissionAttachment: null,
            currentVersion: null
        };
    }

    openObservationDialog(claimActivityId) {
        dialog({
            title: "Observations",
            component: <ClaimObservationsModal claimActivityId={claimActivityId}/>
        });
    }

    observationTemplate(claimActivity) {
        return (
            <div onClick={() => this.openObservationDialog(claimActivity.id)}>
                <span className="text-break cursor-pointer mr-1"><u>Observations</u></span><FontAwesomeIcon icon="paperclip"/>
            </div>
        )
    }

    onStatusFilterChange = (event) => {
        this.dataTableRef.current.filter(event.value, 'status', 'equals');
        this.setState({selectedStatusFilter: event.value  });
    }

    submitClaim = () => {
        const selectedActivityIds = this.state.selectedItems.map(activity => activity.id);
        RemotingService.postRemoteCall(`api/insurance/coordinator/claim/${this.props.claim.id}/claim-submission`, selectedActivityIds, () => {
                this.props.onClaimSubmitted();
        });
    }

    renderHeaders = () =>{
        return <ColumnGroup>
            <Row>
                <Column selectionMode="multiple" headerStyle={{width: '3em'}} />
                <Column header="Activity ID" colSpan={1}/>
                <Column header="Start" colSpan={1}/>
                <Column header="Type" colSpan={1}/>
                <Column header="Code" colSpan={1}/>
                <Column header="Quantity" colSpan={1}/>
                <Column header="Net" colSpan={1}/>
                <Column header="Ordering Clinician" colSpan={1}/>
                <Column header="Performing Clinician" colSpan={1}/>
                <Column header="Prior Authorization ID (Ref No)" colSpan={1}/>
                <Column header="Observation" colSpan={1}/>
                <Column header="Resubmission" colSpan={1}/>
                <Column header="Submission Version" colspan={1}/>
                <Column header="Remittance Version" colspan={1}/>
                <Column header="Actions" colspan={1}/>
            </Row>
        </ColumnGroup>;
    }

    openVersionModal(versions) {
        dialog({
            title: "Versions",
            component: <VersionModal versions={versions}/>
        });
    }

    submissionVersionTemplate(claimActivity) {
        return (
            <div onClick={() => this.openVersionModal(claimActivity.submissionVersionList)}>
                <span className="text-break cursor-pointer mr-1"><u>Versions</u></span><FontAwesomeIcon icon="paperclip"/>
            </div>
        )
    }

    remittanceVersionTemplate(claimActivity) {
        return (
            <div onClick={() => this.openVersionModal(claimActivity.remittanceVersionList)}>
                <span className="text-break cursor-pointer mr-1"><u>Versions</u></span><FontAwesomeIcon icon="paperclip"/>
            </div>
        )
    }

    handleVersionChange = (currentVersion) => {
        this.setState({currentVersion})
    }

    submitInternalComplaint = () => {
        const parameters = {
            type: this.state.resubmission.type,
            comments: this.state.resubmission.comments,
            attachment: this.state.resubmissionAttachment,
            currentVersion: this.state.currentVersion
        }

        RemotingService.postRemoteCall(`api/insurance/coordinator/claim-activity/${this.props.claim.id}/submit-internal-complaint/${this.state.resubmissionClaimItem.id}`, parameters, () => {
            this.props.onClaimSubmitted();
            this.setState({resubmission: null});
        });
    };

    renderInternalComplaintModal = () => {
        let versionOptions = this.state.resubmissionClaimItem?.submissionVersionList?.map(item => {
            return{
                ...item,
                label: "v 1."+item.version
            }
        })

        return (
            <>
                <Modal visible={this.state.internalComplaintModalVisible}
                       header="Claim Internal Complaint"
                       submitContent="Submit Claim"
                       submitAction={() => this.submitInternalComplaint()}
                       closeAction={() => this.setState({internalComplaintModalVisible: false})}
                       submitDisabled={this.state.resubmission?.comments == null || this.state.resubmission?.comments?.length <= 0 || this.state.currentVersion == null}
                       contentStyle={{width: "500px", height: "300px"}}>

                    <Container className={"InputModal"}>
                        <Row>
                            <Col xs={3} className="dim14-form-label">
                                Version
                            </Col>
                            <Col xs={9}>
                                <PrimeDropdown dataKey="id" optionLabel="label"
                                               options={versionOptions}
                                               value={this.state.currentVersion}
                                               onChange={e => this.handleVersionChange(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} className="dim14-form-label">
                                Comments
                            </Col>
                            <Col xs={9}>
                                <TextareaInput style={{width: "100%", maxHeight: "100px"}}
                                               value={this.state.resubmission?.comments}
                                               onChange={(value) => {
                                                   let updatedResubmission = this.state.resubmission;
                                                   updatedResubmission.comments = value;
                                                   this.setState({resubmission: updatedResubmission})
                                               }}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3} className="dim14-form-label">
                                File Attachment
                            </Col>
                            <Col xs={9}>
                                <FileUploader basic accept=".pdf"
                                              value={this.state.resubmissionAttachment}
                                              onChange={(file) => {
                                                  this.state.resubmissionAttachment = file;
                                                  this.state.resubmissionAttachment.contentType = file.mimeType;
                                                  this.state.resubmissionAttachment.data = file.content;
                                                  this.forceUpdate();
                                              }}/>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </>
        )
    }

    renderActions = (rowData) => {
        return (<ActionMenu id="claimactivityactionmenu">
            <ActionMenuItem text={"Internal Complaint"}
                            onClick={() => {
                                let updatedResubmission = this.state.resubmission;
                                updatedResubmission.type = 'INTERNAL_COMPLAINT';
                                this.setState({
                                    resubmission: updatedResubmission,
                                    internalComplaintModalVisible: true,
                                    resubmissionClaimItem: rowData,
                                    currentVersion:
                                        rowData?.submissionVersionList.length > 0 ?
                                            rowData.submissionVersionList[rowData.submissionVersionList.length-1] :
                                            null
                                })
                            }}/>
            <ActionMenuItem text="Correction"
                            onClick={() => {
                                let updatedResubmission = this.state.resubmission;
                                updatedResubmission.type = 'CORRECTION';
                                this.setState({
                                    resubmission: updatedResubmission,
                                    correctionModalVisible: true,
                                    resubmissionClaimItem: rowData
                                })
                            }}/>
        </ActionMenu>)
    }

    resubmisssionTemplate(claimActivity) {
        return (
            <div onClick={() => this.openResubmissionDialog(claimActivity.id)}>
                <span className="text-break cursor-pointer mr-1"><u>Resubmissions</u></span>
            </div>
        )
    }

    openResubmissionDialog(claimActivityId) {
        dialog({
            title: "Resubmissions",
            component: <ResubmissionsModal claimActivityId={claimActivityId}/>
        });
    }

    render() {

        return (
            <>
                <div style={{padding: '10px'}} className="data-table-header-wrapper">
                    <div className="float-left">
                    </div>
                </div>
                <div style={{padding: '10px'}}>
                    <DataTable className="claimListTable" responsive headerColumnGroup={this.renderHeaders()}
                               emptyMessage={"No items found"}
                               scrollable
                               ref={this.dataTableRef}
                               value={this.props.claim.claimActivityList}
                               columnResizeMode="fit"
                               dataKey="id"
                               selection={this.state.selectedItems}
                               onSelectionChange={e => this.setState({selectedItems: e.value})}>
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
                        <Column field="activityId" />
                        <Column field="start" />
                        <Column field="type" />
                        <Column field="code" />
                        <Column field="quantity" />
                        <Column field="net" />
                        <Column field="orderingClinician" />
                        <Column field="performingClinician" />
                        <Column field="referenceNo" />
                        <Column header="Observation" body={(claimActivity) => this.observationTemplate(claimActivity)}/>
                        <Column header="Resubmission" body={(claimActivity) => this.resubmisssionTemplate(claimActivity)}/>
                        <Column header="Submission Version" body={(claimActivity) => this.submissionVersionTemplate(claimActivity)}/>
                        <Column header="Remittance Version" body={(claimActivity) => this.remittanceVersionTemplate(claimActivity)}/>
                        <Column header="Actions" body={this.renderActions.bind(this)} style={{textAlign: "center", width:"75px"}}/>
                    </DataTable>
                </div>
                <div className="float-left" style={{display: "flex"}}>
                    <Button disabled={this.state.selectedItems.length === 0}
                            variant="success"
                            onClick={() => this.submitClaim()}>
                        Submit Claim
                    </Button>
                </div>
                {this.renderInternalComplaintModal()}
            </>
        )
    }
}
