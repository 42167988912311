import * as ArrayUtils from "../../../../../utils/ArrayUtil";
import {GenericChecker} from "../GenericChecker";

export class AllergyChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return !ArrayUtils.isEmpty(entity.ingredients)
            || !ArrayUtils.isEmpty(entity.food)
            || !ArrayUtils.isEmpty(entity.pet)
            || !ArrayUtils.isEmpty(entity.seasonal)
            || !ArrayUtils.isEmpty(entity.environmental)
            || !ArrayUtils.isEmpty(entity.other);
    }
}