import Enum from "../../../../enums/Enum";
import React from "react";

const InformedConsentFormKwtTextEnum = new Enum({
    Informed_Consent_Title: {English: "INFORMED CONSENT FORM", Arabic: "نموذج إقرار بالموافقة"},
    Informed_Consent: {English: "INFORMED CONSENT", Arabic: "إقرار بالموافقة"},
    Informed_Consent_Form: {English: "Informed Consent Form", Arabic: "نموذج إقرار بالموافقة"},
    Date_Of_Admission: {English: "Date of Admission", Arabic: "تاريخ الدخول"},
    MRN: {English: "MRN", Arabic: "رقم الملف الطبي"},
    ID_No: {English: "ID No.", Arabic: "رقم البطاقة المدنية"},
    Date_Of_Birth: {English: "Date of Birth", Arabic: "تاريخ الميلاد"},

    Introduction_Paragraph_1: {
        English: "It is important for you to consider the benefits, risks and alternatives to the treatment options offered by our Physiatrist and/or Physiotherapist in order to make an informed decision about proceeding with treatment.",
        Arabic: "من المهم أن تطلع على المنافع والمخاطر وبدائل خيارات العلاج التي يعرضها عليك أخصائي الطب البديل و / أو أخصائي العلاج الطبيعي لدينا من أجل اتخاذ قرار مستنير (عن دراية) حول المضي في العلاج."
    },

    Introduction_Paragraph_2: {
        English: "Physiatry and/or Physiotherapy services include:",
        Arabic: "تتضمن خدمات الطب البديل و / أو العلاج الطبيعي ما يلي:"
    },

    Introduction_ListItem1_Title: {
        English: "Diagnostic procedures",
        Arabic: "إجراءات التشخيص "
    },

    Introduction_ListItem2_Title: {
        English: "Manual and manipulative therapy",
        Arabic: "العلاج اليدوي وعلاج تحريك المفاصل"
    },

    Introduction_ListItem2_Paragraph: {
        English: "to correct movement dysfunction for all spinal and joint conditions ranging from: Temporal-Mandibular Joint pain, simple backpain, disc herniation rehab to post-operative joint care",
        Arabic: "لتصحيح الخلل الوظيفي في الحركة لجميع حالات العمود الفقري والمفاصل التي تتراوح من: آلام المفصل الصدغي والفك السفلي، آلام الظهر البسيطة، إعادة تأهيل فتق القرص إلى رعاية المفاصل بعد الجراحة"
    },

    Introduction_ListItem3_Title: {
        English: "Soft tissue techniques",
        Arabic: "وتقنيات الأنسجة اللينة "
    },

    Introduction_ListItem3_Paragraph: {
        English: "such as deep tissue therapy and therapeutic massage",
        Arabic: "مثل التدليك وطرق أخرى"
    },

    Introduction_ListItem4_Title: {
        English: "Exercise therapy",
        Arabic: "علاج التمرين "
    },

    Introduction_ListItem4_Paragraph: {
        English: "to offer biomechanical strengthening and conditioning to supplement and complete movement correction needs: postural correction, proper movement patterns to prevent pain and strengthen weak muscles",
        Arabic: "لتقديم التقوية الميكانيكية الحيوية والتكييف لتكملة وإكمال احتياجات تصحيح الحركة مثل: تصحيح الوضع، وأنماط الحركة المناسبة لمنع الألم وتقوية العضلات الضعيفة"
    },

    Introduction_ListItem5_Title: {
        English: "Dry Needling",
        Arabic: "يقلل العلاج بالإبرة الجافة "
    },

    Introduction_ListItem5_Paragraph1: {
        English: "reduces hypertonia and muscle tension similar to Chinese acupuncture, but no substance will be injected into the body. Please let your Healthcare Provider know if you suffer from:",
        Arabic: "من ارتفاع ضغط الدم وتوتر العضلات على غرار الوخز بالإبر الصينية، ولكن لن يتم حقن أي مادة في الجسم. يرجى إخبار مقدم الرعاية الصحية الخاص بك إذا كنت تعاني من:  "
    },

    Introduction_ListItem5_Paragraph2: {
        English: "epilepsy, hyperalgesia, blood diseases, mental and cognitive impairment, immunological diseases, cancer, pregnancy, or a needle phobia.",
        Arabic: "الصرع، فرط التألم، أمراض الدم ، الضعف العقلي والمعرفي ، أمراض المناعة، سرطان، حمل ، أو رهاب الإبر."
    },

    Introduction_ListItem6_Title: {
        English: "Other forms of therapy",
        Arabic: "أشكال أخرى من العلاج"
    },

    Introduction_ListItem6_Paragraph: {
        English: "including, but not limited to electro-therapeutic modalities, and prescribing medication.",
        Arabic: "بما في ذلك، ولكن لا تقتصر على وسائل علاجية كهربائية، ووصف الدواء"
    },

    Benefits_Title: {
        English: "Benefits",
        Arabic: "المنافع"
    },

    Benefits_Paragraph: {
        English: "Physiatrist and/or Physiotherapist treatments have been demonstrated to be effective for pain complaint of the neck, back and other areas of the body caused by injury to the nerves, muscles, joints, tendons, ligaments and fascia. Treatment by your Physiatrist and/or Physiotherapist can relieve pain, including headache, altered sensation, muscle stiffness and spasm. It can also increase mobility, improve function, and reduce or eliminate the need for drugs or surgery.",
        Arabic: "أثبتت علاجات الطب البديل و / أو العلاج الطبيعي فعاليتها في شكاوى آلام الرقبة والمناطق الأخرى من الجسد الناجمة عن اصابة في الأعصاب، أو العضلات أو المفاصل أو الأوتار أو الأربطة أو اللفافة. يمكن للعلاج بواسطة اختصاصي الطب الرياضي، معالج العمود الفقري، المعالج الطبيعي، المعالج الرياضي ومعالج الحركة أن يخفف الألم بما في ذلك الصداع، الاحساس المتغير، تصلب وتشنج العضلات. كما يمكن أن يزيد الحركة ويحسن الوظائف ويقلل أو ينهي الحاجة إلى الأدوية والجراحة."
    },

    Risks_Intro_Title: {
        English: "Risks",
        Arabic: "المخاطر"
    },

    Risks_Intro_Paragraph: {
        English: "You also must be informed that there are or may be some risks associated with such treatments. In particular:",
        Arabic: "يجب أن تعلم أنه يوجد أو قد يوجد بعض الأخطار المرافقة لهذه العلاجات، وعلى وجه الخصوص:"
    },

    Risks_Item1_Title: {
        English: "Temporary worsening of symptoms:",
        Arabic: "التفاقم المؤقت للأعراض: "
    },

    Risks_Item1_Paragraph: {
        English: "Muscle soreness occurs in up to half of patients following treatment and usually dissipates within a couple of days. Although rare, some patients have experienced muscle strain, ligament sprain or rib fracture following manual therapy. Usually, any increase in pre-existing symptoms of pain or stiffness will last only a few hours to a few days. Please consult your provider for questions on management.",
        Arabic: "يحدث ألم العضلات لحوالي نصف المرضى بعد العلاج ويتلاشى عادةً خلال يومين. لقد حدث لبعض المرضى، رغم أن ذلك كان نادراً، أن عانوا من الشد العضلي والتواء في الأربطة أو كسر في أحد الأطراف بعد العلاج اليدوي. عادةً ، أي زيادة في الأعراض الموجودة مسبقاً للألم أو التصلب يستمر بضعة ساعات إلى بضعة أيام. يرجى استشارة مقدم الرعاية الصحية الخاص بك للأسئلة حول كيفية إدارة الأعراض الخاصة بك. "
    },

    Risks_Item2_Title: {
        English: "Skin irritations burn or bruising:",
        Arabic: "تهيج الجلد والحرق والكدمات: "
    },

    Risks_Item2_Paragraph: {
        English: "this may occur in association with the use of some types of soft tissue therapies and electrotherapeutic modalities. Skin irritation can be caused by the use of tape (contact with the glue). Skin irritation should resolve quickly. A burn may leave a permanent scar. Bruising may be caused by dry needling, deep tissue therapy and cupping therapy and should resolve in a few days.",
        Arabic: "قد يحدث تهيج الجلد أو الحرق أو الكدمات عند استخدام بعض أنواع علاج الأنسجة اللينة ووسائل العلاج الكهربائية. يمكن أن يكون سبب تهيج الجلد استخدام شريط (على احتكاك بالغراء). يجب أن يتم ايجاد حل لتهيج الجلد بسرعة. يمكن أن يترك الحرق ندبة دائمة. يمكن أن تحدث الكدمات بسبب الوخز بالإبر الجافة، أو علاج الأنسجة العميقة أو العلاج بالحجامة، ويجب ايجاد حل لهذا خلال أيام قليلة."
    },

    Risks_Item3_Title: {
        English: "Sprain or strain:",
        Arabic: "الالتواء أو الشد العضلي: "
    },

    Risks_Item3_Paragraph: {
        English: "Typically, ligament sprain or muscle strain will resolve itself within a few days or a few weeks with some rest, protection of the area affected and other minor care.",
        Arabic: "بشكل عام، تتلاشى مشكلة الالتواء أو الشد العضلي من تلقاء نفسها خلال بضعة أيام أو عدة أسابيع مع بعض الراحة وحماية المنطقة المتأثرة وإعطاء مزيد من الرعاية الطفيفة. "
    },

    Risks_Item4_Title: {
        English: "Injury or aggravation of a disc:",
        Arabic: "إصابة أو تفاقم الديسك: "
    },

    Risks_Item4_Paragraph1: {
        English: "Over the course of a lifetime, spinal discs may degenerate or become damaged. A disc can degenerate with aging and daily wear and tear with common daily activities such as bending or lifting. Patients who already have a degenerated or damaged disc may or may not have symptoms. They may not know they have a problem with a disc. They also may not know their disc condition is worsening because they only experience back or neck problems once in a while.",
        Arabic: "خلال حياة الانسان، قد تتدهور أو تتلف أقراص العمود الفقري (الديسك). يمكن أن يتدهور القرص مع تقدم العمر والتآكل اليومي مع الأنشطة اليومية الشائعة مثل الانحناء أو الرفع. المرضى الذين لديهم بالفعل قرص متحلل أو تالف قد يعانون أو لا يعانون من أعراض. يمكن ألا يكونون على علم بمرض أقراص العمود الفقري (الديسك). قد لا يعرفون أيضًا أن حالة القرص لديهم تزداد سوءًا لأنهم يعانون فقط من مشاكل في الظهر أو الرقبة من حين لآخر."
    },

    Risks_Item4_Paragraph2: {
        English: "Physiatry and/or Physiotherapy treatment should not damage a disc that is not already degenerated or damaged, but if there is a pre-existing disc condition, the treatment, like many common daily activities, may aggravate the disc condition.",
        Arabic: "يجب ألا يلحق علاج الطب البديل و / أو العلاج الطبيعي الضرر بأقراص العمود الفقري (الديسك) التي لم يلحق بها أي تدهور أو اصابة مسبقاً، لكن في حال كان هناك حالة ديسك موجودة مسبقاً، يمكن أن يؤدي العلاج إلى تفاقم حالة الديسك الموجودة تماماً كما تفعل الكثير من الأعمال اليومية الأخرى"
    },

    Risks_Item4_Paragraph3: {
        English: "The consequences of disc injury or aggravating a pre-existing disc condition will vary with each patient. In the most severe cases, patient symptoms may include impaired back or neck mobility, radiating pain and numbness into the legs or arms, impaired bowel or bladder function, or impaired leg or arm function.",
        Arabic: "تختلف مضاعفات الإصابة بالديسك أو تدهور حالة الديسك الموجودة من مريض لآخر. في معظم الحالات الشديدة، قد تشمل أعراض المريض ضعفًا في الظهر وتحريك الرقبة، وألمًا مشعًا وتخدير في الساقين والذراعين، وضعفًا في وظيفة الأمعاء والمثانة، وضعفًا في وظيفة الساق أو الذراع. "
    },

    Risks_Item5_Title: {
        English: "Stroke:",
        Arabic: "السكتة الدماغية: "
    },

    Risks_Item5_Paragraph1: {
        English: "Blood flows to the brain through two sets of arteries passing through the neck. These arteries may become weakened and damaged, either over time through aging or disease, or as a result of injury. A blood clot may form in a damaged artery. All or part of the clot may break off and travel up the artery to the brain where it can interrupt blood flow and cause a stroke.",
        Arabic: "يتدفق الدم إلى الدماغ من خلال مجموعتين من الشرايين التي تمر عبر العنق. هذه الشرايين قد تصبح أضعف وقد تتضرر إما مع مرور الوقت من خلال الشيخوخة أو المرض، أو نتيجة للإصابة. قد تتشكل جلطة دموية في الشريان التالف. وقد يتقطع كل أو جزء من الجلطة ويتابع مسيره من الشريان إلى الدماغ حيث يمكن أن يقطع تدفق الدم ويتسبب بسكتة دماغية."
    },

    Risks_Item5_Paragraph2: {
        English: "Many common activities of daily living involving ordinary neck movements have been associated with stroke resulting from damage to an artery in the neck, or a clot that already existed in the artery breaking off and travelling up to the brain.",
        Arabic: "لقد ارتبطت العديد من الأعمال اليومية التي تنطوي على حركات الرقبة العادية بالسكتة الدماغية الناجمة عن الأضرار التي لحقت بشريان في الرقبة، أو جلطة كانت موجودة بالفعل في الشريان التي تقطعت واتجهت إلى الدماغ."
    },

    Risks_Item5_Paragraph3: {
        English: "Physiatry and/or Physiotherapy treatments have also been associated with stroke. However, that association occurs very infrequently, and may be explained because an artery was already damaged, and the patient was progressing toward a stroke when the patient consulted the Healthcare Provider. Present medical and scientific evidence does not establish that Physiatry and/or Physiotherapy treatment causes either damage to an artery or stroke. The consequences of a stroke can be very serious, including significant impairment of vision, speech, balance and brain function, as well as paralysis or death.",
        Arabic: "كما ارتبط العلاج من خلال الطب البديل و / أو العلاج الطبيعي بالسكتة الدماغية. ومع ذلك، فإن هذا الارتباط نادر الحدوث، ويمكن تفسيره بأن أحد الشرايين كان قد تضرر مسبقاً وكانت حالة المريض تسير باتجاه السكتة الدماغية عند استشار مقدم الرعاية الصحية. الأدلة الطبية والعلمية الحالية لا تثبت أن العلاج الطب البديل و / أو العلاج الطبيعي يسبب أي ضرر لأحد الشرايين أو السكتة الدماغية. يمكن أن تكون عواقب السكتة الدماغية خطيرة جدا، وتشمل تلك العواقب انخفاضًا كبيرًا في الرؤية والكلام والتوازن ووظيفة الدماغ، بالإضافة إلى الشلل أو الموت."
    },

    Risks_Item6_Title: {
        English: "Osteoporosis, minor undiagnosed fractures, bone tumours or bone infections:",
        Arabic: "هشاشة العظام والكسور الطفيفة غير المشخصة وأورام العظام والتهابات العظام:"
    },

    Risks_Item6_Paragraph: {
        English: "A patient may not be aware of previous bone pathology which may present a risk to spinal manipulation. A manipulation in such patients may cause a bone fracture. In such cases, it is the Doctors responsibility to refer patient for diagnostic imaging, tests and further management.",
        Arabic: " قد لا يكون المريض على علم بأمراض العظام السابقة التي قد تمثل خطرًا على تحريك العمود الفقري. يمكن أن يسبب التحريك لدى هؤلاء المرضى كسورًا في العظام. في مثل هذه الحالات، فعلى الأطباء أن يحيلوا المريض للتصوير التشخيصي، والفحوصات ومزيد من الإدارة."
    },

    Risks_Item7_Title: {
        English: "Dry needling:",
        Arabic: "الوخز بالإبر الجافة: "
    },

    Risks_Item7_Paragraph: {
        English: "I have been informed that dry needling is a safe method of treatment, but that it may have side effects including bruising, bleeding, numbness, tingling or pain near the needling sites that may last a few days and dizziness, nausea or fainting. Unusual risks of dry needling include severe emotional reaction, seizure, pneumothorax, organ puncture, systemic infection or broken needle.",
        Arabic: "لقد تم إبلاغي أن الوخز بالإبر الجافة هو وسيلة آمنة للعلاج، إلا أنه قد يكون لها آثار جانبية بما في ذلك كدمات، نزيف، تخدير، ألم أو وخز بالقرب من الأماكن التي تم فيها الوخز بالإبر وقد تستمر تلك الآثار لبضعة أيام، بالإضافة إلى الدوخة، الغثيان أو الإغماء. وتشمل المخاطر غير العادية للوخز بالإبر الجاف، ردود فعل عاطفية أو نوبة صرع أو استرواح الصدر أو ثقب في الأعضاء أو عدوى جهازية أو كسر الإبرة"
    },

    Alternatives_Title: {
        English: "Alternatives",
        Arabic: "البدائل"
    },

    Alternatives_Paragraph1: {
        English: "Alternatives to Physiatry and/or Physiotherapy treatments may include consulting other health professionals. Your Healthcare Provider may also prescribe rest without treatment, or exercise with or without treatment.",
        Arabic: "يمكن أن تشمل بدائل الطب البديل و / أو العلاج الطبيعي ، التشاور مع المهنيين الصحيين الآخرين. كما يمكن لمقدم الرعاية الصحية أن ينصحك بالراحة دون علاج أو ممارسة الرياضة مع أو بدون العلاج."
    },

    Injection_And_Medication_Title: {
        English: "Injection and Medication",
        Arabic: "الحقن والأدوية"
    },

    Injection_And_Medication_Paragraph1: {
        English: "All injection and medication will be prescribed by a Doctor. This will complement other treatments you may receive. The Doctor will advise you on the benefits and possible side effects.",
        Arabic: "سيتم وصف كافة الحقن والأدوية من قبل طبيب. هذا سيكمل العلاجات الأخرى التي قد تحصل عليها. سيبلغك الطبيب بالفوائد والآثار الجانبية المحتملة."
    },

    Data_Collection_And_Privacy_Title: {
        English: "Data Collection and Privacy",
        Arabic: "جمع البيانات والخصوصية"
    },

    Data_Collection_And_Privacy_Paragraph1: {
        English: "During your treatment at DISC-Kuwait, we will gather information about your personal health information. This is limited to: demographic information, medical history, test and lab results, mental health, insurance information and data collected by healthcare professionals. DISC-Kuwait is committed to protecting your personal information in accordance with our Privacy Policy and the laws of the State of Kuwait.",
        Arabic: "أثناء علاجك في DISC-Kuwait، سنجمع معلومات حول معلوماتك الصحية الشخصية. يقتصر هذا على: المعلومات الديموغرافية، التاريخ الطبي، نتائج الاختبارات والمختبرات، الصحة العقلية، معلومات التأمين والبيانات التي يجمعها المتخصصون في الرعاية الصحية. تلتزم DISC-Kuwait بحماية معلوماتك الشخصية وفقًا لسياسة الخصوصية الخاصة بنا وقوانين دولة الكويت."
    },

    Data_Collection_And_Privacy_Paragraph2: {
        English: "We collect, use and disclose personal health information for the primary purpose of safely and effectively providing you with a seamless experience of our services. This disclosure of personal health information shall be disclosed to the following parties and reasons: treatment (defined as: provision, coordination, or management of health care and related services by one or more health care providers, consultation or patient referral between health care providers relating to a patient), healthcare operations, payment and billing, help with public health and safety matters and comply with the law.",
        Arabic: "اننا نجمع، نستخدم ونكشف معلوماتك الصحية الشخصية لغرض أساسي وهو تزويدك بتجربة سلسة لخدماتنا بشكلٍ أمن وفعال. سيتم الكشف عن المعلومات الصحية الشخصية للأطراف والأسباب التالية:العلاج (يُعرّف على أنه: توفير أو تنسيق أو إدارة الرعاية الصحية والخدمات ذات الصلة من قبل واحد أو أكثر من مقدمي الرعاية الصحية، أو الاستشارة أو إحالة المريض بين مقدمي الرعاية الصحية المتعلقة بالمريض)، عمليات الرعاية الصحية، والدفع والفواتير، المساعدة في مسائل الصحة والسلامة العامة والامتثال للقانون."
    },

    Data_Collection_And_Privacy_Paragraph3: {
        English: "We understand the importance of protecting personal information. For this reason, we have taken the following steps:",
        Arabic: "نحن نتفهم أهمية حماية المعلومات الشخصية. لهذا السبب اتخذنا الخطوات التالية:"
    },

    Data_Collection_And_Privacy_Paragraph3_ListItem1: {
        English: "Paper information is stored in a restricted area at all times.",
        Arabic: "يتم تخزين المعلومات الورقية في منطقة محظورة في جميع الأوقات"
    },

    Data_Collection_And_Privacy_Paragraph3_ListItem2: {
        English: "Electronic hardware has restricted access at all times.",
        Arabic: "تم تقييد الوصول إلى الأجهزة الإلكترونية في جميع الأوقات"
    },

    Data_Collection_And_Privacy_Paragraph3_ListItem3: {
        English: "Electronic information is transmitted through direct lines using encryption and marked as \"personal and confidential\".",
        Arabic:  "يتم نقل المعلومات الإلكترونية عبر خطوط مباشرة باستخدام التشفير ويتم تمييزها على أنها \"شخصية وسرية\"."
    },

    Data_Collection_And_Privacy_Paragraph3_ListItem4: {
        English: "Staff is trained to collect, use, and disclose personal information, as stated above as necessary to fulfil their duties in accordance with our privacy policies.",
        Arabic: "يتم تدريب الموظفين على جمع المعلومات الشخصية واستخدامها والكشف عنها، كما هو مذكور أعلاه حسب الضرورة لأداء واجباتهم وفقًا لسياسات الخصوصية الخاصة بنا."
    },

    Data_Collection_And_Privacy_Paragraph3_ListItem5: {
        English: "External consultants and agencies with access to personal information (Insurance companies) must enter into privacy agreements with us.",
        Arabic: "على المستشارين والوكالات الخارجية التي لديها إمكانية الوصول إلى المعلومات الشخصية (شركات التأمين) ابرام اتفاقيات الخصوصية معنا."
    },

    Data_Collection_And_Privacy_Paragraph4: {
        English: "*We do not share personal information with any agencies not directly related to your treatment care and we do not provide our patient's information to solicitors.",
        Arabic: "اننا لا نشارك المعلومات الشخصية مع أي وكالات لا تتعلق مباشرة برعايتك العلاجية ولا نقدم معلومات مريضنا إلى المحامين"
    },

    Data_Collection_And_Privacy_Paragraph5: {
        English: "*Marketing – we will obtain your written authorization before using your health information to send marketing materials",
        Arabic: "التسويق - سنحصل على إذن كتابي منك قبل استخدام معلوماتك الصحية لإرسال مواد تسويقية"
    },


    Data_Collection_And_Privacy_Paragraph6: {
        English: "You have the right to:",
        Arabic: "لديك الحق في:"
    },

    Data_Collection_And_Privacy_Paragraph6_ListItem1: {
        English: "Get a copy of your paper or electronic medical record",
        Arabic: "الحصول على نسخة من سجلك الطبي الورقي أو الإلكتروني"
    },

    Data_Collection_And_Privacy_Paragraph6_ListItem2: {
        English: "Request confidential communications – you can make a written request that we communicate with you about medical matters in a certain way. For example, you may request that we contact you only in writing.",
        Arabic: "طلب اتصالات سرية - يمكنك تقديم طلب كتابي للتواصل معك بشأن الأمور الطبية بطريقة معينة. على سبيل المثال، قد تطلب منا الاتصال بك كتابيًا فقط."
    },

    Data_Collection_And_Privacy_Paragraph6_ListItem3: {
        English: "Be notified of a breach – we will notify you in the event of a breach of your protected health information.",
        Arabic: "العلم بأي خرق - سوف يتم إعلامك في حالة حدوث خرق لمعلوماتك الصحية المحمية."
    },

    Data_Collection_And_Privacy_Paragraph6_ListItem4: {
        English: "Request to correct or amend your health information - you have the right to request in writing that personal health information that we maintain about you be amended or corrected only if the information we have is incorrect and/or does not reflect updated information. We are not obligated to make all requested amendments but will give each careful consideration.",
        Arabic: "طلب تصحيح أو تعديل معلوماتك الصحية - يحق لك أن تطلب كتابيًا تعديل أو تصحيح المعلومات الصحية الشخصية التي نحتفظ بها عنك فقط إذا كانت المعلومات التي لدينا غير صحيحة و / أو لا تعكس المعلومات المحدثة. لسنا ملزمين بإجراء جميع التعديلات المطلوبة، ولكننا سننظر بعناية في كل منها."
    },

    Data_Collection_And_Privacy_Paragraph6_ListItem5: {
        English: "Get a copy of this privacy notice",
        Arabic: "الحصول على نسخة من إشعار الخصوصية هذا."
    },

    Questions_Of_Concerns_Title: {
        English: "Questions of Concerns",
        Arabic: "الاستفسارات والمخاوف"
    },

    Questions_Of_Concerns_Paragraph: {
        English: "You are encouraged to ask questions at any time regarding your assessment and treatment. Bring any concerns you have to your Healthcare Provider's attention. If you are not comfortable, you may stop treatment at any time. You have the right to have your concerns heard and resolved when possible.",
        Arabic: "لا تتردد في طرح أي أسئلة قد تخطر في ذهنك في أي وقت بخصوص التقييم والعلاج. أبلغ مقدم الرعاية الصحية حول أي مخاوف لديك. إذا لم تكن مرتاحاً، يمكنك التوقف عن العلاج في أي وقت. لك الحق في أن يتم الإصغاء إلى استفساراتك وحلها عندما يكون ذلك ممكناً"
    },

    Complaints_Title: {
        English: "Complaints",
        Arabic: "شكاوى"
    },

    Complaints_Paragraph: {
        English: "If you are not happy with how your concern is resolved or believe your privacy rights have been violated, you may file a complaint, a complaint form is available at our front reception.",
        Arabic: "إذا لم تكن راضيًا عن كيفية حل مشكلتك أو تعتقد أنه قد تم انتهاك حقوق الخصوصية الخاصة بك، فيمكنك تقديم شكوى، يتوفر نموذج شكوى في مكتب الاستقبال الأمامي لدينا. "
    },

    Conclusion_Paragraph1: {
        English: "Please be involved in and responsible for your care. Inform your Provider immediately of any change in your condition.",
        Arabic: "نرجو مشاركتك وأن تكون مسؤولاً عن رعايتك. أبلغ مقدم الرعاية الصحية فورًا بأي تغيير في حالتك."
    },

    Conclusion_Paragraph2: {
        English: "I hereby acknowledge I understand the nature of the treatment to be provided to me. I have considered the benefits and risks of treatment, as well as the alternatives to treatment. I hereby consent to Physiatry and/or Physiotherapy treatment as proposed to me. I grant DISC-Kuwait permission to contact me directly via telephone, text message or email to discuss any matter that may arise in the future.",
        Arabic: "أقر بموجبه أنني أتفهم طبيعة العلاج الذي تم تقديمه لي. لقد فكرت في فوائد ومخاطر العلاج، وكذلك بدائل العلاج. أوافق بموجب هذا على العلاج الطب البديل و / أو العلاج الطبيعي كما هو مقترح لي. أمنح عيادة ديفيرسفيد إنتغرتد سبورتس كلينك - الكويت الإذن بالاتصال بي مباشرة عبر الهاتف أو الرسائل النصية أو البريد الإلكتروني لمناقشة أي مسألة قد تطرأ في المستقبل."
    },

    Conclusion_Paragraph3: {
        English: "I understand that to provide me with Physiatry and/or Physiotherapy services and products, DISC-Kuwait is required to collect personal information. I understand the collection, use, and disclosure of this personal information, and I have been given the chance to ask questions with respect to clarification.",
        Arabic: "أدرك أنه لتزويدي بخدمات ومنتجات علاج الطب البديل و / أو العلاج الطبيعي، مطلوب من DISC-Kuwait جمع المعلومات الشخصية. أفهم جمع هذه المعلومات الشخصية واستخدامها والإفصاح عنها ، وقد أتيحت لي الفرصة لطرح الأسئلة فيما يتعلق بالتوضيح."
    },

    Date: {English: "Date", Arabic: "التاريخ:"},
    Patients_Name: {English: "Patient's Name", Arabic: "اسم المريض (يرجى كتابة الاسم)"},
    Provider_Name: {English: "Provider's name", Arabic: "اسم مقدم الرعاية الصحية"},
    Legal_Guardian_Name: {English: "Legal Guardian Name", Arabic: "اسم الوصي القانوني"},
    Relationship_To_Patient: {English: "Relationship to Patient", Arabic: "العلاقة مع المريض"},
    Signature_of_Patient: {English: "Signature of Patient (or legal guardian)", Arabic: "توقيع المريض (أو الوصي القانوني)"},
    Signature_of_Provider: {English: "Signature of Provider", Arabic: "توقيع مقدم الرعاية الصحية"},
    Signature_Required: {English: "Signature required", Arabic: "Signature required"},
    Provider_Required: {English: "Provider required", Arabic: "Provider required"},
    Signature_input_Signature: {English: "Signature", Arabic: "إمضاء"},
    Signature_input_Clear: {English: "Clear", Arabic: "حذف"},
    Wizard_Previous: {English: "Previous", Arabic: "السابق"},
    Wizard_Next: {English: "Next", Arabic: "التالي"},
    Wizard_Save: {English: "Save", Arabic: "حفظ"}

});

export default InformedConsentFormKwtTextEnum;