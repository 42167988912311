import React, {Component} from "react";
import Select from "react-select";
import TextInput from "../../../../components/text-input/TextInput";
import {Modal, NumberInput, RadioInput, TextareaInput} from "../../../../components";
import PrimeDateInput from "../../../../components/date-input/PrimeDateInput";
import UOMEnum from "./enums/UOMEnum";
import PrescriptionTimeUnitEnum from "./enums/PrescriptionTimeUnitEnum";
import MedicationRouteEnum from "../subjective/enums/medicationuse/MedicationRouteEnum";
import MedicationUseView from "../subjective/views/MedicationUseView";
import AllergyView from "../subjective/views/AllergyView";
import {Button} from "react-bootstrap";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import PreviousPrescriptionsView from "./PreviousPrescriptionsView";
import {noop} from "lodash-es";
import Tooltip from "../../../../components/tooltip/Tooltip";
import AuthService from "../../../../services/auth-service/AuthService";
import ObjectUtil from "../../../../utils/ObjectUtil";
import FormField from "../../../../components/form/FormField";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {emrActions} from "../../../../services/actions";
import {store} from "../../../../utils/store";
import NotificationService from "../../../../services/notification-service/NotificationService";
import {AllergyChecker} from "../subjective/checkers/AllergyChecker";
import {MedicationUseChecker} from "../subjective/checkers/MedicationUseChecker";
import AddButton from "../common/table/AddButton";
import RemoveButton from "../common/table/RemoveButton";
import {PrintablePrescription} from "./PrintablePrescription";
import DiagnosisGroup from "../treatment/views/DiagnosisGroup";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        textAlign: 'left',
        maxWidth: '400px',
        wordBreak: 'break-word',
        fontSize: '12px',

    }),
    menu: styles => ({
        ...styles,
        width: "max-content",
        minWidth: "100%"
    })
}

export default class Prescription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDubai: AuthService.getCountries().includes("UAE"),
            prescription: this.createEmptyPrescription(),
            prescriptionItem: this.createEmptyPrescriptionItem(),
            prescriptions: [],
            prescriptionFormDisabled: false,
            getReferenceNoDisabled: false,
            cancelReferenceNoDisabled: true,
            printWindowVisible: false,
            diagnosisGroups: []
        };
        this.MINIMUM_SEARCH_LENGTH = 2;
    }

    createEmptyPrescription() {
        return {
            prescriptionItems: []
        };
    }

    createEmptyPrescriptionItem() {
        return {
            frequency: {},
            duration: {}
        }
    }

    componentDidMount() {
        this.getPreviousPrescriptions();
        this.getDiagnosisGroups();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentVisit.diagnosis.diagnosisGroups !== this.props.currentVisit.diagnosis.diagnosisGroups) {
            this.getDiagnosisGroups();
        }
    }

    refresh(callback = noop) {
        this.state.prescription = this.createEmptyPrescription();
        this.state.prescriptionItem = this.createEmptyPrescriptionItem();
        this.state.prescriptionFormDisabled = false;
        this.state.getReferenceNoDisabled = false;
        this.state.cancelReferenceNoDisabled = true;
        this.forceUpdate(() => {
            this.getPreviousPrescriptions();
            ValidationUtil.clear(this.prescriptionFields);
            ValidationUtil.clear(this.prescriptionItemFields);
            callback();
        });
    }

    getPreviousPrescriptions() {
        const patientId = this.props.patientId;
        RemotingService.getRemoteCall(`api/emr/patient/${patientId}/prescription`, null, prescriptions => {
            this.setState({prescriptions: prescriptions});
            store.dispatch(emrActions.updatePrescriptions(prescriptions));
        });
    }

    getDiagnosisGroups() {
        const diagnosisGroups = this.props.currentVisit.diagnosis.diagnosisGroups;

        let diagnosisGroupsWithPrincipalItem = [];
        diagnosisGroups.forEach(dg => {
            const principalItem = dg.diagnosisItems.find(i => i.level == "PRINCIPAL");
            if (principalItem) {
                diagnosisGroupsWithPrincipalItem.push(dg);
            }
        });

        this.setState({diagnosisGroups: diagnosisGroupsWithPrincipalItem});
    }

    calculateEndDate(startDate, duration) {
        if (startDate && duration && duration.value && duration.unit) {
            return DateUtil.add(startDate, duration.value, duration.unit);
        }
    }

    calculateEndDateAndBindToState() {
        this.setState(prevState => {
            prevState.prescriptionItem.endDate = this.calculateEndDate(prevState.prescriptionItem.startDate, prevState.prescriptionItem.duration);
            return {...prevState}
        });
    }

    calculateQuantity() {
        const frequency = this.state.prescriptionItem.frequency;
        const duration = this.state.prescriptionItem.duration;
        if (frequency.value && frequency.unit && duration.value && duration.unit) {
            const frequencyByDay = frequency.value / PrescriptionTimeUnitEnum[frequency.unit].days;
            const durationByDay = duration.value * PrescriptionTimeUnitEnum[duration.unit].days;
            this.state.prescriptionItem.quantity = Math.floor(frequencyByDay * durationByDay);
        }
    }

    clearPrescriptionForReuse(prescription) {
        prescription.id = undefined;
        prescription.creationDate = undefined;
        prescription.updateDate = undefined;
        prescription.version = undefined;
        prescription.staff = undefined;
        prescription.patient = undefined;
        prescription.prescriptionItems.forEach(prescriptionItem => {
            prescriptionItem.id = undefined;
        });
    }

    printRx() {
        if (!ValidationUtil.checkWithNotification(this.prescriptionFields)) {
            return;
        }

        const prescriptionId = this.state.prescription.id;
        if (prescriptionId == null) {
            const visitId = this.props.currentVisit.id;
            RemotingService.postRemoteCall(`api/emr/visit/${visitId}/prescription/print`, this.state.prescription, prescription => {
                RemotingService.instance.get('api/file/download/' + prescription.pdfPath, {responseType: 'blob'})
                    .then((response) => {
                        prescription["pdfData"] = response.data;
                        this.refresh(() => this.setState({
                            prescriptionToPrint: prescription,
                            printWindowVisible: true
                        }));
                    });
            });
        } else {
            let updatePrescriptionDto = {
                remarks: this.state.prescription.remarks,
                medicationReconciled: this.state.prescription.medicationReconciled
            };

            RemotingService.postRemoteCall(`api/emr/prescription/${prescriptionId}/update`, updatePrescriptionDto, (updatedPrescription) => {
                const prescriptionToPrint = ObjectUtil.clone(updatedPrescription);
                RemotingService.instance.get('api/file/download/' + prescriptionToPrint.pdfPath, {responseType: 'blob'})
                    .then((response) => {
                        prescriptionToPrint["pdfData"] = response.data;
                        this.refresh(() => this.setState({
                            prescriptionToPrint: prescriptionToPrint,
                            printWindowVisible: true
                        }))
                    });
            }, null, null, false);
        }
    }

    onPrintClosed() {
        this.setState({
            printWindowVisible: false
        });
    }

    save() {
        if (!ValidationUtil.checkWithNotification(this.prescriptionFields)) {
            return;
        }

        const prescriptionId = this.state.prescription.id;
        if (prescriptionId == null) {
            const visitId = this.props.currentVisit.id;
            RemotingService.postRemoteCall(`api/emr/visit/${visitId}/prescription/save`, this.state.prescription, () => {
                this.refresh();
            });
        } else {
            let updatePrescriptionDto = {
                remarks: this.state.prescription.remarks,
                medicationReconciled: this.state.prescription.medicationReconciled
            };

            RemotingService.postRemoteCall(`api/emr/prescription/${prescriptionId}/update`, updatePrescriptionDto, () => {
                this.refresh();
            }, null, null, false);
        }
    }

    getReferenceNo() {
        if (!ValidationUtil.checkWithNotification(this.prescriptionFields)) {
            return;
        }

        if (this.state.prescription.prescriptionItems.filter(i => i.medicationId != null).length === 0) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'There is no any suitable medication for Eclaim.'
            });
            return;
        }

        const visitId = this.props.currentVisit.id;
        const prescriptionDto = ObjectUtil.clone(this.state.prescription);
        this.clearPrescriptionForReuse(prescriptionDto);
        RemotingService.postRemoteCall(`api/emr/visit/${visitId}/prescription-reference-no`, prescriptionDto, prescription => {
            if (prescription) {
                this.setState({prescription: prescription});
            }
        }, noop, true, true, messages => {
            const eclaimSuccessMessage = RemotingService.filterMessagesByCode(messages, "ECLAIMLINK_UPLOAD_REQUEST_SUCCESSFUL");
            if (eclaimSuccessMessage.length > 0) {
                this.setState({
                    prescriptionFormDisabled: true,
                    getReferenceNoDisabled: true,
                    cancelReferenceNoDisabled: false
                });
            }
        });
    }

    cancelReferenceNo() {
        const prescriptionId = this.state.prescription.id;
        RemotingService.postRemoteCall(`api/emr/prescription/${prescriptionId}/prescription-cancel-reference-no`, null, prescription => {
            this.clearPrescriptionForReuse(prescription);
            this.setState({
                prescription: prescription,
                prescriptionFormDisabled: false,
                getReferenceNoDisabled: false,
                cancelReferenceNoDisabled: true
            });
        });
    }

    cancel() {
        this.refresh();
    }

    handleGetReferenceNo(prescription) {
        this.setState({
            prescription: prescription,
            prescriptionFormDisabled: true,
            getReferenceNoDisabled: true,
            cancelReferenceNoDisabled: false,
        }, () => {
            this.getPreviousPrescriptions()
        });
    }

    handleReorder(prescription) {
        const prescriptionToReorder = ObjectUtil.clone(prescription);
        this.clearPrescriptionForReuse(prescriptionToReorder);
        prescriptionToReorder.erxRefNo = undefined;
        prescriptionToReorder.medicationReconciled = undefined;
        prescriptionToReorder.remarks = undefined;
        prescriptionToReorder.prescriptionItems.forEach(prescriptionItem => {
            prescriptionItem.startDate = undefined;
            prescriptionItem.endDate = undefined;
        });
        this.setState({prescription: prescriptionToReorder});
    }

    noOptionsMessage = (input) => {
        if (input.inputValue == null || input.inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
        } else {
            return <div>No options</div>;
        }
    }

    loadMedicationsWithName = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/medication/', {name: inputValue}, (medications) => {
            const result = [{value: inputValue, label: inputValue}];

            medications.forEach(m => {
                result.push({value: m, label: m.name});
            });

            callback(result);
        });
    }

    loadMedicationsWithActiveIngredient = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/medication/ingredient/', {activeIngredient: inputValue}, (medications) => {
            const result = [{value: inputValue, label: inputValue}];

            medications.forEach(m => {
                result.push({value: m, label: m.activeIngredient});
            });

            callback(result);
        });
    }

    handleMedicationSelected = (medication) => {
        this.state.selectedMedication = medication;
        this.state.prescriptionItem.form = medication.form;
        this.state.prescriptionItem.strength = medication.strength;
        this.state.prescriptionItem.medicationId = medication.id;
        if (medication.routes.length === 1) {
            this.state.prescriptionItem.route = medication.routes[0];
        }
        this.forceUpdate();
    }

    handleMedicationBrandSelected = (name) => {
        if (this.state.selectedMedication) {
            this.state.selectedMedication.name = name;
        } else {
            this.state.selectedMedication = {name: name};
        }
        this.state.prescriptionItem.medicationId = undefined;
        this.forceUpdate();
    }

    handleMedicationIngredientSelected = (activeIngredient) => {
        if (this.state.selectedMedication) {
            this.state.selectedMedication.activeIngredient = activeIngredient;
        } else {
            this.state.selectedMedication = {activeIngredient: activeIngredient};
        }
        this.state.prescriptionItem.medicationId = undefined;
        this.forceUpdate();
    }


    render() {
        this.prescriptionFields = [];
        this.prescriptionItemFields = [];
        const {subjective, currentVisit} = this.props;

        let routeOptions = [];
        if (this.state.selectedMedication) {
            if (this.state.selectedMedication.routes) {
                routeOptions = this.state.selectedMedication.routes.map(r => {
                    return {value: r, label: MedicationRouteEnum[r].name}
                });
            } else {
                routeOptions = MedicationRouteEnum.toArray().map(s => {
                    return {value: s.key, label: s.name}
                });
            }
        }

        return (
            <div>
                {!this.props.disabled && AuthService.userHasAuthority("UPDATE_EMR_PRESCRIPTION") &&
                <div className="content-row">
                    <div className="content-col-12">
                        <div className="content-wrapper">
                            <div>
                                <div className="content-label">Add Prescription</div>
                            </div>
                            <div className="content-card" style={{width: '100%'}}>
                                <div style={{width: '100%'}}>
                                    <FormField ref={formField => this.prescriptionFields.push(formField)}
                                               min={1} minMessage="No medication added."
                                               validateOn={this.state.prescription.prescriptionItems.length}>
                                        <input type="hidden"/>
                                    </FormField>
                                </div>
                                <table className="emr-table">
                                    <tbody>
                                    <tr>
                                        <th style={{width: 120}}>ICD</th>
                                        <th style={{width: 250}}>Brand Name</th>
                                        <th style={{width: 200}}>Active Ingredient</th>
                                        <th>Strength</th>
                                        <th>Form</th>
                                        <th style={{width: 120}}>UOM</th>
                                        <th style={{width: 170}}>Frequency</th>
                                        <th>Instruction</th>
                                        <th style={{width: 130}}>Duration</th>
                                        <th style={{width: 130}}>Route</th>
                                        <th>Qty</th>
                                        <th>Refill</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th style={{color: "red"}}>ⓧ</th>
                                    </tr>
                                    {this.state.prescription.prescriptionItems.map((item, index) => {
                                            return (
                                                <tr key={index} style={{backgroundColor: "white"}}>
                                                    <td><DiagnosisGroup diagnosisGroup={item.diagnosisGroup} showSecondaryItems/></td>
                                                    <td>{item.brand}</td>
                                                    <td>{item.activeIngredient}</td>
                                                    <td>{item.strength}</td>
                                                    <td>{item.form}</td>
                                                    <td>{item.uom ? UOMEnum[item.uom].name : null}</td>
                                                    <td>{item.frequency && item.frequency.value && item.frequency.unit ?
                                                        item.frequency.value + " per " + PrescriptionTimeUnitEnum[item.frequency.unit].name : null}
                                                    </td>
                                                    <td>{item.instruction}</td>
                                                    <td>{item.duration && item.duration.value && item.duration.unit ?
                                                        item.duration.value + " " + PrescriptionTimeUnitEnum[item.duration.unit].name : null}
                                                    </td>
                                                    <td>{item.route ? MedicationRouteEnum[item.route].name : null}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.refill}</td>
                                                    <td>
                                                        {item.startDate ? DateUtil.formatDateMMM(item.startDate) :
                                                            <FormField
                                                                ref={formField => this.prescriptionFields.push(formField)}
                                                                required validateOn={item.startDate}>
                                                                <PrimeDateInput value={item.startDate}
                                                                                onChange={date => {
                                                                                    item.startDate = date;
                                                                                    item.endDate = this.calculateEndDate(item.startDate, item.duration);
                                                                                    this.forceUpdate();
                                                                                }}
                                                                                disabled={this.state.prescriptionFormDisabled}
                                                                                disablePast
                                                                                style={{width: 90}}
                                                                                inputStyle={{padding: 1}}/>
                                                            </FormField>
                                                        }
                                                    </td>
                                                    <td>{DateUtil.formatDateMMM(item.endDate)}</td>
                                                    <td>
                                                        {
                                                            !this.state.prescriptionFormDisabled &&
                                                            <RemoveButton
                                                                onClick={() => {
                                                                    this.state.prescription.prescriptionItems.splice(index, 1);
                                                                    this.forceUpdate();
                                                                }}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                                    }
                                    <tr style={{backgroundColor: "white"}}>
                                        <td>
                                            <FormField
                                                ref={formField => this.prescriptionItemFields.push(formField)}
                                                required validateOn={this.state.prescriptionItem.diagnosisGroup}>
                                                <Select
                                                    className="emr-in-table-select-container active-ingredient-dropdown"
                                                    classNamePrefix="emr-in-table-select"
                                                    styles={customStyles}
                                                    value={this.state.prescriptionItem.diagnosisGroup != null ?
                                                        {
                                                            value: this.state.prescriptionItem.diagnosisGroup,
                                                            label: <DiagnosisGroup diagnosisGroup={this.state.prescriptionItem.diagnosisGroup}/>
                                                        } : null}
                                                    onChange={(event) => {
                                                        this.state.prescriptionItem.diagnosisGroup = event.value;
                                                        this.forceUpdate();
                                                    }}
                                                    options={this.state.diagnosisGroups.map(diagnosisGroup => {
                                                        return {
                                                            value: diagnosisGroup,
                                                            label: <DiagnosisGroup diagnosisGroup={diagnosisGroup} showSecondaryItems/>
                                                        }
                                                    })}
                                                    isDisabled={this.state.prescriptionFormDisabled}
                                                />
                                            </FormField>
                                        </td>
                                        <td className="px-2" style={{minWidth: "80px"}}>
                                            <Tooltip title={this.state.selectedMedication != null ?
                                                this.state.selectedMedication.name : ""}>
                                                <FormField
                                                    ref={formField => this.prescriptionItemFields.push(formField)}
                                                    required validateOn={this.state.selectedMedication}>
                                                    <AsyncSelect
                                                        className={"emr-in-table-select-container"}
                                                        classNamePrefix={"emr-in-table-select"}
                                                        styles={customStyles}
                                                        noOptionsMessage={this.noOptionsMessage}
                                                        loadOptions={this.loadMedicationsWithName}
                                                        value={this.state.selectedMedication != null ?
                                                            {
                                                                value: this.state.selectedMedication,
                                                                label: this.state.selectedMedication.name,
                                                            } : null}
                                                        onChange={event => {
                                                            if (typeof event.value === "string") {
                                                                this.handleMedicationBrandSelected(event.value);
                                                            } else {
                                                                this.handleMedicationSelected(event.value);
                                                            }
                                                        }}
                                                        isDisabled={this.state.prescriptionFormDisabled}
                                                    />
                                                </FormField>
                                            </Tooltip>
                                        </td>
                                        <td className="px-1" width={"400"}>
                                            <Tooltip
                                                title={(this.state.selectedMedication && this.state.selectedMedication.activeIngredient) ?
                                                    this.state.selectedMedication.activeIngredient : ""}>
                                                <FormField
                                                    ref={formField => this.prescriptionItemFields.push(formField)}
                                                    required validateOn={this.state.selectedMedication}>
                                                    <AsyncSelect
                                                        className={"emr-in-table-select-container"}
                                                        classNamePrefix={"emr-in-table-select"}
                                                        styles={customStyles}
                                                        noOptionsMessage={this.noOptionsMessage}
                                                        loadOptions={this.loadMedicationsWithActiveIngredient}
                                                        value={this.state.selectedMedication != null ?
                                                            {
                                                                value: this.state.selectedMedication,
                                                                label: this.state.selectedMedication.activeIngredient,
                                                            } : null}
                                                        onChange={event => {
                                                            if (typeof event.value === "string") {
                                                                this.handleMedicationIngredientSelected(event.value);
                                                            } else {
                                                                this.handleMedicationSelected(event.value);
                                                            }
                                                        }}
                                                        isDisabled={this.state.prescriptionFormDisabled}
                                                    />
                                                </FormField>
                                            </Tooltip>
                                        </td>
                                        <td className="px-1">
                                            <FormField ref={formField => this.prescriptionItemFields.push(formField)}
                                                       required validateOn={this.state.prescriptionItem.strength}>
                                                <TextInput value={this.state.prescriptionItem.strength || ''}
                                                           onChange={value => {
                                                               this.state.prescriptionItem.strength = value;
                                                               this.forceUpdate();
                                                           }}
                                                           disabled={this.state.prescriptionFormDisabled}/>
                                            </FormField>
                                        </td>
                                        <td className="px-1">
                                            <Tooltip title={this.state.prescriptionItem.form != null ?
                                                this.state.prescriptionItem.form : ""}>
                                                <FormField
                                                    ref={formField => this.prescriptionItemFields.push(formField)}
                                                    required validateOn={this.state.prescriptionItem.form}>
                                                    <TextInput value={this.state.prescriptionItem.form || ''}
                                                               onChange={value => {
                                                                   this.state.prescriptionItem.form = value;
                                                                   this.forceUpdate();
                                                               }}
                                                               disabled={this.state.prescriptionFormDisabled}/>
                                                </FormField>
                                            </Tooltip>
                                        </td>
                                        <td className="px-1">
                                            <FormField ref={formField => this.prescriptionItemFields.push(formField)}
                                                       required validateOn={this.state.prescriptionItem.uom}>
                                                <Select
                                                    className="emr-in-table-select-container active-ingredient-dropdown"
                                                    classNamePrefix="emr-in-table-select"
                                                    styles={customStyles}
                                                    value={this.state.prescriptionItem.uom != null ?
                                                        {
                                                            value: this.state.prescriptionItem.uom,
                                                            label: UOMEnum[this.state.prescriptionItem.uom].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        this.state.prescriptionItem.uom = event.value;
                                                        this.forceUpdate();
                                                    }}
                                                    options={UOMEnum.toArray().map(s => {
                                                        return {value: s.key, label: s.name}
                                                    })}
                                                    isDisabled={this.state.prescriptionFormDisabled}
                                                />
                                            </FormField>
                                        </td>
                                        <td className="px-1">
                                            <div className="d-flex align-items-baseline">
                                                <FormField
                                                    ref={formField => this.prescriptionItemFields.push(formField)}
                                                    required min={1}
                                                    validateOn={this.state.prescriptionItem.frequency.value}>
                                                    <NumberInput style={{width: 50}}
                                                                 min={0}
                                                                 value={this.state.prescriptionItem.frequency.value || ''}
                                                                 onChange={value => {
                                                                     this.state.prescriptionItem.frequency.value = value;
                                                                     this.calculateQuantity();
                                                                     this.forceUpdate();
                                                                 }}
                                                                 disabled={this.state.prescriptionFormDisabled}/>
                                                </FormField>
                                                <div style={{
                                                    whiteSpace: 'pre',
                                                    alignSelf: 'center',
                                                    paddingLeft: 5,
                                                    paddingBottom: 3
                                                }}>per
                                                </div>
                                                <FormField
                                                    className="w-100"
                                                    ref={formField => this.prescriptionItemFields.push(formField)}
                                                    required validateOn={this.state.prescriptionItem.frequency.unit}>
                                                    <Select className="emr-in-table-select-container"
                                                            classNamePrefix="emr-in-table-select"
                                                            styles={customStyles}
                                                            value={this.state.prescriptionItem.frequency.unit != null ?
                                                                {
                                                                    value: this.state.prescriptionItem.frequency.unit,
                                                                    label: PrescriptionTimeUnitEnum[this.state.prescriptionItem.frequency.unit].name
                                                                } : null}
                                                            onChange={(event) => {
                                                                this.state.prescriptionItem.frequency.unit = event.value;
                                                                this.calculateQuantity();
                                                                this.forceUpdate();
                                                            }}
                                                            options={PrescriptionTimeUnitEnum.toArray().map(s => {
                                                                return {value: s.key, label: s.name}
                                                            })}
                                                            isDisabled={this.state.prescriptionFormDisabled}/>
                                                </FormField>
                                            </div>

                                        </td>
                                        <td className="px-1">
                                            <FormField ref={formField => this.prescriptionItemFields.push(formField)}
                                                       required validateOn={this.state.prescriptionItem.instruction}>
                                                <TextInput value={this.state.prescriptionItem.instruction || ''}
                                                           onChange={value => {
                                                               this.state.prescriptionItem.instruction = value;
                                                               this.forceUpdate();
                                                           }}
                                                           disabled={this.state.prescriptionFormDisabled}/>
                                            </FormField>
                                        </td>
                                        <td className="px-1">
                                            <div style={{display: 'flex'}}>
                                                <FormField
                                                    ref={formField => this.prescriptionItemFields.push(formField)}
                                                    required min={1}
                                                    validateOn={this.state.prescriptionItem.duration.value}>
                                                    <NumberInput style={{width: 50}}
                                                                 min={0}
                                                                 value={this.state.prescriptionItem.duration.value || ''}
                                                                 onChange={value => {
                                                                     this.state.prescriptionItem.duration.value = value;
                                                                     this.calculateEndDateAndBindToState();
                                                                     this.calculateQuantity();
                                                                     this.forceUpdate();
                                                                 }}
                                                                 disabled={this.state.prescriptionFormDisabled}/>
                                                </FormField>
                                                <FormField
                                                    className="w-100"
                                                    ref={formField => this.prescriptionItemFields.push(formField)}
                                                    required validateOn={this.state.prescriptionItem.duration.unit}>
                                                    <Select className="emr-in-table-select-container w-100"
                                                            classNamePrefix="emr-in-table-select"
                                                            styles={customStyles}
                                                            value={this.state.prescriptionItem.duration.unit != null ?
                                                                {
                                                                    value: this.state.prescriptionItem.duration.unit,
                                                                    label: PrescriptionTimeUnitEnum[this.state.prescriptionItem.duration.unit].name
                                                                } : null}
                                                            onChange={(event) => {
                                                                this.state.prescriptionItem.duration.unit = event.value;
                                                                this.calculateEndDateAndBindToState();
                                                                this.calculateQuantity();
                                                                this.forceUpdate();
                                                            }}
                                                            options={PrescriptionTimeUnitEnum.toArray().map(s => {
                                                                return {value: s.key, label: s.name}
                                                            })}
                                                            isDisabled={this.state.prescriptionFormDisabled}/>
                                                </FormField>
                                            </div>
                                        </td>
                                        <td className="px-1">
                                            <FormField ref={formField => this.prescriptionItemFields.push(formField)}
                                                       required validateOn={this.state.prescriptionItem.route}>
                                                <Select className="emr-in-table-select-container"
                                                        classNamePrefix="emr-in-table-select"
                                                        value={this.state.prescriptionItem.route != null ?
                                                            {
                                                                value: this.state.prescriptionItem.route,
                                                                label: MedicationRouteEnum[this.state.prescriptionItem.route].name
                                                            } : null}
                                                        onChange={(event) => {
                                                            this.state.prescriptionItem.route = event.value;
                                                            this.forceUpdate();
                                                        }}
                                                        options={routeOptions}
                                                        isDisabled={this.state.prescriptionFormDisabled}/>
                                            </FormField>
                                        </td>
                                        <td className="px-1">
                                            <NumberInput style={{width: 50}}
                                                         disabled
                                                         value={this.state.prescriptionItem.quantity == null ? '' : this.state.prescriptionItem.quantity}/>
                                        </td>
                                        <td className="px-1">
                                            <FormField ref={formField => this.prescriptionItemFields.push(formField)}
                                                       required min={0} validateOn={this.state.prescriptionItem.refill}>
                                                <NumberInput style={{width: 50}}
                                                             value={this.state.prescriptionItem.refill || ''}
                                                             min={0}
                                                             onChange={value => {
                                                                 this.state.prescriptionItem.refill = value;
                                                                 this.forceUpdate();
                                                             }}
                                                             disabled={this.state.prescriptionFormDisabled}/>
                                            </FormField>
                                        </td>
                                        <td className="px-1">
                                            <FormField ref={formField => this.prescriptionItemFields.push(formField)}
                                                       required maxDate={this.state.prescriptionItem.endDate}
                                                       disabled={!this.state.prescriptionItem.endDate}
                                                       validateOn={this.state.prescriptionItem.startDate}>
                                                <PrimeDateInput value={this.state.prescriptionItem.startDate}
                                                                onChange={date => {
                                                                    this.state.prescriptionItem.startDate = date;
                                                                    this.calculateEndDateAndBindToState();
                                                                    this.forceUpdate();
                                                                }}
                                                                disabled={this.state.prescriptionFormDisabled}
                                                                disablePast
                                                                style={{width: 110}}
                                                                inputStyle={{padding: 1}}/>
                                            </FormField>
                                        </td>
                                        <td className="px-1">
                                            <PrimeDateInput value={this.state.prescriptionItem.endDate}
                                                            disabled
                                                            style={{width: 110}}
                                                            inputStyle={{padding: 1}}/>
                                        </td>
                                        <td className="px-1">
                                            <AddButton
                                                onClick={() => {
                                                    if (!this.state.prescriptionFormDisabled) {
                                                        if (!ValidationUtil.checkWithNotification(this.prescriptionItemFields)) {
                                                            return;
                                                        }

                                                        const {
                                                            name,
                                                            activeIngredient
                                                        } = this.state.selectedMedication || {};
                                                        this.state.prescriptionItem.brand = name;
                                                        this.state.prescriptionItem.activeIngredient = activeIngredient;

                                                        this.state.prescription.prescriptionItems.push(this.state.prescriptionItem);
                                                        this.setState({
                                                                prescriptionItem: this.createEmptyPrescriptionItem(),
                                                                selectedMedication: null
                                                            },
                                                            () => {
                                                                ValidationUtil.clear(this.prescriptionItemFields);
                                                            });
                                                    }
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <div className="d-flex justify-content-space-between align-items-baseline mt-4">
                                    <div className="content-label">Remarks:</div>
                                    <span className="font-weight-bold mr-1">Medication Reconciled:</span>
                                    <FormField ref={formField => this.prescriptionFields.push(formField)}
                                               required
                                               validateOn={this.state.prescription.medicationReconciled}>
                                        <RadioInput className="pt-2 mr-2"
                                                    displayProperty="label"
                                                    valueProperty="value"
                                                    options={[
                                                        {label: "Yes", value: true},
                                                        {label: "No", value: false}
                                                    ]}
                                                    value={this.state.prescription.medicationReconciled || ''}
                                                    onChange={value => {
                                                        this.state.prescription.medicationReconciled = value;
                                                        this.forceUpdate();
                                                    }}/>
                                    </FormField>

                                    {this.state.isDubai &&
                                    <>
                                        <span className="font-weight-bold mr-1">eRx Ref. No:</span>
                                        <NumberInput style={{marginRight: 3, width: 80}} disabled
                                                     value={this.state.prescription.erxRefNo == null ? '' : this.state.prescription.erxRefNo}
                                                     onChange={value => {
                                                         this.state.prescription.erxRefNo = value;
                                                         this.forceUpdate()
                                                     }}/>
                                        <Button size="sm" className="mx-1" onClick={() => this.getReferenceNo()}
                                                disabled={this.state.getReferenceNoDisabled}>Eclaim eRx</Button>
                                        <Button size="sm" className="mx-1" onClick={() => this.cancelReferenceNo()}
                                                disabled={this.state.cancelReferenceNoDisabled}>Cancel eRx</Button>
                                    </>
                                    }

                                </div>
                                <TextareaInput style={{width: '100%'}} value={this.state.prescription.remarks || ''}
                                               onChange={value => {
                                                   this.state.prescription.remarks = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div className="d-flex p-justify-end pt-1">
                                <Button size="sm" className="mx-1" onClick={() => this.printRx()}>Print Rx</Button>
                                <Button size="sm" className="mx-1" onClick={() => this.save()}>Save</Button>
                                <Button size="sm" className="mx-1" onClick={() => this.cancel()}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="content-row">
                    <div className="content-col-12">
                        <div className="content-wrapper">
                            <div className="content-label">Previous Prescriptions</div>
                            <PreviousPrescriptionsView disabled={this.props.disabled}
                                                       prescriptions={this.state.prescriptions}
                                                       visit={currentVisit}
                                                       isDubai={this.state.isDubai}
                                                       onReorder={(prescription) => this.handleReorder(prescription)}
                                                       onGetReferenceNo={(prescription) => this.handleGetReferenceNo(prescription)}
                                                       refreshData={() => this.getPreviousPrescriptions()}/>
                        </div>
                    </div>
                </div>
                <div className="content-row pl-1">
                    <div className="content-col-6" style={{paddingleft: 0}}>
                        <div className="content-wrapper">
                            <div className="d-flex align-items-center">
                                <div className="content-label">Medications</div>
                                <div>{MedicationUseChecker.isNone(subjective.medicationUse) ? "No Medication" : ""}</div>
                            </div>
                            {MedicationUseChecker.isDataEntered(subjective.medicationUse) &&
                            <div className={"content-card"}>
                                <MedicationUseView medicationUse={subjective.medicationUse}/>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="content-col-6">
                        <div className="content-wrapper">
                            <div className="d-flex align-items-center">
                                <div className="content-label">Allergies</div>
                                <div>{AllergyChecker.isNone(subjective.allergy) ? "None Known" : ""}</div>
                            </div>
                            {AllergyChecker.isDataEntered(subjective.allergy) &&
                            <div className={"content-card"}>
                                <AllergyView allergy={subjective.allergy}/>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.printWindowVisible &&
                    <Modal visible={true} header="Prescription Printable" closeAction={() => this.onPrintClosed()}>
                        <PrintablePrescription prescription={this.state.prescriptionToPrint}/>
                    </Modal>
                }
            </div>
        )
    }

}
