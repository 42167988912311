import React from 'react';
import DateUtil from "../../../../utils/DateUtil";
import AuthService from "../../../../services/auth-service/AuthService";
import RemotingService from '../../../../services/remoting-service/RemotingService';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormBase, FormField, FormSection} from "../../../../components/form";
import {Card, Form, ListGroup, ListGroupItem} from 'react-bootstrap';
import {DateInput, Modal, SelectInput, TextareaInput, TextInput, PhoneInput} from "../../../../components";
import EnumGender from "../../../../enums/EnumGender";
import EnumLanguage from "../../../../enums/EnumLanguage";
import StaffProfileImage from "./StaffProfileImage";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import PrimeDateInput from "../../../../components/date-input/PrimeDateInput";
import moment from "moment";
import PhoneNumberUtil from "../../../../utils/PhoneNumberUtil";
import EnumSalutation from "../../enums/EnumSalutation";
import Tooltip from "../../../../components/tooltip/Tooltip";

class StaffProfileLeftPart extends React.Component {

    constructor(props) {
        super(props);
        let {
            id, staffType, title, salutation, qualification, name, gender, nationality, mobilePhone, email, address,
            joiningDate, leaveDate, birthday, thumbnail, speciality, skills, languages, departments,
            linkedinLink, twitterLink, instagramLink, facebookLink , clinicianLicense,licenceIssueDate
            , licenceExpiryDate
        } = this.props.staff;

        this.state = {
            id: id,
            staffType: staffType,
            name: name,
            birthday: birthday ? DateUtil.parse(birthday, "YYYY-MM-DD").toDate() : new Date(),
            gender: gender,
            nationality: nationality,
            mobilePhone: mobilePhone,
            email: email,
            address: address,
            title: title,
            salutation: salutation,
            qualification: qualification,
            joiningDate: joiningDate,
            leaveDate: leaveDate,
            thumbnail: thumbnail,
            speciality: speciality,
            specialityList: null,
            skills: skills,
            languages: languages,
            departments: departments,
            linkedinLink: linkedinLink,
            twitterLink: twitterLink,
            instagramLink: instagramLink,
            facebookLink: facebookLink,
            clinicianLicense: clinicianLicense,
            licenceIssueDate: licenceIssueDate,
            licenceExpiryDate: licenceExpiryDate,
            newDepartments: departments.map(d => d.id),
            newClinics: [...new Set(departments.map(d => d.clinic.id))],
            newGender: gender,
            newBirthday: birthday ? DateUtil.parse(birthday, "YYYY-MM-DD").toDate() : new Date(),
            newJoiningDate: joiningDate ? moment.utc(joiningDate).toDate() : new Date(),
            newLeaveDate: leaveDate ? moment.utc(leaveDate).toDate() : null,
            newLanguages: languages,
            newNationality: nationality,
            newMobilePhone: mobilePhone,
            newAddress: address,
            newSalutation: salutation,
            newQualification: qualification,
            newSpeciality: speciality,
            newLinkedinLink: linkedinLink,
            newTwitterLink: twitterLink,
            newInstagramLink: instagramLink,
            newFacebookLink: facebookLink,
            newClinicianLicense: clinicianLicense,
            newIssueDate: licenceIssueDate,
            newExpiryDate: licenceExpiryDate,
            updateDeparmentClinicModalVisible: false,
            updateProfileModalVisible: false,
            updateContactsModalVisible: false,
            updateLinksModalVisible: false,
            updateLicenceModalVisible: false
        };
    }

    state = {
        updateProfileModalVisible: false,
        updateContactsModalVisible: false,
        updateLinksModalVisible: false,
        updateLicenceModalVisible: false
    }

    render() {
        return (
            <div>
                <Card style={{height: "100%", minWidth:"265px"}}>
                    <Card.Body>
                        <div class="d-flex justify-content-end">
                            {AuthService.isSuperUser() &&
                            <FontAwesomeIcon icon={["fas", "edit"]} onClick={this.handleEditDepartmentAndClinic}/>}
                        </div>
                        <StaffProfileImage editable={this.props.editable} staffId={this.state.id}/>
                        <Card.Title style={{display: 'flex', justifyContent: 'center'}}>{this.state.name}</Card.Title>
                        {this.state.departments.map(department => (
                            <Card.Subtitle className="mb-2 text-muted"
                                           style={{display: 'flex', justifyContent: 'center'}}>
                                {department.clinic.name + ' / ' + department.name}
                            </Card.Subtitle>
                        ))}
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <div class="d-flex justify-content-between">
                                <Card.Title>Profile</Card.Title>
                                {(this.props.editable &&
                                    <FontAwesomeIcon icon={["fas", "edit"]}
                                                     onClick={this.handleEditProfile}/>
                                )}
                            </div>

                            <div style={{width: '100%', marginBottom: '0rem'}}>
                                <FormBase>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Age/Gender">
                                        {(this.state.birthday ? DateUtil.getAge(this.state.birthday) : '-') + '/' + (this.state.gender ? EnumGender[this.state.gender] : '-')}
                                    </FormSection>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Speciality">
                                        {this.state.speciality.name}
                                    </FormSection>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Date of Birth">
                                        {DateUtil.formatDateMMM(this.state.birthday)}
                                    </FormSection>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Nationality">
                                        {this.state.nationality?.nameInEnglish}
                                    </FormSection>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Languages">
                                        {this.state.languages.map(l => l.name).join(", ")}
                                    </FormSection>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Joining Date">
                                        {DateUtil.formatDateMMM(this.state.joiningDate)}
                                    </FormSection>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Leave Date">
                                        {DateUtil.formatDateMMM(this.state.leaveDate)}
                                    </FormSection>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Salutation">
                                        <label>
                                            {EnumSalutation[this.state.salutation]?.name}
                                        </label>
                                    </FormSection>
                                    <FormSection labelPercent={5} inputPercent={7} sectionId={1} label="Qualification">
                                        <label>
                                            {this.state.qualification}
                                        </label>
                                    </FormSection>
                                </FormBase>
                            </div>

                        </ListGroupItem>
                        <ListGroupItem>
                            <div class="d-flex justify-content-between">
                                <Card.Title>Contact</Card.Title>
                                {(this.props.editable &&
                                    <FontAwesomeIcon icon={["fas", "edit"]}
                                                     onClick={() => this.setState({updateContactsModalVisible: true})}/>
                                )}
                            </div>
                            <div>
                                <Form>
                                    <IconValuePair iconName="phone" val={PhoneNumberUtil.formatInternational(this.state.mobilePhone)}/>
                                    <IconValuePair iconName="envelope" val={this.state.email}/>
                                    <IconValuePair iconName="map-marker-alt" val={this.state.address}/>
                                </Form>
                            </div>
                        </ListGroupItem>
                        <ListGroupItem>
                            <div class="d-flex justify-content-between">
                                <Card.Title>Licence</Card.Title>
                                {(this.props.editable &&
                                    <FontAwesomeIcon icon={["fas", "edit"]}
                                                     onClick={() => this.setState({updateLicenceModalVisible: true})}/>
                                )}
                            </div>

                            <div style={{width: '100%', marginBottom: '0rem'}}>
                                <FormBase>
                                    <FormSection labelPercent={1} inputPercent={1} sectionId={1} label="Clinician License Number">
                                        {this.state.clinicianLicense}
                                    </FormSection>
                                    <FormSection labelPercent={1} inputPercent={1} sectionId={1} label="Issue Date">
                                        {DateUtil.formatDateMMM(this.state.licenceIssueDate)}
                                    </FormSection>
                                    <FormSection labelPercent={1} inputPercent={1} sectionId={1} label="Expiry Date">
                                        {DateUtil.formatDateMMM(this.state.licenceExpiryDate)}
                                    </FormSection>
                                </FormBase>
                            </div>

                        </ListGroupItem>
                        <ListGroupItem>
                            <div class="d-flex justify-content-between">
                                <Card.Title>Links</Card.Title>
                                {(this.props.editable &&
                                    <FontAwesomeIcon icon={["fas", "edit"]}
                                                     onClick={() => this.setState({updateLinksModalVisible: true})}/>
                                )}
                            </div>
                            <div class="d-flex align-items-start flex-column">
                                <Card.Link class="p-2" href={this.state.instagramLink}>Instagram</Card.Link>
                                <Card.Link class="p-2" href={this.state.facebookLink}>Facebook</Card.Link>
                                <Card.Link class="p-2" href={this.state.linkedinLink}>Linkedin</Card.Link>
                                <Card.Link class="p-2" href={this.state.twitterLink}>Twitter</Card.Link>
                            </div>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
                {this.renderUpdateDepartmentClinicModal()}
                {this.renderUpdateProfileModal()}
                {this.renderUpdateContactsModal()}
                {this.renderUpdateLicenceModal()}
                {this.renderUpdateLinksModal()}
            </div>
        );
    }

    handleEditDepartmentAndClinic = () => {
        RemotingService.getRemoteCall('/api/speciality/list', null, (dtoSpeciality) => {
            RemotingService.getRemoteCall('api/clinic/list?isActive=true&orderBy=id&orderDirection=ASC', null, (dtoClinic) => {
                this.getDepartmentsFromServerByClinicIds(this.state.departments.map(d => d.clinic.id), (dto) => {
                    let departmentList = dto.items.filter(d => d.name != 'Reception' && d.name != 'Management')
                    this.setState({
                        departmentList,
                        clinicList: dtoClinic.items,
                        updateDeparmentClinicModalVisible: true,
                        specialityList: dtoSpeciality,
                        newDepartments: this.state.departments.map(d => d.id),
                        newClinics: [...new Set(this.state.departments.map(d => d.clinic.id))]
                    });
                });
            });
        });
    }

    renderUpdateDepartmentClinicModal = () => {
        return this.state.updateDeparmentClinicModalVisible && (
            <Modal visible={this.state.updateDeparmentClinicModalVisible}
                   header="Update Department And Clinic"
                   submitContent="Update"
                   submitAction={this.handleDepartmentClinicUpdate}

                   closeAction={() => this.setState({updateDeparmentClinicModalVisible: false})}>
                {this.updateDepartmentClinicModalBody()}
            </Modal>);

    }

    updateDepartmentClinicModalBody = () => {
        return (
            <div className={"dim14-dialog-body"}>
                <FormBase>
                    <FormSection sectionId={1} label="Clinics">
                        <SelectInput className={"w-100"} value={this.state.newClinics} options={this.state.clinicList}
                                     displayProperty="name" valueProperty="id" searchEnabled={false} multiSelect
                                     onChange={this.handleClinicSelected}
                        />
                    </FormSection>
                    <FormSection sectionId={1} label="Departments">
                        {this.state.departmentList && (
                            <SelectInput className={"w-100"} value={this.state.newDepartments}
                                         options={this.state.departmentList}
                                         displayProperty="combinedName" valueProperty="id" searchEnabled={false}
                                         multiSelect
                                         onChange={this.handleDepartmentSelected}/>)}
                    </FormSection>
                </FormBase>
            </div>);
    }

    handleClinicSelected = (val) => {
        this.getDepartmentsFromServerByClinicIds(val, (dto) => this.setState({
            newClinics: val,
            departmentList: dto.items
        }))
    }

    getDepartmentsFromServerByClinicIds = (idList, callBack) => {
        if (idList) {
            let clinicIds = idList.map(id => {
                return 'clinicIds=' + id + '&';
            }).join('');
            RemotingService.getRemoteCall('api/clinic/department/list?' + clinicIds + 'isActive=true&orderBy=id&orderDirection=ASC&size=50', null, (dto) => {
                callBack(dto);
            });
        }
    }

    handleDepartmentSelected = (val) => {
        this.setState({newDepartments: val})
    }

    handleDepartmentClinicUpdate = () => {
        const departmentsToUpdate = (this.state.newDepartments && this.state.newDepartments.length > 0) ?
            this.state.departmentList.filter(d => this.state.newDepartments.includes(d.id))
            : [];
        const staffDTO = {
            id: this.state.id,
            departments: departmentsToUpdate
        };
        RemotingService.postRemoteCall('api/staff/update-departments', staffDTO, () => {
            this.setState({
                updateDeparmentClinicModalVisible: false,
                departments: departmentsToUpdate,
            });
        });
    }

    handleEditProfile = () => {
        RemotingService.getRemoteCall('/api/speciality/list', null, (dtoSpeciality) => {
            this.getDepartmentsFromServerByClinicIds(this.state.departments.map(d => d.clinic.id), (dto) => {
                this.setState({
                    updateProfileModalVisible: true,
                    specialityList: dtoSpeciality,
                });
            });
        });
    }


    renderUpdateProfileModal = () => {
        return this.state.updateProfileModalVisible && (<Modal visible={this.state.updateProfileModalVisible}
                                                               header="Update Profile"
                                                               submitContent="Update"
                                                               submitAction={this.handleProfileUpdate}
                                                               closeAction={() => this.setState({updateProfileModalVisible: false})}>
            {this.updateProfileModalBody()}
        </Modal>);

    }


    updateProfileModalBody = () => {
        return (
            <div style={{minWidth:"450px"}}>
                <FormBase>
                    <FormSection sectionId={1} label="Gender">
                        <SelectInput value={this.state.newGender} options={EnumGender.toArray()}
                                     displayProperty="name" valueProperty="key" searchEnabled={false}
                                     onChange={(val) => {
                                         this.setState({newGender: val})
                                     }}
                        />
                    </FormSection>
                    <FormSection sectionId={1} label="Date of Birth">
                        <DateInput value={this.state.newBirthday} disableFuture="true"
                                   onChange={(val) => this.setState({newBirthday: val})}>
                        </DateInput>
                    </FormSection>
                    <FormSection sectionId={1} label="Speciality">
                        <SelectInput value={this.state.newSpeciality.id} options={this.state.specialityList}
                                     displayProperty="name" valueProperty="id" searchEnabled={false} showClear={false}
                                     onChange={this.handleSpecialitySelected}
                        />
                    </FormSection>
                    <FormSection sectionId={1} label="Nationality">
                        <SelectInput value={this.state.newNationality}
                                     options={this.props.nationalities}
                                     displayProperty={"nameInEnglish"}
                                     onChange={(val) => this.setState({newNationality: val})}
                        />
                    </FormSection>
                    <FormSection sectionId={1} label="Languages">
                        <SelectInput value={this.state.newLanguages.map(l => l.name)}
                                     options={EnumLanguage.toArray()}
                                     displayProperty="name" valueProperty="key" searchEnabled={false} multiSelect
                                     onChange={(val) => {
                                         this.setState({newLanguages: val.map(element => ({'name': element}))})
                                     }}
                        />
                    </FormSection>
                    {this.props.editableByAdmin && <FormSection sectionId={1} label="Joining Date">
                        <PrimeDateInput value={this.state.newJoiningDate}
                                   onChange={(val) => this.setState({newJoiningDate: val, newLeaveDate: null})}>
                        </PrimeDateInput>
                    </FormSection>}
                    {this.props.editableByAdmin && <FormSection sectionId={1} label="Leave Date">
                        <PrimeDateInput clearable value={this.state.newLeaveDate}
                                        minDate={moment(this.state.newJoiningDate).toDate()}
                                        onChange={(val) => this.setState({newLeaveDate: val})}>
                        </PrimeDateInput>
                    </FormSection>}
                    <FormSection sectionId={1} label="Salutation">
                        <SelectInput value={this.state.newSalutation}
                                     options={EnumSalutation.toArray()}
                                     displayProperty="name" valueProperty="key" searchEnabled={false}
                                     onChange={(value) => {
                                         this.setState({newSalutation: value})
                                     }}
                        />
                    </FormSection>
                    <FormSection sectionId={1} label="Qualification">
                        <TextInput style={{width: '100%'}} value={this.state.newQualification}
                                       onChange={(val) => this.setState({newQualification: val})}/>
                    </FormSection>
                </FormBase>
            </div>);
    }

    handleSpecialitySelected = (val) => {
        let speciality = this.state.specialityList.find(e => e.id === val);
        this.setState({newSpeciality: speciality, speciality: speciality});
    };

    handleProfileUpdate = () => {
        const staffDTO = {
            id: this.state.id,
            gender: this.state.newGender,
            birthday: this.state.newBirthday,
            speciality: this.state.newSpeciality,
            nationality: this.state.newNationality,
            languages: this.state.newLanguages,
            joiningDate: this.state.newJoiningDate,
            leaveDate: this.state.newLeaveDate,
            salutation: this.state.newSalutation,
            qualification: this.state.newQualification
        };
        RemotingService.postRemoteCall('api/staff/update', staffDTO, () => {
            this.setState({
                updateProfileModalVisible: false,
                gender: this.state.newGender,
                birthday: this.state.newBirthday,
                speciality: this.state.newSpeciality,
                nationality: this.state.newNationality,
                languages: this.state.newLanguages,
                joiningDate: this.state.newJoiningDate,
                leaveDate: this.state.newLeaveDate,
                salutation: this.state.newSalutation,
                qualification: this.state.newQualification
            });
            this.props.onStaffProfileUpdate();
        });
    }


    renderUpdateContactsModal = () => {
        this.formFields = [];
        return this.state.updateContactsModalVisible ?
            (<Modal visible={this.state.updateContactsModalVisible}
                    header="Update Contacts"
                    id="contact-modal"
                    submitContent="Update"
                    submitAction={this.handleContactsUpdate}
                    closeAction={() => this.setState({updateContactsModalVisible: false})}>
                <div className={"dim14-dialog-body"}>
                    <FormBase>
                        <FormSection sectionId={1} label="Phone">
                            <PhoneInput value={this.state.newMobilePhone}
                                        container
                                        onChange={(value) => this.setState({newMobilePhone: value})}/>
                        </FormSection>
                        <FormSection sectionId={1} label="E-mail">
                            {this.state.email}
                            <Tooltip title="Contact administration if you need change your email address" style={{width: "fit-content", flexGrow: "unset"}}>
                                <FontAwesomeIcon icon="info-circle" className="mx-1" size="sm" color="blue"/>
                            </Tooltip>
                        </FormSection>
                        <FormSection sectionId={1} label="Address">
                            <TextareaInput style={{width: '100%'}} value={this.state.newAddress}
                                           onChange={(val) => this.setState({newAddress: val})}/>
                        </FormSection>
                    </FormBase>
                </div>
            </Modal>)
            : null;
    }

    handleContactsUpdate = () => {
        if (!ValidationUtil.isValid(this.formFields)) {
            ValidationUtil.showNotification();
            return;
        }

        const staffDTO = {
            id: this.state.id,
            mobilePhone: this.state.newMobilePhone,
            address: this.state.newAddress
        };
        RemotingService.postRemoteCall('api/staff/update', staffDTO, () => {
            this.setState({
                updateContactsModalVisible: false,
                mobilePhone: this.state.newMobilePhone,
                address: this.state.newAddress
            });
            this.props.onStaffProfileUpdate();
        });
    }

    renderUpdateLinksModal = () => {
        return this.state.updateLinksModalVisible ?
            (<Modal visible={this.state.updateLinksModalVisible}
                    header="Update Links"
                    submitContent="Update"
                    submitAction={this.handleLinksUpdate}
                    closeAction={() => this.setState({updateLinksModalVisible: false})}>
                {this.updateLinksModalBody()}
            </Modal>)
            : null;
    }

    updateLinksModalBody = () => {
        return (
            <div className={"dim14-dialog-body"} style={{marginLeft: '10px', width: '100%'}}>
                <FormBase>
                    <FormSection sectionId={1} label="Instagram">
                        <TextInput value={this.state.newInstagramLink}
                                   onChange={(val) => this.setState({newInstagramLink: val})}/>
                    </FormSection>
                    <FormSection sectionId={1} label="Facebook">
                        <TextInput value={this.state.newFacebookLink}
                                   onChange={(val) => this.setState({newFacebookLink: val})}/>
                    </FormSection>
                    <FormSection sectionId={1} label="Linkedin">
                        <TextInput value={this.state.newLinkedinLink}
                                   onChange={(val) => this.setState({newLinkedinLink: val})}/>
                    </FormSection>
                    <FormSection sectionId={1} label="Twitter">
                        <TextInput value={this.state.newTwitterLink}
                                   onChange={(val) => this.setState({newTwitterLink: val})}/>
                    </FormSection>
                </FormBase>
            </div>
        );
    }

    handleLinksUpdate = () => {
        const staffDTO = {
            id: this.state.id,
            instagramLink: this.state.newInstagramLink,
            facebookLink: this.state.newFacebookLink,
            linkedinLink: this.state.newLinkedinLink,
            twitterLink: this.state.newTwitterLink
        };
        RemotingService.postRemoteCall('api/staff/update', staffDTO, () => {
            this.setState({
                updateLinksModalVisible: false,
                instagramLink: this.state.newInstagramLink,
                facebookLink: this.state.facebookLink,
                linkedinLink: this.state.newLinkedinLink,
                twitterLink: this.state.newTwitterLink
            });
        });
    }

    renderUpdateLicenceModal = () => {
        return this.state.updateLicenceModalVisible && (<Modal visible={this.state.updateLicenceModalVisible}
                                                               header="Update Licence"
                                                               submitContent="Update"
                                                               submitAction={this.handleLicenceUpdate}
                                                               closeAction={() => this.setState({updateLicenceModalVisible: false})}>
            {this.updateLicenceModalBody()}
        </Modal>);

    }
    updateLicenceModalBody = () => {
        return (
            <div className={"dim14-dialog-body"} style={{marginLeft: '10px', width: '100%'}}>
                <FormBase>
                    <FormSection labelPercent={2} inputPercent={3} sectionId={1} label="Clinician License Number">
                        <TextInput value={this.state.newClinicianLicense}
                                   onChange={(val) => this.setState({newClinicianLicense: val})}/>
                    </FormSection>
                    <FormSection labelPercent={2} inputPercent={3} sectionId={1} label="Issue Date">
                        <DateInput value={this.state.newIssueDate}
                                   onChange={(val) => this.setState({newIssueDate: val})}/>
                    </FormSection>
                    <FormSection labelPercent={2} inputPercent={3} sectionId={1} label="Expiry Date">
                        <DateInput value={this.state.newExpiryDate}
                                   onChange={(val) => this.setState({newExpiryDate: val})}>
                        </DateInput>
                    </FormSection>
                </FormBase>
            </div>
        );
    }
    handleLicenceUpdate = () => {
        // do Update Opps
        const staffDTO = {
            id: this.state.id,
            clinicianLicense: this.state.newClinicianLicense,
            licenceIssueDate: this.state.newIssueDate,
            licenceExpiryDate: this.state.newExpiryDate
        };
        RemotingService.postRemoteCall('api/staff/update', staffDTO, () => {
            this.setState({
                updateLicenceModalVisible: false,
                clinicianLicense: this.state.newClinicianLicense,
                licenceIssueDate: this.state.newIssueDate,
                licenceExpiryDate: this.state.newExpiryDate
            });
            this.props.onStaffProfileUpdate();
        });
    }
}

const IconValuePair = (props) => {
    return (
        <div key={props.name} className="form-group row" style={{marginBottom: '0rem'}}>
            <div className="col" style={{flex: '0 0 24px', display: 'flex', alignItems: 'center'}}>
                <FontAwesomeIcon icon={["fas", props.iconName]}/>
            </div>
            <div className="col">
                <label className="form-control-plaintext">
                    {props.val}
                </label>
            </div>
        </div>);
};

export default StaffProfileLeftPart;