import React from 'react';
import EnumAppointmentType from "../../../appointment/enums/EnumAppointmentType";


export class StatusInfo extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let list = [];

        for (let pair of this.props.patientTypeMap) {
            let [key, value] = pair;
            let obj = {
                "type": EnumAppointmentType[key].name,
                "count": value,
                "color": EnumAppointmentType[key].bgColor
            };
            list.push(obj);
        }
        list.sort((a,b) => b["count"] - a["count"]);
        return (
            <div style={{width: "inherit"}}>
                    {list.map(function(d, idx){
                        return (
                                <div key={idx} className="row" style={{height: "auto"}}>
                                    <div className="col-2" style={{color: `${d["color"]}`}}>
                                        {d["count"]}
                                    </div>
                                    <div className="col-9">
                                        {d["type"]}
                                    </div>
                                </div>
                        )
                    })}
            </div>
        );
    }


}