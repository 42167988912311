import React, {Component} from 'react'
import PageTitle from "../../layout/PageTitle";
import DateUtil from "../../utils/DateUtil";
import BillingService from "../../services/billing/BillingService";
import RemotingService from "../../services/remoting-service/RemotingService";
import {Column} from "primereact/column";
import EnumBillTypeShort from "../patient/enums/EnumBillTypeShort";
import {DataTable} from "primereact/datatable";
import moment from "moment";
import {Link} from "react-router-dom";
import './Billing.css'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import {Button} from "react-bootstrap";
import FileUtil from "../../utils/FileUtil";
import {DateInput, TextInput} from "../../components";
import ActionMenu from "../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../components/action-menu/ActionMenuItem";
import CancelIcon from "../../components/action-menu/icons/CancelIcon";
import ConfirmIcon from "../../components/action-menu/icons/ConfirmIcon";
import confirmDialog from "../../components/dialog/ConfirmDialog";
import ExpansionPanel from "../../components/expansion-panel/CustomExpansionPanel";
import DelayedTextInput from "../../components/text-input/DelayedTextInput";
import EnumInsurancePaymentStatus from "./enums/EnumInsurancePaymentStatus";
import AuthService from "../../services/auth-service/AuthService";
import ViewIcon from "../../components/action-menu/icons/ViewIcon";
import AttachmentUtils from "../emr/recordpage/treatment/utils/AttachmentUtils";
import {downloadBlob} from "../../utils/FileDownloader";
import PrintIcon from "../../components/action-menu/icons/PrintIcon";
import DownloadIcon from "../../components/action-menu/icons/DownloadIcon";
import {FormField} from "../../components/form";
import NumberUtils from "../../utils/NumberUtil";
import MoneyUtil from "../../utils/MoneyUtil";

const CASHIER_ID = 0;

export default class Billing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            staffs: [],
            revenuePerProviderStaffs: [],
            revenuePerProviderStartDate: new Date(),
            revenuePerProviderEndDate: new Date(),
            discWalletTrackerStartDate: new Date(),
            discWalletTrackerEndDate: new Date(),
            discWalletUsageStartDate: new Date(),
            discWalletUsageEndDate: new Date(),
            insuranceFollowUpStartDate: new Date(),
            insuranceFollowUpEndDate: new Date(),
            unpaidPaymentTrackerStartDate: new Date(),
            unpaidPaymentTrackerEndDate: new Date(),
            selectedStartDate: new Date(),
            selectedEndDate: new Date(),
        };
    }

    componentDidMount() {
        this.retrieveStaffs();
    }

    retrieveStaffs() {
        RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
            let staffs = result.items;
            staffs.map(s => {
                s.label = s.name;
                s.value = s.id;
            });

            let revenuePerProviderStaffs = [{label: "Cashier", value: CASHIER_ID}, ...staffs];

            this.setState({staffs,revenuePerProviderStaffs}, () => {
                this.retrieveItems();
            });
        });
    }

    retrieveItems() {
        RemotingService.getRemoteCall('api/billing/item', null, (items) => {
            this.setState({items}, () => {
                this.retrieveInvoices();
                this.retrievePendingOnlinePaymentInvoices();
                this.retrievePendingInsurancePaymentInvoices();
            });
        });
    }

    retrieveInvoices() {
        RemotingService.getRemoteCall('api/billing/invoice/list',
            {
                startDate: moment(this.state.selectedStartDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.selectedEndDate).format('YYYY-MM-DD'),
                notStatus: ["PENDING_ONLINE_PAYMENT"]
            },
            (result) => {
                result.items.map(i => BillingService.hydrateInvoice(i, this.state.items, this.state.staffs));
                this.setState({invoices: result.items});
            });
    }

    retrievePendingOnlinePaymentInvoices() {
        RemotingService.getRemoteCall('api/billing/invoice/list',
            {
                startDate: moment(this.state.selectedStartDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.selectedEndDate).format('YYYY-MM-DD'),
                status: ["PENDING_ONLINE_PAYMENT"]
            },
            (result) => {
                result.items.map(i => BillingService.hydrateInvoice(i, this.state.items, this.state.staffs));
                this.setState({onlinePaymentPendingInvoices: result.items});
            });
    }

    retrievePendingInsurancePaymentInvoices() {
        RemotingService.getRemoteCall('api/billing/invoice/list',
            {
                startDate: moment(this.state.selectedStartDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.selectedEndDate).format('YYYY-MM-DD'),
                insurancePaymentStatus: ["PENDING", "RESUBMITTED"]
            },
            (result) => {
                result.items.map(i => BillingService.hydrateInvoice(i, this.state.items, this.state.staffs));
                this.setState({insurancePaymentPendingInvoices: result.items});
            });
    }

    handleDateStateChange = () => {
        this.retrieveInvoices();
        this.retrievePendingOnlinePaymentInvoices();
        this.retrievePendingInsurancePaymentInvoices();
    }

    createRevenuePerProviderReport = () => {
        const params = {
            providerId: this.state.revenuePerProviderStaffId,
            start: this.state.revenuePerProviderStartDate,
            end: this.state.revenuePerProviderEndDate,
        };

        RemotingService.postRemoteCall('api/billing/report/revenue-per-provider', params, (reportFile) => {
            FileUtil.saveReportAs('revenue-per-provider', reportFile);
        });
    }

    createDiscWalletTrackerReport = () => {
        const params = {
            start: this.state.discWalletTrackerStartDate,
            end: this.state.discWalletTrackerEndDate,
        };

        RemotingService.postRemoteCall('api/billing/report/disc-wallet-tracker', params, (reportFile) => {
            FileUtil.saveReportAs('disc-wallet-tracker', reportFile);
        });
    }

    createDiscWalletUsageReport = () => {
        const params = {
            start: this.state.discWalletUsageStartDate,
            end: this.state.discWalletUsageEndDate,
        };

        RemotingService.postRemoteCall('api/billing/report/disc-wallet-usage', params, (reportFile) => {
            FileUtil.saveReportAs('disc-wallet-usage', reportFile);
        });
    }

    createInsuranceFollowUpReport = () => {
        const params = {
            start: this.state.insuranceFollowUpStartDate,
            end: this.state.insuranceFollowUpEndDate,
        };

        RemotingService.postRemoteCall('api/billing/report/insurance-follow-up', params, (reportFile) => {
            FileUtil.saveReportAs('insurance-follow-up', reportFile);
        });
    }

    createUnpaidPaymentTrackerReport = () => {
        const params = {
            providerId: this.state.unpaidPaymentTrackerStaffId,
            start: this.state.unpaidPaymentTrackerStartDate,
            end: this.state.unpaidPaymentTrackerEndDate,
        };

        RemotingService.postRemoteCall('api/billing/report/unpaid-payment-tracker', params, (reportFile) => {
            FileUtil.saveReportAs('unpaid-payment-tracker', reportFile);
        });
    }

    invoiceColumns = () => {
        return [
            <Column expander={true} style={{width: '20px'}}/>,
            <Column style={{width: '75px'}} header="Clinic" field="clinic.name" sortable/>,
            <Column style={{width: '115px'}} header="Invoice Number" field="id" sortable/>,
            <Column style={{width: '100px'}} header="Invoice Date" field="invoiceDate" sortable
                    body={(invoice) => DateUtil.formatDateMMM(invoice.invoiceDate)}/>,
            <Column style={{width: '75px'}} header="Bill Type" field="billType" body={(invoice) => EnumBillTypeShort[invoice.billType]}
                    sortable/>,
            <Column style={{width: '105px'}} header="MRN Number" field="patientMrn" sortable/>,
            <Column style={{width: '105px'}} header="Patient Name" field="patientName" body={(invoice) =>
                <Link to={{
                    pathname: '/patientdetail/' + invoice.patientId,
                    selectedView: 'billing'
                }}>{invoice.patientName}</Link>} sortable/>,
            <Column header="Provider" field="providerName" sortable/>,
            <Column header="Referred By" field="referrer" sortable/>,
            <Column style={{width: '165px'}} className="text-break" header="Insurance Approval Code" field="insuranceApprovalCode" sortable/>,
            <Column style={{width: '75px'}}header="Bill Status" field="status" body={BillingService.billStatusTemplate}
                    className="text-center"/>,
            <Column style={{width: '105px'}} header="Total Amount" field="total" body={BillingService.moneyTemplate("net")} sortable/>,
            <Column style={{width: '115px'}} header="Patient Payable" field="patientPayable" body={BillingService.moneyTemplate("patientPayable")} sortable/>,
            <Column header="Payment" field="paymentType" style={{width: 125}}/>,
            <Column header="Notes" field="notes"/>
        ]
    }

    confirmInvoicePayment = (invoice) => {
        confirmDialog("Confirm Invoice Payment",
            "You are about to confirm payment for this invoice. Are you sure?",
            () => {
                RemotingService.postRemoteCall(
                    `api/billing/invoice/${invoice.id}/confirm-payment`,
                    null,
                    (invoice) => {
                        this.retrieveInvoices();
                        this.retrievePendingOnlinePaymentInvoices();
                    });
            });
    }

    cancelInvoice = (invoice) => {
        confirmDialog("Cancel Invoice Payment",
            "You are about to cancel a payment pending invoice. Are you sure?",
            () => {
                RemotingService.postRemoteCall(
                    `api/billing/invoice/${invoice.id}/cancel?reason=${this.state.cancelReason}&detail=${this.state.cancelDetail}`,
                    null,
                    (invoice) => {
                        this.retrieveInvoices();
                        this.retrievePendingOnlinePaymentInvoices();
                    });
            });
    }

    updatePaymentInvoice = (invoice, field, value) => {
        const {creditReceived, insurancePaymentStatus} = invoice;
        const updateDto = {creditReceived, insurancePaymentStatus};
        updateDto[field] = value;

        RemotingService.postRemoteCall(
            `api/billing/invoice/${invoice.id}/insurance-payment-update`,
            updateDto,
            (invoice) => {
                this.retrieveInvoices();
                this.retrievePendingInsurancePaymentInvoices();
            });
    }

    renderTitle = (title) => {
        return <PageTitle title={title} className="py-3 pl-4 mx-0 mb-1" labelClassName="font-weight-bold"/>
    }

    downloadInvoicePdf(invoice) {
        RemotingService.instance.get('api/file/download/' + invoice.pdfPath, {responseType: 'blob'})
            .then((response) => {
                downloadBlob(new Blob([response.data]), this.getInvoiceFileName(invoice));
            });
    }

    getInvoiceFileName(invoice) {
        return 'invoice_' + invoice.id + '_' + DateUtil.formatDateTimeSeparatelyForFileStamp(new Date()) + '.pdf';
    }
    viewInvoicePdf(invoice) {
        RemotingService.instance.get('api/file/download/' + invoice.pdfPath, {responseType: 'blob'})
            .then((response) => {
                AttachmentUtils.viewFile({
                    fileName: this.getInvoiceFileName(invoice), blob: response.data, id: "invoiceViewer",
                    windowWidth: "1200x", windowHeight: "800px"
                });
            });
    }

    printInvoicePdf(invoice) {
        RemotingService.instance.get('api/file/download/' + invoice.pdfPath, {responseType: 'blob'})
            .then((response) => {
                AttachmentUtils.viewFile({
                    fileName: this.getInvoiceFileName(invoice), blob: response.data, id: "invoiceViewer",
                    windowWidth: "1200x", windowHeight: "800px"
                });
            });
    }

    handleSelectedStartDateChange(date) {
        DateUtil.isAfter(date, this.state.selectedEndDate) ?
            this.setState({selectedStartDate: date, selectedEndDate: date}, () => this.handleDateStateChange()):
            this.setState({selectedStartDate: date}, () => this.handleDateStateChange());
    }

    handleSelectedEndDateChange(date) {
        DateUtil.isBefore(date, this.state.selectedStartDate) ?
            this.setState({selectedEndDate: this.state.selectedStartDate}, () => this.handleDateStateChange()):
            this.setState({selectedEndDate: date}, () => this.handleDateStateChange());
    }

    preparePageTitle() {
        return `${DateUtil.formatDateDo(this.state.selectedStartDate)} - ${DateUtil.formatDateDo(this.state.selectedEndDate)}`;
    }

    textInputTemplate = (invoiceItem, fieldName) => {
        return (invoiceItem[fieldName] === undefined || invoiceItem[fieldName] === null) ? 0 :
                NumberUtils.toFixed(invoiceItem[fieldName], MoneyUtil.getFractionDigitsWithCountry(invoiceItem["clinic"].country))
    }

    render() {
        return (
            <>
                <div style={{padding: '15px 15px 5px 15px'}}
                     className="data-table-header-wrapper"> {/*Remove this class and date navigation won't work.}*/}
                    <div className="float-left">
                        <PageTitle title={this.preparePageTitle()} className="bg-white"/>
                    </div>
                    <div className="float-right">
                        <div className={"row"}>
                            <div style={{margin: '10px'}}>
                                <label className={"mr-2"}>Start Date:</label>
                                <DateInput value={this.state.selectedStartDate}
                                           onChange={(date) => this.handleSelectedStartDateChange(date)}/>
                            </div>
                            <div style={{margin: '10px'}}>
                                <label className={"mr-2"}>End Date:</label>
                                <DateInput value={this.state.selectedEndDate}
                                           onChange={(date) => this.handleSelectedEndDateChange(date)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{padding: '15px'}}>
                    {this.renderTitle("All Invoices")}
                    <DataTable className="InvoiceTable" responsive paginator={true} rows={10}
                               emptyMessage={"No results found"}
                               value={this.state.invoices}
                               expandedRows={this.state.expandedInvoices}
                               rowExpansionTemplate={(invoice) => BillingService.invoiceItemsExpansionTemplate(invoice, true, this.textInputTemplate)}
                               onRowToggle={(e) => {
                                   this.setState({
                                       ...this.state,
                                       expandedInvoices: e.data,
                                   })
                               }}
                               rowClassName={BillingService.disabledRowClassFunction}
                               dataKey="id">
                        {this.invoiceColumns()}
                        <Column style={{width: '65px'}} header="Credit" field="credit" body={BillingService.moneyTemplate("credit")} sortable/>
                        <Column style={{width: '115px'}} header="Credit Received" field="creditReceived" body={BillingService.moneyTemplate("creditReceived")} sortable/>
                        <Column style={{width: '145px'}} header="Reconciliation Status" field="insurancePaymentStatus"
                                body={BillingService.insurancePaymentStatusTemplate} sortable/>
                        <Column style={{width: '50px'}} header="Actions" body={(invoice) => {
                            return invoice.pdfPath ?
                                <ActionMenu id="invoiceActionPanel">
                                    <ActionMenuItem
                                        icon={<ViewIcon/>}
                                        onClick={() => {
                                            this.viewInvoicePdf(invoice)
                                        }} text={"View"}/>
                                    <ActionMenuItem
                                        icon={<PrintIcon/>}
                                        onClick={() => {
                                            this.printInvoicePdf(invoice)
                                        }} text={"Print"}/>
                                    <ActionMenuItem
                                        icon={<DownloadIcon/>}
                                        onClick={() => {
                                            this.downloadInvoicePdf(invoice)
                                        }} text={"Download"}/>
                                </ActionMenu>: ""
                        }}/>
                    </DataTable>
                </div>
                <div style={{padding: '15px'}}>
                    {this.renderTitle("Invoices Waiting for Online Payment")}
                    <DataTable className="InvoiceTable" responsive paginator={true} rows={10}
                               emptyMessage={"No results found"}
                               value={this.state.onlinePaymentPendingInvoices}
                               expandedRows={this.state.expandedPendingInvoices}
                               rowExpansionTemplate={(invoice) => BillingService.invoiceItemsExpansionTemplate(invoice, true, this.textInputTemplate)}
                               onRowToggle={(e) => {
                                   this.setState({
                                       ...this.state,
                                       expandedPendingInvoices: e.data,
                                   })
                               }}
                               rowClassName={BillingService.disabledRowClassFunction}
                               dataKey="id">
                        {this.invoiceColumns()}
                        <Column header="" body={(invoice) => {
                            return (<ActionMenu id="pendingInvoiceActionPanel">
                                <ActionMenuItem
                                    icon={<ConfirmIcon/>}
                                    onClick={() => {
                                        this.confirmInvoicePayment(invoice)
                                    }} text={"Confirm Payment"}/>
                                <ActionMenuItem
                                    icon={<CancelIcon/>}
                                    onClick={() => {
                                        this.cancelInvoice(invoice)
                                    }} text={"Cancel"}/>
                            </ActionMenu>)
                        }}/>
                    </DataTable>
                </div>
                <div style={{padding: '15px'}}>
                    {this.renderTitle("Invoices Waiting for Insurance Payment")}
                    <DataTable className="InvoiceTable" responsive paginator={true} rows={10}
                               emptyMessage={"No results found"}
                               value={this.state.insurancePaymentPendingInvoices}
                               expandedRows={this.state.expandedInsurancePendingInvoices}
                               rowExpansionTemplate={(invoice) => BillingService.invoiceItemsExpansionTemplate(invoice, true, this.textInputTemplate)}
                               onRowToggle={(e) => {
                                   this.setState({
                                       ...this.state,
                                       expandedInsurancePendingInvoices: e.data,
                                   })
                               }}
                               rowClassName={BillingService.disabledRowClassFunction}
                               dataKey="id">
                        {this.invoiceColumns()}
                        <Column style={{width: '65px'}}  header="Credit" field="credit" body={BillingService.moneyTemplate("credit")} sortable/>
                        <Column  style={{width: '115px'}} header="Credit Received" field="creditReceived" sortable
                                body={(invoice) =>
                                    <DelayedTextInput style={{width: '75px'}}
                                                      value={invoice.creditReceived}
                                                      onChange={(value) => this.updatePaymentInvoice(invoice, 'creditReceived', value)}/>}
                        />
                        <Column style={{width: '115px'}}  header="Reconciliation Status" field="insurancePaymentStatus"
                                body={(invoice) =>
                                    <PrimeDropdown optionLabel="name" optionValue="key"
                                                   options={EnumInsurancePaymentStatus.toArray()}
                                                   value={invoice.insurancePaymentStatus}
                                                   onChange={event => this.updatePaymentInvoice(invoice, 'insurancePaymentStatus', event.value)}
                                                   style={{width: '100%'}}/>} sortable/>
                    </DataTable>
                </div>
                {AuthService.userHasAuthority("VIEW_BILLING_REPORT") &&
                <div style={{padding: '15px'}}>
                    {this.renderTitle("Reports")}
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            Revenue Per Provider Report
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="d-flex justify-content-between align-items-center">
                            <div className={"col"}>
                                <label className={"mr-2"}> Provider:</label>

                                <PrimeDropdown options={this.state.revenuePerProviderStaffs} value={this.state.revenuePerProviderStaffId}
                                               style={{width: '250px'}}
                                               onChange={(e) => {
                                                   this.setState({revenuePerProviderStaffId: e.target.value})
                                               }}/>
                            </div>

                            <div className={"col"}>
                                <label className={"mr-2"}>Invoice Date Start:</label>

                                <DateInput value={this.state.revenuePerProviderStartDate}
                                           onChange={date => {
                                               this.setState({revenuePerProviderStartDate: date})
                                           }}/>
                            </div>
                            <div className={"col"}>
                                <label className={"mr-2"}> Invoice Date End:</label>

                                <DateInput value={this.state.revenuePerProviderEndDate}
                                           onChange={date => {
                                               this.setState({revenuePerProviderEndDate: date})
                                           }}/>
                            </div>

                            <Button onClick={() => this.createRevenuePerProviderReport()}>
                                Download As XLS
                            </Button>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            DISC Wallet Report
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="d-flex justify-content-between align-items-center">

                            <div className={"col"}>
                                <label className={"mr-2"}>Start Date:</label>

                                <DateInput value={this.state.discWalletTrackerStartDate}
                                           onChange={date => {
                                               this.setState({discWalletTrackerStartDate: date})
                                           }}/>
                                <label className={"mr-2 ml-2"}>End Date:</label>

                                <DateInput value={this.state.discWalletTrackerEndDate}
                                           onChange={date => {
                                               this.setState({discWalletTrackerEndDate: date})
                                           }}/>
                            </div>


                            <Button onClick={() => this.createDiscWalletTrackerReport()}>
                                Download As XLS
                            </Button>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            DISC Wallet Usage Report
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="d-flex justify-content-between align-items-center">
                            <div className={"col"}>
                                <label className={"mr-2"}> Start Date:</label>

                                <DateInput value={this.state.discWalletUsageStartDate}
                                           onChange={date => {
                                               this.setState({discWalletUsageStartDate: date})
                                           }}/>
                                <label className={"mr-2 ml-2"}> End Date:</label>

                                <DateInput value={this.state.discWalletUsageEndDate}
                                           onChange={date => {
                                               this.setState({discWalletUsageEndDate: date})
                                           }}/>

                            </div>

                            <Button onClick={() => this.createDiscWalletUsageReport()}>
                                Download As XLS
                            </Button>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            Insurance Follow Up Report
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="d-flex justify-content-between align-items-center">
                            <div className={"col"}>
                                <label className={"mr-2"}> Start Date:</label>
                                <DateInput value={this.state.insuranceFollowUpStartDate}
                                           onChange={date => {
                                               this.setState({insuranceFollowUpStartDate: date})
                                           }}/>
                                <label className={"mr-2 ml-2"}>End Date:</label>

                                <DateInput value={this.state.insuranceFollowUpEndDate}
                                           onChange={date => {
                                               this.setState({insuranceFollowUpEndDate: date})
                                           }}/>
                            </div>
                            <Button onClick={() => this.createInsuranceFollowUpReport()}>
                                Download As XLS
                            </Button>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            Unpaid Payment Tracker Report
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="d-flex justify-content-between align-items-center">
                            <div className={"col"}>
                                <label className={"mr-2"}>Provider:</label>
                                <PrimeDropdown options={this.state.staffs}
                                               value={this.state.unpaidPaymentTrackerStaffId}

                                               style={{width: '250px'}}
                                               onChange={(e) => {
                                                   this.setState({unpaidPaymentTrackerStaffId: e.target.value})
                                               }}/>
                            </div>
                            <div className={"col"}>
                                <label className={"mr-2"}>Invoice Date Start:</label>
                                <DateInput value={this.state.unpaidPaymentTrackerStartDate}
                                           defaultValue="Normal"
                                           onChange={date => {
                                               this.setState({unpaidPaymentTrackerStartDate: date})
                                           }}/>
                            </div>
                            <div className={"col"}>
                                <label className={"mr-2"}>Invoice Date End: </label>
                                <DateInput value={this.state.unpaidPaymentTrackerEndDate}

                                           onChange={date => {
                                               this.setState({unpaidPaymentTrackerEndDate: date})
                                           }}/>
                            </div>
                            <Button onClick={() => this.createUnpaidPaymentTrackerReport()}>
                                Download As XLS
                            </Button>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                }
            </>
        )
    }

}
