import React, { Component } from "react";
import './BorderedRadioInput.css';

export default class BorderedRadioInput extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const items = []

        this.props.values.map((enumObject, idx) => {
            if(this.props.selected == enumObject.key) {
                items.push(
                    <div className={"bordered-radiobutton selected"}
                         style = {this.props.itemStyle}
                         key={idx}
                         onClick={()=>
                         {
                             this.props.onSelect(null);
                             this.forceUpdate();
                         }}>
                        {enumObject.name}
                    </div>);
            }else {
                items.push(
                    <div key={enumObject.key}
                         className={"bordered-radiobutton"}
                         style = {this.props.itemStyle}
                         key={idx}
                         onClick={()=> {
                             this.props.onSelect(enumObject.key);
                             this.forceUpdate();
                         }}>
                        {enumObject.name}
                    </div>)
            }
        });

        return (<div className={"disc-radiobutton-container"}>
                {items}
            </div>);
    }
}
