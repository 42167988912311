import React, {Component} from "react";
import '../../EMR.css';
import TypeEnum from "../enums/result/TypeEnum";
import DateUtil from "../../../../../utils/DateUtil";
import CommunicationMethod from "../../../../../components/radio-input/CommunicationMethod";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ActionMenu from "../../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../../../../components/action-menu/icons/ViewIcon";
import AttachmentUtils from "../utils/AttachmentUtils";
import DeleteIcon from "../../../../../components/action-menu/icons/DeleteIcon";
import AuthService from "../../../../../services/auth-service/AuthService";
import ReduxService from "../../../../../services/redux-service/ReduxService";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import Modal from "../../../../../components/modal/Modal";

export default class ResultView extends Component {

    state = {
        viewerVisible: false,
        viewerHeader: null,
        blobUrl: null
    }

    render() {
        if(this.props.results == null
            || this.props.results.length == 0) {
            return null;
        }

        return (
            <div>
                <div className={"content-card"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th>Type</th>
                            <th>Details</th>
                            <th>Findings</th>
                            <th>Remarks</th>
                            <th>Date Ordered</th>
                            <th>Ordered By</th>
                            <th>Result Received</th>
                            <th>Patient Informed</th>
                            <th>Attach</th>
                        </tr>
                        {this.props.results.map((m, index) => {
                            return (<tr key={"resultView" + index} style={{backgroundColor: "white"}}>
                                <td>{m.type == null ? "" : TypeEnum[m.type].name}</td>
                                <td>{m.detail}</td>
                                <td>{m.findings}</td>
                                <td>{m.remarks}</td>
                                <td>{m.dateOrdered == null ? "" : DateUtil.formatDateMMM(m.dateOrdered)}</td>
                                <td>{m.orderedBy}</td>
                                <td>{m.resultReceived == null ? "" : DateUtil.formatDateMMM(m.resultReceived)}</td>
                                <td>
                                    <CommunicationMethod value={m.patientInformed}
                                                         informedDate={m.informedDate}
                                                         disabled={true}/>
                                </td>
                                <td style={{maxWidth: 180, padding:"5px 0px"}}>
                                    {this.attachmentTable(m.files)}
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
                {this.state.viewerVisible && this.renderAttachmentModal()}
            </div>
        );
    }

    downloadOrView = (file) => {
        if(AuthService.isAdminOrManager()){
            if (AttachmentUtils.viewEnable(file.name)) {
                return this.view(file);
            }
            return AttachmentUtils.download(file);
        }
    }

    view = (file) => {
        ReduxService.incrementRemotingOperationCount();

        RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
            .then((response) => {
                let blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                this.setState({
                    viewerVisible: true,
                    viewerHeader: file.name,
                    blobUrl: blobUrl
                });
            })
            .finally(() => {
                ReduxService.decrementRemotingOperationCount();
            });
    }

    attachmentTable = (files) => {
        if(files == null || files.length == 0) {
            return null;
        }
        return (
            <div>
                <FontAwesomeIcon icon={["fas", "paperclip"]} size={"lg"} style={{marginLeft: '5px'}}/>
                <table className={"attachment-list"}>
                    <tbody>
                    {files.map((f) => {
                        return (
                            <tr>
                                <td className={"link-column"}>
                                   <span onClick={() => this.downloadOrView(f)}>{f.name} </span>
                                </td>
                                <td>
                                    <ActionMenu>
                                        <ActionMenuItem text="View" icon={<ViewIcon/>} onClick={() => this.view(f)}
                                                        disabled={!AttachmentUtils.viewEnable(f.name)}/>
                                        <ActionMenuItem text="Download"
                                                        icon={<FontAwesomeIcon icon={["fas", "file-download"]}/>}
                                                        onClick={() => AttachmentUtils.download(f)}/>
                                    </ActionMenu>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    renderAttachmentModal() {
        return(
            <Modal visible={true}
                   header={this.state.viewerHeader}
                   closeAction={() => this.setState({
                       viewerVisible: false
                   })}>
                <iframe id="documentViewer" src={this.state.blobUrl} width="800px" height="600px"></iframe>
            </Modal>
        );
    }
}